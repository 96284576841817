import { defineMessages } from "react-intl";

export const messages = defineMessages({
  viewCampaignLabel: {
    id: "campaignReporting.detailsPage.viewCampaignLabel",
    defaultMessage: "View Campaign",
    description: "View Campaign Label",
  },
  campaignsBreadcrumb: {
    id: "campaignReporting.detailsPage.campaignsBreadcrumb",
    defaultMessage: "Campaigns",
    description: "Campaigns Breadcrumb",
  },
  errorMessage: {
    id: "campaignReporting.detailsPage.errorMessage",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Error message",
  },
  jobsFilter: {
    id: "campaignReporting.detailsPage.filter.jobs",
    defaultMessage: "Job created",
    description: "What the email table is filtered by",
  },
  openedFilter: {
    id: "campaignReporting.detailsPage.filter.opened",
    defaultMessage: "Opened",
    description: "What the email table is filtered by",
  },
  clickedFilter: {
    id: "campaignReporting.detailsPage.filter.clicked",
    defaultMessage: "Clicked",
    description: "What the email table is filtered by",
  },
  unsubscribedFilter: {
    id: "campaignReporting.detailsPage.filter.unsubscribed",
    defaultMessage: "Unsubscribed",
    description: "What the email table is filtered by",
  },
  automationActiveStatusLabel: {
    id: "campaignReporting.detailsPage.status.automationActiveLabel",
    defaultMessage: "Active",
    description: "Automation Active Label",
  },
  automationInactiveStatusLabel: {
    id: "campaignReporting.detailsPage.status.automationInactiveLabel",
    defaultMessage: "Inactive",
    description: "Automation Inactive Label",
  },
});
