import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Markdown } from "@jobber/components/Markdown";
import { messages } from "./messages";
import { Disclaimer } from "../Disclaimer";

interface MobileBilledDisclaimerProps {
  billingPlatform: string | undefined;
}

export function MobileBilledDisclaimer({
  billingPlatform,
}: MobileBilledDisclaimerProps) {
  const { formatMessage } = useIntl();
  const formattedBillingPlatform =
    billingPlatform === "app_store"
      ? "Apple ID"
      : billingPlatform === "play_store"
        ? "Google Play account"
        : "External account";
  const content = (
    <Content>
      <Text>
        {formatMessage(messages.primaryDescription, {
          billingPlatform: formattedBillingPlatform,
        })}
      </Text>
      <Markdown
        content={formatMessage(messages.secondaryDescription, {
          link: "/accounts/billing_info/account_and_billing",
        })}
      />
    </Content>
  );
  return <Disclaimer content={content} />;
}
