import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { IntlProvider } from "@translations/IntlProvider";
import UpgradePagePromotionBanner from "@images/UpgradePagePromotionBanner.png";
import styles from "./PromotionBanner.module.css";
import { messages } from "./messages";

interface PromotionBannerProps {
  bannerPromotionInfo: string | null;
}

function PromotionBanner({ bannerPromotionInfo }: PromotionBannerProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.promotionBannerContainer}>
      <Content spacing={"small"}>
        <Heading level={2}>{bannerPromotionInfo}</Heading>
        <Text>{formatMessage(messages.bannerDescription)}</Text>
      </Content>
      <img
        src={UpgradePagePromotionBanner}
        alt={formatMessage(messages.bannerImageAltText)}
      />
    </div>
  );
}

function WrappedPromotionBanner({ bannerPromotionInfo }: PromotionBannerProps) {
  return (
    <IntlProvider>
      <PromotionBanner bannerPromotionInfo={bannerPromotionInfo} />
    </IntlProvider>
  );
}

export { WrappedPromotionBanner as PromotionBanner };
