import { defineMessages } from "react-intl";

export const messages = defineMessages({
  onlinePaymentSettingsTitle: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.title",
    defaultMessage: "{object} Payment Options",
    description: "Title for the online payments settings component",
  },

  onlinePaymentSettingsSecondaryText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.secondaryText",
    defaultMessage:
      "Disabling payment options on this {object} won't change your {link}",
    description: "Secondary Text for the online payments settings component",
  },

  onlinePaymentSettingsLinkText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.linkText",
    defaultMessage: "default payment preferences",
    description: "link Text for the online payments settings component",
  },

  onlinePaymentSettingsNotEnabledText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.notEnabledText",
    defaultMessage:
      "Client Hub {object} are currently disabled in {link} Turn {object} ON in settings to accept payments based on the settings above.",
    description:
      "Text for the online payments settings component notEnabled text",
  },

  onlinePaymentSettingsNotEnabledLinkText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.notEnabledLinkText",
    defaultMessage: "Jobber Payments Settings.",
    description:
      "link Text for the online payments settings component notEnabled text",
  },
});
