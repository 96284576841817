import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Divider } from "@jobber/components/Divider";
import { Icon } from "@jobber/components/Icon";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { APIProvider } from "~/utilities/API/APIProvider";
import styles from "./ReverseTrialPostTrialModal.module.css";

function FeatureListItem({
  text,
  isAvailable,
}: {
  text: string;
  isAvailable: boolean;
}) {
  return (
    <li className={styles.feature}>
      {isAvailable ? (
        <Icon name="checkmark" color="green" />
      ) : (
        <Icon name="cross" color="red" />
      )}
      <Text size="large">{text}</Text>
    </li>
  );
}

export function ReverseTrialPostTrialModal() {
  const [modalOpen, setModalOpen] = useState(true);
  const ctaName = "reverse_trial_post_trial_modal_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  useEffect(() => {
    if (modalOpen) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
      });
    }
  }, [modalOpen]);

  return (
    <APIProvider>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          title="Your 14-day Grow trial has expired"
          open={modalOpen}
          onRequestClose={closeModal}
          primaryAction={{
            label: "See Plan Prices",
            onClick: handleSeePlansClick,
          }}
          secondaryAction={{
            label: "Got It",
            onClick: closeModal,
            type: "secondary",
            variation: "work",
          }}
        >
          <Content>
            <Text size="large">
              You are now on the free <Emphasis variation="bold">Core</Emphasis>{" "}
              plan with a 5 item creation per month limit.
            </Text>
            <Text size="large">
              This plan will no longer have access to these Grow features:
            </Text>
            <Divider size="large" />
            <div className={styles.featureContainer}>
              <div className={styles.featureColumn}>
                <ul>
                  <FeatureListItem
                    text="Optional quote line items"
                    isAvailable={false}
                  />
                  <FeatureListItem
                    text="Automated quote follow-ups"
                    isAvailable={false}
                  />
                </ul>
              </div>
              <div className={styles.featureColumn}>
                <ul>
                  <FeatureListItem
                    text="Quote line item markups"
                    isAvailable={false}
                  />
                  <FeatureListItem
                    text="Quote line item images"
                    isAvailable={false}
                  />
                </ul>
              </div>
            </div>
            <Text size="large">Your free Core plan includes:</Text>
            <Divider size="large" />
            <div className={styles.featureContainer}>
              <div className={styles.featureColumn}>
                <ul>
                  <FeatureListItem text="Up to 1 user" isAvailable={true} />
                  <FeatureListItem
                    text="Client manager (CRM)"
                    isAvailable={true}
                  />
                  <FeatureListItem text="Quoting" isAvailable={true} />
                </ul>
              </div>
              <div className={styles.featureColumn}>
                <ul>
                  <FeatureListItem text="Invoicing" isAvailable={true} />
                  <FeatureListItem text="Scheduling" isAvailable={true} />
                  <FeatureListItem
                    text="Job details and attachments"
                    isAvailable={true}
                  />
                </ul>
              </div>
            </div>
            <Text size="large">
              Learn more about our plans and features in the pricing page.
            </Text>
          </Content>
        </Modal>
      </CallToAction>
    </APIProvider>
  );

  function handleSeePlansClick() {
    convertCTA(ctaRef)();
    navigateToPricing();
  }

  function navigateToPricing() {
    window.location.assign("/accounts/billing_info/pricing");
  }

  function closeModal() {
    dismissCTA(ctaRef)();
    setModalOpen(false);
  }
}
