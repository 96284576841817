import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useState } from "react";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { BankAccount, type BankAccountProps } from "./BankAccount";
import type { SuccessBank } from "./bankAccountReducer";

export interface BankDetailsProps {
  bank?: SuccessBank;
  bankAccountProps: BankAccountProps;
  payoutsErrorMessage?: string;
}

export function BankDetails(props: BankDetailsProps) {
  const { bank, bankAccountProps, payoutsErrorMessage } = props;
  const { payoutScheduleInDays } = useJobberPayments();
  const [learnMoreUrl] = useUrls("docsPayoutsUrl");

  const headingCopy = bank?.payoutsToJobberMoney
    ? "Hourly Payouts"
    : "Standard Payouts";
  const textCopy = bank?.payoutsToJobberMoney
    ? "Payouts arrive in your Jobber Money account in as little as one hour"
    : `Payouts are made on a ${
        bank?.payoutScheduleInDays ?? payoutScheduleInDays
      } business day schedule`;

  return (
    <Content>
      <Heading level={3}>{headingCopy}</Heading>
      {payoutsErrorMessage && (
        <Banner type="warning" dismissible={false}>
          <Text>{payoutsErrorMessage}</Text>
        </Banner>
      )}
      <Text variation="subdued">
        {textCopy}
        {". "}
        <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </Text>
      <Content>
        <section>
          {bank && bank.bankName && <Text>{bank.bankName}</Text>}
          {bank && bank.bankLast4 && (
            <Text>Account Ending in {bank.bankLast4}</Text>
          )}
        </section>
        <ChangeBankAccount
          bankAccountProps={bankAccountProps}
          payoutsToJobberMoney={!!bank?.payoutsToJobberMoney}
        />
      </Content>
    </Content>
  );
}

function ChangeBankAccount(props: {
  payoutsToJobberMoney: boolean;
  bankAccountProps: BankAccountProps;
}) {
  const { payoutsToJobberMoney, bankAccountProps } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { permissions, enabled: jobberPaymentsEnabled } = useJobberPayments();
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const shouldShow = permissions.canChangeBankAccount;
  const shouldDisable = !jobberPaymentsEnabled;

  useEffect(() => {
    if (
      window.location.pathname === "/jobber_payments/oauth" &&
      window.location.search.includes("oauth_state_id")
    ) {
      openDialog();
    }
  }, []);

  return (
    <>
      {shouldShow && (
        <Button
          label="CHANGE BANK ACCOUNT"
          disabled={shouldDisable}
          onClick={onChangeBankClick}
          type="tertiary"
          fullWidth={true}
        />
      )}
      <Modal
        open={isDialogOpen}
        onRequestClose={closeDialog}
        title="CHANGE BANK ACCOUNT"
      >
        <BankAccount
          {...bankAccountProps}
          payoutsToJobberMoney={payoutsToJobberMoney}
          embedded={true}
          completedCallback={onComplete}
        />
      </Modal>
    </>
  );

  function onChangeBankClick() {
    openDialog();
  }

  function onComplete(bank?: SuccessBank) {
    closeDialog();
    bankAccountProps.completedCallback(bank);
  }
}
