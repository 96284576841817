import { Heading, StatusLabel, Text } from "@jobber/components";
import React from "react";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./UkKycComplianceRegistrationStatus.module.css";
import { complianceRegistrationStatusMessages } from "./complianceRegistrationStatusMessages";

export interface UkKycComplianceRegistrationStatusProps {
  status: string;
  message: string;
  type: "inactive" | "critical" | "warning" | "informative" | "success";
}

function UkKycComplianceRegistrationStatusContent(
  props: UkKycComplianceRegistrationStatusProps,
) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.inline}>
          <Heading level={2}>
            {formatMessage(complianceRegistrationStatusMessages.statusHeader)}
          </Heading>
          <StatusLabel
            label={props.status}
            alignment={"start"}
            status={props.type}
          />
        </div>
        <Text>{props.message}</Text>
      </div>
    </div>
  );
}

export function UkKycComplianceRegistrationStatus(
  props: UkKycComplianceRegistrationStatusProps,
) {
  return (
    <IntlProvider>
      <UkKycComplianceRegistrationStatusContent {...props} />
    </IntlProvider>
  );
}
