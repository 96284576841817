import type React from "react";
import { useIntl } from "react-intl";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/messages";
import type {
  AllConditionTypes,
  AutomationAction,
  AutomationTrigger,
  RuleConditionText,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/types";
import {
  ActionTask,
  CreateRuleConditionTypes,
  TriggerTask,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/types";
import {
  containsOptions,
  includesOptions,
  pastClientsConditionTypesOptions,
  quoteSentLeadsConditionTypesOptions,
  recurringOptions,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/utils/dropdownOptions";
import type {
  AddConditionAction,
  RemoveConditionAction,
  UpdateConditionAction,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/RuleBuilderReducer";

const clientTagCondition = {
  task: "condition",
  arguments: {
    type: CreateRuleConditionTypes.ClientTags,
    fields: {
      contains: containsOptions[0].value,
      tag: "",
    },
  },
} as AllConditionTypes;

const quoteClientTagCondition = {
  task: "condition",
  arguments: {
    type: CreateRuleConditionTypes.QuoteClientTags,
    fields: {
      contains: containsOptions[0].value,
      tag: "",
    },
  },
} as AllConditionTypes;

const quoteLineItemCondition = {
  task: "condition",
  arguments: {
    type: CreateRuleConditionTypes.QuoteLineItems,
    fields: {
      includes: includesOptions[0].value,
      item: "",
    },
  },
} as AllConditionTypes;

const lineItemCondition = {
  task: "condition",
  arguments: {
    type: CreateRuleConditionTypes.LineItems,
    fields: {
      includes: includesOptions[0].value,
      item: "",
    },
  },
} as AllConditionTypes;

const jobTypeCondition = {
  task: "condition",
  arguments: {
    type: CreateRuleConditionTypes.JobType,
    fields: {
      type: recurringOptions[0].value,
    },
  },
} as AllConditionTypes;

const clientLeadStatus = {
  task: "condition",
  arguments: {
    type: CreateRuleConditionTypes.ClientLeadStatus,
    fields: {
      isLead: true,
    },
  },
} as AllConditionTypes;

// eslint-disable-next-line max-statements
export function useRuleBuilder(
  dispatch: React.Dispatch<
    AddConditionAction | UpdateConditionAction | RemoveConditionAction
  >,
) {
  const { formatMessage } = useIntl();

  const pastClientsConditionOptions = [
    {
      label: "Client tags",
      onClick: () => createRuleCondition(CreateRuleConditionTypes.ClientTags),
    },
    {
      label: "Line items",
      onClick: () => createRuleCondition(CreateRuleConditionTypes.LineItems),
    },
    {
      label: "Job type",
      onClick: () => createRuleCondition(CreateRuleConditionTypes.JobType),
    },
  ];

  const quoteSentLeadsConditionOptions = [
    {
      label: "Client tags",
      onClick: () =>
        createRuleCondition(CreateRuleConditionTypes.QuoteClientTags),
    },
    {
      label: "Line items",
      onClick: () =>
        createRuleCondition(CreateRuleConditionTypes.QuoteLineItems),
    },
  ];

  const conditionOptions = (ruleTrigger: TriggerTask) => {
    switch (ruleTrigger) {
      case TriggerTask.AFTER_DATE:
        return quoteSentLeadsConditionOptions;
      case TriggerTask.TIME_SINCE_LAST_JOB:
      default:
        return pastClientsConditionOptions;
    }
  };

  const conditionTypesOptions = (ruleTrigger: TriggerTask) => {
    switch (ruleTrigger) {
      case TriggerTask.AFTER_DATE:
        return quoteSentLeadsConditionTypesOptions;
      case TriggerTask.TIME_SINCE_LAST_JOB:
      default:
        return pastClientsConditionTypesOptions;
    }
  };

  const parseRuleCondition = (
    type: CreateRuleConditionTypes,
  ): AllConditionTypes => {
    let payload: AllConditionTypes;
    switch (type) {
      case CreateRuleConditionTypes.ClientTags:
        payload = clientTagCondition;
        break;
      case CreateRuleConditionTypes.QuoteClientTags:
        payload = quoteClientTagCondition;
        break;
      case CreateRuleConditionTypes.QuoteLineItems:
        payload = quoteLineItemCondition;
        break;
      case CreateRuleConditionTypes.LineItems:
        payload = lineItemCondition;
        break;
      case CreateRuleConditionTypes.JobType:
        payload = jobTypeCondition;
        break;
      case CreateRuleConditionTypes.ClientLeadStatus:
        payload = clientLeadStatus;
        break;
      default:
        throw new Error("Invalid condition type");
    }
    return payload;
  };

  const createRuleCondition = (type: CreateRuleConditionTypes) => {
    dispatch({ type: "addCondition", payload: parseRuleCondition(type) });
  };

  const removeRuleCondition = (index: number) => {
    dispatch({ type: "removeCondition", index });
  };

  const resetCondition = (type: CreateRuleConditionTypes, index: number) => {
    dispatch({
      type: "resetConditionType",
      index: index,
      payload: parseRuleCondition(type),
    });
  };

  function getExpressionTextForTrigger(trigger: AutomationTrigger) {
    switch (trigger.task) {
      case TriggerTask.TIME_SINCE_LAST_JOB:
        return `{months} after a job ends`;
      case TriggerTask.AFTER_DATE:
        return `{months} after a quote is {date_field}`;
      default:
        return "";
    }
  }

  function getExpressionTextForCampaignCondition(
    condition: AllConditionTypes,
  ): RuleConditionText {
    switch (condition.arguments.type) {
      case CreateRuleConditionTypes.ClientTags:
        return { text: "and only if {client_tag} {contains} {tag}" };
      case CreateRuleConditionTypes.QuoteClientTags:
        return { text: "and only if {client_tag} {contains} {tag}" };
      case CreateRuleConditionTypes.LineItems:
      case CreateRuleConditionTypes.QuoteLineItems:
        return { text: "and only if {line_item} {includes} {item}" };
      case CreateRuleConditionTypes.JobType:
        return { text: "and only if {job_type} {type}" };
      case CreateRuleConditionTypes.QuoteStatus:
        return { text: "and only if quote status is {status}" };
      case CreateRuleConditionTypes.ClientLeadStatus:
        return {
          text: formatMessage(messages.quoteSentLeadsDefaultCondition),
          tooltip: formatMessage(
            messages.quoteSentLeadsDefaultConditionTooltip,
          ),
        };
      case CreateRuleConditionTypes.TextOnly:
        return {
          text: condition.arguments.fields.text || "",
          tooltip: condition.arguments.fields.tooltip || "",
        };
      default:
        return { text: "" };
    }
  }

  function getExpressionTextForAction(action: AutomationAction) {
    switch (action.task) {
      case ActionTask.CAMPAIGN_DELIVERY:
      default:
        return formatMessage(messages.campaignAutomationRuleAction);
    }
  }

  return {
    conditionOptions,
    conditionTypesOptions,
    removeRuleCondition,
    resetCondition,
    getExpressionTextForTrigger,
    getExpressionTextForCampaignCondition,
    getExpressionTextForAction,
  };
}
