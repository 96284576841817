import React from "react";
import { Spinner } from "@jobber/components/Spinner";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { Job, JobCostLineItemFragment } from "~/utilities/API/graphql";
import { AuthorizationContextProvider } from "~/utilities/contexts/authorization/AuthorizationContextProvider";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { randomLineItemId } from "~/jobber/lineItems/utils";
import type { LineItem } from "~/jobber/lineItems/types";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import { useJobCostQuery } from "./hooks";
import { JobCost } from "./JobCost";
import styles from "./JobCost.module.css";
import { messages } from "./messages";

interface JobCostLoaderProps {
  job: Pick<Job, "id">;
}

function ContextWrappedJobCostLoader({ job }: JobCostLoaderProps) {
  return (
    <APIProvider>
      <AuthorizationContextProvider>
        <IntlProvider>
          <InternalJobCostLoader jobId={job.id} />
        </IntlProvider>
      </AuthorizationContextProvider>
    </APIProvider>
  );
}

function InternalJobCostLoader({ jobId }: { jobId: string }) {
  const { formatMessage } = useIntl();

  const {
    authorizationLoading,
    canViewJobCosts,
    canViewExpenses,
    canViewTimeSheetEntries,
    canViewPricing,
  } = usePermissions();

  const {
    timesheets,
    timesheetsTotalCost,
    timesheetsTotalDuration,
    expenses,
    expensesTotal,
    job,
    lineItems,
    lineItemsTotalCost,
    lineItemsTotalPrice,
    jobCosting,
    hasMoreLineItems,
    hasMoreExpenses,
    hasMoreTimesheets,
    fetchMoreLineItems,
    fetchMoreTimesheets,
    fetchMoreExpenses,
    user,
    loading,
    error,
  } = useJobCostQuery({
    jobId,
    authorizationLoading,
    canViewJobCosts,
    canViewExpenses,
    canViewTimeSheetEntries,
    canViewPricing,
  });

  const jobCostingData = jobCosting ?? {
    recordDoesNotExist: true,
    id: "",
    expenseCost: 0,
    labourCost: 0,
    labourDuration: 0,
    lineItemCost: 0,
    profitAmount: 0,
    profitPercentage: 0,
    totalCost: 0,
    totalRevenue: 0,
    invoiceRemindersCount: 0,
    visitsCount: 0,
  };

  if (loading || authorizationLoading) {
    return (
      <div className={styles.jobCost}>
        <Card>
          <div className={styles.loading}>
            <Spinner size="small" />
          </div>
        </Card>
      </div>
    );
  }

  if (error || !user || !lineItems) {
    return (
      <div className={styles.jobCost}>
        <Card>
          <div className={styles.loading}>
            <Icon name="alert" color="red"></Icon>
            <div className={styles.errorText}>
              <Text variation="error">
                {formatMessage(messages.errorLoadingData)}
              </Text>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  function jobCostLineItemFragmentToLineItem(
    fragment: JobCostLineItemFragment,
  ): LineItem {
    return {
      ...fragment,
      reactKey: randomLineItemId(),
      unitCost: fragment.unitCost || 0,
      isDeleted: false,
      totalCost:
        fragment.totalCost ?? (fragment.unitCost || 0) * fragment.quantity,
      overrideDates: fragment.overrideDates?.nodes?.map(
        overrideDate => overrideDate.startAt,
      ),
    };
  }

  return (
    <JobCost
      timesheets={timesheets}
      timesheetsTotalCost={timesheetsTotalCost}
      timesheetsTotalDuration={timesheetsTotalDuration}
      expenses={expenses}
      expensesTotal={expensesTotal || 0}
      lineItems={lineItems.map(jobCostLineItemFragmentToLineItem)}
      lineItemsTotalCost={lineItemsTotalCost}
      lineItemsTotalPrice={lineItemsTotalPrice}
      jobCosting={jobCostingData}
      hasMoreLineItems={hasMoreLineItems}
      fetchMoreLineItems={fetchMoreLineItems}
      hasMoreExpenses={hasMoreExpenses}
      hasMoreTimesheets={hasMoreTimesheets}
      fetchMoreTimesheets={fetchMoreTimesheets}
      fetchMoreExpenses={fetchMoreExpenses}
      job={job}
      user={user}
    />
  );
}

function usePermissions() {
  const { can, authorizationLoading } = useAuthorization();

  const canViewJobCosts = can("view", "JobCosts");
  const canViewExpenses = can("view", "Expenses");
  const canViewTimeSheetEntries = can("view", "Timesheets");
  const canViewPricing = can("view", "Pricing");

  return {
    authorizationLoading,
    canViewJobCosts,
    canViewExpenses,
    canViewTimeSheetEntries,
    canViewPricing,
  };
}

const JobCostLoaderWithRailsProps = withRailsPropsAsContexts()(
  ContextWrappedJobCostLoader,
);
export { JobCostLoaderWithRailsProps as JobCostLoader };
