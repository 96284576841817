import { defineMessages } from "react-intl";

export const messages = defineMessages({
  instantPayoutUnavailable: {
    id: "managedAccount.paymentsCard.instantPayoutDetails.instantPayoutUnavailable",
    defaultMessage:
      "Instant payouts are not available because you're receiving automatic hourly payouts to your Jobber Money account for free.",
    description:
      "Message when instant payouts are not available because the users payout accout is a Jobber Money account",
  },
});
