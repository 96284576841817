import React from "react";
import { Card } from "@jobber/components/Card";
import styles from "./Disclaimer.module.css";

interface DisclaimerProps {
  content: JSX.Element;
}

export function Disclaimer({ content }: DisclaimerProps) {
  return (
    <div data-testid="disclaimer" className={styles.disclaimer}>
      <Card>{content}</Card>
    </div>
  );
}
