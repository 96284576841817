import React from "react";
import { Grid } from "@jobber/components/Grid";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { SubscriptionAddonCard } from "~/jobber/billing/components/SubscriptionAddonCard/SubscriptionAddonCard";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { compareAddons } from "./utils";
import { messages } from "./messages";

interface SubscriptionAddonsProps {
  recurlyPublicKey: string;
  subscriptionAddons: SubscriptionAddon[];
}

export function SubscriptionAddons(props: SubscriptionAddonsProps) {
  const { recurlyPublicKey, subscriptionAddons } = props;
  const { formatMessage } = useIntl();

  return subscriptionAddons.length ? (
    <Grid>
      {subscriptionAddons
        .sort(compareAddons)
        .map((subscriptionAddon, index) => (
          <Grid.Cell
            key={`addon-card-${index}`}
            size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}
          >
            <SubscriptionAddonCard
              recurlyPublicKey={recurlyPublicKey}
              subscriptionAddon={subscriptionAddon}
            />
          </Grid.Cell>
        ))}
    </Grid>
  ) : (
    <Text>{formatMessage(messages.unableToPurchaseMessage)}</Text>
  );
}
