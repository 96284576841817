import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import { usePreloadImages } from "jobber/setupWizard/hooks/usePreloadImages";
import type { SetupWizardDataQuery } from "~/utilities/API/graphql";
import { withSplitClient } from "utilities/split";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import { DatadogErrorBoundaryProvider } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";
import { SIGNUP_FLOW } from "~/utilities/errors/Datadog/loggerNames";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import styles from "./SetupWizardRoute.module.css";
import { SetupWizardRoute } from "./SetupWizardRoute";
import { SetupWizardProvider } from "./context";
import { SETUP_WIZARD_QUERY } from "./SetupWizard.graphql";
import {
  type IndustryCategory,
  type PromoDetails,
  Routes,
  type SetupWizardData,
  type SetupWizardExperiments,
  type SignupConsentData,
} from "./types";
import { safelyExtractAnswer } from "./utils";
import { defaultAssets } from "./assets";
import { lastSubmittedStepQuestionKey } from "./components/constants";

export interface SetupWizardLoaderProps {
  industryMap: IndustryCategory[];
  promotionData: {
    promoTrackingTag: string;
    showPromo: boolean;
    showPromoStatusQuestionKey: string;
    showPromoStatusInterestedValue: string;
    promoDetails: PromoDetails;
  };
  experiments: SetupWizardExperiments;
  accountCreatedAt: string;
  signupConsentData: SignupConsentData;
}

function SetupWizardLoader(props: SetupWizardLoaderProps) {
  const { loading, data: queryResult } =
    useQuery<SetupWizardDataQuery>(SETUP_WIZARD_QUERY);

  const { preloadImages, isPreloadingImages } = usePreloadImages();
  useEffect(() => {
    const preloadStepImages = async () => {
      await preloadImages([...Object.values(defaultAssets)]);
    };
    void preloadStepImages();
  }, [preloadImages]);

  const parsedSetupWizardData: SetupWizardData | undefined = useMemo(() => {
    if (!queryResult || !queryResult.account) return;

    const lastSubmittedRoute = safelyExtractAnswer(
      lastSubmittedStepQuestionKey,
      queryResult.account.setupWizard?.questionsAndAnswers,
    );
    return {
      email: queryResult.account.accountOwner?.email?.raw,
      accountOwnerName: lastSubmittedRoute
        ? queryResult.account.accountOwner?.fullName
        : "",
      companyName:
        lastSubmittedRoute && lastSubmittedRoute !== Routes.profile
          ? queryResult.account.name
          : "",
      companySize: queryResult.account.setupWizard?.companySize,
      heardAboutUs: queryResult.account.setupWizard?.heardAboutUs,
      industry: lastSubmittedRoute ? queryResult.account.industry : undefined,
      phoneNumber: queryResult.account.phone,
      questionsAndAnswers: queryResult.account.setupWizard?.questionsAndAnswers,
      smsOptIn: queryResult.account.setupWizard?.smsOptIn,
    };
  }, [queryResult]);

  return (
    <DatadogErrorBoundaryProvider loggerName={SIGNUP_FLOW}>
      {loading || isPreloadingImages || !parsedSetupWizardData ? (
        <div
          data-testid="setupWizardLoadingContainer"
          className={styles.loadingContainer}
        >
          <Spinner />
        </div>
      ) : (
        <SetupWizardProvider
          experiments={props.experiments}
          defaultState={parsedSetupWizardData}
          accountCreatedAt={props.accountCreatedAt}
        >
          <SetupWizardRoute
            industryMap={props.industryMap}
            promotionData={props.promotionData}
            signupConsentData={props.signupConsentData}
          />
        </SetupWizardProvider>
      )}
    </DatadogErrorBoundaryProvider>
  );
}

const ContextWrappedSetupWizard = withEntryPointProviders(SetupWizardLoader);
const Enhanced = withSplitClient(
  withRailsPropsAsContexts()(ContextWrappedSetupWizard),
);

export { Enhanced as SetupWizardLoader };
