import React, { useState } from "react";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  CoreToConnectTrialBeginModal,
  CoreToConnectTrialStartSource,
} from "~/jobber/coreToConnectTrial/components/CoreToConnectTrialBeginModal/CoreToConnectTrialBeginModal";
import ServicesCheckboxCalendarImg from "@images/servicesCheckboxCalendar.png";
import CalendarUpgradePromptImg from "@images/calendarUpgradePrompt.png";
import styles from "./CoreToConnectTrialUpgradePrompt.module.css";
import { messages } from "./messages";
import { TrialPromptCard } from "../TrialPromptCard";

export function CoreToConnectTrialUpgradePrompt() {
  const [modalOpen, setModalOpen] = useState(false);
  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();

  return (
    <div ref={ref} className={styles.trialPromptContainer}>
      <TrialPromptCard
        trialTitle={messages.coreToConnectTrialUpgradePrompt.title}
        trialDescription={messages.coreToConnectTrialUpgradePrompt.description}
        trialDescriptionSecondary={
          messages.coreToConnectTrialUpgradePrompt.secondaryDescription
        }
        ctaLabel={messages.coreToConnectTrialUpgradePrompt.ctaLabel}
        ctaCallBack={openCoreToConnectBeginTrialModal}
        imgSource={
          width > Breakpoints.base
            ? ServicesCheckboxCalendarImg
            : CalendarUpgradePromptImg
        }
        imgAlt={
          width > Breakpoints.base
            ? messages.coreToConnectTrialUpgradePrompt.imgAltDesktop
            : messages.coreToConnectTrialUpgradePrompt.imgAltMobile
        }
      />
      {modalOpen && (
        <CoreToConnectTrialBeginModal
          source={CoreToConnectTrialStartSource.UPGRADE_PAGE}
          showModal={modalOpen}
          dismissModal={() => setModalOpen(false)}
        />
      )}
    </div>
  );

  function openCoreToConnectBeginTrialModal() {
    setModalOpen(true);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: "core_to_connect_trial_upgrade_prompt",
    });
  }
}
