import React, { type MutableRefObject, useEffect, useRef } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import {
  CallToAction,
  type CallToActionRef,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./LiteToCoreTrialPreTrialCard.module.css";
import { CalendarImg } from "./images";

export interface LiteToCoreTrialPreTrialCardProps {
  ctaName: string;
  hasViewedCTA: boolean;
  onLearnMoreButtonClick(): void;
}

export function LiteToCoreTrialPreTrialCard({
  ctaName,
  hasViewedCTA,
  onLearnMoreButtonClick,
}: LiteToCoreTrialPreTrialCardProps) {
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  useEffect(() => {
    if (!hasViewedCTA) {
      trackFirstView();
    }
  }, []);

  return (
    <CallToAction ref={ctaRef} ctaName={ctaName}>
      <div className={styles.card}>
        <div className={styles.row}>
          <div className={styles.imageContainer}>
            <img
              src={CalendarImg.src}
              alt={CalendarImg.alt}
              className={styles.image}
            />
          </div>
          <div className={styles.cardContent}>
            <Content>
              <Heading level={2}>Free 14-day core plan trial</Heading>
              <Text>
                Organize your operations all in one place. The Core plan helps
                you visualize all jobs in your workflow and the revenue
                they&apos;re generating, keeping you on track to meet your
                business goals.
              </Text>
              <Text variation="subdued">
                You will automatically return to your current plan after the
                trial.
                <br />
                Restart the Jobber app to access the trial on your phone.
              </Text>
            </Content>
            <div className={styles.buttonsBlock}>
              <div className={styles.dismissButton}>
                <Button
                  label="Dismiss"
                  type="secondary"
                  variation="subtle"
                  onClick={dismissCTA(ctaRef)}
                />
              </div>
              <div className={styles.learnMoreButton}>
                <Button
                  label="Learn More"
                  variation="learning"
                  onClick={onLearnMoreButtonClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.divider}>
          <Divider />
        </div>
      </div>
    </CallToAction>
  );

  function trackFirstView() {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: ctaName,
    });
  }
}
