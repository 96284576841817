import { Button } from "@jobber/components/Button";
import React, {
  type MutableRefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useQuery } from "@apollo/client";
import { ChatDrawer } from "jobber/chat/components/ChatDrawer";
import { SideDrawer } from "components/SideDrawer";
import {
  UNREAD_CONVERSATIONS,
  UNREAD_CONVERSATIONS_SUBSCRIPTION,
} from "jobber/chat/components/MessageCenterButton/MessageCenterButton.graphql";
import { MessageCenterNotificationBadge } from "jobber/chat/components/MessageCenterButton/MessageCenterNotificationBadge";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  UnreadConversationsQuery,
  UpdateUnreadConversationsSubscription,
} from "~/utilities/API/graphql";
import {
  InitialMessageCenterState,
  MessageCenterContext,
  MessageCenterReducer,
} from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import styles from "./MessageCenterButton.module.css";
import { useRegistrationStatus } from "./hooks";

export interface MessageCenterButtonProps {
  hasPhoneNumber: boolean;
}
export function MessageCenterButton({
  hasPhoneNumber,
}: MessageCenterButtonProps) {
  return (
    <APIProvider>
      <ChatIcon hasPhoneNumber={hasPhoneNumber} />
    </APIProvider>
  );
}

function ChatIcon({ hasPhoneNumber }: MessageCenterButtonProps) {
  const { data, subscribeToMore } =
    useQuery<UnreadConversationsQuery>(UNREAD_CONVERSATIONS);
  const [state, dispatch] = useReducer(
    MessageCenterReducer,
    InitialMessageCenterState,
  );
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    return subscribeToMore<UpdateUnreadConversationsSubscription>({
      document: UNREAD_CONVERSATIONS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const newCount =
          subscriptionData?.data?.unreadConversations?.totalCount;
        if (newCount == undefined) {
          return prev;
        }

        return Object.assign({}, prev, {
          unreadConversations: {
            totalCount: newCount,
            __typename: "ConversationConnection",
          },
        });
      },
    });
  }, []);

  const loadingRegistration = useRegistrationStatus(dispatch);

  const buttonContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const count = data?.unreadConversations.totalCount || 0;
  const buttonLabel = "Open Text Message Inbox";
  return (
    <div className={styles.messageCenterButtonContainer}>
      <div ref={buttonContainerRef}>
        <Button
          type="secondary"
          variation="subtle"
          icon={"sms2"}
          ariaLabel={buttonLabel}
          onClick={onButtonPress}
        />
        {count > 0 && <MessageCenterNotificationBadge unreadCount={count} />}
        <MessageCenterContext.Provider value={[state, dispatch]}>
          <SideDrawer open={isOpen} requestDrawerClose={onCloseSideDrawer}>
            {!loadingRegistration && (
              <ChatDrawer
                hasPhoneNumber={hasPhoneNumber}
                requestDrawerClose={onCloseSideDrawer}
              />
            )}
          </SideDrawer>
        </MessageCenterContext.Provider>
      </div>
    </div>
  );

  function onButtonPress() {
    setIsOpen(!isOpen);
  }

  function setFocusOnMessageCenterButton() {
    const button = buttonContainerRef.current.querySelector(
      `button[aria-label='${buttonLabel}']`,
    ) as HTMLButtonElement;
    button.focus();
  }

  function onCloseSideDrawer() {
    setIsOpen(false);

    // We need the tick to wait for the redraw after the drawer is removed.
    setTimeout(() => {
      setFocusOnMessageCenterButton();
    }, 0);
  }
}
