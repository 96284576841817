import { gql } from "@apollo/client";

export const ACCOUNT_ADDON_INFO = gql`
  query AccountAddonInfo($addonSetIdentifier: String!) {
    accountAddonInfo(addonSetIdentifier: $addonSetIdentifier) {
      currency
      identifier
      monthlyBillingCycle {
        addonCode
        monthlyCost
        name
      }
      name
    }
    addonPreviewGroup(addonSetIdentifiers: [$addonSetIdentifier]) {
      monthlyPreview {
        currency
        nextBillingDate
        proratedDiscount
        proratedTax
        proratedTotal
      }
    }
    addonDiscountGroup(addonSetIdentifier: $addonSetIdentifier) {
      monthlyAddonDiscount {
        addonCostMonthlyDiscounted
        discountAmount
        discountDurationUnit
        discountEndDate
        discountType
        hasMultipleActiveDiscounts
      }
    }
    account {
      billingInformation {
        startBillingOnDate
      }
    }
  }
`;
