import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { FormatFile } from "@jobber/components/FormatFile";
import {
  type FileUpload,
  InputFile,
  updateFiles,
} from "@jobber/components/InputFile";
import { useIntl } from "react-intl";
import type Quill from "quill";
import { DirectUploadDestinations } from "~/utilities/API/graphql";
import {
  completeActiveStorageFileDirectUpload,
  fetchActiveStorageUploadParams,
} from "utilities/fetchUploadParams";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { messages } from "./messages";

const MAX_IMAGE_SIZE = 5 * 1000 * 1000; // 5 MB

interface ImageUploaderProps {
  quillInstance: Quill | null;
  setIsImageUploadModalOpen: (isOpen: boolean) => void;
}

export function ImageUploader({
  quillInstance,
  setIsImageUploadModalOpen,
}: ImageUploaderProps) {
  const { formatMessage } = useIntl();
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [completedUploads, setCompletedUploads] = useState(0);
  const { campaignContent } = useCampaignWizardContext();

  function handleUpload(file: FileUpload) {
    setFiles(oldFiles => updateFiles(file, oldFiles));
  }

  const handleUploadComplete = async (upload: FileUpload) => {
    setCompletedUploads(prevCount => prevCount + 1);

    if (completedUploads === files.length) {
      setIsImageUploadModalOpen(false);
    }

    const data = await completeActiveStorageFileDirectUpload({
      key: upload.key,
    });

    quillInstance?.insertEmbed(
      quillInstance?.getSelection()?.index || 0,
      "image",
      data?.directUploadComplete.directUpload?.url,
    );

    campaignContent.setImageIds([
      ...(campaignContent.imageIds || []),
      data?.directUploadComplete?.directUpload?.id || "",
    ]);
  };

  const fileValidator = (file: globalThis.File) => {
    if (file.size > MAX_IMAGE_SIZE) {
      return {
        code: "MAX_IMAGE_SIZE_ERROR",
        message: formatMessage(messages.uploadSizeError),
      };
    }
    return null;
  };

  return (
    <Content spacing="small" data-testid={`$CampaignImageUpload`}>
      <InputFile
        getUploadParams={fetchActiveStorageUploadParams(
          DirectUploadDestinations.PUBLIC_IMAGES,
        )}
        allowedTypes={"basicImages"}
        allowMultiple={true}
        onUploadStart={handleUpload}
        onUploadComplete={handleUploadComplete}
        onUploadProgress={handleUpload}
        validator={fileValidator}
        buttonLabel={formatMessage(messages.uploadButton)}
      />

      {files.map(file => (
        <FormatFile file={file} key={file.key} />
      ))}
    </Content>
  );
}
