import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import Lottie from "lottie-react";
import { useCampaignWizardContext } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignWizardProvider";
import { messages } from "./messages";
import sentAnimation from "./sentAnimation.json";
import styles from "./SentDelighterModal.module.css";

export function SentDelighterModal() {
  const { formatMessage } = useIntl();
  const {
    sentDelighter: { open, setOpen, clientCount, setClientCount },
  } = useCampaignWizardContext();

  const closeModal = () => {
    setOpen(false);
    setClientCount(null);
  };

  const sentDelighterText =
    clientCount === 0
      ? formatMessage(messages.sentDelighterTextWithoutCount)
      : formatMessage(messages.sentDelighterTextWithCount, {
          count: clientCount,
        });

  return (
    <Modal
      title={formatMessage(messages.sentDelighterTitle)}
      size="small"
      open={open}
      dismissible={true}
      onRequestClose={closeModal}
      primaryAction={{
        label: formatMessage(messages.sentDelighterButtonLabel),
        onClick: closeModal,
      }}
    >
      <Content>
        <Lottie animationData={sentAnimation} className={styles.lottie} loop />
        <Text>{sentDelighterText}</Text>
      </Content>
    </Modal>
  );
}
