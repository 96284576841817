/**
 * Reviews Add On Prompt Page Object Model
 *
 * Selectors:
 *
 * - reviewsAddOnPromptTitle
 * - reviewsAddOnPromptDescription
 * - reviewsAddOnPromptCtaLabel
 * - reviewsAddOnPromptImg
 * - reviewsAddOnPromptImgAlt
 * - reviewsAddOnPromptNewLabel
 *
 * Simulators:
 * - iClickLearnMoreButton
 */

import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage: t } = createIntl();

export function reviewsAddOnPromptTitle() {
  return screen.getByRole("heading", {
    level: 2,
    name: t(messages.reviewsAddOnPromptTitle),
  });
}

export function reviewsAddOnPromptDescription() {
  return screen.getAllByText(t(messages.reviewsAddOnPromptDescription))[0];
}

export function reviewsAddOnPromptCtaLabel() {
  return screen.getByRole("button", {
    name: t(messages.reviewsAddOnPromptCtaLabel),
  });
}

export function reviewsAddOnPromptImg() {
  return screen.getByRole("img", {
    name: t(messages.reviewsAddOnPromptimgAlt),
  }) as HTMLElement;
}

export function reviewsAddOnPromptImgAlt() {
  return screen.getByAltText(t(messages.reviewsAddOnPromptimgAlt));
}

export function reviewsAddOnPromptNewLabel() {
  return screen.getByText("New");
}

/***************** Simulators ********************/

export function iClickLearnMoreButton() {
  screen
    .getByRole("button", {
      name: t(messages.reviewsAddOnPromptCtaLabel),
    })
    .click();
}
