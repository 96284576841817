import { HeardAboutUsPage } from "jobber/setupWizard/components/HeardAboutUsPage";
import { BusinessPage } from "jobber/setupWizard/components/BusinessPage";
import { ProfilePage } from "jobber/setupWizard/components/ProfilePage";
import { TopPriorityPage } from "jobber/setupWizard/components/TopPriorityPage";
import { PromotionPage } from "jobber/setupWizard/components/PromotionPage";
import { Routes, type SetupWizardStep } from "./types";

export const defaultSteps: SetupWizardStep[] = [
  {
    route: Routes.profile,
    component: ProfilePage,
    includeInProgress: true,
  },
  {
    route: Routes.business,
    component: BusinessPage,
    includeInProgress: true,
  },
  {
    route: Routes.topPriority,
    component: TopPriorityPage,
    includeInProgress: true,
  },
  {
    route: Routes.hdyhau,
    component: HeardAboutUsPage,
    includeInProgress: true,
  },
];

export const promotionStep = {
  route: Routes.promotion,
  component: PromotionPage,
  includeInProgress: false,
};
