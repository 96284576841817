import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { LiteToCoreTrialBeginModal } from "jobber/liteToCoreTrial/LiteToCoreTrialBeginModal";
import { CalendarImg } from "./images";
import styles from "./LiteToCoreTrialUpgradePrompt.module.css";
import { messages } from "./messages";
import { TrialPromptCard } from "../TrialPromptCard";

export function LiteToCoreTrialUpgradePrompt() {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  function openLiteToCoreBeginTrialModal() {
    setModalOpen(true);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: "lite_to_core_trial_upgrade_prompt",
    });
  }

  function closeLiteToCoreBeginTrialModal() {
    setModalOpen(false);
  }

  return (
    <div className={styles.liteToCoreTrialUpgradePrompt}>
      <TrialPromptCard
        trialTitle={formatMessage(messages.title)}
        trialDescription={formatMessage(messages.description)}
        trialDescriptionSecondary={formatMessage(messages.secondaryDescription)}
        ctaLabel={formatMessage(messages.ctaLabel)}
        ctaCallBack={openLiteToCoreBeginTrialModal}
        imgSource={CalendarImg.src}
        imgAlt={CalendarImg.alt}
      />
      <LiteToCoreTrialBeginModal
        source="upgrade_prompt"
        showModal={modalOpen}
        closeModal={closeLiteToCoreBeginTrialModal}
      />
    </div>
  );
}
