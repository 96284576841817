import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import RestartTrialCardImg from "@images/ReverseTrialRestartTrialCardImage.png";
import { APIProvider } from "~/utilities/API/APIProvider";
import styles from "./ReverseTrialRestartTrialCard.module.css";
import { messages } from "./messages";
import { ReverseTrialRestartTrialModal } from "./ReverseTrialRestartTrialModal";

export function ReverseTrialRestartTrialCard() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.restartTrialCardWrapper}>
      <Card>
        <div className={styles.restartTrialCardContent}>
          <div className={styles.restartTrialCardImage}>
            <img src={RestartTrialCardImg} alt={messages.image.alt} />
          </div>
          <div className={styles.restartTrialRightContent}>
            <div className={styles.restartTrialBodyContent}>
              <div className={styles.restartTrialCardTitle}>
                <Heading level={3}>{messages.content.header}</Heading>
              </div>
              <div className={styles.restartTrialCardDescription}>
                {messages.content.body}
              </div>
            </div>
            <div className={styles.restartTrialCardButton}>
              <Button
                label={messages.button.label}
                variation="learning"
                type="secondary"
                onClick={openModal}
              />
            </div>
          </div>
        </div>
      </Card>
      <APIProvider>
        <ReverseTrialRestartTrialModal
          shouldShowModal={showModal}
          closeModal={closeModal}
        />
      </APIProvider>
    </div>
  );

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }
}
