import Quill from "quill";
import { QuillImageBlot } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/hooks/useQuill/modules/imageResize";

// https://quilljs.com/docs/formats/ has the list of available formats.
export const QUILL_FORMATS = [
  "bold",
  "italic",
  "link",
  "strike",
  "underline",
  "header",
  "align",
  "font",
  "image",
  "width",
  "style",
];

/**
 * Helvetica is placed first as it is the primary font for Campaigns.
 * Quill renders these fonts in the order specified below. First font must
 * always be the one used as primary in the templates
 * `config/locales/views/marketing/en.yml`
 */
export const SUPPORTED_FONTS = [
  "Helvetica",
  "Arial",
  "Trebuchet MS",
  "Georgia",
  "Courier New",
];

// eslint-disable-next-line max-statements
export function setupAttributors() {
  // This forces Quill to use inline styles instead of classes.
  const DirectionAttribute = Quill.import("attributors/attribute/direction");
  Quill.register(DirectionAttribute, true);

  const AlignClass = Quill.import("attributors/class/align");
  Quill.register(AlignClass, true);

  const BackgroundClass = Quill.import("attributors/class/background");
  Quill.register(BackgroundClass, true);

  const ColorClass = Quill.import("attributors/class/color");
  Quill.register(ColorClass, true);

  const DirectionClass = Quill.import("attributors/class/direction");
  Quill.register(DirectionClass, true);

  const FontClass = Quill.import("attributors/class/font");
  FontClass.whitelist = SUPPORTED_FONTS;
  Quill.register(FontClass, true);

  const SizeClass = Quill.import("attributors/class/size");
  Quill.register(SizeClass, true);

  const AlignStyle = Quill.import("attributors/style/align");
  Quill.register(AlignStyle, true);

  const BackgroundStyle = Quill.import("attributors/style/background");
  Quill.register(BackgroundStyle, true);

  const ColorStyle = Quill.import("attributors/style/color");
  Quill.register(ColorStyle, true);

  const DirectionStyle = Quill.import("attributors/style/direction");
  Quill.register(DirectionStyle, true);

  const FontStyle = Quill.import("attributors/style/font");
  Quill.register(FontStyle, true);

  const SizeStyle = Quill.import("attributors/style/size");
  Quill.register(SizeStyle, true);

  const Font = Quill.import("formats/font");
  Font.whitelist = SUPPORTED_FONTS;
  Quill.register(Font, true);

  // Custom image formatter
  Quill.register(QuillImageBlot, true);
}
