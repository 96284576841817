import React, { useCallback, useEffect } from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Grid } from "@jobber/components/Grid";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { PurchaseFlowButton } from "~/jobber/marketingCampaignsExpansion/components/PurchaseFlowButton";
import { TrialFlowButton } from "~/jobber/marketingCampaignsExpansion/components/TrialFlowButton";
import { formatCurrency } from "utilities/formatCurrency";
import { TrialFlowButtonType } from "~/jobber/marketingCampaignsExpansion/components/TrialFlowButton/TrialFlowButton";
import styles from "./styles.module.css";
import {
  MoreClientsImg,
  PlanTapeImg,
  ProtectReputationImg,
  ReviewsHeroImg,
  TrustedBrandImg,
} from "./images";
import { messages } from "./messages";
import type { FeatureHighlightProps } from "../types";

interface PurchasePageProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  monthlyCost?: number;
  monthlyDiscountedCost?: number;
  isMobileBilled: boolean;
  discountCallToAction?: string;
  salesforceTrackingId: string | null;
  inTrial?: boolean;
}

interface PurchaseAddonProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  isMobileBilled: boolean;
  discountCallToAction?: string;
  onSubmitPurchase: () => void;
  onDismissPurchase: () => void;
  inTrial?: boolean;
}

export function PurchasePage(props: PurchasePageProps): JSX.Element {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    monthlyCost,
    monthlyDiscountedCost,
    isMobileBilled,
    discountCallToAction,
    salesforceTrackingId,
    inTrial,
  } = props;

  const onSubmitPurchase = () => {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "reviews_purchase_page_purchase_modal",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      salesforce_id: `${salesforceTrackingId}`,
    });
  };

  const onDismissPurchase = () => {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      name: "reviews_purchase_page_purchase_modal",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      salesforce_id: `${salesforceTrackingId}`,
    });
  };

  useEffect(() => {
    Amplitude.TRACK_EVENT("Viewed Page", {
      name: "review_add_on_landing_page",
    });
  }, []);

  return (
    <div className={styles.landingPageContainer}>
      <ValuePropHeader
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        isMobileBilled={isMobileBilled}
        discountCallToAction={discountCallToAction}
        onSubmitPurchase={onSubmitPurchase}
        onDismissPurchase={onDismissPurchase}
        inTrial={inTrial}
      />
      <FeatureHighlights />
      <ComingSoon />
      {monthlyCost && (
        <PlanUpgradeFooter
          recurlyPublicKey={recurlyPublicKey}
          addonSetIdentifier={addonSetIdentifier}
          monthlyCost={monthlyCost}
          monthlyDiscountedCost={monthlyDiscountedCost}
          isMobileBilled={isMobileBilled}
          discountCallToAction={discountCallToAction}
          onSubmitPurchase={onSubmitPurchase}
          onDismissPurchase={onDismissPurchase}
        />
      )}
    </div>
  );
}

function ValuePropHeader({
  recurlyPublicKey,
  addonSetIdentifier,
  isMobileBilled,
  discountCallToAction,
  onSubmitPurchase,
  onDismissPurchase,
  inTrial,
}: PurchaseAddonProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.headerContainer}>
      <Content>
        <img
          className={styles.tapeHeaderImg}
          src={PlanTapeImg.src}
          alt={formatMessage(PlanTapeImg.alt)}
        />
        <Heading level={1}>{formatMessage(messages.headingTitle)}</Heading>
        <Text size={"large"} align={"center"}>
          {formatMessage(messages.headingBody)}
        </Text>
        {discountCallToAction && !isMobileBilled && (
          <Text size={"large"} align={"center"}>
            <Emphasis variation={"bold"}>
              <Emphasis variation={"highlight"}>
                {discountCallToAction}
              </Emphasis>
            </Emphasis>
          </Text>
        )}

        {inTrial ? (
          <TrialFlowButton feature={TrialFlowButtonType.REVIEWS} />
        ) : (
          <PurchaseModal
            recurlyPublicKey={recurlyPublicKey}
            addonSetIdentifier={addonSetIdentifier}
            isMobileBilled={isMobileBilled}
            onSubmitPurchase={onSubmitPurchase}
            onDismissPurchase={onDismissPurchase}
          />
        )}
      </Content>
      <img src={ReviewsHeroImg.src} alt={formatMessage(ReviewsHeroImg.alt)} />
    </div>
  );
}

function FeatureHighlights(): JSX.Element {
  const { formatMessage } = useIntl();

  const rowData: FeatureHighlightProps[] = [
    {
      img: TrustedBrandImg,
      title: messages.firstRowTitle,
      body: messages.firstRowBody,
    },
    {
      img: ProtectReputationImg,
      title: messages.secondRowTitle,
      body: messages.secondRowBody,
    },
    {
      img: MoreClientsImg,
      title: messages.thirdRowTitle,
      body: messages.thirdRowBody,
    },
  ];

  return (
    <div className={styles.highlightsContainer}>
      {rowData.map((data, idx) => (
        <Grid key={idx} alignItems={"center"} gap={true}>
          <Grid.Cell size={{ xs: 12, sm: 12, md: 7, lg: 7 }}>
            <img src={data.img.src} alt={formatMessage(data.img.alt)} />
          </Grid.Cell>
          <Grid.Cell size={{ xs: 12, sm: 12, md: 5, lg: 5 }}>
            <Content>
              <Heading level={3}>{formatMessage(data.title)}</Heading>
              <Text size={"large"}>{formatMessage(data.body)}</Text>
            </Content>
          </Grid.Cell>
        </Grid>
      ))}
    </div>
  );
}

function ComingSoon(): JSX.Element {
  const { formatMessage } = useIntl();

  const updateSignupCallback = useCallback(() => {
    Intercom.EXECUTE("startSurvey", "38822598");
  }, []);

  return (
    <div className={styles.comingSoonContainer}>
      <div className={styles.content}>
        <Heading level={5}>{formatMessage(messages.comingSoonTitle)}</Heading>
        <Heading level={2}>{formatMessage(messages.comingSoonBody)}</Heading>
        <Button
          label={formatMessage(messages.signUpButton)}
          ariaLabel={formatMessage(messages.signUpButton)}
          variation={"subtle"}
          type={"primary"}
          onClick={updateSignupCallback}
        />
      </div>
    </div>
  );
}

function PlanUpgradeFooter({
  recurlyPublicKey,
  addonSetIdentifier,
  monthlyCost,
  monthlyDiscountedCost,
  isMobileBilled,
  onSubmitPurchase,
  onDismissPurchase,
  discountCallToAction,
}: PurchaseAddonProps & {
  monthlyCost: number;
  monthlyDiscountedCost?: number;
  discountCallToAction?: string;
}): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.content}>
        <Content>
          <p className={styles.footerBody}>
            {discountCallToAction || formatMessage(messages.footerToolboxTitle)}
          </p>
          <p className={styles.footerTitle}>
            {formatMessage(messages.footerPricing)}
            <FooterPrice
              monthlyCost={monthlyCost}
              monthlyDiscountedCost={monthlyDiscountedCost}
            />
          </p>
          <PurchaseModal
            recurlyPublicKey={recurlyPublicKey}
            addonSetIdentifier={addonSetIdentifier}
            isMobileBilled={isMobileBilled}
            onSubmitPurchase={onSubmitPurchase}
            onDismissPurchase={onDismissPurchase}
          />
        </Content>
      </div>
    </div>
  );
}

// TODO: Replace with PriceHighlightText component
function FooterPrice({
  monthlyCost,
  monthlyDiscountedCost,
}: {
  monthlyCost: number;
  monthlyDiscountedCost?: number;
}) {
  if (monthlyDiscountedCost) {
    return (
      <span className={styles.footerPrice}>
        <span className={styles.footerBasePrice}>
          {formatCurrency(monthlyCost, "$", 0)}
        </span>
        {getFooterPrice(monthlyDiscountedCost)}
      </span>
    );
  }

  return (
    <span className={styles.footerPrice}>{getFooterPrice(monthlyCost)}</span>
  );
}

function getFooterPrice(monthlyCost: number) {
  return `${formatCurrency(monthlyCost, "$", 0)}/mo`;
}

function PurchaseModal({
  recurlyPublicKey,
  addonSetIdentifier,
  isMobileBilled,
  onSubmitPurchase,
  onDismissPurchase,
}: PurchaseAddonProps) {
  const onClickAddToPlan = () => {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "clicked_add_to_plan_for_reviews",
    });
  };
  return (
    <PurchaseFlowButton
      recurlyPublicKey={recurlyPublicKey}
      addonSetIdentifier={addonSetIdentifier}
      buttonSize={"large"}
      successAction={() => window.location.reload()}
      isMobileBilled={isMobileBilled}
      modalActionProps={{
        onClick: onSubmitPurchase,
      }}
      onDismiss={onDismissPurchase}
      onOpen={onClickAddToPlan}
    />
  );
}
