import { gql } from "@apollo/client";

export const FEATURES_BY_CATEGORY = gql`
  query FeaturesByCategory {
    featuresByCategory {
      featureCategory
      features {
        featureDetails
        featureId
        featureLabel
        learnMoreLink
      }
    }
  }
`;
