import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Content, Heading, Page } from "@jobber/components";
import { useQuery } from "@apollo/client";
import type {
  ClientReferralsFeatureQuery,
  Template,
} from "~/utilities/API/graphql";
import { IntlProvider } from "@translations/IntlProvider";
import {
  CampaignsBreadCrumb,
  breadCrumbMessages,
} from "jobber/campaigns/components/Breadcrumbs";
import { BottomBar } from "jobber/campaigns/components/Bottombar/BottomBar";
import { ErrorBanner } from "jobber/campaigns/components/ErrorBanner";
import { useCommsCampaignQuery } from "jobber/campaigns/hooks/useCommsCampaignQuery";
import { SelectableCards } from "jobber/campaigns/components/SelectableCards";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { usePreventEditCampaign } from "jobber/campaigns/hooks/usePreventEditCampaign/usePreventEditCampaign";
import { useCampaignAutomationsSplit } from "jobber/campaigns/hooks/useCampaignAutomationsSplit/useCampaignAutomationsSplit";
import { CLIENT_REFERRAL_FEATURE_QUERY } from "~/jobber/clients/leadAttribution/ClientLeadAttribution.graphql";
import styles from "./CampaignsTemplatePage.module.css";
import {
  useAutomatedTemplateOptions,
  useConfirmTemplateChange,
  useOneOffTemplateOptions,
  useTemplatePageNavigation,
} from "./hooks";
import { messages } from "./messages";
import { ConfirmTemplateSwitchModal } from "./components/ConfirmTemplateSwitchModal";

export interface CampaignTemplatePageProps {
  campaignId?: string;
}
export function CampaignsTemplatePage(props: CampaignTemplatePageProps) {
  return (
    <IntlProvider>
      <CampaignsTemplatePageInternal {...props} />
    </IntlProvider>
  );
}

// eslint-disable-next-line max-statements
function CampaignsTemplatePageInternal({
  campaignId,
}: CampaignTemplatePageProps) {
  const { formatMessage } = useIntl();
  const {
    campaignContent: { templateType },
  } = useCampaignWizardContext();
  const { isInCampaignAutomations } = useCampaignAutomationsSplit();

  const {
    data: clientReferralsFeatureData,
    loading: clientReferralsFeatureLoading,
  } = useQuery<ClientReferralsFeatureQuery>(CLIENT_REFERRAL_FEATURE_QUERY);
  const hasAccessToReferrals =
    clientReferralsFeatureData?.feature?.enabled ?? false;

  const oneOffTemplates = useOneOffTemplateOptions(hasAccessToReferrals);
  const automatedTemplates = useAutomatedTemplateOptions();
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template>(templateType);

  const { onBack, onNext, onCampaignNotFound } = useTemplatePageNavigation({
    campaignId,
    template: selectedTemplate,
  });

  const {
    data,
    loading,
    error: campaignError,
  } = useCommsCampaignQuery({
    campaignId,
    onCampaignNotFound,
  });

  usePreventEditCampaign({ campaignStatus: data?.commsCampaign?.status });

  const {
    confirmationModalOpen,
    handleOnConfirm,
    handleShowConfirmation,
    closeConfirmationModal,
    loadingChangeTemplate,
    error: changeTemplateError,
  } = useConfirmTemplateChange({
    campaign: data?.commsCampaign,
    selectedTemplate,
    onNext,
  });

  useEffect(() => {
    if (
      data?.commsCampaign?.templates?.nodes?.[0]?.type &&
      campaignId &&
      !loading
    ) {
      setSelectedTemplate(data.commsCampaign.templates.nodes[0].type);
    }
  }, [data, setSelectedTemplate, campaignId, loading]);

  return (
    <div className={styles.container}>
      <ErrorBanner isVisible={Boolean(campaignError) || changeTemplateError} />
      <CampaignsBreadCrumb
        currentStep={formatMessage(breadCrumbMessages.templateLabel)}
        onBack={onBack}
      />
      <div className={styles.templatePageContent}>
        <Page title={formatMessage(messages.selectATemplate)}>
          <Content spacing="large">
            <Content spacing="base">
              <Heading level={2}>
                {isInCampaignAutomations
                  ? formatMessage(messages.oneOffTemplateSubTitle)
                  : null}
              </Heading>
              <SelectableCards
                loading={
                  loading &&
                  clientReferralsFeatureLoading &&
                  Boolean(campaignId)
                }
                gridWidth={hasAccessToReferrals ? 4 : undefined}
                cards={oneOffTemplates}
                value={selectedTemplate}
                onChange={(value: Template) => setSelectedTemplate(value)}
                name={"template"}
                disabled={data?.commsCampaign?.isAutomated}
                disabledMessage={formatMessage(messages.unableToSwitch)}
              />
            </Content>
            {isInCampaignAutomations && (
              <Content spacing="base">
                <Heading level={2}>
                  {isInCampaignAutomations
                    ? formatMessage(messages.automatedTemplateSubtitle)
                    : null}
                </Heading>
                <SelectableCards
                  campaign={data?.commsCampaign}
                  loading={loading && Boolean(campaignId)}
                  gridWidth={hasAccessToReferrals ? 4 : undefined}
                  value={selectedTemplate}
                  cards={automatedTemplates}
                  onChange={(value: Template) => setSelectedTemplate(value)}
                  name={"template"}
                  disabled={
                    data?.commsCampaign && !data?.commsCampaign?.isAutomated
                  }
                  disabledMessage={formatMessage(messages.unableToSwitch)}
                />
              </Content>
            )}
          </Content>
        </Page>
        <ConfirmTemplateSwitchModal
          isOpen={confirmationModalOpen}
          close={closeConfirmationModal}
          onCancel={closeConfirmationModal}
          onConfirm={handleOnConfirm}
        />
      </div>
      <BottomBar
        loading={loadingChangeTemplate || loading}
        onNext={handleShowConfirmation}
        onCancel={onBack}
        step={1}
      />
    </div>
  );
}
