import React from "react";
import { Button, Emphasis, Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { initialStateMessages } from "./initialStateMessages";

export interface UkKycInitialStatePops {
  onClick: () => void;
}

export function UkKycInitialState(props: UkKycInitialStatePops) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Heading level={4}>
        <Emphasis variation={"highlight"}>
          {formatMessage(initialStateMessages.verifyFragment)}
        </Emphasis>{" "}
        {formatMessage(initialStateMessages.inSevenMinsFragment)}
      </Heading>
      <p>{formatMessage(initialStateMessages.initialRegistrationBody)}</p>
      <Button
        label={formatMessage(initialStateMessages.initialButtonText)}
        fullWidth={true}
        size={"base"}
        variation={"work"}
        onClick={props.onClick}
      />
    </>
  );
}
