import React from "react";
import { Text } from "@jobber/components/Text";
import styles from "./LinkPreview.module.css";
import { trackExternalLinkOpen } from "../utils";

interface LinkPreviewProps {
  title: string;
  altText: string;
  url: string;
  supportingLinkUrl?: URL;
}

export function LinkPreview({
  title,
  altText,
  url,
  supportingLinkUrl,
}: LinkPreviewProps) {
  const content = (
    <div className={styles.imageContainer}>
      <div>
        <img
          src={url.toString()}
          alt={altText}
          className={styles.image}
          data-testid="link-preview-image"
        />
      </div>
      <div className={styles.imageTitle}>
        <Text>{title}</Text>
        {supportingLinkUrl && <Text>{supportingLinkUrl.hostname}</Text>}
      </div>
    </div>
  );

  if (supportingLinkUrl) {
    return (
      <a
        onClick={() =>
          trackExternalLinkOpen(supportingLinkUrl.toString(), "link_preview")
        }
        target="_blank"
        rel="noopener noreferrer"
        href={supportingLinkUrl.toString()}
      >
        {content}
      </a>
    );
  }

  return content;
}
