import type { IntlFormatters } from "react-intl";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import type {
  BillingCycleNameType,
  PlanInfo,
  SubscriptionAddonPreview,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
  SubscriptionPreviewGroup,
} from "~/shared/billing/pricePreview/types";
import { messages } from "~/shared/billing/pricePreview/components/BillingDetails/messages";
import { BillingCycleName } from "~/shared/billing/pricePreview/types";

export function isAddonSelected(
  selectedAddonCodes: string[],
  subscriptionAddon: SubscriptionAddonPreview,
) {
  return selectedAddonCodes.includes(
    subscriptionAddon.monthlyBillingCycle.addonCode,
  );
}
export function formatPrice(price?: number) {
  const symbol = "$";

  if (price === undefined) {
    return "";
  }

  return currencyWithUnit(price.toString(), symbol).format();
}

export function pricePerPeriod(price?: number, period?: string | undefined) {
  const formattedPrice = formatPrice(price);
  return period ? `${formattedPrice}/${period}` : formattedPrice;
}

export function currentPreview(
  previews: SubscriptionPreviewGroup,
  billingCycle: BillingCycleNameType,
): SubscriptionPreview {
  return billingCycle === BillingCycleName.ANNUAL
    ? previews.annualPreview
    : previews.monthlyPreview;
}

export function savingsMessage(
  formatMessage: IntlFormatters["formatMessage"],
  monthlyCycleAnnualPrice?: number,
  annualCycleAnnualPrice?: number,
) {
  if (!monthlyCycleAnnualPrice || !annualCycleAnnualPrice) {
    return "";
  }

  const savings = monthlyCycleAnnualPrice - annualCycleAnnualPrice;

  return formatMessage(messages.savingsMessage, {
    amount: formatPrice(savings),
  });
}

export function getPurchaseDisclaimerInformation(
  selectedBillingCycle: BillingCycleNameType,
  planInfo: PlanInfo,
  discountInformation: SubscriptionDiscountGroup,
) {
  if (selectedBillingCycle.valueOf() === BillingCycleName.ANNUAL) {
    const discountUnitPrice = discountInformation.annualDiscount
      ?.planCostMonthlyDiscounted
      ? discountInformation.annualDiscount.planCostMonthlyDiscounted * 12
      : undefined;

    return {
      baseUnitPrice: planInfo.annualBillingCycle.annualPrice,
      discountUnitPrice: discountUnitPrice,
      discountEndDate: discountInformation.annualDiscount?.discountEndDate,
    };
  }

  return {
    baseUnitPrice: planInfo.monthlyBillingCycle.monthlyPrice,
    discountUnitPrice:
      discountInformation.monthlyDiscount?.planCostMonthlyDiscounted,
    discountEndDate: discountInformation.monthlyDiscount?.discountEndDate,
  };
}

export function getDiscountInformationByBillingCycle(
  selectedBillingCycle: BillingCycleNameType,
  discountInformation: SubscriptionDiscountGroup,
) {
  return selectedBillingCycle === BillingCycleName.MONTHLY
    ? discountInformation.monthlyDiscount
    : discountInformation.annualDiscount;
}

export function getAddonPurchaseDiscountInformation(
  selectedAddonCodes: string[],
  subscriptionAddons: SubscriptionAddonPreview[],
) {
  const selectedAddonsBaseUnitPrice = subscriptionAddons?.reduce(
    (total, subscriptionAddon) => {
      if (
        selectedAddonCodes.includes(
          subscriptionAddon.monthlyBillingCycle.addonCode,
        )
      ) {
        return total + subscriptionAddon.monthlyBillingCycle.monthlyCost;
      }
      return total;
    },
    0,
  );

  const selectedAddonsDiscountedUnitPrice = subscriptionAddons?.reduce(
    (total, subscriptionAddon) => {
      if (
        selectedAddonCodes.includes(
          subscriptionAddon.monthlyBillingCycle.addonCode,
        )
      ) {
        return (
          total +
          (subscriptionAddon.discountGroup?.monthlyAddonDiscount
            ?.addonCostMonthlyDiscounted || 0)
        );
      }
      return total;
    },
    0,
  );

  const addonsNextBillingDates = selectedAddonCodes.map(selectedAddonCode => {
    const addon = subscriptionAddons.find(
      subscriptionAddon =>
        subscriptionAddon.monthlyBillingCycle.addonCode === selectedAddonCode,
    );

    return addon?.previewGroup?.monthlyPreview?.nextBillingDate;
  });

  const addonsNextBillingDate = getEarliestDate(addonsNextBillingDates);

  const addonsDiscountEndDates = selectedAddonCodes.map(selectedAddonCode => {
    const addon = subscriptionAddons.find(
      subscriptionAddon =>
        subscriptionAddon.monthlyBillingCycle.addonCode === selectedAddonCode,
    );

    return addon?.discountGroup?.monthlyAddonDiscount?.discountEndDate;
  });

  const addonsDiscountEndDate = getEarliestDate(addonsDiscountEndDates);

  return {
    selectedAddonsBaseUnitPrice,
    addonsNextBillingDate,
    selectedAddonsDiscountedUnitPrice,
    addonsDiscountEndDate,
  };
}

function getEarliestDate(dates: (string | undefined)[]) {
  if (dates.length !== 0) {
    return dates.reduce((earliest, current) => {
      if (earliest && current) {
        return new Date(earliest) < new Date(current) ? earliest : current;
      }
    });
  }
}

export function calculateTaxTotal(
  preview: SubscriptionPreview,
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  const selectedAddonsTax = calculateSelectedAddonsTax(
    subscriptionAddons,
    selectedAddonCodes,
  );
  return (preview.proratedTax || 0) + selectedAddonsTax;
}

function calculateSelectedAddonsTax(
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  return subscriptionAddons.reduce((total, addon) => {
    if (selectedAddonCodes.includes(addon.monthlyBillingCycle.addonCode)) {
      return total + (addon.previewGroup.monthlyPreview.proratedTax || 0);
    }
    return total;
  }, 0);
}

function calculateSelectedAddonsCost(
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  return subscriptionAddons.reduce((total, addon) => {
    if (selectedAddonCodes.includes(addon.monthlyBillingCycle.addonCode)) {
      return total + addon.previewGroup.monthlyPreview.proratedTotal;
    }
    return total;
  }, 0);
}

export function calculatePurchaseTotal(
  shouldShowTax: boolean,
  subscriptionPreview: SubscriptionPreview,
  subscriptionAddons: SubscriptionAddonPreview[],
  selectedAddonCodes: string[],
) {
  const selectedAddonsCost = calculateSelectedAddonsCost(
    subscriptionAddons,
    selectedAddonCodes,
  );

  const taxTotal = calculateTaxTotal(
    subscriptionPreview,
    subscriptionAddons,
    selectedAddonCodes,
  );

  const totalWithTax =
    subscriptionPreview.proratedTotal +
    subscriptionPreview.totalCredit + // totalCredit is a negative value
    selectedAddonsCost;

  const totalWithoutTax = totalWithTax - taxTotal;

  const calculatedTotal = shouldShowTax ? totalWithTax : totalWithoutTax;

  // Ensure the displayed total is not negative
  return Math.max(calculatedTotal, 0);
}
