import React from "react";
import { useIntl } from "react-intl";
import { Divider } from "@jobber/components/Divider";
import { Text } from "@jobber/components/Text";
import type {
  BillingCycleNameType,
  SubscriptionAddonPreview,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
} from "~/shared/billing/pricePreview/types";
import { BillingCycleName } from "~/shared/billing/pricePreview/types";
import { PurchaseTotal } from "~/jobber/billing/components/PurchaseTotal/PurchaseTotal";
import { calculateTaxTotal } from "~/shared/billing/pricePreview/utils";
import { AddonBillingDetails } from "~/shared/billing/pricePreview/components/AddonBillingDetails/AddonBillingDetails";
import styles from "./BillingDetails.module.css";
import { BillingCycleRadioInput } from "./components/BillingCycleRadioInput";
import { TaxInfo } from "./components/TaxInfo";
import { messages } from "./messages";
import { DiscountInfo } from "./components/DiscountInfo/DiscountInfo";
import { CreditInfo } from "./components/CreditInfo/CreditInfo";

interface BillingDetailsProps {
  monthlyPrice: number;
  annualPrice: number;
  savingsMessage: string;
  subscriptionPreview: SubscriptionPreview;
  selectedBillingCycle: BillingCycleNameType;
  viewingCurrentPlan: boolean;
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  discountInformation: SubscriptionDiscountGroup;
  shouldShowTax: boolean;
  purchaseTotal: number;
  onSetSelectedCycle(cycle: BillingCycleNameType): void;
}

export function BillingDetails({
  monthlyPrice,
  annualPrice,
  savingsMessage,
  subscriptionPreview,
  selectedBillingCycle,
  viewingCurrentPlan,
  discountInformation,
  subscriptionAddons,
  selectedAddonCodes,
  purchaseTotal,
  shouldShowTax,
  onSetSelectedCycle,
}: BillingDetailsProps) {
  const { formatMessage } = useIntl();

  const taxTotal = calculateTaxTotal(
    subscriptionPreview,
    subscriptionAddons,
    selectedAddonCodes,
  );

  return (
    <>
      <Divider direction={"horizontal"} />
      <BillingCycleRadioInput
        selected={selectedBillingCycle === BillingCycleName.MONTHLY}
        option={BillingCycleName.MONTHLY}
        label={formatMessage(messages.billedMonthly)}
        price={monthlyPrice}
        onSetSelectedCycle={onSetSelectedCycle}
      />
      <BillingCycleRadioInput
        selected={selectedBillingCycle === BillingCycleName.ANNUAL}
        option={BillingCycleName.ANNUAL}
        label={formatMessage(messages.billedYearly)}
        price={annualPrice}
        onSetSelectedCycle={onSetSelectedCycle}
        savingsMessage={savingsMessage}
      />
      {!viewingCurrentPlan && (
        <DiscountAndCreditLineItems
          subscriptionPreview={subscriptionPreview}
          discountInformation={discountInformation}
          selectedBillingCycle={selectedBillingCycle}
        />
      )}
      {selectedAddonCodes.length > 0 && (
        <>
          <Divider direction={"horizontal"} />
          <AddonBillingDetails
            subscriptionAddons={subscriptionAddons}
            selectedAddonCodes={selectedAddonCodes}
          />
        </>
      )}
      {shouldShowTax && (
        <>
          <Divider direction={"horizontal"} />
          <TaxInfo taxAmount={taxTotal} taxRate={subscriptionPreview.taxRate} />
        </>
      )}
      <Divider direction={"horizontal"} />
      {!viewingCurrentPlan &&
        subscriptionPreview.proratedTotal !== undefined && (
          <PurchaseTotal
            startBillingDate={new Date(subscriptionPreview.startBillingDate)}
            purchaseTotal={purchaseTotal}
          />
        )}
      <div className={styles.disclaimer}>
        <Text variation="subdued" align="end">
          {formatMessage(messages.allPricesUSD)}
        </Text>
      </div>
      <Divider direction={"horizontal"} />
    </>
  );
}

function DiscountAndCreditLineItems({
  subscriptionPreview,
  discountInformation,
  selectedBillingCycle,
}: {
  subscriptionPreview: SubscriptionPreview;
  discountInformation: SubscriptionDiscountGroup;
  selectedBillingCycle: BillingCycleNameType;
}) {
  return (
    <div>
      <div className={styles.rowContainer}>
        <DiscountInfo
          subscriptionPreview={subscriptionPreview}
          discountInformation={discountInformation}
          selectedBillingCycle={selectedBillingCycle}
        />
      </div>
      <div className={styles.rowContainer}>
        <CreditInfo subscriptionPreview={subscriptionPreview} />
      </div>
    </div>
  );
}
