import { defineMessages } from "react-intl";

export const messages = defineMessages({
  priceLabel: {
    id: "upgradePage.planComparison.priceLabel",
    defaultMessage:
      "All prices are in USD. Subscription prices exclude sales tax where applicable.",
    description: "Price label for the plan comparison table",
  },
});
