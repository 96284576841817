import React, { useMemo } from "react";
import type { IconColorNames, IconNames } from "@jobber/design";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import classNames from "classnames";
import { Markdown } from "@jobber/components";
import type {
  AiAssistantAction,
  AiAssistantFollowup,
  AiAssistantImage,
} from "~/utilities/API/graphql";
import { AiAssistantActionIntent } from "~/utilities/API/graphql";
import { ChatWithSuccessButton } from "./ChatWithSuccessButton";
import { SupportQuestionFollowup } from "./SupportQuestionFollowup";
import styles from "./ConversationListItem.module.css";
import { Feedback } from "./Feedback";
import { ConversationPills } from "./ConversationPills";
import { LinkPreview } from "./LinkPreview";
import { trackExternalLinkOpen } from "../utils";

export enum messageType {
  ERROR = "error",
  REQUEST = "request",
  RESPONSE = "response",
  PARTIAL_RESPONSE = "partial_response",
  SYSTEM = "system",
}

export interface ConversationListItem {
  readonly actions: AiAssistantAction[];
  readonly content: string;
  readonly footnotes?: string[];
  readonly icon?: IconNames;
  readonly iconColor?: IconColorNames;
  readonly id: string;
  readonly requestId: string;
  readonly type: messageType;
  readonly onClick?: (prompt: string) => void;
  readonly visuals: AiAssistantImage[];
  readonly followups: AiAssistantFollowup[];
}

export interface ConversationListItemProps {
  readonly item: ConversationListItem;
  readonly showFeedbackOptions?: boolean;
}

export function ConversationListItem({
  item,
  showFeedbackOptions,
}: ConversationListItemProps) {
  const { content, icon, iconColor, type } = item;

  return (
    <div className={styles.conversationListItem}>
      {type === messageType.REQUEST ? (
        <Request content={content} />
      ) : type === messageType.ERROR ? (
        <Error content={content} icon={icon} iconColor={iconColor} />
      ) : (
        <Response item={item} showFeedbackOptions={showFeedbackOptions} />
      )}
    </div>
  );
}

interface RequestProps {
  readonly content: string;
}

const Request = ({ content }: RequestProps) => {
  return <div className={styles.requestContainer}>{content}</div>;
};

interface ErrorProps {
  readonly content: string;
  readonly icon?: IconNames;
  readonly iconColor?: IconColorNames;
}

const Error = ({ content, icon, iconColor }: ErrorProps) => {
  return (
    <div className={classNames(styles.requestContainer, styles.error)}>
      {icon && iconColor && (
        <div className={styles.icon}>
          <Icon name={icon} color={iconColor} />
        </div>
      )}
      <div>{content}</div>
    </div>
  );
};

const Response = ({ item, showFeedbackOptions }: ConversationListItemProps) => {
  const {
    actions,
    content,
    footnotes,
    icon,
    iconColor,
    id,
    requestId,
    onClick,
    visuals,
    followups,
  } = item;

  const images = useMemo(
    () =>
      visuals.filter(v => v.__typename === "AiAssistantImage" && v.imageUrl),
    [visuals],
  );

  return (
    <div id={id.toString()} className={styles.responseContainer}>
      {icon && iconColor && (
        <div className={styles.icon}>
          <Icon name={icon} color={iconColor} />
        </div>
      )}
      <div className={styles.responseContentColumn}>
        <Markdown
          onLinkClick={({ href }) => trackExternalLinkOpen(href, "inline")}
          content={content}
          basicUsage={false}
          externalLink={true}
        />
        {images.map((image, index) => (
          <LinkPreview
            key={index}
            title={image.title}
            url={image.imageUrl!}
            altText={image.altText}
            supportingLinkUrl={
              image.supportingLinkUrl
                ? new URL(image.supportingLinkUrl)
                : undefined
            }
          />
        ))}
        {actions.map((action, index) => {
          const commonProps = {
            key: index,
            requestId,
            message: action.metadata ?? "",
          };
          switch (action.intent) {
            case AiAssistantActionIntent.SHOW_SUPPORT_PILLS:
              return <SupportQuestionFollowup {...commonProps} />;
            case AiAssistantActionIntent.LIVE_HELP_DIALOG:
            case AiAssistantActionIntent.TRANSFER_TO_SUPPORT:
              return <ChatWithSuccessButton {...commonProps} />;
            default:
              return undefined;
          }
        })}
        {footnotes && footnotes.length > 0 && (
          <Text variation="subdued" size="small">
            <Emphasis variation="italic">{footnotes.join(" ")}</Emphasis>
          </Text>
        )}
        {followups.length > 0 && onClick && (
          <ConversationPills prompts={followups} onClick={onClick} />
        )}
        {showFeedbackOptions && item.type == messageType.RESPONSE && (
          <Feedback requestId={requestId} />
        )}
      </div>
    </div>
  );
};
