import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "billing.subscriptionAddons.title",
    defaultMessage: "Add-ons",
    description: "Page title",
  },
  unableToPurchaseMessage: {
    id: "billing.subscriptionAddons.unableToPurchaseMessage",
    defaultMessage: "Add-on subscriptions are not available for this account",
    description: "Message shown when add-on subscriptions are not available",
  },
});
