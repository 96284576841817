import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Form, Option, Select } from "@jobber/components";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import { useFormState } from "@jobber/hooks/useFormState";
import {
  ClientNotificationId,
  NotificationDeliveryMethod,
} from "~/utilities/API/graphql";
import { useFollowUpMessageSettingsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useFollowUpMessageSettingsQuery";
import { useUpdateNotification } from "jobber/reviews/views/ReviewsPage/hooks/useUpdateNotification";
import { ErrorLayout } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ErrorLayout/ErrorLayout";
import { LoadingLayout } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/LoadingLayout/LoadingLayout";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { MessagePreviewer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessagePreviewer/ReviewMessagePreviewer";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";
import type { Schedules } from "../types";

interface FollowUpMessageSettingsProps {
  scheduleValue: Schedules;
  hasDPN: boolean;
}

export function FollowUpMessageSettings({
  scheduleValue,
  hasDPN,
}: FollowUpMessageSettingsProps) {
  const { formatMessage } = useIntl();
  const { drawerActions } = useContext(ReviewsSettingsDrawerContext);

  const {
    loading,
    error: fetchMethodError,
    schedules,
    templates,
  } = useFollowUpMessageSettingsQuery();

  const { scheduleId, scheduleDeliveryMethod } = useMemo(() => {
    const schedule = schedules.find(
      node => node?.offset?.value === scheduleValue,
    );
    return {
      scheduleId: schedule?.id,
      scheduleDeliveryMethod: schedule?.deliveryMethod,
    };
  }, [schedules, scheduleValue]);

  const { updateNotification } = useUpdateNotification();
  const [deliveryMethod, setDeliveryMethod] =
    useState<NotificationDeliveryMethod>(
      scheduleDeliveryMethod || NotificationDeliveryMethod.EMAIL,
    );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [{ isDirty }, setFormState] = useFormState();
  const [errorState, setErrorState] = useState({
    dismissed: false,
    shouldShow: false,
  });

  useEffect(() => {
    if (scheduleDeliveryMethod) {
      setDeliveryMethod(scheduleDeliveryMethod);
    }
  }, [scheduleDeliveryMethod]);

  const handleSubmit = async () => {
    if (!isDirty) {
      drawerActions?.closeSideDrawer();
    } else if (scheduleId !== undefined && isDirty) {
      setIsSubmitting(true);

      const errors = await updateNotification({
        clientNotificationId: ClientNotificationId.REVIEW_REQUEST_REMINDER,
        deliveryMethod: deliveryMethod as NotificationDeliveryMethod,
        scheduleId,
      });

      if (errors.length > 0) {
        setErrorState({ dismissed: false, shouldShow: true });
      } else {
        drawerActions?.closeSideDrawer();
        showToast({
          message: formatMessage(messages.messageSettingsSaveSuccess),
        });
      }

      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.backButton}>
        <Button
          type="tertiary"
          variation="subtle"
          icon="longArrowLeft"
          ariaLabel={formatMessage(messages.messageSettingsBackAriaLabel)}
          onClick={() => {
            drawerActions?.goTo(DrawerView.ManageSettings);
          }}
        />
      </div>
      <div className={styles.settingsContainer}>
        {errorState.shouldShow && (
          <ErrorLayout
            hasDeliveryMethodError={fetchMethodError !== undefined}
            hasGeneralError={true}
          />
        )}

        <Form onSubmit={handleSubmit} onStateChange={setFormState}>
          {loading ? (
            <LoadingLayout />
          ) : (
            <>
              <Select
                defaultValue={scheduleDeliveryMethod}
                placeholder={formatMessage(messages.messageSettingsPlaceholder)}
                name={formatMessage(messages.messageSettingsSelectName)}
                value={deliveryMethod}
                onChange={(value: NotificationDeliveryMethod) => {
                  if (errorState.shouldShow) {
                    setErrorState({ dismissed: false, shouldShow: false });
                  }
                  setDeliveryMethod(value);
                }}
              >
                <Option value={NotificationDeliveryMethod.EMAIL}>
                  {formatMessage(messages.emailOptionLabel)}
                </Option>
                <Option value={NotificationDeliveryMethod.SMS}>
                  {formatMessage(messages.textOptionLabel)}
                </Option>
              </Select>
              <div className={styles.editContainer}>
                <MessagePreviewer
                  template={
                    deliveryMethod === "EMAIL"
                      ? templates?.email
                      : templates?.sms
                  }
                  hasDPN={hasDPN}
                />
              </div>
              <div className={styles.footer}>
                <Button
                  type="primary"
                  size="large"
                  submit={true}
                  fullWidth={true}
                  ariaLabel={formatMessage(
                    messages.messageSettingsSaveAriaLabel,
                  )}
                  label={formatMessage(messages.messageSettingsSaveLabel)}
                  disabled={isSubmitting}
                />
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
}
