import { gql } from "@apollo/client";

export const ACCOUNT_ADDONS_INFO = gql`
  query AccountAddonsInfo {
    accountAddonsInfo {
      addons {
        currency
        identifier
        isActive
        name
        currentBillingCycle {
          addonCode
          monthlyCost
          name
          renewalDate
          futureStartBillingOnDate
        }
        monthlyBillingCycle {
          monthlyCost
        }
        discountGroup {
          monthlyAddonDiscount {
            addonCostMonthlyDiscounted
            hasMultipleActiveDiscounts
            remainingDiscountedFullMonths
            discountType
            discountAmount
          }
        }
      }
    }
  }
`;

export const ACCOUNT_ADDON_REMOVE = gql`
  mutation SubscriptionAddonRemove($input: AddonRemoveInput!) {
    subscriptionAddonRemove(input: $input) {
      success
      userErrors {
        message
        path
      }
    }
  }
`;
