import React, { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { SideDrawer } from "components/SideDrawer";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ManageSettings } from "./ManageSettings";
import { InitialMessageSettings } from "./InitialMessageSettings";
import { messages } from "./messages";
import { FollowUpMessageSettings } from "./FollowUpMessageSettings";
import { Schedules } from "./types";

export interface ReviewsSettingsProps {
  showCustomizations: boolean;
}
export function ReviewsSettings({
  showCustomizations,
}: ReviewsSettingsProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { currentView, drawerActions } = useContext(
    ReviewsSettingsDrawerContext,
  );

  const drawerRenderEventName = "Viewed Reviews Message Customization";

  const currentDrawerProps = useMemo(() => {
    switch (currentView) {
      case DrawerView.MessageSettings:
        Amplitude.TRACK_EVENT(drawerRenderEventName, {
          interaction: "initial_message",
          source: "drawer",
        });

        return {
          title: formatMessage(messages.commsSettingsTitle),
          component: (
            <InitialMessageSettings showCustomizations={showCustomizations} />
          ),
        };
      case DrawerView.FollowUpMessageSettings1:
        Amplitude.TRACK_EVENT(drawerRenderEventName, {
          interaction: "1st_follow_up",
          source: "drawer",
        });

        return {
          title: formatMessage(messages.followUpTitle),
          component: (
            <FollowUpMessageSettings
              scheduleValue={Schedules.RemindIn3Days}
              hasDPN={showCustomizations}
            />
          ),
        };
      case DrawerView.FollowUpMessageSettings2:
        Amplitude.TRACK_EVENT(drawerRenderEventName, {
          interaction: "2nd_follow_up",
          source: "drawer",
        });
        return {
          title: formatMessage(messages.followUpTitle),
          component: (
            <FollowUpMessageSettings
              scheduleValue={Schedules.RemindIn5Days}
              hasDPN={showCustomizations}
            />
          ),
        };
      case DrawerView.ManageSettings:
      default:
        return {
          title: formatMessage(messages.title),
          component: <ManageSettings />,
        };
    }
  }, [currentView, formatMessage, showCustomizations]);

  return (
    <SideDrawer
      title={currentDrawerProps.title}
      open={true}
      requestDrawerClose={() => {
        drawerActions?.closeSideDrawer();

        Amplitude.TRACK_EVENT("Interacted with Reviews Message Customization", {
          interaction: "closed",
        });
      }}
    >
      {currentDrawerProps.component}
    </SideDrawer>
  );
}
