import { gql } from "@apollo/client";

export const reviewsNotificationScheduleFragment = gql`
  fragment reviewsNotificationSchedule on NotificationSchedule {
    id
    offset {
      value
      unit
    }
    deliveryMethod
    at
  }
`;

export const stringWithDefaultFieldsFragment = gql`
  fragment stringWithDefaultFields on StringWithDefault {
    current
    default
  }
`;

export const variableSectionFragment = gql`
  fragment variableSection on TemplateVariableSection {
    label
    variables {
      nodes {
        ...variableFields
      }
    }
  }
`;

export const variableFieldsFragment = gql`
  fragment variableFields on TemplateVariable {
    label
    name
    sample
  }
`;
