import { type ApolloError, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import type { CommsUkKycRegistrationDetailsQuery } from "~/utilities/API/graphql";

export const GET_UK_KYC_REGISTRATION_DETAILS_QUERY = gql`
  query commsUkKycRegistrationDetails {
    commsUkKycRegistrationDetails {
      registration {
        acknowledgedAt
        endUserType
        createdAt
        failureReason
        id
        registrationId
        status
        updatedAt
      }
      registrationRequirement
    }
  }
`;
export interface UseUkKycRegistrationDetailsQuery {
  loading: boolean;
  data?: CommsUkKycRegistrationDetailsQuery;
  error?: ApolloError;
}

export function useUkKycRegistrationDetailsQuery(): UseUkKycRegistrationDetailsQuery {
  const { data, error, loading } = useQuery<CommsUkKycRegistrationDetailsQuery>(
    GET_UK_KYC_REGISTRATION_DETAILS_QUERY,
  );

  return {
    data,
    loading,
    error,
  };
}
