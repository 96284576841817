import { generatePath, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_CONTENT_PATH,
  CAMPAIGNS_LANDING_PAGE_PATH,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/components/Bottombar/messages";
import { Template } from "~/utilities/API/graphql";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";

export function useClientSegmentPageNavigation(
  templateType: string = Template.RE_ENGAGE,
  campaignId?: string,
) {
  const history = useHistory();
  const search = history.location.search;
  const { formatMessage } = useIntl();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const fromLanding = query.get("from_landing") === "1";
  const { segmentPageOnBackPath } = useCampaignUpsellSplit();
  const backPath = segmentPageOnBackPath({ campaignId, fromLanding });

  function onCancel() {
    history.push(backPath);
  }

  function onCampaignNotFound() {
    history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
  }

  function onNext() {
    let segmentPath;
    if (campaignId) {
      segmentPath = generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
        campaignId: campaignId,
      });
    } else {
      segmentPath = generatePath(CAMPAIGNS_CONTENT_PATH, { templateType });
    }
    history.push({ pathname: segmentPath, search: history.location.search });
  }

  return {
    onCancel,
    onNext,
    onCampaignNotFound,
    onCancelLabel:
      backPath === CAMPAIGNS_LANDING_PAGE_PATH
        ? formatMessage(messages.cancelButton)
        : formatMessage(messages.backButton),
  };
}
