import { defineMessages } from "react-intl";

export const messages = defineMessages({
  topPriorityHeading: {
    id: "setupWizard.topPriorityPage.topPriorityHeading",
    defaultMessage: "What is your top priority?",
    description: "Top priority heading for the setup wizard",
  },
  topPriorityCopy: {
    id: "setupWizard.topPriorityPage.topPriorityCopy",
    defaultMessage:
      "While Jobber is committed to helping you succeed in all aspects, which is your immediate focus for this year?",
    description: "Top priority copy for the setup wizard",
  },
  topPriorityPlaceholder: {
    id: "setupWizard.topPriorityPage.topPriorityPlaceholder",
    defaultMessage: "What is your top priority right now?",
    description: "Top priority placeholder for the setup wizard",
  },
  topPriorityError: {
    id: "setupWizard.topPriorityPage.topPriorityError",
    defaultMessage: "Please tell us your top priority",
    description: "Top priority error for the setup wizard",
  },
  topPriorityWinMoreWork: {
    id: "setupWizard.topPriorityPage.topPriorityWinMoreWork",
    defaultMessage: "Win more work",
    description: "Top priority 'win more work' option for the setup wizard",
  },
  topPriorityIncreaseEfficiency: {
    id: "setupWizard.topPriorityPage.topPriorityIncreaseEfficiency",
    defaultMessage: "Increase efficiency",
    description:
      "Top priority 'increase efficiency' option for the setup wizard",
  },
  topPriorityGetPaidFaster: {
    id: "setupWizard.topPriorityPage.topPriorityGetPaidFaster",
    defaultMessage: "Get paid faster",
    description: "Top priority 'get paid faster' option for the setup wizard",
  },
});
