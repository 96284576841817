import React, { type MutableRefObject, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@jobber/components/Grid";
import { Page } from "@jobber/components/Page";
import { useMutation } from "@apollo/client";
import { PurchaseFormContextProvider } from "jobber/billing/hooks/PurchaseFormContext";
import { useStoredUpdateResult } from "jobber/billing/hooks/useStoredUpdateResult";
import {
  EditBillingInfo,
  type EditBillingInfoRef,
} from "jobber/billing/components/EditBillingInfo";
import {
  CONFIRM_SUBSCRIPTION_INTERACTION,
  type TrackingDetails,
  trackFailedSubmitRecurlyForm,
  trackInteractedWithButton,
  trackInteractedWithInput,
} from "jobber/billing/utils/trackInteractions";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import type {
  BillingCycleName,
  MutationErrors,
  SubscriptionUpdateMutation,
  SubscriptionUpdateMutationVariables,
  SubscriptionUpdatePayload,
} from "~/utilities/API/graphql";
import type {
  FieldErrorState,
  FormErrorState,
} from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { csrfToken } from "utilities/csrfToken";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { PurchaseFormErrors } from "jobber/billing/components/PurchaseFormErrors";
import { formatCurrency } from "utilities/formatCurrency";
import { SubscriptionAddonCards } from "./components/SubscriptionAddonCards/SubscriptionAddonCards";
import { CheckoutSummary } from "./components/CheckoutSummary";
import { messages } from "./messages";
import styles from "./Checkout.module.css";
import { SUBSCRIPTION_UPDATE } from "./Checkout.graphql";

interface CheckoutProps {
  recurlyPublicKey: string;
  planSetIdentifier: string;
  billingCycleName: BillingCycleName;
  subscriptionAddons: SubscriptionAddonPreview[];
}

const trackingDetails: TrackingDetails = {
  name: "Checkout",
};

// eslint-disable-next-line max-statements
export function Checkout(checkoutProps: CheckoutProps) {
  const { formatMessage } = useIntl();
  const {
    recurlyPublicKey,
    planSetIdentifier,
    billingCycleName,
    subscriptionAddons,
  } = checkoutProps;
  const editBillingInfoFormRef =
    useRef() as MutableRefObject<EditBillingInfoRef>;
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycleName>(billingCycleName);
  const [selectedAddonCodes, setSelectedAddonCodes] = useState<string[]>([]);
  const [displayedPurchaseTotal, setDisplayedPurchaseTotal] = useState<
    number | undefined
  >();
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<FormErrorState>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { setStoredUpdateBannerMessage, setStoredUpdateResult } =
    useStoredUpdateResult();

  const [subscriptionUpdate] = useMutation<
    SubscriptionUpdateMutation,
    SubscriptionUpdateMutationVariables
  >(SUBSCRIPTION_UPDATE);

  const hasValidationError = Object.values(validationErrors).some(
    error => !!error,
  );

  return (
    <PurchaseFormContextProvider submitting={isSubmitting}>
      <div className={styles.container}>
        <Page title={formatMessage(messages.title)}>
          <Grid>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 8 }}>
              <PurchaseFormErrors errors={submissionErrors} />
              <EditBillingInfo
                isPurchasing={true}
                recurlyPublicKey={recurlyPublicKey}
                ref={editBillingInfoFormRef}
                trackingDetails={trackingDetails}
                onSubmitSuccess={callPurchaseMutation}
                onSubmitError={onSubmitError}
                onValidationError={onValidationError}
              />
              {!isSubmitting && (
                <SubscriptionAddonCards
                  subscriptionAddons={subscriptionAddons}
                  selectedAddonCodes={selectedAddonCodes}
                  onSelectAddon={(addonCode: string) => {
                    trackAddonSelection(addonCode, "added");
                    setSelectedAddonCodes([...selectedAddonCodes, addonCode]);
                  }}
                  onDeselectAddon={(addonCode: string) => {
                    trackAddonSelection(addonCode, "removed");
                    setSelectedAddonCodes(
                      selectedAddonCodes.filter(code => code !== addonCode),
                    );
                  }}
                />
              )}
            </Grid.Cell>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
              <CheckoutSummary
                trackingDetails={trackingDetails}
                planSetIdentifier={planSetIdentifier}
                selectedBillingCycle={selectedBillingCycle}
                enablePurchaseButton={!hasValidationError}
                subscriptionAddons={subscriptionAddons}
                selectedAddonCodes={selectedAddonCodes}
                displayedPurchaseTotal={displayedPurchaseTotal}
                onSetSelectedBillingCycle={setSelectedBillingCycle}
                onSetPurchaseTotal={handlePurchaseTotalUpdate}
                onConfirmSubscription={handleConfirmSubscription}
              />
            </Grid.Cell>
          </Grid>
        </Page>
      </div>
    </PurchaseFormContextProvider>
  );

  function handlePurchaseTotalUpdate(purchaseTotal: number) {
    setDisplayedPurchaseTotal(purchaseTotal);
  }

  async function handleConfirmSubscription() {
    setSubmissionErrors([]);
    setIsSubmitting(true);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: CONFIRM_SUBSCRIPTION_INTERACTION,
      addonCodes: selectedAddonCodes,
    });

    editBillingInfoFormRef.current &&
      (await editBillingInfoFormRef.current.submit());
  }

  async function callPurchaseMutation() {
    const result = await purchaseMutation();

    const userErrors: MutationErrors[] | undefined =
      result?.data?.subscriptionUpdate?.userErrors;

    const hasAddonErrors = hasAddonPurchaseError(userErrors);

    if (result?.data?.subscriptionUpdate?.success) {
      return onSubmitSuccess(result.data.subscriptionUpdate, hasAddonErrors);
    }
    if (userErrors?.length) {
      return onSubmitError(userErrors);
    }
  }

  async function purchaseMutation() {
    try {
      const result = await subscriptionUpdate({
        variables: {
          input: {
            planSetIdentifier: planSetIdentifier,
            billingCycleName: selectedBillingCycle,
            selectedAddonCodes: selectedAddonCodes,
            displayedPurchaseTotal: displayedPurchaseTotal
              ? formatCurrency(displayedPurchaseTotal, "$", 2)
              : undefined,
          },
        },
      });
      return result;
    } catch (error) {
      onSubmitError([
        { message: formatMessage(messages.defaultSubmitError), path: [] },
      ]);
    }
  }

  async function onSubmitSuccess(
    result: SubscriptionUpdatePayload,
    hasAddonErrors?: boolean,
  ) {
    if (result.shouldRecordFirstTimeSubscriptionEvents) {
      await handlePostSubscriptionUpdates();
    }

    const addonsMessage = hasAddonErrors
      ? result.userErrors[0].message
      : undefined;

    if (result.successRedirectUrl) {
      return redirect(result.successRedirectUrl, addonsMessage);
    }
  }

  function redirect(url: string, addonsMessage?: string) {
    if (addonsMessage) {
      setStoredUpdateBannerMessage(addonsMessage);
    } else {
      setStoredUpdateResult(formatMessage(messages.successfulUpdate));
    }
    window.location.href = url;
  }

  function onSubmitError(errors: MutationErrors[]) {
    setIsSubmitting(false);
    setSubmissionErrors(errors.map(error => error.message));
    trackFailedSubmitRecurlyForm(trackingDetails);
  }

  function onValidationError(newErrorState: FieldErrorState) {
    const fieldName = newErrorState.field;

    setValidationErrors({
      ...validationErrors,
      [fieldName]: newErrorState.message,
    });
  }

  async function handlePostSubscriptionUpdates() {
    const headers = new Headers([
      ["X-CSRF-Token", csrfToken],
      ["Content-type", "application/json"],
    ]);

    const request = new Request("checkout/set_first_subscription_events", {
      method: "PUT",
      credentials: "include",
      headers,
    });

    try {
      await fetch(request);
    } catch (error) {
      Rollbar.EXECUTE(
        `Post subscription updates failed: ${error.message}`,
        new Error("Checkout"),
      );
    }
  }

  function trackAddonSelection(addonCode: string, action: string) {
    const addonName = subscriptionAddons.find(
      addon => addon.monthlyBillingCycle?.addonCode === addonCode,
    )?.name;

    trackInteractedWithInput({
      ...trackingDetails,
      addonName: addonName,
      interaction: "addon_selection",
      action: action,
    });
  }
}

function hasAddonPurchaseError(userErrors: MutationErrors[] | undefined) {
  return userErrors?.some(userError => {
    return userError.path.includes("addons_purchase");
  });
}
