import { defineMessages } from "react-intl";

export const messages = defineMessages({
  confirmDeleteCustomFieldTitle: {
    id: "confirmDeleteCustomFieldTitle",
    defaultMessage: "Delete {customFieldName} custom field",
    description: "Title for the delete custom field modal",
  },
  confirmDeleteCustomFieldDescription: {
    id: "confirmDeleteCustomFieldDescription",
    defaultMessage:
      "Deleting the {customFieldName} custom field will permanently remove all of its associated values from your Jobber account. This action cannot be reversed.",
    description:
      "Explains to the user what will happen if they confirm the deletion of the custom field",
  },
});
