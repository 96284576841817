import { Button, Heading, Option, Select } from "@jobber/components";
import React from "react";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./UkKycComplianceRegistrationStart.module.css";
import { complianceStartMessages } from "./complianceStartMessages";

export interface UkKycComplianceRegistrationStartProps {
  onClick: (endUserType: string) => void;
}

const endUserTypeOptions = {
  business: "business",
  individual: "individual",
};

const defaultEndUserType = endUserTypeOptions.business;

function UkKycComplianceRegistrationStartContent(
  props: UkKycComplianceRegistrationStartProps,
) {
  const [endUserType, setEndUserType] = React.useState<string | undefined>(
    defaultEndUserType,
  );
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Heading level={2}>
          {formatMessage(complianceStartMessages.gettingStartedHeader)}
        </Heading>
        <Select
          placeholder={"I am registering as a"}
          value={defaultEndUserType}
          onChange={e => setEndUserType(e.toString())}
        >
          <Option value={endUserTypeOptions.business}>
            {formatMessage(complianceStartMessages.business)}
          </Option>
          <Option value={endUserTypeOptions.individual}>
            {formatMessage(complianceStartMessages.individual)}
          </Option>
        </Select>
        <Button
          label={formatMessage(complianceStartMessages.continueButton)}
          fullWidth={true}
          size={"base"}
          variation={"work"}
          onClick={() => {
            if (endUserType) {
              props.onClick(endUserType);
            }
          }}
        />
      </div>
    </div>
  );
}

export function UkKycComplianceRegistrationStart(
  props: UkKycComplianceRegistrationStartProps,
) {
  return (
    <IntlProvider>
      <UkKycComplianceRegistrationStartContent {...props} />
    </IntlProvider>
  );
}
