import { defineMessages } from "react-intl";

export const messages = defineMessages({
  featureAvailable: {
    id: "upgradePage.featureTable.featureAvailable",
    defaultMessage: "Feature available",
    description: "feature available aria label",
  },
  featureUnavailable: {
    id: "upgradePage.featureTable.featureUnavailable",
    defaultMessage: "Feature not available",
    description: "feature unavailable aria label",
  },
  learnMoreLabel: {
    id: "upgradePage.featureTable.learnMoreLabel",
    defaultMessage: "Learn more",
    description: "learn more label",
  },
  featureCost: {
    id: "upgradePage.featureTable.featureCost",
    defaultMessage: "{percent}% + {centsPerCharge}¢",
    description: "feature cost label",
  },
});
