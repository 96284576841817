import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { DrawerMode } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/messages";
import { ClientSegmentTable } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentTable/ClientSegmentTable";
import { Segment } from "~/utilities/API/graphql";
import { useClientSegmentTable } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentTable";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import styles from "./ClientSegmentDrawer.module.css";
import type { CriteriaProps } from "./ClientSegmentDrawer";
// eslint-disable-next-line import/no-internal-modules
import { useClientSegmentData } from "../../hooks/useClientSegmentData";

export interface ClientSegmentViewProps {
  segmentName: string;

  selectedSegment: Segment;
  closeSideDrawer(): void;
  setDrawerState(drawerState: DrawerMode): void;

  criteria: CriteriaProps;
}

export function ClientSegmentView({
  segmentName,
  criteria,
  selectedSegment,
  closeSideDrawer,
  setDrawerState,
}: ClientSegmentViewProps) {
  const { formatMessage } = useIntl();

  const {
    campaignSegment: {
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    },
  } = useCampaignWizardContext();
  const segmentCriterias = useMemo(() => {
    return {
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    };
  }, [
    allClientsSegmentCriteria,
    pastClientsSegmentCriteria,
    upcomingClientsSegmentCriteria,
  ]);

  const { fetchData, fullReload, loadingMore, refetch, clientSegmentsData } =
    useClientSegmentData({
      conditionalVariables: {
        isPastClientDrawer: selectedSegment === Segment.PAST_CLIENTS,
        isAllClientDrawer: selectedSegment === Segment.ALL_CLIENTS,
        isUpcomingClientDrawer: selectedSegment === Segment.UPCOMING_CLIENTS,
      },
      segmentCriterias,
      segmentType: selectedSegment,
    });

  const {
    getPaginationProps,
    clientSegmentSortingState,
    resetPagination,
    clientSegment,
    handleSortingChange,
  } = useClientSegmentTable({
    segmentCriteria: criteria.baseCriteria,
    selectedSegment: selectedSegment,
    refetch: refetch,
    fetchData: fetchData,
    clientSegments: clientSegmentsData,
    loadingState: { fullReload, loadingMore },
  });

  return (
    <div className={styles.clientSegmentDrawer}>
      <DrawerHeader onClose={closeSideDrawerFn} />

      <div className={styles.segmentsCriteriaContainer}>
        <div>
          <Heading level={4}>{segmentName}</Heading>
          <SegmentClientsTotal
            loading={fullReload}
            total={clientSegment.total}
          />
        </div>
        <Button
          label={formatMessage(messages.clientSegmentSideDrawerButton)}
          variation="work"
          type="tertiary"
          onClick={() => setDrawerState(DrawerMode.Edit)}
        />
      </div>

      <div className={styles.tableContainer}>
        <ClientSegmentTable
          pagination={getPaginationProps(clientSegment.total || 0)}
          loading={fullReload || loadingMore}
          clientData={clientSegment.clientData}
          sorting={{
            manualSorting: true,
            state: clientSegmentSortingState,
            onSortingChange: handleSortingChange,
          }}
        />
      </div>
    </div>
  );

  function closeSideDrawerFn() {
    closeSideDrawer();
    resetPagination(criteria.baseCriteria);
  }
}

interface DrawerHeaderProps {
  onClose: () => void;
}
function DrawerHeader({ onClose }: DrawerHeaderProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.drawerHeader}>
      <Heading level={3}>
        {formatMessage(messages.clientSegmentSideDrawerTitle)}
      </Heading>
      <Button
        variation="subtle"
        type="tertiary"
        icon="remove"
        ariaLabel="close"
        onClick={onClose}
      />
    </div>
  );
}

interface SegmentCountProps {
  loading?: boolean;
  total?: number;
}
function SegmentClientsTotal({ loading, total }: SegmentCountProps) {
  const { formatMessage } = useIntl();

  if (loading) return <Glimmer />;
  return (
    <Text variation="subdued">
      {formatMessage(messages.clients, {
        totalCount: total,
      })}
    </Text>
  );
}
