import type {
  DeliveryMethod,
  MessageTemplateEmail,
  MessageTemplateSms,
} from "~/utilities/API/graphql";

export interface FormValues {
  deliveryMethod: DeliveryMethod | undefined;
  email: MessageTemplateEmail | undefined;
  sms: MessageTemplateSms | undefined;
}

export type setFormValues = (values: React.SetStateAction<FormValues>) => void;

export enum Schedules {
  RemindIn3Days = 3,
  RemindIn5Days = 5,
}
