import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { Disclaimer } from "../Disclaimer";

interface LegacyPlanDisclaimerProps {
  plan: string;
}

export function LegacyPlanDisclaimer({ plan }: LegacyPlanDisclaimerProps) {
  const { formatMessage } = useIntl();
  const formattedPlanName = plan.replace("Monthly", "").replace("Annual", "");
  const content = (
    <Content>
      <Text>
        {formatMessage(messages.primaryDescription, {
          planName: formattedPlanName,
        })}
      </Text>
      <Text>{formatMessage(messages.secondaryDescription)}</Text>
    </Content>
  );
  return <Disclaimer content={content} />;
}
