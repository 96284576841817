import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Content,
  Flex,
  Heading,
  Icon,
  Tooltip,
} from "@jobber/components";
import { formatDistanceToNowStrict } from "date-fns";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { SideNavItem } from "../SideNavItem";

type Name = string;
type Used = number;
type URL = string;

export interface SideNavSectionProps {
  resetTimestamp: string;
  items: [Name, Used, URL][];
  collapseSideNavSection: boolean;
}

export function SideNavSection({
  items,
  resetTimestamp,
  collapseSideNavSection,
}: SideNavSectionProps) {
  const onUpgradeButtonClick = () => {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "usage_based_trial_side_nav",
      name: "upgrade",
    });
  };

  const { pause, resume, timeLeft } = useCountdown(resetTimestamp);

  return (
    <Box padding={{ horizontal: "base", top: "base" }} gap="small">
      {!collapseSideNavSection && (
        <Flex template={["grow", "shrink"]}>
          <Heading level={6}>Monthly Limit</Heading>
          <Tooltip message={`Your monthly limit will reset ${timeLeft}`}>
            <div
              onBlur={pause}
              onFocus={resume}
              onMouseLeave={pause}
              onMouseOver={resume}
              role="img"
            >
              <Icon name="help" color="heading" />
            </div>
          </Tooltip>
        </Flex>
      )}

      <Content spacing="smaller">
        {items.map(([name, used, url]) => (
          <SideNavItem
            key={name}
            name={name}
            used={used}
            url={url}
            shouldShowTooltip={collapseSideNavSection}
          />
        ))}
      </Content>

      {!collapseSideNavSection && (
        <Button
          fullWidth
          label="Upgrade"
          size="small"
          url="/accounts/billing_info/pricing"
          onClick={onUpgradeButtonClick}
        />
      )}
    </Box>
  );
}

function format(resetTimestamp: string) {
  return formatDistanceToNowStrict(new Date(resetTimestamp), {
    addSuffix: true,
  });
}

function useCountdown(resetTimestamp: string) {
  const [timeLeft, setTimeLeft] = useState<string>(format(resetTimestamp));
  const [paused, setPaused] = useState(true);

  useEffect(() => {
    const updateCountdown = () => {
      setTimeLeft(format(resetTimestamp));
    };

    let interval: NodeJS.Timeout | null = null;

    if (!paused) {
      updateCountdown(); // Update immediately before starting the interval
      interval = setInterval(updateCountdown, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [resetTimestamp, paused]);

  return {
    pause: () => setPaused(true),
    resume: () => setPaused(false),
    timeLeft,
  };
}
