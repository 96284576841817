import {
  addDays,
  addMonths,
  differenceInDays,
  endOfDay,
  endOfMonth,
  format,
  getYear,
  isAfter,
  isBefore,
  isMatch,
  isSameDay,
  isWithinInterval,
  parse,
  parseISO,
  startOfMonth,
} from "date-fns";
import { ChartDataAggregationInterval } from "jobber/dataVisualizations/types";
import {
  getYearForLabel,
  timePeriodMapping,
  xAxisLabelMapping,
} from "jobber/dataVisualizations/utils/dataVisualizationsUtils";
import { getEndOfWeekDay } from "jobber/dataVisualizations/utils/dateRangeUtils";
import type {
  Iso8601DateRange,
  LineChartTooltipNode,
  TooltipData,
} from "jobber/dataVisualizations/types";

const PARTIAL_DATE_FORMAT = "MMM d";
const FULL_DATE_FORMAT = "MMM d, yyyy";

export function formatTooltipDate(
  year?: string | number | Date,
  month?: string | number | Date,
) {
  return format(parse(`${year}-${month}`, "yyyy-MMM", new Date()), "yyyy-MM");
}

function formatDailyTooltipDate(date: string) {
  return format(
    parse(
      date,
      timePeriodMapping[ChartDataAggregationInterval.DAILY],
      new Date(),
    ),
    FULL_DATE_FORMAT,
  );
}

// Returns the formatted date range for each weekly grouping, handling the logic for if the beginning or end of the entire date range has a partial week.
function formatWeeklyTooltipDate({
  date,
  dateRange,
  weekStartsOnMonday,
}: {
  date: string;
  dateRange: Iso8601DateRange;
  weekStartsOnMonday: boolean;
}) {
  const startOfInterval = parse(
    date,
    timePeriodMapping[ChartDataAggregationInterval.WEEKLY],
    new Date(),
  );
  const daysToAdd = Math.min(
    6,
    differenceInDays(parseISO(dateRange.before), startOfInterval),
  );
  const endOfInterval = isSameDay(startOfInterval, parseISO(dateRange.after))
    ? getEndOfWeekDay(startOfInterval, weekStartsOnMonday)
    : addDays(startOfInterval, daysToAdd);
  return `${format(startOfInterval, getYear(startOfInterval) === getYear(endOfInterval) ? PARTIAL_DATE_FORMAT : FULL_DATE_FORMAT)} - ${format(endOfInterval, FULL_DATE_FORMAT)}`;
}

function formatMonthlyTooltipDate({
  date,
  dateRange,
  year,
}: {
  date: string;
  dateRange: Iso8601DateRange;
  year?: string;
}) {
  // for line charts, quotes passes date as MMM yyyy but jobs passes date as MMM
  const month = isMatch(
    date,
    xAxisLabelMapping[ChartDataAggregationInterval.MONTHLY],
  )
    ? date.split(" ")[0]
    : date;

  // Check to see if the format is already yyyy-MM
  // Bar charts pass in as yyyy-MM, line charts for jobs pass in as MMM, but line charts for quotes pass in MMM yyyy
  const monthAndYear = isMatch(
    date,
    timePeriodMapping[ChartDataAggregationInterval.MONTHLY],
  )
    ? date
    : formatTooltipDate(year, month);

  const parsedDate = parse(
    monthAndYear,
    timePeriodMapping[ChartDataAggregationInterval.MONTHLY],
    new Date(),
  );

  const firstDayOfMonth = startOfMonth(parsedDate);
  const startOfInterval = isAfter(parseISO(dateRange.after), firstDayOfMonth)
    ? parseISO(dateRange.after)
    : firstDayOfMonth;

  const lastDayOfMonth = endOfMonth(startOfInterval);
  const endOfInterval = isBefore(
    endOfDay(parseISO(dateRange.before)),
    lastDayOfMonth,
  )
    ? endOfDay(parseISO(dateRange.before))
    : lastDayOfMonth;

  return `${format(startOfInterval, PARTIAL_DATE_FORMAT)} - ${format(endOfInterval, FULL_DATE_FORMAT)}`;
}

// Returns the formatted date range for each quarter grouping, handling the logic for if the beginning/end of the entire date range has a partial quarter
function formatQuarterlyTooltipDate(date: string, dateRange: Iso8601DateRange) {
  const parsedDate = parse(
    date,
    timePeriodMapping[ChartDataAggregationInterval.QUARTERLY],
    new Date(),
  );

  const startOfInterval = isWithinInterval(parseISO(dateRange.after), {
    start: parsedDate,
    end: addMonths(parsedDate, 2),
  })
    ? parseISO(dateRange.after)
    : startOfMonth(parsedDate);

  const endOfInterval = isWithinInterval(parseISO(dateRange.before), {
    start: startOfInterval,
    end: endOfMonth(addMonths(startOfInterval, 2)),
  })
    ? parseISO(dateRange.before)
    : endOfMonth(addMonths(startOfInterval, 2));

  return `${format(startOfInterval, getYear(startOfInterval) === getYear(endOfInterval) ? PARTIAL_DATE_FORMAT : FULL_DATE_FORMAT)} - ${format(endOfInterval, FULL_DATE_FORMAT)}`;
}

export function getAdditionalDateInformationForTooltipLabel({
  chartDataAggregationInterval,
  tooltipData,
  totalsData,
}: {
  chartDataAggregationInterval: string;
  tooltipData: TooltipData;
  totalsData: LineChartTooltipNode[];
}): string {
  switch (chartDataAggregationInterval) {
    case ChartDataAggregationInterval.DAILY:
    case ChartDataAggregationInterval.WEEKLY:
    case ChartDataAggregationInterval.QUARTERLY: {
      const { timePeriod } =
        totalsData.find(data => data.xAxisTick === tooltipData.indexValue) ||
        {};
      return timePeriod || "";
    }
    case ChartDataAggregationInterval.MONTHLY: {
      return (
        getYearForLabel(totalsData, tooltipData) ||
        getYear(new Date()).toString()
      );
    }
    default:
      return "";
  }
}

export function formatResponsiveChartTooltipDate({
  chartDataAggregationInterval,
  dateRange,
  fullDate,
  tooltipData,
  weekStartsOnMonday,
}: {
  chartDataAggregationInterval: string;
  dateRange: Iso8601DateRange;
  fullDate?: string;
  tooltipData: TooltipData;
  weekStartsOnMonday: boolean;
}): string {
  const {
    data: { timePeriod, xFormatted },
  } = tooltipData;
  const date = xFormatted ? xFormatted : timePeriod;

  if (typeof date !== "string") {
    return "";
  }

  switch (chartDataAggregationInterval) {
    case ChartDataAggregationInterval.DAILY: {
      return formatDailyTooltipDate(fullDate || date);
    }
    case ChartDataAggregationInterval.WEEKLY: {
      return formatWeeklyTooltipDate({
        date: fullDate || date,
        dateRange,
        weekStartsOnMonday,
      });
    }
    case ChartDataAggregationInterval.MONTHLY: {
      return formatMonthlyTooltipDate({ date, dateRange, year: fullDate });
    }
    case ChartDataAggregationInterval.QUARTERLY: {
      return formatQuarterlyTooltipDate(fullDate || date, dateRange);
    }
    case ChartDataAggregationInterval.YEARLY: {
      return date;
    }
    default:
      return "";
  }
}
