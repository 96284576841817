import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import type {
  ActivateConnectToGrowTrialMutation,
  MutationErrors,
} from "~/utilities/API/graphql";
import { ACTIVATE_GROW_TRIAL } from "jobber/connectToGrowTrial/ConnectToGrowTrial.graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  ConnectToGrowStartOfTrialModal,
  ConnectToGrowStartOfTrialModalFeaturesEnum,
} from "jobber/connectToGrowTrial/components/ConnectToGrowStartOfTrialModal";
import styles from "./JobCostDiscoveryCard.module.css";
import { messages } from "./messages";
import { JobCostProfitMarginDemoContent } from "./components/JobCostProfitMarginDemoContent";

interface JobCostDiscoveryCardProps {
  eligibleForTrial?: boolean;
}

function JobCostDiscoveryCard({
  eligibleForTrial = false,
}: JobCostDiscoveryCardProps) {
  const { formatMessage } = useIntl();
  const ctaName = eligibleForTrial
    ? "job_cost_discovery_trial_card_cta"
    : "saved_job_job_costing_ple_card_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    showConnectToGrowStartOfTrialModal,
    setShowConnectToGrowStartOfTrialModal,
  ] = useState(false);
  const [activateGrowTrial] =
    useMutation<ActivateConnectToGrowTrialMutation>(ACTIVATE_GROW_TRIAL);

  useEffect(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: ctaName,
    });
  }, [ctaName]);

  return (
    <APIProvider>
      {showConnectToGrowStartOfTrialModal && (
        <ConnectToGrowStartOfTrialModal
          feature={ConnectToGrowStartOfTrialModalFeaturesEnum.JobCosting}
          showModal={showConnectToGrowStartOfTrialModal}
          onClose={afterTrialStartConfirmationAction}
        />
      )}
      <CallToAction ctaName={ctaName} ref={ctaRef}>
        {errorMessage && (
          <Banner type="error" dismissible={false}>
            {errorMessage}
          </Banner>
        )}
        <div
          className={styles.jobCostDiscoveryCard}
          data-testid="jobCostDiscovery"
        >
          <div className={styles.jobCostDiscoveryContainer}>
            <div className={styles.jobCostDiscoveryTextHeader}>
              <Heading level={3}>
                {`${formatMessage(messages.headerText)} `}
                <span className={styles.jobCostDiscoveryHighlight}>
                  <Emphasis variation="highlight">
                    <Emphasis variation="bold">
                      {formatMessage(messages.highlightCopy)}
                    </Emphasis>
                  </Emphasis>
                </span>
                <span className={styles.jobCostDiscoveryNonHighlight}>
                  {formatMessage(messages.highlightCopy)}
                </span>
              </Heading>
              <span className={styles.dismissButtonContainer}>
                <button
                  className={styles.dismissButton}
                  onClick={dismissCTA(ctaRef)}
                  type="button"
                  aria-label={formatMessage(messages.dismissAriaLabel)}
                  data-testid="dismissButton"
                >
                  <Icon name="cross" />
                </button>
              </span>
            </div>

            <div className={styles.jobCostDiscoveryText}>
              {eligibleForTrial ? (
                <Text>{formatMessage(messages.cardTrialText)}</Text>
              ) : (
                <Text>{formatMessage(messages.cardText)}</Text>
              )}
            </div>
            <JobCostProfitMarginDemoContent />
            <div className={styles.jobCostDiscoveryButtonContainer}>
              <div className={styles.jobCostDiscoveryLearnMoreButton}>
                {eligibleForTrial ? (
                  <Button
                    label={formatMessage(messages.tryFeatureLabel)}
                    variation="learning"
                    type="secondary"
                    onClick={() => handleStartFreeTrial()}
                    disabled={isLoading}
                  />
                ) : (
                  <Button
                    label={formatMessage(messages.learnMoreLabel)}
                    variation="learning"
                    type="secondary"
                    onClick={convertCTA(ctaRef, {
                      type: "learn_more",
                    })}
                    url={formatMessage(messages.jobCostFeatureUrl)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </CallToAction>
    </APIProvider>
  );

  async function handleStartFreeTrial() {
    setIsLoading(true);
    await updateTrialState();
  }

  async function updateTrialState() {
    const defaultError = new Error(formatMessage(messages.defaultError));
    try {
      const { data } = await activateGrowTrial();
      const errors = data?.activateConnectToGrowTrial
        .userErrors as MutationErrors[];
      if (errors.length === 0) {
        convertCTA(ctaRef, { type: "try_this_feature" })();
        setShowConnectToGrowStartOfTrialModal(true);
      } else {
        setErrorMessage(defaultError.message);
        setIsLoading(false);
      }
    } catch (e) {
      setErrorMessage(defaultError.message);
      setIsLoading(false);
    }
  }

  function afterTrialStartConfirmationAction() {
    window.location.reload();
  }
}

function WrappedJobCostDiscoveryCard({
  eligibleForTrial = false,
}: JobCostDiscoveryCardProps) {
  return (
    <div data-testid="jobCostDiscoveryWrapper">
      <IntlProvider>
        {eligibleForTrial === undefined ? null : (
          <JobCostDiscoveryCard eligibleForTrial={eligibleForTrial} />
        )}
      </IntlProvider>
    </div>
  );
}

export { WrappedJobCostDiscoveryCard as JobCostDiscoveryCard };
