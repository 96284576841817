import { useQuery } from "@apollo/client";
import type { QueryUpgradePlansArgs } from "~/utilities/API/graphql";
import { PlanTier } from "~/utilities/API/graphql";
import {
  LANDING_PAGE_PLAN_QUERY,
  type LandingPagePlanCardQueryUpgradePlansType,
} from "./useLandingPagePlan.graphql";

export function useLandingPagePlan(tier: string) {
  const planTier = PlanTier[tier.toUpperCase() as keyof typeof PlanTier];
  const { data, loading } = useQuery<
    LandingPagePlanCardQueryUpgradePlansType,
    QueryUpgradePlansArgs
  >(LANDING_PAGE_PLAN_QUERY, {
    variables: {
      filter: {
        planTier,
      },
    },
  });

  // default to the plan with the same team size billing option as the current subscription
  const landingPagePlan =
    data?.upgradePlans?.find(
      plan =>
        plan.belugaPhaseOneSingleUserPlan ===
        data?.accountPlanInfo.belugaPhaseOneSingleUserPlan,
    ) || data?.upgradePlans?.[0];

  return { landingPagePlan, loading };
}
