import React from "react";
import { Text } from "@jobber/components/Text";
import { Switch } from "@jobber/components/Switch";
import styles from "./PaymentMethodToggle.module.css";

export interface PaymentMethodToggleProps {
  labelText: string;
  paymentMethodType: "credit_card" | "ach";
  disableToggle?: boolean;
  objectName: string;
  enabled: boolean;
  disabledToggleHelpText?: string;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PaymentMethodToggle(props: PaymentMethodToggleProps) {
  const {
    labelText,
    paymentMethodType,
    objectName,
    disabledToggleHelpText,
    disableToggle,
    enabled,
    onChange,
  } = props;

  const disableClass = disableToggle ? styles.disabled : "";
  const textVariation = disableToggle ? "subdued" : undefined;

  return (
    <>
      <div className={`${styles.onlinePaymentOption} ${disableClass}`}>
        <div>
          <Text variation={textVariation}>{labelText}</Text>
          {disableToggle && (
            <div className={styles.helpText}>
              <Text variation="subdued">{disabledToggleHelpText}</Text>
            </div>
          )}
        </div>
        <div className={styles.rightSwitch}>
          <Switch
            onChange={onChange}
            value={enabled && !disableToggle}
            ariaLabel={`Toggle ${labelText}`}
            disabled={disableToggle}
          />
        </div>
      </div>
      <input
        type="hidden"
        name={`${objectName}[disable_client_hub_${paymentMethodType}_payments]`}
        value={(!enabled).toString()}
        data-testid={`${paymentMethodType}_hidden_field`}
      />
    </>
  );
}
