import { defineMessages } from "react-intl";

export const messages = defineMessages({
  annual: {
    id: "UpgradePage.BillingToggle.annual",
    defaultMessage: "Annual",
    description: "Toggle label for annual billing",
  },
  monthly: {
    id: "UpgradePage.BillingToggle.monthly",
    defaultMessage: "Monthly",
    description: "Toggle label for monthly billing",
  },
  defaultDiscount: {
    id: "UpgradePage.BillingToggle.defaultDiscount",
    defaultMessage: "Save 20%",
    description: "Label for default discount",
  },
});
