import React from "react";
import { gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import type { ProjectedRevenueDataFragment } from "~/utilities/API/graphql";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import { messages } from "./messages";

export const ProjectedRevenueData = gql`
  fragment ProjectedRevenueData on RevenueCalculation {
    revenueTotal
  }
`;
export interface ProjectedRevenueProps {
  revenue?: ProjectedRevenueDataFragment;
  trackEvents: boolean;
}

export function ProjectedRevenueInsight({
  revenue,
  trackEvents,
}: ProjectedRevenueProps) {
  const [calendarPath] = useUrls("calendarPath");
  const currencyFormatter = useFormatCurrency();

  const { formatMessage } = useIntl();
  const projectedRevenue = currencyFormatter(revenue?.revenueTotal, false);

  return (
    <>
      <InsightCard
        title={formatMessage(messages.projectedRevenue)}
        description={formatMessage(messages.projectedRevenueDescription, {
          projectedRevenue,
        })}
        actionLabel={formatMessage(messages.viewScheduled)}
        actionUrl={calendarPath}
        rawNumber={revenue?.revenueTotal}
        renderWith={currencyFormatter}
        hideEmptyCard={false}
        trackEvents={trackEvents}
      />
    </>
  );
}
