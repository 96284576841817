import { Button, Heading } from "@jobber/components";
import React from "react";
import { useIntl } from "react-intl";
import { rejectedStateMessages } from "./rejectedStateMessages";

export interface UkKycRejectedStateProps {
  onClick: () => void;
}

export function UkKycRejectedState(props: UkKycRejectedStateProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Heading level={4}>
        {formatMessage(rejectedStateMessages.moreInfoNeeded)}
      </Heading>
      <p>{formatMessage(rejectedStateMessages.verificationNotComplete)}</p>
      <Button
        fullWidth={true}
        size={"base"}
        variation={"work"}
        label={formatMessage(rejectedStateMessages.rejectedButtonText)}
        onClick={props.onClick}
      />
    </>
  );
}
