/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import { Page } from "@jobber/components/Page";
import { useQuery } from "@apollo/client";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components/Glimmer";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import {
  ACCOUNT_BILLING_INFO,
  ACCOUNT_PLAN_INFO,
  ACCOUNT_PROMOTION,
  FEATURE_TRIAL_ELIGIBILITY,
  UPGRADE_PLANS,
} from "jobber/upgradePage/UpgradePage.graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { Carousel } from "components/Carousel";
import { IntlProvider } from "@translations/IntlProvider";
import { useAccountFeatures } from "jobber/hooks/useAccountFeatures";
import { TalkToSales } from "./components/TalkToSales/TalkToSales";
import styles from "./UpgradePage.module.css";
import { FAQ } from "./components/FAQ";
import { PlanComparison } from "./components/PlanComparison";
import { BillingToggle } from "./components/BillingToggle";
import { LiteToCoreTrialUpgradePrompt } from "./components/TrialPromptCard/LiteToCoreTrial";
import { LegacyPlanDisclaimer } from "./components/Disclaimer/LegacyPlanDisclaimer";
import { MobileBilledDisclaimer } from "./components/Disclaimer/MobileBilledDisclaimer";
import { CoreToConnectTrialUpgradePrompt } from "./components/TrialPromptCard/CoreToConnectTrial/CoreToConnectTrialUpgradePrompt";
import { JobCostingUpgradePrompt } from "./components/JobCostingUpgradePrompt";
import { GlimmerLoadingPlanCard } from "./components/GlimmerLoadingPlanCard/GlimmerLoadingPlanCard";
import { FeatureTable } from "./components/FeatureTable";
import { messages } from "./messages";
import { PromotionBanner } from "./components/PromotionBanner";
import { CampaignsAddOnPrompt } from "./components/CampaignsAddOnPrompt";
import { ReviewsAddOnPrompt } from "./components/ReviewsAddOnPrompt/ReviewsAddOnPrompt";

export interface PlanFeature {
  featureId: string;
}

export interface PlanRate {
  centsPerCharge: number;
  percent: number;
}

export interface BillingCycleData {
  planCode: string;
  annualPrice: number;
  monthlyPrice: number;
}

export interface BillingInformation {
  account: {
    billingInformation: {
      planDisplayName: string;
      planCode: string;
      planPriceMonthly: number;
      userCount: number;
      billingCycleInterval: string;
      allowInWebBillingChanges: boolean;
      allowResubscribeCurrentPlan: boolean;
    };
  };
}

export interface CurrentPlan {
  planTier: string;
  planIdentifier: string;
  userCost: number;
  userLimit: number | null;
  additionalUsers: number;
  features: Array<PlanFeature>;
  jobberPaymentsRate?: PlanRate;
  mobileCardReaderRate?: PlanRate;
  billingPlatform: string;
  allowUserLimitIncrease: boolean;
}

export interface UpgradablePlan {
  isRecommended: boolean;
  planTier: string;
  planIdentifier: string;
  planCostAnnual: number;
  planCostAnnualDiscounted: number;
  annualDiscountDuration: number;
  planCostMonthly: number;
  planCostMonthlyDiscounted: number;
  monthlyDiscountDuration: number;
  userLimit: number | null;
  userCost: number;
  features: Array<PlanFeature>;
  jobberPaymentsRate?: PlanRate;
  mobileCardReaderRate?: PlanRate;
  allowUserLimitIncrease: boolean;
  annualBillingCycle: BillingCycleData | undefined;
  monthlyBillingCycle: BillingCycleData | undefined;
}

export interface AccountPromotion {
  bannerInfo: string | null;
  billingDialogText: string | null;
  pricingPageAnnualDiscountLabel: string | null;
  termsAndConditions: string | null;
  trackingTag: string | null;
}

export interface FeatureTrialEligibilityType {
  accountFeatureTrialEligibility: {
    eligibleForLiteToCoreTrial: boolean | null;
    eligibleForCoreToConnectTrial: boolean | null;
  };
}

export interface AccountPlanInfoType {
  accountPlanInfo: CurrentPlan;
}

export interface UpgradePlansType {
  upgradePlans: Array<UpgradablePlan> | undefined;
}

export interface AccountPromotionType {
  accountPromotion: AccountPromotion;
}

function UpgradePageWrapper() {
  const [seeAllFeatures, setSeeAllFeatures] = useState<boolean>(false);
  const featureTrialEligibilityQuery = useQuery<FeatureTrialEligibilityType>(
    FEATURE_TRIAL_ELIGIBILITY,
  );
  const upgradePlansQuery = useQuery<UpgradePlansType>(UPGRADE_PLANS);
  const accountPlanInfoData = useQuery<AccountPlanInfoType>(ACCOUNT_PLAN_INFO);
  const accountBillingInfo = useQuery<BillingInformation>(ACCOUNT_BILLING_INFO);
  const accountPromotionData =
    useQuery<AccountPromotionType>(ACCOUNT_PROMOTION);

  return (
    <UpgradePageWrapperComponent
      featureTrialEligibilityData={featureTrialEligibilityQuery.data}
      featureTrialEligibilityLoading={featureTrialEligibilityQuery.loading}
      accountPlanInfoData={accountPlanInfoData.data}
      accountPlanInfoLoading={accountPlanInfoData.loading}
      accountBillingInfo={accountBillingInfo.data}
      upgradePlansData={upgradePlansQuery.data}
      accountPromotionData={accountPromotionData.data}
      seeAllFeatures={seeAllFeatures}
      setSeeAllFeatures={setSeeAllFeatures}
    />
  );
}

export function UpgradePage() {
  return (
    <APIProvider>
      <IntlProvider>
        <UpgradePageWrapper />
      </IntlProvider>
    </APIProvider>
  );
}

interface UpgradePageWrapperComponentProps {
  upgradePlansData?: UpgradePlansType | undefined;
  featureTrialEligibilityData?: FeatureTrialEligibilityType;
  featureTrialEligibilityLoading?: boolean;
  accountPlanInfoData?: AccountPlanInfoType | undefined;
  accountPlanInfoLoading?: boolean;
  accountBillingInfo?: BillingInformation;
  accountPromotionData?: AccountPromotionType | undefined;
  seeAllFeatures: boolean;
  setSeeAllFeatures: React.Dispatch<React.SetStateAction<boolean>>;
}

export function UpgradePageWrapperComponent({
  featureTrialEligibilityData,
  featureTrialEligibilityLoading,
  accountPlanInfoData,
  accountPlanInfoLoading,
  accountBillingInfo,
  seeAllFeatures,
  setSeeAllFeatures,
  upgradePlansData,
  accountPromotionData,
}: UpgradePageWrapperComponentProps) {
  const { formatMessage } = useIntl();
  const [isAnnual, setIsAnnual] = useState<boolean>(true);
  const [upgradePrompts, setUpgradePrompts] = useState<JSX.Element[]>([]);
  const [showBillingToggle, setShowBillingToggle] = useState<boolean>(false);
  const legacyDisclaimerPlans = ["LOYALTY"];

  const emailCampaignsFeature = useAccountFeatures().find(
    feature => feature.name === "Email Campaigns",
  );

  const automatedReviewsFeature = useAccountFeatures().find(
    feature => feature.name === "Automated Reviews",
  );

  useEffect(() => {
    if (accountPromotionData) {
      Amplitude.TRACK_EVENT("Viewed Page", {
        name: "Pricing",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        show_grid: "false",
        referrer: document.referrer.replace(/[0-9]*/g, ""),
        promo: accountPromotionData?.accountPromotion?.trackingTag,
      });
    }
  }, [accountPromotionData]);

  useEffect(() => {
    const upgradePromptList = [];

    if (!featureTrialEligibilityLoading && !accountPlanInfoLoading) {
      if (emailCampaignsFeature?.available && !emailCampaignsFeature?.enabled) {
        upgradePromptList.push(<CampaignsAddOnPrompt />);
      }
      if (
        automatedReviewsFeature?.available &&
        !automatedReviewsFeature?.enabled
      ) {
        upgradePromptList.push(<ReviewsAddOnPrompt />);
      }
      if (
        accountPlanInfoData?.accountPlanInfo?.planTier &&
        ["CONNECT", "LOYALTY"].includes(
          accountPlanInfoData?.accountPlanInfo?.planTier,
        )
      ) {
        upgradePromptList.push(<JobCostingUpgradePrompt />);
      }
      if (
        featureTrialEligibilityData?.accountFeatureTrialEligibility
          .eligibleForLiteToCoreTrial
      ) {
        upgradePromptList.push(
          <LiteToCoreTrialUpgradePrompt key="LiteToCoreTrialUpgradePrompt" />,
        );
      }
      if (
        featureTrialEligibilityData?.accountFeatureTrialEligibility
          .eligibleForCoreToConnectTrial
      ) {
        upgradePromptList.push(
          <CoreToConnectTrialUpgradePrompt key="CoreToConnectTrialUpgradePrompt" />,
        );
      }
      setUpgradePrompts(upgradePromptList);
    }
  }, [
    featureTrialEligibilityData,
    accountPlanInfoData?.accountPlanInfo?.planTier,
    accountPlanInfoLoading,
    featureTrialEligibilityLoading,
    emailCampaignsFeature,
    automatedReviewsFeature,
  ]);

  useEffect(() => {
    if (upgradePlansData?.upgradePlans) {
      const check = { monthly: false, annual: false };

      upgradePlansData.upgradePlans.forEach(plan => {
        check.monthly = check.monthly || !!plan.monthlyBillingCycle;
        check.annual = check.annual || !!plan.annualBillingCycle;

        if (check.monthly && check.annual) {
          setShowBillingToggle(true);
          return;
        }
      });
    }
  }, [upgradePlansData, setShowBillingToggle]);

  useEffect(() => {
    const annualBillingCycle =
      accountBillingInfo?.account.billingInformation?.billingCycleInterval ===
      "annual";

    setIsAnnual(showBillingToggle || annualBillingCycle);
  }, [accountBillingInfo, showBillingToggle, setIsAnnual]);

  return (
    <Page title="" width="fill">
      <>
        {accountBillingInfo && accountPlanInfoData && (
          <div className={styles.disclaimerContainer}>
            <div className={styles.disclaimer}>
              {legacyDisclaimerPlans.includes(
                accountPlanInfoData.accountPlanInfo?.planTier,
              ) &&
                accountBillingInfo.account.billingInformation
                  ?.planDisplayName && (
                  <LegacyPlanDisclaimer
                    plan={
                      accountBillingInfo.account.billingInformation
                        .planDisplayName
                    }
                  />
                )}
              {!accountBillingInfo.account.billingInformation
                ?.allowInWebBillingChanges && (
                <MobileBilledDisclaimer
                  billingPlatform={
                    accountPlanInfoData.accountPlanInfo?.billingPlatform
                  }
                />
              )}
            </div>
          </div>
        )}
        {accountPromotionData && (
          <div className={styles.promoBannerContainer}>
            <div className={styles.promoBanner}>
              {accountPromotionData?.accountPromotion?.bannerInfo && (
                <PromotionBanner
                  bannerPromotionInfo={
                    accountPromotionData?.accountPromotion?.bannerInfo
                  }
                />
              )}
            </div>
          </div>
        )}

        <div className={styles.upgradePageHeadingsContainer}>
          <div
            className={
              upgradePlansData?.upgradePlans?.length === 0
                ? styles.upgradePageHeadingsCenterAlign
                : styles.upgradePageHeadingContainer
            }
          >
            <div
              data-testid={"upgradePageHeadings"}
              className={
                upgradePlansData?.upgradePlans?.length === 0
                  ? styles.upgradePageHeadingsCenterAlign
                  : styles.upgradePageHeadingsLeftAlign
              }
            >
              <div>
                {(!upgradePlansData && (
                  <Glimmer width={250} size="largest" />
                )) || (
                  <Heading level={1}>{formatMessage(messages.header)}</Heading>
                )}
              </div>
              <div className={styles.upgradePageSubtitle}>
                <Heading level={4}>
                  {(!upgradePlansData && (
                    <div className={styles.glimmerHeader}>
                      <Glimmer width={250} size="large" />
                    </div>
                  )) ||
                    (upgradePlansData && formatMessage(messages.subtitle))}
                </Heading>
              </div>
            </div>

            {(!upgradePlansData || (upgradePlansData && showBillingToggle)) && (
              <div
                data-testid="billingToggleSection"
                className={styles.billingToggleSection}
              >
                {upgradePlansData ? (
                  showBillingToggle && (
                    <BillingToggle
                      isAnnual={isAnnual}
                      onToggle={() => setIsAnnual(a => !a)}
                      discountLabel={
                        accountPromotionData?.accountPromotion
                          ?.pricingPageAnnualDiscountLabel || undefined
                      }
                    />
                  )
                ) : (
                  <Glimmer width={190} size="largest" />
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.upgradePageContent}>
          {!upgradePlansData ||
          !accountPromotionData ||
          !accountPlanInfoData ||
          !accountBillingInfo ? (
            <div className={styles.loadingCardContainer}>
              <GlimmerLoadingPlanCard />
            </div>
          ) : (
            <PlanComparison
              isAnnual={isAnnual}
              billingInformation={accountBillingInfo.account.billingInformation}
              currentPlan={accountPlanInfoData?.accountPlanInfo}
              upgradePlans={upgradePlansData?.upgradePlans}
              setSeeAllFeatures={setSeeAllFeatures}
              activePromotion={accountPromotionData?.accountPromotion}
            />
          )}

          {seeAllFeatures && (
            <>
              <div
                className={
                  upgradePlansData?.upgradePlans?.length
                    ? styles.featureTablesSectionOneColumn
                    : styles.featureTablesSection
                }
                data-testid="featureTable"
                id="featureTableSection"
              >
                {accountBillingInfo &&
                  accountPromotionData &&
                  accountPlanInfoData && (
                    <FeatureTable
                      isAnnual={isAnnual}
                      currentPlan={accountPlanInfoData.accountPlanInfo}
                      currentPlanCost={
                        accountBillingInfo.account.billingInformation
                          .planPriceMonthly
                      }
                      upgradePlans={upgradePlansData?.upgradePlans}
                      activePromotion={accountPromotionData?.accountPromotion}
                      canUpgrade={
                        accountBillingInfo.account.billingInformation
                          ?.allowInWebBillingChanges
                      }
                    />
                  )}
              </div>
              <div className={styles.hideAllFeatures}>
                <Button
                  label={formatMessage(messages.hideFeaturesButton)}
                  onClick={hideFeatures}
                  type="tertiary"
                />
              </div>
            </>
          )}
          <div
            className={
              upgradePlansData?.upgradePlans?.length === 0 &&
              upgradePrompts.length === 0
                ? styles.cardsSectionNoUpgradePlan
                : styles.cardsSection
            }
          >
            {upgradePrompts.length >= 1 && (
              <div className={styles.upgradeTrialPrompt}>
                <Carousel
                  showIndicators={upgradePrompts.length > 1}
                  autoplaySeconds={6}
                >
                  {upgradePrompts}
                </Carousel>
              </div>
            )}
            <div
              className={
                upgradePlansData?.upgradePlans?.length === 0
                  ? styles.talkToSalesSectionNoUpgradePlan
                  : styles.talkToSalesSection
              }
            >
              {upgradePlansData && (
                <TalkToSales
                  growAccount={upgradePlansData.upgradePlans?.length === 0}
                  allowResubscribeCurrentPlan={
                    accountBillingInfo?.account.billingInformation
                      ?.allowResubscribeCurrentPlan
                  }
                />
              )}
            </div>
          </div>

          <div className={styles.faqSection}>
            <FAQ />
            {accountPromotionData?.accountPromotion?.termsAndConditions && (
              <div className={styles.promoTermsAndConditions}>
                <Text variation="subdued">
                  {formatMessage(messages.disclaimer, {
                    disclaimer:
                      accountPromotionData?.accountPromotion
                        ?.termsAndConditions,
                  })}
                </Text>
              </div>
            )}
          </div>
        </div>
      </>
    </Page>
  );

  function hideFeatures() {
    setSeeAllFeatures(false);
  }
}
