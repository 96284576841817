import React, { useEffect, useMemo, useRef } from "react";
import { Text } from "@jobber/components/Text";
import styles from "./ChartTooltip.module.css";

export function ChartTooltip({
  label,
  content,
  boldContent,
  statusLabel,
  children,
}: {
  label: string;
  content?: JSX.Element | string;
  boldContent?: boolean;
  statusLabel?: JSX.Element;
  children?: React.ReactNode;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const observer = useMemo<IntersectionObserver>(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    return new IntersectionObserver(entries => {
      if (!containerRef.current) return;
      const [entry] = entries;
      const { intersectionRect, boundingClientRect } = entry;
      const leftDiff = boundingClientRect!.left - intersectionRect!.left;
      const rightDiff = intersectionRect!.right - boundingClientRect!.right;

      if (entry.intersectionRatio < 1 && leftDiff < 0) {
        const translate = `translateX(${-(leftDiff - 5)}px)`;

        containerRef.current.style.transform = translate;
      } else if (entry.intersectionRatio < 1 && rightDiff < 0) {
        const translate = `translateX(${rightDiff - 5}px)`;
        containerRef.current.style.transform = translate;
      }
    }, options);
  }, [containerRef]);

  useEffect(() => {
    const containerRefCurrent = containerRef.current;

    if (containerRefCurrent) observer.observe(containerRefCurrent);

    return () => {
      if (containerRefCurrent) observer.unobserve(containerRefCurrent);
    };
  }, [observer]);

  return (
    <div className={styles.container} ref={containerRef}>
      {statusLabel}
      <div className={styles.textContainer}>
        <Text variation="subdued" size="small">
          {label}
        </Text>
        {content && (
          <span className={boldContent ? styles.boldContent : ""}>
            {content}
          </span>
        )}
        {children}
      </div>
    </div>
  );
}
