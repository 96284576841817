import type { Option } from "@jobber/components/Autocomplete";
import type { AdditionalCriteriaFragment } from "~/utilities/API/graphql";

export const SupportedCriteriaTypes: Record<
  "CLIENT_TAGS" | "LINE_ITEMS" | "JOB_TYPE" | "JOB_STATUS",
  AdditionalCriteriaFragment["__typename"]
> = {
  CLIENT_TAGS: "ClientTagCriteria",
  LINE_ITEMS: "LineItemCriteria",
  JOB_TYPE: "JobTypeCriteria",
  JOB_STATUS: "JobStatusCriteria",
};

export interface NewAdditionalCriteria {
  __typename: undefined;
  index: number;
}

export type AdditionalCriteriaUnion =
  | AdditionalCriteriaFragment
  | NewAdditionalCriteria;

export interface AdditionalCriteriaOptions {
  options: Option[];
  optionsLoaded: boolean;
  getOptions(lineItemName: string): Option[];
}
