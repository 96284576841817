import { defineMessages } from "react-intl";

const profilePageIdPrefix = "setupWizard.profilePage.";

export const messages = defineMessages({
  profilePageDefaultStatsCopy1: {
    id: `${profilePageIdPrefix}profilePageDefaultStatsCopy1`,
    defaultMessage: "Powering",
    description: "Default stats copy one for the setup wizard",
  },
  profilePageDefaultStatsCopy2: {
    id: `${profilePageIdPrefix}profilePageDefaultStatsCopy2`,
    defaultMessage: "200,000+",
    description: "Default stats copy two for the setup wizard",
  },
  profilePageDefaultStatsCopy3: {
    id: `${profilePageIdPrefix}profilePageDefaultStatsCopy3`,
    defaultMessage: "Home Service Pros",
    description: "Default stats copy three for the setup wizard",
  },
  profilePageCopy: {
    id: `${profilePageIdPrefix}profilePageCopy`,
    defaultMessage: "Let's get you started.",
    description: "Profile page copy for the setup wizard",
  },
  serviceProviderCollageAltText: {
    id: `${profilePageIdPrefix}serviceProviderCollageAltText`,
    defaultMessage: "service provider collage",
    description: "Service provider collage alt text for the setup wizard",
  },
});
