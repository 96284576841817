import { cloneDeep } from "lodash";
import { showToast } from "@jobber/components/Toast";
import { type ApolloClient, useApolloClient } from "@apollo/client";
import type {
  CustomFieldAppliesTo,
  CustomFieldConfigurationNodeFragment,
  CustomFieldPageListQuery,
} from "~/utilities/API/graphql";
import { csrfToken } from "utilities/csrfToken";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { CUSTOM_FIELD_LIST } from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";

export interface CustomFieldConfigurationList {
  client: Array<CustomFieldConfigurationNodeFragment>;
  property: Array<CustomFieldConfigurationNodeFragment>;
  job: Array<CustomFieldConfigurationNodeFragment>;
  invoice: Array<CustomFieldConfigurationNodeFragment>;
  quote: Array<CustomFieldConfigurationNodeFragment>;
  team: Array<CustomFieldConfigurationNodeFragment>;
}

interface MakeTransferableResponse {
  success: boolean;
  errorMessage?: string;
}

export interface UseCustomFieldConfigurationsType {
  updateCustomFieldPosition: (
    customFieldList: CustomFieldConfigurationNodeFragment[],
    appliesToSection: CustomFieldAppliesTo,
    customFieldConfigurationId: CustomFieldConfigurationNodeFragment["id"],
  ) => void;
  deleteCustomField: (
    customFieldConfigValue: CustomFieldConfigurationNodeFragment,
  ) => Promise<boolean>;
  makeTransferable: (
    customFieldConfigValue: CustomFieldConfigurationNodeFragment,
  ) => Promise<MakeTransferableResponse>;
}

const APPLIES_TO_LIST = {
  ALL_PROPERTIES: "properties",
  ALL_QUOTES: "quotes",
  ALL_JOBS: "work_orders",
  ALL_CLIENTS: "clients",
  ALL_INVOICES: "invoices",
  TEAM: "users",
};

export function useCustomFieldConfigurations(): UseCustomFieldConfigurationsType {
  const client = useApolloClient();

  async function updateCustomFieldPosition(
    customFieldGrouping: CustomFieldConfigurationNodeFragment[],
    appliesToSection: CustomFieldAppliesTo,
    customFieldConfigurationId: CustomFieldConfigurationNodeFragment["id"],
  ) {
    const customFieldConfigIndex = customFieldGrouping.findIndex(
      cfc => cfc.id == customFieldConfigurationId,
    );

    const customFieldConfig = customFieldGrouping[customFieldConfigIndex];

    if (!customFieldConfig) {
      showToast({
        message:
          "Custom field reordering failed due to unknown error. Try again. If the issue persists, contact Jobber Support",
        variation: "error",
      });
      return;
    }

    fixSortOrderForGrouping(customFieldGrouping);

    updateCacheAndServer(customFieldGrouping, appliesToSection);
  }

  function fixSortOrderForGrouping(
    grouping: CustomFieldConfigurationNodeFragment[],
  ) {
    grouping.forEach((cfc, index) => {
      cfc.sortOrder = index;
    });
  }

  function updateCacheAndServer(
    customFieldGrouping: CustomFieldConfigurationNodeFragment[],
    appliesToSection: CustomFieldAppliesTo,
  ) {
    const previousCache = client.readQuery<CustomFieldPageListQuery>({
      query: CUSTOM_FIELD_LIST,
    });

    updateReorderedCache(previousCache, customFieldGrouping);

    performLegacySort(customFieldGrouping, appliesToSection).catch(() => {
      client.writeQuery({
        query: CUSTOM_FIELD_LIST,
        data: previousCache,
      });
      showToast({
        message:
          "Custom field reordering failed due to unknown error. Try again. If the issue persists, contact Jobber Support",
        variation: "error",
      });
    });
  }

  async function makeTransferable(
    customFieldConfigValue: CustomFieldConfigurationNodeFragment,
  ) {
    const response = await performLegacyMakeTransferable(
      customFieldConfigValue,
    );
    if (!response) {
      return {
        success: false,
        errorMessage: "Something went wrong",
      };
    }

    if (!response.ok) {
      const body = await response.json();
      return {
        success: false,
        errorMessage: body.error,
      };
    } else {
      await client.refetchQueries({
        include: [CUSTOM_FIELD_LIST],
      });

      return {
        success: true,
      };
    }
  }

  async function deleteCustomField(
    customFieldConfigValue: CustomFieldConfigurationNodeFragment,
  ) {
    const response = await performLegacyDelete(customFieldConfigValue);

    if (!response || !response.ok) {
      return false;
    }

    if (customFieldConfigValue.transferable) {
      await client.refetchQueries({
        include: [CUSTOM_FIELD_LIST],
      });
    } else {
      removeFromCache(customFieldConfigValue, client);
    }

    return true;
  }

  return {
    updateCustomFieldPosition,
    makeTransferable,
    deleteCustomField,
  };

  function updateReorderedCache(
    cachedQuery: CustomFieldPageListQuery | null,
    customFieldGrouping: CustomFieldConfigurationNodeFragment[],
  ) {
    if (cachedQuery) {
      const reorderedCache = reorderCustomFieldConfigurationsInCache(
        cachedQuery,
        customFieldGrouping,
      );

      client.writeQuery({
        query: CUSTOM_FIELD_LIST,
        data: reorderedCache,
      });
    }
  }
}

async function performLegacySort(
  customFieldGrouping: CustomFieldConfigurationNodeFragment[],
  appliesToSection: CustomFieldAppliesTo,
) {
  const authenticityToken = csrfToken;
  const response = await fetch("/custom_fields/sort", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      list: APPLIES_TO_LIST[appliesToSection],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      custom_field: customFieldGrouping.map(customField =>
        decodeId(customField.id),
      ),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      authenticity_token: authenticityToken,
    }),
  });

  if (!response.ok) {
    throw new Error(`Something went wrong`);
  }
}

export async function performLegacyDelete(
  customFieldConfigValue: CustomFieldConfigurationNodeFragment,
) {
  if (!customFieldConfigValue.id) {
    return;
  }

  const authenticityToken = csrfToken;

  return fetch(`/custom_fields/${decodeId(customFieldConfigValue.id)}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      custom_field: customFieldConfigValue,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      authenticity_token: authenticityToken,
    }),
  });
}

function removeFromCache(
  customFieldConfigValue: CustomFieldConfigurationNodeFragment,
  client: ApolloClient<unknown>,
) {
  const customFieldId = customFieldConfigValue.id;
  const customFieldType = customFieldConfigValue.__typename;
  if (!customFieldId || !customFieldType) {
    return;
  }

  const cacheId = `${customFieldType}:${customFieldId}`;
  client.cache.evict({ id: cacheId });
}

export function emptyCustomFieldConfigurationGroupState(): CustomFieldConfigurationList {
  return {
    client: [],
    property: [],
    job: [],
    invoice: [],
    quote: [],
    team: [],
  };
}

function reorderCustomFieldConfigurationsInCache(
  cachedQuery: CustomFieldPageListQuery,
  customFieldGrouping: CustomFieldConfigurationNodeFragment[],
) {
  // We need to deep clone this to another object, since the cacheQuery is readonly
  const reorderedCache = cloneDeep(cachedQuery);

  if (reorderedCache.customFieldConfigurations.nodes) {
    for (
      let i = 0;
      i < reorderedCache.customFieldConfigurations.nodes.length;
      i++
    ) {
      const cacheConfigurationId =
        reorderedCache.customFieldConfigurations.nodes[i].id;

      // We find the matching custom field in the already grouped custom fields
      const alreadyOrderedCustomField = customFieldGrouping.find(
        customFieldGroup => {
          return customFieldGroup.id === cacheConfigurationId;
        },
      );

      // We set the sort order of the apollo cache custom field to its sister value in the sorted custom field group
      if (alreadyOrderedCustomField) {
        reorderedCache.customFieldConfigurations.nodes[i] = {
          ...reorderedCache.customFieldConfigurations.nodes[i],
          sortOrder: alreadyOrderedCustomField.sortOrder,
        };
      }
    }
  }

  // We need to actually sort the edges array according to sortOrder, as these are rendered one after
  // another as they appear
  reorderedCache.customFieldConfigurations.nodes?.sort((node1, node2) => {
    return node1.sortOrder - node2.sortOrder;
  });

  return reorderedCache;
}

async function performLegacyMakeTransferable(
  customFieldConfigValue: CustomFieldConfigurationNodeFragment,
) {
  if (!customFieldConfigValue.id) {
    return;
  }

  const authenticityToken = csrfToken;

  return fetch(
    `/custom_fields/${decodeId(customFieldConfigValue.id)}/make_transferable`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        custom_field: customFieldConfigValue,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authenticity_token: authenticityToken,
      }),
    },
  );
}
