import { useMemo } from "react";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "./formatCurrency";

export const useCurrencyFormat = (unformattedNumber: number, precision = 0) => {
  const { currencySymbol } = useAccount();
  return useMemo(() => {
    return formatCurrency(unformattedNumber, currencySymbol || "$", precision);
  }, [unformattedNumber, currencySymbol]);
};
