import classNames from "classnames";
import React from "react";
import { useIntl } from "react-intl";
import { Switch } from "@jobber/components/Switch";
import { Heading } from "@jobber/components/Heading";
import { IntlProvider } from "@translations/IntlProvider";
import { CurvedArrowImg } from "./images";
import styles from "./BillingToggle.module.css";
import { messages } from "./messages";

export interface BillingToggleProps {
  isAnnual: boolean;
  onToggle: () => void;
  discountLabel?: string;
}

function BillingToggleInternal({
  isAnnual,
  onToggle,
  discountLabel,
}: BillingToggleProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.billingToggleContainer}>
      <div
        data-testid="billingToggleDiscount"
        className={classNames(styles.billingToggleDiscount, {
          [styles.billingTogglePromoDiscount]: discountLabel,
        })}
      >
        <div className={styles.discountCopy}>
          <Heading level={5}>
            {discountLabel || formatMessage(messages.defaultDiscount)}
          </Heading>
        </div>
        <img src={CurvedArrowImg.src} alt={CurvedArrowImg.alt} />
      </div>
      <div
        data-testid="billingToggleSwitch"
        className={styles.billingToggleSwitchSection}
      >
        <span className={styles.billingToggleLabel}>
          {formatMessage(messages.monthly)}
        </span>
        <div className={styles.billingToggleSwitch}>
          <Switch
            ariaLabel="Billing Toggle"
            name="billingToggle"
            onChange={onToggle}
            value={isAnnual}
          />
        </div>
        <span className={styles.billingToggleLabel}>
          {formatMessage(messages.annual)}
        </span>
      </div>
    </div>
  );
}

function WrappedBillingToggle({
  isAnnual,
  onToggle,
  discountLabel,
}: BillingToggleProps) {
  return (
    <IntlProvider>
      <BillingToggleInternal
        isAnnual={isAnnual}
        onToggle={onToggle}
        discountLabel={discountLabel}
      />
    </IntlProvider>
  );
}

export { WrappedBillingToggle as BillingToggle };
