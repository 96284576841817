import { gql } from "@apollo/client";

export const READ_CONVERSATION_MUTATION = gql`
  mutation ConversationRead($id: EncodedId!) {
    conversationRead(id: $id) {
      conversation {
        id
        readFlag
      }
      userErrors {
        message
      }
    }
  }
`;

export const UNREAD_CONVERSATION_MUTATION = gql`
  mutation ConversationUnread($id: EncodedId!) {
    conversationUnread(id: $id) {
      conversation {
        id
        readFlag
      }
      userErrors {
        message
      }
    }
  }
`;

export const DELETE_CONVERSATION_MUTATION = gql`
  mutation ConversationDelete($id: EncodedId!) {
    conversationDelete(id: $id) {
      conversation {
        id
        deletedAt
      }
      userErrors {
        message
      }
    }
  }
`;

export const REASSIGN_CONVERSATION_MUTATION = gql`
  mutation ReassignConversation($id: EncodedId!, $clientId: EncodedId!) {
    conversationReassign(id: $id, clientId: $clientId) {
      conversation {
        id
      }
      userErrors {
        message
      }
    }
  }
`;

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendTextMessage(
    $recipient: String!
    $message: String!
    $source: String
    $clientGeneratedId: Uuid
  ) {
    sendSms(
      recipient: $recipient
      message: $message
      source: $source
      clientGeneratedId: $clientGeneratedId
    ) {
      textMessage {
        id
        clientGeneratedId
      }
      userErrors {
        message
      }
    }
  }
`;

export const messageData = gql`
  fragment messageData on MessageInterface {
    id
    clientGeneratedId
    content
    internalContactObject {
      ... on Client {
        id
        clientName: name
      }
      ... on AiReceptionist {
        id
        assistantName: name
      }
      ... on User {
        id
        name {
          first
          full
        }
      }
    }
    subject
    direction
    status
    commType
    timestamp
    linkableWorkObject {
      __typename
      ... on Invoice {
        id
      }
      ... on Quote {
        id
      }
      ... on Job {
        id
      }
    }
    ... on EmailMessage {
      ccEmails
      toEmails
      files {
        nodes {
          ... on File {
            url
            contentType
            fileSize
            fileName
          }
        }
      }
    }
    ... on TextMessage {
      files {
        nodes {
          ... on SmsFile {
            url
            contentType
          }
        }
      }
    }

    externalContactObject {
      ... on Client {
        id
        clientName: name
      }
      ... on AiReceptionist {
        id
        assistantName: name
      }
      ... on User {
        id
        name {
          full
        }
      }
    }
  }
`;

export const CONVERSATION_MESSAGES_QUERY = gql`
  query ConversationMessages($id: EncodedId!, $cursor: String) {
    conversation(id: $id) {
      id
      readFlag
      with {
        id
        name
        phoneNumber {
          friendly
          raw
        }
      }
      messages(first: 20, after: $cursor) {
        edges {
          node {
            ...messageData
          }
          cursor
        }
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
      }
    }
  }

  ${messageData}
`;

export const CONVERSATION_MESSAGE_SUBSCRIPTION = gql`
  subscription ConversationMessage($conversationId: EncodedId!) {
    conversationMessage(conversationId: $conversationId) {
      message {
        ...messageData
      }
    }
  }

  ${messageData}
`;
