import { defineMessages } from "react-intl";

export const messages = defineMessages({
  callUsAt: {
    id: "upgradePage.talkToSales.callUsAt",
    defaultMessage:
      "Call us at {allowResubscribeCurrentPlan, select, true {{supportPhoneNumber}} false {{salesPhoneNumber}} other{}} or {intercomLink}.",
    description: "Call us at copy",
  },
  salesPhoneNumber: {
    id: "upgradePage.talkToSales.salesPhoneNumber",
    defaultMessage: "1-833-460-0177 ",
    description: "Sales phone number copy",
  },
  supportPhoneNumber: {
    id: "upgradePage.talkToSales.supportPhoneNumber",
    defaultMessage: "1-888-424-5301 ",
    description: "Support phone number copy",
  },
  chatWithUsLive: {
    id: "upgradePage.talkToSales.chatWithUsLive",
    defaultMessage: "chat with us live",
    description: "Chat with us live copy",
  },
  lookingToExpand: {
    id: "upgradePage.talkToSales.lookingToExpand",
    defaultMessage: "LOOKING TO EXPAND?",
    description: "Looking to expand copy",
  },
  weCanGrowWithYou: {
    id: "upgradePage.talkToSales.weCanGrowWithYou",
    defaultMessage: "WE CAN GROW WITH YOU.",
    description: "We can grow with you copy",
  },
  enterprisePricingAvailable: {
    id: "upgradePage.talkToSales.enterprisePricingAvailable",
    defaultMessage:
      "Enterprise pricing available for businesses with over 30 users or multiple locations.",
    description: "Enterprise pricing available copy",
  },
  haveQuestionsAboutOurPlans: {
    id: "upgradePage.talkToSales.haveQuestionsAboutOurPlans",
    defaultMessage: "Have questions about our plans? Let's talk.",
    description: "Have questions about our plans? copy",
  },
});
