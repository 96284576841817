import { fireEvent, screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { CAMPAIGN_TEMPLATE_CTA_TEST_ID } from "jobber/campaigns/views/CampaignsContentPage/components/CtaButtonInput/CtaButtonInput";
import { messages } from "jobber/campaigns/views/CampaignsContentPage/messages";
import type {
  CampaignTemplateCtaLinkTo,
  TemplateVariablesFragment,
} from "~/utilities/API/graphql";

export function getCTASection() {
  return screen.getByTestId(CAMPAIGN_TEMPLATE_CTA_TEST_ID);
}

export function queryCTASection() {
  return screen.queryByTestId(CAMPAIGN_TEMPLATE_CTA_TEST_ID);
}

export function getCTARemove() {
  return screen.getByTestId("trash");
}

export function getCTAAdd() {
  return screen.getByText("Add Button");
}

export function getPageTitle() {
  return screen.getByText(messages.campaignContentPageTitle.defaultMessage);
}

export function getError(errorMessage: string) {
  return screen.getByText(errorMessage);
}

export function queryError(errorMessage: string) {
  return screen.queryByText(errorMessage);
}

export function getSubjectLabel() {
  return screen.getByLabelText(messages.campaignSubjectLine.defaultMessage);
}

export function getInsertVariableButton() {
  return screen.getByText(messages.insertVariable.defaultMessage);
}

export function getCTALinkType() {
  return screen.getByLabelText(messages.ctaLinkToPlaceholder.defaultMessage);
}

export function queryCTALinkType() {
  return screen.queryByLabelText(messages.ctaLinkToPlaceholder.defaultMessage);
}

export function getCTALinkValue() {
  return screen.getByLabelText(
    messages.ctaButtonLinkPlaceholder.defaultMessage,
  );
}

export function queryCTALinkValue() {
  return screen.queryByLabelText(
    messages.ctaButtonLinkPlaceholder.defaultMessage,
  );
}

export function getCTAButtonText() {
  return screen.getByLabelText(
    messages.ctaButtonTextPlaceholder.defaultMessage,
  );
}

export function queryCTAButtonText() {
  return screen.queryByLabelText(
    messages.ctaButtonTextPlaceholder.defaultMessage,
  );
}

export function iInsertAVariable(
  selectedOption: TemplateVariablesFragment["variables"]["nodes"][0],
) {
  const button = getInsertVariableButton();

  userEvent.click(button);

  const firstOption = screen.getByText(selectedOption.label);
  userEvent.click(firstOption);
}

export function iUpdateSubject(subject: string) {
  const subjectLabel = getSubjectLabel();

  fireEvent.change(subjectLabel, { target: { value: subject } });
  fireEvent.blur(subjectLabel);
}

export function iUpdateCTALinkType(linkType: CampaignTemplateCtaLinkTo) {
  const linkTypeSelector = getCTALinkType();

  fireEvent.change(linkTypeSelector, {
    target: { value: linkType },
  });
}

export function iUpdateCTALinkValue(value: string) {
  const linkValueInput = getCTALinkValue();

  fireEvent.change(linkValueInput, {
    target: { value: value },
  });

  fireEvent.blur(linkValueInput);
}

export function iUpdateCTAButtonText(value: string) {
  const buttonTextInput = getCTAButtonText();

  fireEvent.change(buttonTextInput, {
    target: { value: value },
  });

  fireEvent.blur(buttonTextInput);
}

export function iRemoveCTA() {
  const removeCTAButton = getCTARemove();

  userEvent.click(removeCTAButton);
}

export function iAddCTA() {
  const addCTAButton = getCTAAdd();

  userEvent.click(addCTAButton);
}

export function iRewriteBody(intent: string) {
  userEvent.click(screen.getByText("Rewrite"));
  userEvent.click(screen.getByText(intent));
}

export function iDismissNotification() {
  userEvent.click(screen.getByLabelText("Dismiss notification"));
}
