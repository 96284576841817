import classNames from "classnames";
import { ChipDismissible } from "@jobber/components/Chips";
import React, { type MouseEvent } from "react";
import styles from "./InputList.module.css";

export interface InputListProps {
  recipients: string[];
  inputName: string;
  highlightedIndex?: number;
  onRemove(selectedRecipients: string): void;
}

export function InputList({
  recipients,
  inputName,
  highlightedIndex,
  onRemove,
}: InputListProps) {
  const removeRecipient = (event: MouseEvent) => {
    const selected = event.currentTarget.previousElementSibling?.textContent;
    if (selected) {
      onRemove(selected);
      return;
    }
  };

  return (
    <>
      {recipients.map((recipient: string, index: number) => {
        const listItemClass = classNames("u-marginSmallest", {
          [styles.highlight]: highlightedIndex === index,
        });

        return (
          <div key={`key${index}-${recipient}`} className={listItemClass}>
            <ChipDismissible
              label={recipient}
              onRequestRemove={removeRecipient}
              key={`key${index}-${recipient}`}
            />
            <input
              type="hidden"
              name={inputName}
              id={`recipients-${recipient}`}
              value={recipient}
              data-testid="InputList-input"
            />
          </div>
        );
      })}
    </>
  );
}
