import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Emphasis, Text } from "@jobber/components";
import { messages } from "./messages";

export enum TrialFlowButtonType {
  REVIEWS = "Reviews",
  CAMPAIGNS = "Campaigns",
}

interface TrialFlowButtonProps {
  feature: TrialFlowButtonType.REVIEWS | TrialFlowButtonType.CAMPAIGNS;
}

export function TrialFlowButton({
  feature,
}: TrialFlowButtonProps): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <>
      <Button
        label={formatMessage(messages.inTrialButton)}
        ariaLabel={formatMessage(messages.inTrialButton)}
        size={"large"}
        type={"primary"}
        onClick={navigateToPricing}
      />
      <div style={{ marginBottom: "var(--space-largest)" }}>
        <Text variation={"info"}>
          <Emphasis variation={"italic"}>
            {formatMessage(messages.inTrialFooter, { feature: feature })}
          </Emphasis>
        </Text>
      </div>
    </>
  );
}

function navigateToPricing() {
  window.location.assign("/accounts/billing_info/pricing");
}
