import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { PromotionPageForm } from "jobber/setupWizard/components/PromotionPage/components/PromotionPageForm";
import { BareColumn } from "jobber/setupWizard/layouts/BareColumn/BareColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import promotionImageSrc from "@images/signup/device-samples-retheme.jpg";
import { messages } from "jobber/setupWizard/messages";
import styles from "./PromotionPage.module.css";

export const promotionImage: ImageType = {
  alt: messages.jobberOnLaptopMobileDeviceAltText,
  src: promotionImageSrc,
};

export function PromotionPage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.promotion,
    });
  }, []);

  return (
    <TwoColumn
      leftComponent={
        <BareColumn
          child={
            <PromotionPageForm
              {...stepProps}
              welcomeText={stepProps.promoDetails.welcomeText}
              title={stepProps.promoDetails.title}
              information={stepProps.promoDetails.information}
              subtext={stepProps.promoDetails.subtext}
              buttonText={stepProps.promoDetails.buttonText}
              cancelButtonText={formatMessage(
                messages.promotionPageCancelButtonText,
              )}
            />
          }
        />
      }
      rightComponent={
        <MediaColumn showRip={false}>
          <div className={styles.promotionImage}>
            <img
              src={promotionImage.src}
              alt={formatMessage(promotionImage.alt)}
            />
          </div>
        </MediaColumn>
      }
      showColumnDivider={false}
    />
  );
}
