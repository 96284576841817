import React, { useMemo } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components/Glimmer";
import classNames from "classnames";
import { useCampaignCompanyInformation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignCompanyInformation";
import { IntlProvider } from "@translations/IntlProvider";
import { EmailBody } from "jobber/campaigns/components/EmailPreview/components/EmailBody";
import type { TemplateVariablesFragment } from "~/utilities/API/graphql";
import styles from "./EmailPreview.module.css";
import { messages } from "./messages";

export interface EmailPreviewProps {
  header: string;
  body: string;
  headerImageUrl: string | undefined;
  ctaButtonEnabled: boolean;
  buttonText: string;
  buttonColor: string;
  loading: boolean;
  variables: TemplateVariablesFragment[];
  showLogo?: boolean;
  modalView?: boolean;
  referralLink?: string;
}
interface PlaceHolderVariablesInterface {
  [key: string]: string | number;
}

const placeHolderVariables: PlaceHolderVariablesInterface = {};

export function EmailPreview(props: EmailPreviewProps) {
  return (
    <IntlProvider>
      <EmailPreviewInternal {...props} />
    </IntlProvider>
  );
}

function EmailPreviewInternal({
  body,
  headerImageUrl,
  header,
  loading,
  ctaButtonEnabled,
  buttonText,
  buttonColor,
  variables,
  showLogo = true,
  modalView = false,
  referralLink,
}: EmailPreviewProps): JSX.Element {
  const {
    logoUrl,
    address,
    name: companyName,
  } = useCampaignCompanyInformation();
  const { formatMessage } = useIntl();

  variables?.forEach(variableSection => {
    variableSection.variables.nodes.forEach(node => {
      placeHolderVariables[node.name] = node.sample;
    });
  });

  /**
   * Header is a string rendered as Level 3 Heading. This memo
   * extracts all place holder variables and replaces them with the sample data
   * and returns them as a span supporting the appropriate CSS.
   */
  const substitutedHeader = useMemo(() => {
    const response = header.split(/({{[A-Z_]*}}|\s)/g);
    const builder: JSX.Element[] = [];

    const renderHeaderText = (
      text: string | number,
      index: number,
      isPlaceHolder = false,
    ) => {
      return (
        <span
          key={index}
          className={classNames({
            ["substitutedCampaignEmailPreviewVariableText"]: isPlaceHolder,
          })}
        >
          {text}
        </span>
      );
    };

    response.forEach((value: string, index: number) => {
      if (placeHolderVariables[value]) {
        builder.push(
          renderHeaderText(placeHolderVariables[value], index, true),
        );
      } else {
        builder.push(renderHeaderText(value, index));
      }
    });

    return builder;
  }, [header]);

  /**
   * Body is an HTML string created from the Rich Text Editor. This prevents us from inserting
   * React components into it. As such, using regex, the place holder variables are replaced
   * with a span with custom CSS allowing for it to be differentiated from the rest of text
   */
  const substitutedBody = useMemo(() => {
    let response = body;

    for (const [key, value] of Object.entries(placeHolderVariables)) {
      const regex = new RegExp(key, "g");
      response = response.replace(
        regex,
        `<span class="substitutedCampaignEmailPreviewVariableText">${value}</span>`,
      );
    }

    return response;
  }, [body]);

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={classNames(styles.container, {
        [styles.modalView]: modalView,
      })}
    >
      {/* eslint-disable @typescript-eslint/naming-convention */}
      <div
        className={styles.previewContent}
        style={{ "--previewContent-color": buttonColor } as never}
      >
        {/* eslint-enable @typescript-eslint/naming-convention */}
        {logoUrl && (showLogo === null || showLogo) && (
          <img
            className={styles.brandingLogo}
            src={logoUrl}
            alt={formatMessage(messages.logoAltText)}
            data-testid="campaign-branding-logo"
          />
        )}
        <Content spacing={"large"}>
          <div className={styles.previewHeaderImage}>
            {headerImageUrl && (
              <img
                className={styles.emailHeaderImage}
                src={headerImageUrl}
                alt=""
              />
            )}
          </div>
          <div className={styles.previewHeader}>
            <Heading level={3}>{substitutedHeader}</Heading>
          </div>
          {loading ? (
            <EmailBodyLoading />
          ) : (
            <EmailBody body={substitutedBody} />
          )}
          {referralLink && <ReferralLink link={referralLink} />}
          {ctaButtonEnabled && <Button label={buttonText} />}
          <Text>
            {formatMessage(messages.emailPreviewQuestionsMessage, {
              companyName,
            })}
          </Text>
        </Content>
      </div>

      <div className={styles.footer}>
        <Content>
          <div>
            <Text>
              {formatMessage(messages.emailPreviewFooterCompanyInfo, {
                currentYear,
                companyName,
              })}
            </Text>
            <Text>{address}</Text>
          </div>
          <Typography textColor={"textSecondary"} size={"smaller"}>
            {formatMessage(messages.emailPreviewFooterPoweredBy)}
          </Typography>
          <Text>
            {formatMessage(messages.emailPreviewFooterUnsubscribeMessage)}{" "}
            <Typography
              element="span"
              textColor={"textSecondary"}
              size={"smaller"}
            >
              <span className={styles.unsubscribeLink}>
                {formatMessage(messages.emailPreviewFooterUnsubscribeLink)}
              </span>
            </Typography>
          </Text>
        </Content>
      </div>
    </div>
  );
}

function EmailBodyLoading() {
  return (
    <div className={styles.emailBodyLoading}>
      <Glimmer />
      <Glimmer />
      <Glimmer />
      <Glimmer />
      <Glimmer />
      <Glimmer width={250} />
    </div>
  );
}

function ReferralLink({ link }: { link?: string }) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.referralLink}>
      <div className={styles.referralLinkTitle}>
        <Text>
          <strong>{formatMessage(messages.referralLinkTitle)}</strong>
        </Text>
      </div>
      <Text>
        <a href={link}>{link}</a>
      </Text>
    </div>
  );
}
