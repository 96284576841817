import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { GLIMMER_TEST_ID } from "@jobber/components";
import { type DateRange, DateRanges } from "~/shared/InputDateRange/types";
import { dateRangeLabel } from "~/shared/InputDateRange/InputDateRange";
import {
  JOB_CREATED_EMAIL_RECIPIENT_NAME,
  deliveredOpenedUnsubscribedEmailMock,
} from "jobber/campaigns/mocks/campaignEmailMocks";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

export function getDateRangeButton(dateRange: DateRange) {
  return screen.getByText(dateRangeLabel(dateRange));
}
// Not exposing this because we're not supposed to get things by ARIA label.
// But in the default case where no date range is selected, the button will have
// just the label "All" which is hard to find on a page full of filters with
// "all" labels.

export function getEmailsTableTitle() {
  return screen.getByText(messages.emailsTableTitle.defaultMessage);
}

export function loadingStateGlimmer() {
  return screen.getAllByTestId(GLIMMER_TEST_ID);
}

export function loadingState() {
  return screen.getByLabelText("loading");
}

export function getClickableRowWithNoClientId() {
  return screen.getByText(
    deliveredOpenedUnsubscribedEmailMock.node.recipientName,
  ) as HTMLElement;
}

export function getClickableRowWithClientId() {
  return screen.getByText(JOB_CREATED_EMAIL_RECIPIENT_NAME) as HTMLElement;
}

export function queryRecipientHeader() {
  return screen.queryByText(messages.emailsTableRecipientHeader.defaultMessage);
}

export function queryDeliveredHeader() {
  return screen.queryByText(messages.emailsTableDeliveredHeader.defaultMessage);
}

export function queryOpenedHeader() {
  return screen.queryByText(messages.emailsTableOpenedHeader.defaultMessage);
}

export function queryClickedHeader() {
  return screen.queryByText(messages.emailsTableClickedHeader.defaultMessage);
}

export function queryUnsubscribedHeader() {
  return screen.queryByText(
    messages.emailsTableUnsubscribedHeader.defaultMessage,
  );
}

export function queryJobsHeader() {
  return screen.queryByText(messages.emailsTableJobsHeader.defaultMessage);
}

export function queryRevenueHeader() {
  return screen.queryByText(messages.emailsTableRevenueHeader.defaultMessage);
}
export function getRecipientHeader() {
  return screen.getByText(messages.emailsTableRecipientHeader.defaultMessage);
}

export function getDeliveredHeader() {
  return screen.getByText(messages.emailsTableDeliveredHeader.defaultMessage);
}

export function getDeliveredOpenedHeader() {
  return screen.getByText(
    `${messages.emailsTableDeliveredHeader.defaultMessage}, ${messages.emailsTableOpenedHeader.defaultMessage}`,
  );
}

export function getDeliveredOpenedClickedHeader() {
  return screen.getByText(
    `${messages.emailsTableDeliveredHeader.defaultMessage}, ${messages.emailsTableOpenedHeader.defaultMessage}, ${messages.emailsTableClickedHeader.defaultMessage}`,
  );
}

export function getDeliveredOpenedClickedUnsubscribedHeader() {
  return screen.getByText(
    `${messages.emailsTableDeliveredHeader.defaultMessage}, ${messages.emailsTableOpenedHeader.defaultMessage}, ${messages.emailsTableClickedHeader.defaultMessage}, ${messages.emailsTableUnsubscribedHeader.defaultMessage}`,
  );
}

export function getJobsHeader() {
  return screen.getByText(messages.emailsTableJobsHeader.defaultMessage);
}

export function getRevenueHeader() {
  return screen.getByText(messages.emailsTableRevenueHeader.defaultMessage);
}

export function getDateRangeButtonByName() {
  return screen.getByLabelText(/select date range/i);
}

export function queryApplyButton() {
  return screen.queryByText("Apply");
}

export function getApplyButton() {
  return screen.getByText("Apply");
}
export function getPresetSelector() {
  return screen.getByLabelText("Date range");
}

export function selectDateRangePreset(preset: DateRanges) {
  return userEvent.selectOptions(getPresetSelector(), preset);
}

export function getDateRangeOption(option: string) {
  return screen.getByText(option);
}

export function numberOfFilteredResults() {
  return screen.getByTestId("ATL-DataList-TotalCount").textContent;
}

export function getSearchRecipientPlaceholder() {
  return screen.getByLabelText(
    messages.emailSearchRecipientPlaceholder.defaultMessage,
  );
}

export function getSearchInputText() {
  return screen.getByLabelText(
    messages.emailSearchRecipientPlaceholder.defaultMessage,
  );
}

export function getEmptyList() {
  return screen.getByText("List is looking empty");
}

export function jobNumberButton() {
  return screen.getByRole("link");
}

export function getRevenueAssociateWithJob(revenue: string) {
  return screen.getByText(revenue);
}

export function getDefaultValue() {
  return screen.getByText("-");
}

export function getJobsHeaderWithColon() {
  return screen.queryByText(
    messages.emailsTableJobsHeaderWithColon.defaultMessage,
  );
}

export function getSortButton() {
  return screen.getByText("Sort");
}

export function mobileSortRecipientAtoZ() {
  return screen.getByText(messages.mobileSortRecipientAtoZ.defaultMessage);
}

export function mobileSortRecipientZtoA() {
  return screen.getByText(messages.mobileSortRecipientZtoA.defaultMessage);
}

export function getFilteredTableTitle() {
  const { formatMessage } = createIntl();

  return screen.getByText(formatMessage(messages.filteredEmailsTableTitle));
}

export function getEmptyFilteredState() {
  const { formatMessage } = createIntl();
  return screen.getByText(formatMessage(messages.noResultsFound));
}

export function getClearFilterButton() {
  const { formatMessage } = createIntl();
  return screen.getByText(formatMessage(messages.clearFilter));
}

// Solo Actions

export function iClickDateRangeFilterButton() {
  userEvent.click(getDateRangeButtonByName());
}

export function iClickDateRangeDropdown() {
  userEvent.click(getPresetSelector());
}

export function iClickRecipientNameWithNoClientId() {
  userEvent.click(getClickableRowWithNoClientId());
}

export function iClickRecipientNameWithClientId() {
  userEvent.click(getClickableRowWithClientId());
}

export function iTypeSearchText() {
  userEvent.type(
    screen.getByLabelText(
      messages.emailSearchRecipientPlaceholder.defaultMessage,
    ),
    "test",
  );
}

export function iClickRecipientHeader() {
  userEvent.click(getRecipientHeader());
}

export function iClickTheSortButton() {
  userEvent.click(getSortButton());
}

export function iClickTheSortOptionDesc() {
  userEvent.click(mobileSortRecipientZtoA() as HTMLElement);
}

export function iClickTheClearFilterButtion() {
  userEvent.click(getClearFilterButton());
}

// Multi-Step Actions

export function iSelectLast30DaysDateRangeOption() {
  iClickDateRangeFilterButton();
  iClickDateRangeDropdown();
  userEvent.click(getDateRangeOption("Last 30 days"));
  selectDateRangePreset(DateRanges.thirtyDays);
  userEvent.click(getApplyButton());
}
