import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import { useResizeObserver } from "@jobber/hooks/useResizeObserver";
import { useIntl } from "react-intl";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./TalkToSales.module.css";
import { messages } from "./messages";

interface TalkToSalesProps {
  growAccount: boolean;
  allowResubscribeCurrentPlan?: boolean;
}

export function TalkToSales({
  growAccount,
  allowResubscribeCurrentPlan = false,
}: TalkToSalesProps) {
  const { formatMessage } = useIntl();
  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();

  const renderPhoneNumber = (phoneNumber: string, message: object) => {
    return width < 640 ? (
      <a href={`tel:+${phoneNumber}`}>{formatMessage(message)}</a>
    ) : (
      <span className={styles.phoneNumberNotClickable}>
        {formatMessage(message)}
      </span>
    );
  };

  const ContactUsSection = (
    <>
      {formatMessage(messages.callUsAt, {
        allowResubscribeCurrentPlan: allowResubscribeCurrentPlan,
        salesPhoneNumber: renderPhoneNumber(
          "18334600177",
          messages.salesPhoneNumber,
        ),
        supportPhoneNumber: renderPhoneNumber(
          "18884245301",
          messages.supportPhoneNumber,
        ),
        intercomLink: (
          <button onClick={openChatWidget}>
            {formatMessage(messages.chatWithUsLive)}
          </button>
        ),
      })}
    </>
  );
  return (
    <div ref={ref}>
      {growAccount ? (
        <div className={styles.growTalkToSalesSectionWrapper}>
          <div className={styles.growTalkToSalesSection}>
            <div className={styles.container}>
              <div className={styles.title}>
                <Heading level={2}>
                  {formatMessage(messages.lookingToExpand)}
                </Heading>
                <Heading level={2}>
                  {formatMessage(messages.weCanGrowWithYou)}
                </Heading>
              </div>
              <p>{formatMessage(messages.enterprisePricingAvailable)}</p>
              <p className={styles.talkToSalesColumn__contact_us}>
                {ContactUsSection}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.talkToSalesSection}>
          <div
            className={classNames([
              styles.talkToSalesColumn,
              styles.talkToSalesColumn__message,
            ])}
          >
            <Heading level={width > 640 ? 2 : 4}>
              {formatMessage(messages.haveQuestionsAboutOurPlans)}
            </Heading>
          </div>
          <div
            className={classNames([
              styles.talkToSalesColumn,
              styles.talkToSalesColumn__contact_us,
            ])}
          >
            <Text size={width > 640 ? "large" : "base"}>
              {ContactUsSection}
            </Text>
          </div>
        </div>
      )}
    </div>
  );

  function openChatWidget() {
    Intercom.EXECUTE("showNewMessage");
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "chat_live",
    });
  }
}
