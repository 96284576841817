import React from "react";
import { useIntl } from "react-intl";
import paperRipSrc from "@images/signup/paper-rip.svg";
import type { ImageType } from "jobber/setupWizard/types";
import { messages } from "jobber/setupWizard/messages";
import defaultBusinessImageSrc from "@images/signup/set-up-your-business.jpg";
import styles from "./MediaColumn.module.css";

interface MediaColumnProps {
  backgroundImage?: ImageType;
  showRip?: boolean;
  children?: React.ReactNode;
}

export const paperRip: ImageType = {
  alt: messages.paperRipAltText,
  src: paperRipSrc,
};

export function MediaColumn({
  children,
  backgroundImage,
  showRip = true,
}: MediaColumnProps) {
  const { formatMessage } = useIntl();
  function backgroundImageStyles() {
    const imageStyles = [styles.mediaBackgroundImage];
    backgroundImage?.src == defaultBusinessImageSrc &&
      imageStyles.push(styles.businessBackground);

    return imageStyles.join(" ");
  }

  return (
    <div className={styles.mediaColumnWrapper}>
      {showRip && (
        <img
          className={styles.paperRip}
          src={paperRip.src}
          alt={formatMessage(paperRip.alt)}
        />
      )}
      {backgroundImage && (
        <img
          className={backgroundImageStyles()}
          src={backgroundImage.src}
          alt={formatMessage(backgroundImage.alt)}
        />
      )}
      {children}
    </div>
  );
}
