import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "upgradePage.liteToCoreUpgradePrompt.title",
    defaultMessage: "Free 14-day Core trial",
    description: "The title for the lite to core upgrade prompt",
  },
  description: {
    id: "upgradePage.liteToCoreUpgradePrompt.description",
    defaultMessage:
      "Organize your operations all in one place. The Core plan helps you visualize all jobs in your workflow and the revenue they’re generating, keeping you on track to meet your business goals.",
    description: "The description for the lite to core upgrade prompt",
  },
  secondaryDescription: {
    id: "upgradePage.liteToCoreUpgradePrompt.secondaryDescription",
    defaultMessage:
      "You will automatically return to your current plan after the trial. Restart the Jobber app to access the trial on your phone.",
    description:
      "The secondary description for the lite to core upgrade prompt",
  },
  ctaLabel: {
    id: "upgradePage.liteToCoreUpgradePrompt.ctaLabel",
    defaultMessage: "Learn More",
    description: "The label for the call to action button",
  },
});
