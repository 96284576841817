import { defineMessages } from "react-intl";

export const messages = defineMessages({
  inTrialButton: {
    id: "marketing.landing.inTrialButton",
    defaultMessage: "See plans and pricing",
    description: "Button text for accounts in trial",
  },
  inTrialFooter: {
    id: "marketing.landing.inTrialFooter",
    defaultMessage:
      "{feature} is available as an add-on to Jobber plans. You must select a plan before adding {feature}.",
    description: "Footer text for accounts in trial",
  },
});
