export const messages = {
  coreToConnectTrialUpgradePrompt: {
    title: "Try Connect for 14-days",
    description:
      "Experience our most popular features! With Connect, you can track your expenses, automate your follow-ups, and let customers book an appointment with you online.",
    secondaryDescription:
      "You will automatically return to your current plan after the trial.",
    ctaLabel: "Learn More",
    imgAltDesktop:
      "Core to Connect trial image displaying list of services and calendar",
    imgAltMobile: "Core to Connect trial image displaying calendar",
  },
};
