import { defineMessages } from "react-intl";

export const messages = defineMessages({
  businessPageCopy: {
    id: "setupWizard.businessPage.businessPageCopy",
    defaultMessage: "This will help us customize your experience at Jobber.",
    description: "Business page copy for the setup wizard",
  },
  companyNamePlaceholder: {
    id: "setupWizard.businessPageForm.companyNamePlaceholder",
    defaultMessage: "Company name",
    description: "Company name placeholder for the setup wizard",
  },
  companyNameError: {
    id: "setupWizard.businessPageForm.companyNameError",
    defaultMessage: "Please fill in the name of your company",
    description: "Company name error for the setup wizard",
  },
  companySizePlaceholder: {
    id: "setupWizard.businessPageForm.companySizePlaceholder",
    defaultMessage: "What's your team size (including yourself)?",
    description: "Company size placeholder for the setup wizard",
  },
  companySizeError: {
    id: "setupWizard.businessPageForm.companySizeError",
    defaultMessage: "Please tell us your team size",
    description: "Company size error for the setup wizard",
  },
  companyRevenuePlaceholder: {
    id: "setupWizard.businessPageForm.companyRevenuePlaceholder",
    defaultMessage: "What's your estimated annual revenue?",
    description: "Company revenue for the setup wizard",
  },
  companyRevenueError: {
    id: "setupWizard.businessPageForm.companyRevenueError",
    defaultMessage: "Please tell us your estimated annual revenue",
    description: "Company revenue error for the setup wizard",
  },
  businessPageDefaultHeader: {
    id: "setupWizard.businessPage.businessPageHeading",
    defaultMessage: "Set up your business",
    description: "Default business page heading for the setup wizard",
  },
  businessPageArboristHeader: {
    id: "setupWizard.businessPage.businessPageArboristHeader",
    defaultMessage: "Set up your arborist business",
    description:
      "Business page heading for the setup wizard for arborist industry",
  },
  businessPageCleaningHeader: {
    id: "setupWizard.businessPage.businessPageCleaningHeader",
    defaultMessage: "Set up your cleaning business",
    description:
      "Default business page heading for the setup wizard for cleaning industry",
  },
  businessPageConstructionHeader: {
    id: "setupWizard.businessPage.businessPageConstructionHeader",
    defaultMessage: "Set up your construction business",
    description:
      "Default business page heading for the setup wizard for construction industry",
  },
  businessPageElectricalHeader: {
    id: "setupWizard.businessPage.businessPageElectricalHeader",
    defaultMessage: "Set up your electrical business",
    description:
      "Default business page heading for the setup wizard for electrical industry",
  },
  businessPageHandymanHeader: {
    id: "setupWizard.businessPage.businessPageHandymanHeader",
    defaultMessage: "Set up your handyman business",
    description:
      "Default business page heading for the setup wizard for handyman industry",
  },
  businessPageHVACHeader: {
    id: "setupWizard.businessPage.businessPageHVACHeader",
    defaultMessage: "Set up your HVAC business",
    description:
      "Default business page heading for the setup wizard for HVAC industry",
  },
  businessPageLandscapingHeader: {
    id: "setupWizard.businessPage.businessPageLandscapingHeader",
    defaultMessage: "Set up your landscaping business",
    description:
      "Default business page heading for the setup wizard for landscaping industry",
  },
  businessPageLawnCareHeader: {
    id: "setupWizard.businessPage.businessPageLawnCareHeader",
    defaultMessage: "Set up your lawn care business",
    description:
      "Default business page heading for the setup wizard for lawn care industry",
  },
  businessPagePaintingHeader: {
    id: "setupWizard.businessPage.businessPagePaintingHeader",
    defaultMessage: "Set up your painting business",
    description:
      "Default business page heading for the setup wizard for painting industry",
  },
  businessPagePlumbingHeader: {
    id: "setupWizard.businessPage.businessPagePlumbingHeader",
    defaultMessage: "Set up your plumbing business",
    description:
      "Default business page heading for the setup wizard for plumbing industry",
  },
  businessPagePressureWashingHeader: {
    id: "setupWizard.businessPage.businessPagePressureWashingHeader",
    defaultMessage: "Set up your pressure washing business",
    description:
      "Default business page heading for the setup wizard for pressure washing industry",
  },
  businessPageWindowWashingHeader: {
    id: "setupWizard.businessPage.businessPageWindowWashingHeader",
    defaultMessage: "Set up your window washing business",
    description:
      "Default business page heading for the setup wizard for window washing industry",
  },
  companySizeJustMe: {
    id: "setupWizard.businessPageForm.companySizeJustMe",
    defaultMessage: "Just me",
    description: "Company size 'just me' option for the setup wizard",
  },
  companySize2To3People: {
    id: "setupWizard.businessPageForm.companySize2To3People",
    defaultMessage: "2-3 people",
    description: "Company size '2-3 people' option for the setup wizard",
  },
  companySize4To10People: {
    id: "setupWizard.businessPageForm.companySize4To10People",
    defaultMessage: "4-10 people",
    description: "Company size '4-10 people' option for the setup wizard",
  },
  companySize10PlusPeople: {
    id: "setupWizard.businessPageForm.companySize10PlusPeople",
    defaultMessage: "10+ people",
    description: "Company size '10+ people' option for the setup wizard",
  },
  companyRevenue0To50K: {
    id: "setupWizard.businessPageForm.companyRevenue0To50K",
    defaultMessage: "$0 - $50,000",
    description: "Company revenue '$0 - $50,000' option for the setup wizard",
  },
  companyRevenue50To150K: {
    id: "setupWizard.businessPageForm.companyRevenue50To150K",
    defaultMessage: "$50,000 - $150,000",
    description:
      "Company revenue '$50,000 - $150,000' option for the setup wizard",
  },
  companyRevenue150To500K: {
    id: "setupWizard.businessPageForm.companyRevenue150To500K",
    defaultMessage: "$150,000 - $500,000",
    description:
      "Company revenue '$150,000 - $500,000' option for the setup wizard",
  },
  companyRevenue500KPlus: {
    id: "setupWizard.businessPageForm.companyRevenue500KPlus",
    defaultMessage: "$500,000+",
    description: "Company revenue '$500,000+' option for the setup wizard",
  },
  companyRevenuePreferNotToSay: {
    id: "setupWizard.businessPageForm.companyRevenuePreferNotToSay",
    defaultMessage: "I'd prefer not to say",
    description:
      "Company revenue 'I'd prefer not to say' option for the setup wizard",
  },
  defaultSelectPlaceholder: {
    id: "setupWizard.businessPageForm.defaultSelectPlaceholder",
    defaultMessage: "Please select",
    description: "Default select for the setup wizard",
  },
  serviceProviderAltText: {
    id: "setupWizard.businessPage.serviceProviderAltText",
    defaultMessage: "service provider",
    description: "Service provider alt text for the setup wizard",
  },
  serviceProviderUsingMobileAppAltText: {
    id: "setupWizard.businessPage.serviceProviderUsingMobileAppAltText",
    defaultMessage: "service provider using mobile app",
    description:
      "Service provider using mobile app alt text for the setup wizard",
  },
  serviceProviderUsingDrillAltText: {
    id: "setupWizard.businessPage.serviceProviderUsingDrillAltText",
    defaultMessage: "service provider using a drill",
    description: "Service provider using drill alt text for the setup wizard",
  },
});
