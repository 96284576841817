import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Form } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { Option, Select } from "@jobber/components/Select";
import { Content } from "@jobber/components/Content";
import {
  companyRevenueArray,
  companySizeArray,
} from "jobber/setupWizard/components/constants";
import { Routes, type SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import {
  generateLastSubmittedStepQandA,
  safelyExtractAnswer,
} from "jobber/setupWizard/utils";
import { messages } from "../messages";
import styles from "../../SetupWizardForms.module.css";

export function BusinessPageForm({
  navigation,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [companyName, setCompanyName] = useState<string>(
    wizardData.companyName ?? "",
  );
  const [companySize, setCompanySize] = useState<string>(
    wizardData.companySize ?? "",
  );
  const [companyRevenue, setCompanyRevenue] = useState<string>(() => {
    return safelyExtractAnswer(
      "business_revenue",
      wizardData.questionsAndAnswers,
    );
  });

  const handleSubmit = async () => {
    const dataToSend = {
      companyName,
      companySize,
      questionsAndAnswers: [
        {
          question: "business_revenue",
          answer: companyRevenue,
        },
        generateLastSubmittedStepQandA(Routes.business),
      ],
    };

    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  function handleCompanyNameChange(name: string) {
    setCompanyName(name);
  }
  function handleCompanySizeChange(size: string) {
    setCompanySize(size);
  }
  function handleCompanyRevenueChange(revenue: string) {
    setCompanyRevenue(revenue);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <InputText
          name="companyName"
          placeholder={formatMessage(messages.companyNamePlaceholder)}
          value={companyName}
          validations={{
            required: {
              message: formatMessage(messages.companyNameError),
              value: true,
            },
          }}
          onChange={handleCompanyNameChange}
          size="large"
        />
        <Select
          name="companySize"
          placeholder={formatMessage(messages.companySizePlaceholder)}
          value={companySize}
          validations={{
            required: {
              message: formatMessage(messages.companySizeError),
              value: true,
            },
          }}
          onChange={handleCompanySizeChange}
          size="large"
        >
          <Option key={-1} value="" disabled>
            {formatMessage(messages.defaultSelectPlaceholder)}
          </Option>
          {companySizeArray.map((option, index) => {
            return (
              <Option key={index} value={option.value}>
                {formatMessage(option.label)}
              </Option>
            );
          })}
        </Select>
        <Select
          name="companyRevenue"
          placeholder={formatMessage(messages.companyRevenuePlaceholder)}
          value={companyRevenue}
          onChange={handleCompanyRevenueChange}
          size="large"
          validations={{
            required: {
              message: formatMessage(messages.companyRevenueError),
              value: true,
            },
          }}
        >
          <Option key={-1} value="" disabled>
            {formatMessage(messages.defaultSelectPlaceholder)}
          </Option>
          {companyRevenueArray.map((option, index) => {
            return (
              <Option key={index} value={option.value}>
                {formatMessage(option.label)}
              </Option>
            );
          })}
        </Select>
        <div className={styles.button}>
          <Button
            id={"businessPageSubmit"}
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
