import * as React from "react";
import type { CommonSignaturePadProps } from "components/SignaturePad/SignaturePadTypes";
import { useSignaturePadCallback } from "components/SignaturePad/useSignaturePadCallback";

export function TypeSignaturePad(props: CommonSignaturePadProps) {
  const {
    onSignatureDataUrlChange: onChange,
    Component = "type-signature-pad",
  } = props;
  const [ref, update, reset] = useSignaturePadCallback({ onChange });

  const onInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const hasText = e.target.value;
      if (hasText) {
        update();
      } else {
        reset();
      }
    },
    [ref],
  );

  const padProps = {
    ref,
    onInput,
  };

  return (
    <>
      <Component {...padProps} />
    </>
  );
}
