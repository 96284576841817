import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import type {
  AccountPromotion,
  BillingInformation,
  CurrentPlan,
  UpgradablePlan,
} from "jobber/upgradePage/UpgradePage";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./PlanComparison.module.css";
import { messages } from "./messages";
import { PlanCard, type PlanCardProps } from "../PlanCard";

interface DotsProps {
  slide: number;
  numberOfSlides: number;
  onDotClick: (index: number) => void;
}

function Dots({ slide, numberOfSlides, onDotClick }: DotsProps): JSX.Element {
  return (
    <div className={styles.dots}>
      {Array.from({ length: numberOfSlides }).map((_, dotIndex) => (
        <button
          data-testid="dot"
          key={`dot-${dotIndex}`}
          className={classNames(styles.dot, {
            [styles.activeDot]: dotIndex === slide,
          })}
          onClick={() => onDotClick(dotIndex)}
        />
      ))}
    </div>
  );
}

interface PlanComparisonProps {
  isAnnual: boolean;
  billingInformation:
    | BillingInformation["account"]["billingInformation"]
    | undefined;
  currentPlan: CurrentPlan | undefined;
  upgradePlans: Array<UpgradablePlan> | undefined;
  setSeeAllFeatures: (value: boolean) => void;
  activePromotion: AccountPromotion | undefined;
}

// eslint-disable-next-line max-statements
export function PlanComparison({
  isAnnual,
  billingInformation,
  currentPlan,
  upgradePlans,
  setSeeAllFeatures,
  activePromotion,
}: PlanComparisonProps) {
  const [plans, setPlans] = useState<PlanCardProps[]>([]);
  const [slide, setSlide] = useState<number>(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const minSwipeDistance = 50;
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (currentPlan && billingInformation && upgradePlans) {
      Amplitude.TRACK_EVENT("Viewed Upgrade Cards", {});

      const billingCyclePlanCode = (upgradePlan: UpgradablePlan) => {
        const planCode = isAnnual
          ? upgradePlan.annualBillingCycle?.planCode
          : upgradePlan.monthlyBillingCycle?.planCode;

        return planCode || "";
      };

      const accountsCurrentPlan = {
        planTier: currentPlan.planTier,
        planIdentifier: currentPlan.planIdentifier,
        planUserLimit: currentPlan.userLimit,
        planAdditionalUsers: currentPlan.additionalUsers,
        allowUserLimitIncrease: currentPlan.allowUserLimitIncrease,
        planCode: billingInformation.planCode,
        planPriceMonthly: billingInformation.planPriceMonthly,
        planPriceMonthlyDiscounted: billingInformation.planPriceMonthly,
        monthlyDiscountDuration: 0,
        planPriceAnnual: billingInformation.planPriceMonthly,
        planPriceAnnualDiscounted: billingInformation.planPriceMonthly,
        annualDiscountDuration: 0,
        planPriceAdditionalUser: currentPlan.userCost,
        isPricingAnnual: false,
        isFourPlanSection: upgradePlans.length === 3,
        isAccountPlanTier: true,
        isMostPopular: false,
        isHighlighted: false,
        billingCycleInterval: billingInformation.billingCycleInterval,
        allowInWebBillingChanges: billingInformation.allowInWebBillingChanges,
        setSeeAllFeatures: setSeeAllFeatures,
        activePromotionTrackingTag: activePromotion?.trackingTag,
        allowResubscribeCurrentPlan:
          billingInformation.allowResubscribeCurrentPlan,
      };
      const planList: PlanCardProps[] = [accountsCurrentPlan];
      upgradePlans.forEach(upgradePlan => {
        if (
          (isAnnual && !upgradePlan.annualBillingCycle) ||
          (!isAnnual && !upgradePlan.monthlyBillingCycle)
        ) {
          return;
        }

        const plan = {
          planTier: upgradePlan.planTier,
          planIdentifier: upgradePlan.planIdentifier,
          planUserLimit: upgradePlan.userLimit,
          planAdditionalUsers: 0,
          allowUserLimitIncrease: upgradePlan.allowUserLimitIncrease,
          planCode: billingCyclePlanCode(upgradePlan),
          planPriceMonthly: upgradePlan.planCostMonthly,
          planPriceMonthlyDiscounted: upgradePlan.planCostMonthlyDiscounted,
          monthlyDiscountDuration: upgradePlan.monthlyDiscountDuration,
          planPriceAnnual: upgradePlan.planCostAnnual,
          planPriceAnnualDiscounted: upgradePlan.planCostAnnualDiscounted,
          annualDiscountDuration: upgradePlan.annualDiscountDuration,
          planPriceAdditionalUser: upgradePlan.userCost,
          isPricingAnnual: isAnnual,
          isFourPlanSection: upgradePlans.length === 3,
          isAccountPlanTier: false,
          isMostPopular: upgradePlan.isRecommended,
          isHighlighted:
            upgradePlan.isRecommended ||
            (upgradePlan.planTier === "GROW" && upgradePlans.length === 1),
          billingCycleInterval: "",
          allowInWebBillingChanges: billingInformation.allowInWebBillingChanges,
          setSeeAllFeatures: setSeeAllFeatures,
          activePromotionTrackingTag: activePromotion?.trackingTag,
        };
        planList.push(plan);
      });
      setPlans(planList);
    }
  }, [
    billingInformation,
    currentPlan,
    upgradePlans,
    isAnnual,
    setSeeAllFeatures,
    activePromotion,
  ]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart && touchEnd) {
      const diff = touchStart - touchEnd;
      const isLeftSwipe = diff > minSwipeDistance;
      const isRightSwipe = diff < -minSwipeDistance;
      if (isLeftSwipe) {
        slideRight();
      } else if (isRightSwipe) {
        slideLeft();
      }
    }
  };

  return (
    <div
      className={styles.planComparison}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.planCards}>
        {plans.map((plan, planIndex) => (
          <div
            key={`card-${planIndex}`}
            className={classNames(styles.planCard, {
              [styles.slide]: planIndex === slide,
              [styles.leftSlide]: planIndex === slide - 1,
              [styles.rightSlide]: planIndex === slide + 1,
              [styles.fourPlanCard]: plans.length === 4,
            })}
          >
            <PlanCard {...plan} />
          </div>
        ))}
      </div>
      {plans.length > 1 && (
        <Dots
          slide={slide}
          numberOfSlides={plans.length}
          onDotClick={setSlide}
        />
      )}
      <div className={styles.priceText}>
        <Text variation="subdued">{formatMessage(messages.priceLabel)}</Text>
      </div>
    </div>
  );

  function slideRight() {
    if (slide < plans.length - 1) {
      setSlide(slide + 1);
    }
  }
  function slideLeft() {
    if (slide > 0) {
      setSlide(slide - 1);
    }
  }
}
