import {
  CreateRuleConditionTypes,
  TriggerTime,
} from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/types";

export const dayOptions = [
  {
    label: "1 day",
    value: 24,
  },
  {
    label: "2 days",
    value: 48,
  },
  {
    label: "3 days",
    value: 72,
  },
  {
    label: "5 days",
    value: 120,
  },
  {
    label: "7 days",
    value: 168,
  },
  {
    label: "14 days",
    value: 336,
  },
  {
    label: "30 days",
    value: 720,
  },
];

export const modelOptions = [
  {
    label: "campaigns",
    value: "Campaign",
  },
];

export const dateFieldOptions = [
  {
    label: "ends",
    value: "ends_at",
  },
  {
    label: "sent",
    value: "sent_at",
  },
];

// All automation trigger times should be in hours
export const monthOptions = [
  {
    label: "1 month",
    value: TriggerTime.ONE_MONTH_IN_HOURS,
  },
  {
    label: "2 months",
    value: TriggerTime.TWO_MONTHS_IN_HOURS,
  },
  {
    label: "4 months",
    value: TriggerTime.FOUR_MONTHS_IN_HOURS,
  },
  {
    label: "6 months",
    value: TriggerTime.SIX_MONTHS_IN_HOURS,
  },
  {
    label: "12 months",
    value: TriggerTime.TWELVE_MONTHS_IN_HOURS,
  },
];

export const pastClientsConditionTypesOptions = [
  { label: "client tags", value: CreateRuleConditionTypes.ClientTags },
  { label: "line items", value: CreateRuleConditionTypes.LineItems },
  { label: "job type", value: CreateRuleConditionTypes.JobType },
];

export const quoteSentLeadsConditionTypesOptions = [
  { label: "client tags", value: CreateRuleConditionTypes.QuoteClientTags },
  { label: "line items", value: CreateRuleConditionTypes.QuoteLineItems },
];

export const containsOptions = [
  { label: "contains", value: true },
  { label: "does not contain", value: false },
];

export const includesOptions = [
  { label: "includes", value: true },
  { label: "does not include", value: false },
];

export const recurringOptions = [
  { label: "is recurring", value: "contract" },
  { label: "is one-off", value: "one-off" },
];
