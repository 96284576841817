export const messages = {
  content: {
    header: "Still deciding?",
    body: "Restart your 14-day trial to get access to Grow plan features one last time. No credit card required.",
  },
  button: {
    label: "Learn More",
  },
  image: {
    alt: "Restart trial prompt marketing image",
  },
};
