import { useHistory } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  URL_PARAM_CAMPAIGNS_TYPE,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";
import { useCampaignStatusUpdateMutation } from "jobber/campaigns/hooks/useCampaignStatusUpdateMutation/useCampaignStatusUpdateMutation";
import {
  CampaignStatus,
  CampaignTransitionalStatus,
  CampaignsFilterFields,
} from "~/utilities/API/graphql";
import { CampaignReviewErrorState } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions";

export function useActivateAutomatedCampaign(
  campaignId: string,
  status: CampaignStatus | undefined,
) {
  const history = useHistory();
  const formatMessage = useIntl().formatMessage;

  const { campaignStatusUpdate } = useCampaignStatusUpdateMutation();

  const activateAutomatedCampaign = async () => {
    if (
      !status ||
      (status !== CampaignStatus.DRAFT &&
        status !== CampaignStatus.AUTOMATIONINACTIVE)
    ) {
      throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
    }

    await campaignStatusUpdate({
      input: {
        campaignId: campaignId,
        input: {
          status: CampaignTransitionalStatus.AUTOMATIONACTIVE,
        },
      },
      onError: () => {
        throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
      },
      onCompleted: data => {
        if (data.campaignStatusUpdate.userErrors.length === 0) {
          history.replace(
            `${CAMPAIGNS_LANDING_PAGE_PATH}?${URL_PARAM_CAMPAIGNS_TYPE}=${CampaignsFilterFields.AUTOMATED}`,
          );
          showToast({
            message: formatMessage(messages.activatedCampaignToast),
            variation: "success",
          });
        } else {
          throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
        }
      },
    });
  };
  return { activateAutomatedCampaign };
}
