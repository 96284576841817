import { defineMessages } from "react-intl";

export const messages = defineMessages({
  billingDialogLink: {
    id: "upgradePage.stickyHeader.billingDialogLink",
    defaultMessage: `/accounts/billing_info/edit.dialog?plan_set_identifier={planIdentifier}&tier_name={tierName}&is_annual={isAnnual}&is_choosing_plan=true&offer={offer}`,
    description: "billing dialog link",
  },
  choosePlanButtonCopy: {
    id: "upgradePage.stickyHeader.choosePlanButtonCopy",
    defaultMessage: "Choose Plan",
    description: "choose plan button copy",
  },
  yourCurrentPlanButtonCopy: {
    id: "upgradePage.stickyHeader.yourCurrentPlanButtonCopy",
    defaultMessage: "Your Current Plan",
    description: "Your current plan copy",
  },
});
