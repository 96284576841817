import { useContext, useState } from "react";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { ClientNotificationId } from "~/utilities/API/graphql";
import type {
  DeliveryMethod,
  MessageTemplateEmail,
  MessageTemplateSms,
} from "~/utilities/API/graphql";
import { useEditGoogleBusinessAccount } from "jobber/reviews/hooks/useEditGoogleBusinessAccount";
import { ReviewsSettingsDrawerContext } from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useUpdateNotification } from "./useUpdateNotification";
import type { FormValues } from "../components/ReviewsSettings/types";
import type { ErrorState, SubmitProps, UpdateNotificationArgs } from "../types";
import { messages } from "../components/ReviewsSettings/messages";

export function useSettingsFormState(submitProps: SubmitProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    deliveryMethod: undefined,
    email: undefined,
    sms: undefined,
  });
  const [errorState, setErrorState] = useState<ErrorState>({
    dismissed: false,
    shouldShow: false,
    hasGeneralError: false,
  });
  const { drawerActions, showInsights } = useContext(
    ReviewsSettingsDrawerContext,
  );

  const { formatMessage } = useIntl();
  const { editGoogleBusinessAccount } = useEditGoogleBusinessAccount();
  const { updateNotification } = useUpdateNotification();

  const onChange = (value: DeliveryMethod) => {
    if (errorState.shouldShow) {
      resetErrorState();
    }

    setFormValues(prev => ({
      ...prev,
      deliveryMethod: value,
    }));
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    resetErrorState();

    const errors = await handleUpdates();

    if (errors.length > 0) {
      setErrorState({
        dismissed: false,
        shouldShow: true,
        hasGeneralError: true,
      });
    } else {
      showToast({
        message: formatMessage(messages.messageSettingsSaveSuccess),
      });
      drawerActions?.closeSideDrawer();
    }

    setIsSubmitting(false);
  };

  // eslint-disable-next-line max-statements
  const handleUpdates = async () => {
    let errors = [],
      emailChanged = false,
      smsChanged = false;

    const updateSubmitEmail = async (
      template: MessageTemplateEmail | undefined,
    ) => {
      errors = await updateNotificationValues(template, updateNotification);
      emailChanged = true;
    };

    const updateSubmitSms = async (
      template: MessageTemplateSms | undefined,
    ) => {
      errors = await updateNotificationValues(template, updateNotification);
      smsChanged = true;
    };

    if (showInsights) {
      await updateSubmitEmail(submitProps.initialValues.email);
      await updateSubmitSms(submitProps.initialValues.sms);
    }

    if (
      formValuesHaveChanged(formValues?.email, submitProps.initialValues.email)
    ) {
      await updateSubmitEmail(formValues.email);
    }

    if (formValuesHaveChanged(formValues?.sms, submitProps.initialValues.sms)) {
      await updateSubmitSms(formValues?.sms);
    }

    Amplitude.TRACK_EVENT("Interacted with Reviews Message Customization", {
      ...determineAmplitudeInfo(emailChanged, smsChanged),
    });

    if (deliveryMethodHasChanged(formValues, submitProps)) {
      errors = await editGoogleBusinessAccount({
        deliveryMethod: formValues.deliveryMethod as DeliveryMethod,
      });
    }

    return errors;
  };

  const resetErrorState = () => {
    setErrorState({
      dismissed: false,
      shouldShow: false,
      hasGeneralError: false,
    });
  };

  return {
    isSubmitting,
    setIsSubmitting,
    formValues,
    setFormValues,
    errorState,
    setErrorState,
    onChange,
    onSubmit,
  };
}

function deliveryMethodHasChanged(
  formValues: FormValues,
  submitProps: SubmitProps,
) {
  return (
    formValues.deliveryMethod &&
    formValues.deliveryMethod !== submitProps.initialValues.deliveryMethod
  );
}

async function updateNotificationValues(
  values: MessageTemplateEmail | MessageTemplateSms | undefined,
  updateNotification: ({
    deliveryMethod,
    clientNotificationId,
    scheduleId,
    templateId,
    message,
    subject,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UpdateNotificationArgs) => Promise<any[]>,
) {
  let errors = [];

  if (values?.__typename === "MessageTemplateEmail") {
    errors = await updateNotification({
      clientNotificationId: ClientNotificationId.REVIEW_REQUEST,
      templateId: values?.id,
      subject: values?.subject?.current,
      message: values?.message?.current,
    });
  }
  if (values?.__typename === "MessageTemplateSMS") {
    errors = await updateNotification({
      clientNotificationId: ClientNotificationId.REVIEW_V2_REQUEST,
      templateId: values?.id,
      message: values?.message?.current,
    });
  }
  return errors;
}

function formValuesHaveChanged(
  formValues: MessageTemplateEmail | MessageTemplateSms | undefined,
  initialValues: MessageTemplateEmail | MessageTemplateSms | undefined,
) {
  if (!formValues || !initialValues) return false;

  if (formValues.deliveryMethod !== initialValues.deliveryMethod) return true;

  const messageHasChanged =
    formValues?.message.current !== initialValues?.message.current;

  if (
    formValues?.__typename === "MessageTemplateSMS" &&
    initialValues?.__typename === "MessageTemplateSMS"
  ) {
    return messageHasChanged;
  }

  if (
    formValues?.__typename === "MessageTemplateEmail" &&
    initialValues?.__typename === "MessageTemplateEmail"
  ) {
    return (
      messageHasChanged ||
      formValues?.subject.current !== initialValues?.subject.current
    );
  }
}

function determineAmplitudeInfo(
  emailTemplateSaved: boolean,
  smsTemplateSaved: boolean,
): { interaction: string; source?: string } {
  if (emailTemplateSaved && smsTemplateSaved) {
    return { interaction: "changed_and_saved", source: "both" };
  } else if (emailTemplateSaved) {
    return { interaction: "changed_and_saved", source: "email" };
  } else if (smsTemplateSaved) {
    return { interaction: "changed_and_saved", source: "sms" };
  } else {
    return { interaction: "unchanged_and_saved" };
  }
}
