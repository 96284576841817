import React from "react";
import { Text } from "@jobber/components/Text";
import { Flex } from "@jobber/components";
import { ItemDonut } from "jobber/usageBasedTrial/ItemDonut/ItemDonut";
import styles from "./SideNavItem.module.css";
import type { ItemType } from "../types";

export interface SideNavItemProps {
  name: string;
  used: number;
  url: string;
  shouldShowTooltip: boolean;
}

export function SideNavItem({
  name,
  used,
  url,
  shouldShowTooltip,
}: SideNavItemProps) {
  const itemsUsed = Math.min(used, 5);

  return (
    <a className={styles.itemContainer} href={url}>
      <Flex template={["shrink", "grow", "shrink"]}>
        <ItemDonut
          itemType={name as ItemType}
          currentAmount={itemsUsed}
          shouldShowTooltip={shouldShowTooltip}
        />

        {!shouldShowTooltip && (
          <>
            <Text>{name}</Text>
            <Text>{itemsUsed}/5</Text>
          </>
        )}
      </Flex>
    </a>
  );
}
