import React, { createContext, useState } from "react";
import { Source } from "./types";

export enum DrawerView {
  ManageSettings = "manageSettings",
  MessageSettings = "messageSettings",
  FollowUpMessageSettings1 = "followUpMessageSettings1",
  FollowUpMessageSettings2 = "followUpMessageSettings2",
}

export interface DrawerActions {
  currentView: DrawerView | null;
  showInsights: boolean;
  drawerActions?: {
    goTo: (drawer: DrawerView, source?: Source) => void;
    closeSideDrawer: () => void;
  };
}

export const ReviewsSettingsDrawerContext = createContext<DrawerActions>({
  currentView: null,
  showInsights: false,
});

export function ReviewsSettingsDrawerContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentView, setInitialView] = useState<DrawerView | null>(null);
  const [showInsights, setShowInsights] = useState<boolean>(false);

  const drawerActions = {
    goTo: (drawer: DrawerView, source?: Source) => {
      setInitialView(drawer);
      if (source === Source.insights) {
        setShowInsights(true);
      } else {
        setShowInsights(false);
      }
    },
    closeSideDrawer: () => {
      setInitialView(null);
      setShowInsights(false);
    },
  };

  return (
    <ReviewsSettingsDrawerContext.Provider
      value={{ currentView, showInsights, drawerActions }}
    >
      {children}
    </ReviewsSettingsDrawerContext.Provider>
  );
}
