import { Industry } from "~/utilities/API/graphql";
import { messages as businessPageMessages } from "jobber/setupWizard/components/BusinessPage/messages";
import {
  arboristImages,
  cleaningImages,
  constructionImages,
  defaultImages,
  electricalImages,
  handymanImages,
  hvacImages,
  landscapingImages,
  lawnImages,
  paintingImages,
  plumbingImages,
  pressureWashingImages,
  windowWashingImages,
} from "./assets";
import {
  type IndustrySpecificContentType,
  RouteToContentKeyMap,
  type Routes,
} from "./types";
import { lastSubmittedStepQuestionKey } from "./components/constants";
import { quotes } from "./components/SPQuote/quotes";

export const safelyGetEmailParts = (
  email: string,
): { prefix: string; suffix: string } => {
  if (email === "") return { prefix: "", suffix: "" };
  const emailParts = email.split("@");
  return { prefix: emailParts[0].split("+")[0], suffix: emailParts[1] };
};

export const safelyExtractAnswer = (
  questionText: string,
  questionsAndAnswers?: { question: string; answer: string }[],
): string => {
  if (questionsAndAnswers) {
    const answers = questionsAndAnswers.filter(
      x => x.question === questionText,
    );
    return answers.length === 1 ? answers[0].answer : "";
  } else {
    return "";
  }
};

export const generateLastSubmittedStepQandA = (route: Routes) => {
  return {
    question: lastSubmittedStepQuestionKey,
    answer: route,
  };
};

const defaultContentValues = {
  businessPage: {
    heading: businessPageMessages.businessPageDefaultHeader,
    backgroundImage: {
      alt: businessPageMessages.serviceProviderAltText,
      src: defaultImages.business,
    },
  },
  topPriorityPage: {
    backgroundImage: {
      alt: businessPageMessages.serviceProviderUsingDrillAltText,
      src: defaultImages.topPriority,
    },
    quote: quotes.defaultQuote,
  },
  heardAboutUsPage: {
    backgroundImage: {
      alt: businessPageMessages.serviceProviderAltText,
      src: defaultImages.heardAboutUs,
    },
  },
};

export const extractBackgroundImageSrc = (
  content: IndustrySpecificContentType,
  route: Routes,
): string | null => {
  const contentKey = RouteToContentKeyMap[route];

  if (!contentKey) return null;

  return content[contentKey]?.backgroundImage?.src || null;
};

export const getIndustrySpecificContent = (
  industry?: Industry,
  route?: Routes,
): IndustrySpecificContentType => {
  const contentForIndustry = getContentForIndustry(industry);

  if (!route) {
    return contentForIndustry;
  }

  const contentKey = RouteToContentKeyMap[
    route
  ] as keyof IndustrySpecificContentType;

  // Check if contentKey exists in the contentForIndustry
  if (contentKey && contentForIndustry[contentKey]) {
    return {
      [contentKey]: contentForIndustry[contentKey],
    };
  }

  return defaultContentValues;
};

const getContentForIndustry = (
  industry?: Industry,
): IndustrySpecificContentType => {
  switch (industry) {
    case Industry.ARBORIST_TREE_CARE:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: arboristImages.business,
          },
          heading: businessPageMessages.businessPageArboristHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: arboristImages.topPriority,
          },
          quote: quotes.arboristIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: arboristImages.heardAboutUs,
          },
        },
      };
    case Industry.CARPET_CLEANING:
    case Industry.COMMERCIAL_CLEANING:
    case Industry.RESIDENTIAL_CLEANING:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: cleaningImages.business,
          },
          heading: businessPageMessages.businessPageCleaningHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: cleaningImages.topPriority,
          },
          quote: quotes.cleaningIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: cleaningImages.heardAboutUs,
          },
        },
      };
    case Industry.CONSTRUCTION_CONTRACTING:
    case Industry.FLOORING_SERVICE:
    case Industry.RENOVATIONS:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: constructionImages.business,
          },
          heading: businessPageMessages.businessPageConstructionHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderUsingMobileAppAltText,
            src: constructionImages.topPriority,
          },
          quote: quotes.constructionIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderUsingMobileAppAltText,
            src: constructionImages.heardAboutUs,
          },
        },
      };

    case Industry.ELECTRICAL_CONTRACTOR:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: electricalImages.business,
          },
          heading: businessPageMessages.businessPageElectricalHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: electricalImages.topPriority,
          },
          quote: quotes.electricalIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: electricalImages.heardAboutUs,
          },
        },
      };
    case Industry.HANDYMAN:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: defaultImages.topPriority,
          },
          heading: businessPageMessages.businessPageHandymanHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: handymanImages.topPriority,
          },
          quote: quotes.defaultQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: handymanImages.heardAboutUs,
          },
        },
      };
    case Industry.HVAC:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: hvacImages.business,
          },
          heading: businessPageMessages.businessPageHVACHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: hvacImages.topPriority,
          },
          quote: quotes.hvacIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: hvacImages.heardAboutUs,
          },
        },
      };
    case Industry.LANDSCAPING_CONTRACTOR:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: landscapingImages.business,
          },
          heading: businessPageMessages.businessPageLandscapingHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: landscapingImages.topPriority,
          },
          quote: quotes.landscapingIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: landscapingImages.heardAboutUs,
          },
        },
      };
    case Industry.LAWN_CARE_LAWN_MAINTENANCE:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: lawnImages.business,
          },
          heading: businessPageMessages.businessPageLawnCareHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: lawnImages.topPriority,
          },
          quote: quotes.lawnIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: lawnImages.heardAboutUs,
          },
        },
      };
    case Industry.PAINTING:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: paintingImages.business,
          },
          heading: businessPageMessages.businessPagePaintingHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: paintingImages.topPriority,
          },
          quote: quotes.paintingIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: paintingImages.heardAboutUs,
          },
        },
      };
    case Industry.PLUMBING:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: plumbingImages.business,
          },
          heading: businessPageMessages.businessPagePlumbingHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: plumbingImages.topPriority,
          },
          quote: quotes.plumbingIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: plumbingImages.heardAboutUs,
          },
        },
      };
    case Industry.PRESSURE_WASHING_SERVICE:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: pressureWashingImages.business,
          },
          heading: businessPageMessages.businessPagePressureWashingHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: pressureWashingImages.topPriority,
          },
          quote: quotes.pressureWashingIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: pressureWashingImages.heardAboutUs,
          },
        },
      };
    case Industry.WINDOW_WASHING:
      return {
        businessPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: windowWashingImages.business,
          },
          heading: businessPageMessages.businessPageWindowWashingHeader,
        },
        topPriorityPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: windowWashingImages.topPriority,
          },
          quote: quotes.windowWashingIndustryQuote,
        },
        heardAboutUsPage: {
          backgroundImage: {
            alt: businessPageMessages.serviceProviderAltText,
            src: windowWashingImages.heardAboutUs,
          },
        },
      };
    default:
      return defaultContentValues;
  }
};
