import React from "react";
import { Text } from "@jobber/components/Text";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { type MessageDescriptor, useIntl } from "react-intl";
import { PriceHighlightText } from "legacy/jobber/billing/components/PriceHighlightText/PriceHighlightText";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { messages } from "./messages";
import styles from "./InactiveAddonDetails.module.css";

interface InactiveAddonDetailsProps {
  subscriptionAddon: SubscriptionAddon;
}

const addonToDescriptionMap: { [key: string]: MessageDescriptor } = {
  campaigns: messages.campaignsDescription,
  reviews: messages.reviewsDescription,
};

export function InactiveAddonDetails(props: InactiveAddonDetailsProps) {
  const { subscriptionAddon } = props;
  const { identifier } = subscriptionAddon;

  const addonDescription = addonToDescriptionMap[identifier];

  const { formatMessage } = useIntl();

  return (
    <>
      {addonDescription && <Text>{formatMessage(addonDescription)}</Text>}
      <div className={styles.footer}>
        <PriceHighlightText
          cost={subscriptionAddon.monthlyBillingCycle?.monthlyCost}
          discountedCost={
            subscriptionAddon.discountGroup?.monthlyAddonDiscount
              ?.addonCostMonthlyDiscounted
          }
          hideDiscountedCost={
            subscriptionAddon.discountGroup?.monthlyAddonDiscount
              ?.hasMultipleActiveDiscounts
          }
        />
        <div>
          <InlineLabel color="lightBlue">
            {formatMessage(messages.recommended)}
          </InlineLabel>
        </div>
      </div>
    </>
  );
}
