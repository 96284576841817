import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Markdown } from "@jobber/components/Markdown";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useReducer, useState } from "react";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import { makeHostedOnboardingUrl } from "./managedAccountUtilities";
import {
  type HostedOnboardingProgress,
  init,
  verifyReducer,
} from "./verifyReducer";

export interface VerifyProps {
  hostedOnboardingUrlCreationUrl: string;
  hostedOnboardingProgress: HostedOnboardingProgress;
}

export function Verify(props: VerifyProps) {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [authenticityToken] = useAuth();
  const [t] = useTranslation();
  const [{ verifyButtonLabel, verifying, verifyComplete }, dispatch] =
    useReducer(verifyReducer, props.hostedOnboardingProgress, init);
  const { permissions } = useJobberPayments();

  useEffect(() => {
    dispatch(props.hostedOnboardingProgress);
  }, [props.hostedOnboardingProgress]);

  return (
    <Content>
      {verifyComplete ? (
        <>
          <h2 className="u-colorGreen">
            <Icon color="green" name="checkmark" /> Let’s get you paid!
          </h2>
          <Text>
            You’re ready to go! Collect card payments on any quotes or
            invoices—online and in the field!
          </Text>
        </>
      ) : (
        <>
          {errorMessage && <Banner type="error">{errorMessage}</Banner>}
          <Heading level={3}>{t("verifyBusinessHeading")}</Heading>
          <Markdown content={t("managedAccountRequirements")} />
          <Text>{t("managedAccountSecurityAssurance")}</Text>
          <Button
            label={verifyButtonLabel}
            onClick={onVerifyButtonClick}
            loading={isLoading || verifying}
            disabled={!permissions.canSetup}
          />
        </>
      )}
    </Content>
  );

  async function onVerifyButtonClick() {
    setLoading(true);
    setErrorMessage("");

    Amplitude.TRACK_EVENT("Interacted with Jobber Payments Setup", {
      interaction: "Clicked Verify",
    });

    try {
      const successUrl = await makeHostedOnboardingUrl(
        props.hostedOnboardingUrlCreationUrl,
        authenticityToken,
      );
      window.location.href = successUrl;
    } catch (e) {
      const error = e as Error;
      setErrorMessage(error.message);
      setLoading(false);
    }
  }
}
