import { gql } from "@apollo/client";

export const SUBSCRIPTION_UPDATE = gql`
  mutation SubscriptionUpdate($input: SubscriptionUpdateInput!) {
    subscriptionUpdate(input: $input) {
      success
      shouldRecordFirstTimeSubscriptionEvents
      successRedirectUrl
      userErrors {
        message
        path
      }
    }
  }
`;

export const CHECKOUT_ACCOUNT_ADDONS_INFO = gql`
  query CheckoutAccountAddonsInfo($planSetIdentifier: String!) {
    accountAddonsInfo(planSetIdentifier: $planSetIdentifier) {
      addons {
        name
        identifier
        currency
        isActive
        discountGroup {
          monthlyAddonDiscount {
            addonCostMonthlyDiscounted
            hasMultipleActiveDiscounts
            remainingDiscountedFullMonths
            discountType
            discountAmount
            discountEndDate
          }
        }
        monthlyBillingCycle {
          addonCode
          monthlyCost
        }
        previewGroup {
          monthlyPreview {
            proratedDiscount
            proratedTotal
            proratedTax
            nextBillingDate
          }
        }
      }
    }
  }
`;
