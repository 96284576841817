import React, {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { type CountryTuple, useCountries } from "./useCountries";

export interface CountrySelectProps {
  countryCodesToNameMap?: { [key: string]: string };
  priorityCountryCodes?: string[];
  countryCode?: string;

  className?: string;
  disabled?: boolean;

  onCountryCodeSelect?(countryCode: string): void;
}

function tupleToOption([code, display]: CountryTuple) {
  return (
    // it's used in a native select, aria-selected would be applied
    <option value={code} key={code}>
      {display}
    </option>
  );
}

function useOptions({
  countryCodesToNameMap,
  priorityCountryCodes,
}: {
  countryCodesToNameMap: { [key: string]: string };
  priorityCountryCodes?: string[];
}) {
  const [priorities, all] = useCountries({
    countryCodesToNameMap,
    priorityCountryCodes,
  });
  const priorityOptions = useMemo(
    () => priorities.map(tupleToOption),
    [priorities],
  );
  const allOptions = React.useMemo(() => all.map(tupleToOption), [all]);

  return [priorityOptions, allOptions];
}

export function CountrySelect(props: CountrySelectProps) {
  const {
    countryCode,
    onCountryCodeSelect,
    countryCodesToNameMap = {},
    priorityCountryCodes,
    className,
    disabled,
  } = props;
  const [priorityOptions, allOptions] = useOptions({
    countryCodesToNameMap,
    priorityCountryCodes,
  });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (onCountryCodeSelect) {
        onCountryCodeSelect(e.target.value);
      }
    },
    [onCountryCodeSelect],
  );
  const showDivider = useMemo(
    () => priorityOptions.length > 0 && allOptions.length > 0,
    [priorityOptions, allOptions],
  );

  useEffect(() => {
    if (!countryCode) {
      if (onCountryCodeSelect) {
        onCountryCodeSelect("US");
      }
    }
  }, [countryCode, onCountryCodeSelect]);

  return (
    <>
      <div className={`select ${className}`}>
        <select
          value={countryCode}
          onChange={onChange}
          onBlur={onChange}
          autoComplete="billing country"
          disabled={disabled}
        >
          {priorityOptions}
          {showDivider && (
            // it's used in a native select, aria-selected would be applied
            // eslint-disable-next-line jsx-a11y/no-redundant-roles -- Grandfathered error: Please fix if touching this code.
            <option disabled={true} role="option">
              ──────────
            </option>
          )}
          {allOptions}
        </select>
      </div>
    </>
  );
}
