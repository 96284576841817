import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationInterface,
  type CustomFieldConfigurationNodeCustomFieldConfigurationAreaFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationDropdownFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationLinkFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationNumericFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationTextFragment,
  type CustomFieldConfigurationNodeCustomFieldConfigurationTrueFalseFragment,
  CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";

export function makeLinkCustomField(
  overrides: Partial<CustomFieldConfigurationNodeCustomFieldConfigurationLinkFragment> = {},
): CustomFieldConfigurationNodeCustomFieldConfigurationLinkFragment {
  const base = makeInterfaceCustomField(CustomFieldConfigurationValueType.LINK);
  return Object.assign(
    base,
    {
      __typename: "CustomFieldConfigurationLink",
      linkDefaultValue: {
        text: "Testing",
        url: "https://www.google.com",
      },
    },
    overrides,
  );
}

export function makeDropdownCustomField(
  overrides: Partial<CustomFieldConfigurationNodeCustomFieldConfigurationDropdownFragment> = {},
): CustomFieldConfigurationNodeCustomFieldConfigurationDropdownFragment {
  const base = makeInterfaceCustomField(
    CustomFieldConfigurationValueType.DROPDOWN,
  );
  return Object.assign(
    base,
    {
      dropdownDefaultValue: "Foo Bar",
      dropdownOptions: ["Foo Bar", "Baz"],
      __typename: "CustomFieldConfigurationDropdown",
    },
    overrides,
  );
}

export function makeAreaCustomField(
  overrides: Partial<CustomFieldConfigurationNodeCustomFieldConfigurationAreaFragment> = {},
): CustomFieldConfigurationNodeCustomFieldConfigurationAreaFragment {
  const base = makeInterfaceCustomField(CustomFieldConfigurationValueType.AREA);
  return Object.assign(
    base,
    {
      __typename: "CustomFieldConfigurationArea",
      areaDefaultValue: {
        length: Math.floor(Math.random() * 1000),
        width: Math.floor(Math.random() * 1000),
      },
      unit: ["M", "Ft"][Math.floor(Math.random() * 2)],
    },
    overrides,
  );
}

export function makeTrueFalseCustomField(
  overrides: Partial<CustomFieldConfigurationNodeCustomFieldConfigurationTrueFalseFragment> = {},
): CustomFieldConfigurationNodeCustomFieldConfigurationTrueFalseFragment {
  const base = makeInterfaceCustomField(
    CustomFieldConfigurationValueType.TRUE_FALSE,
  );
  return Object.assign(
    base,
    {
      __typename: "CustomFieldConfigurationTrueFalse",
      booleanDefaultValue: [true, false][Math.floor(Math.random() * 2)],
    },
    overrides,
  );
}

export function makeNumberCustomField(
  overrides: Partial<CustomFieldConfigurationNodeCustomFieldConfigurationNumericFragment> = {},
): CustomFieldConfigurationNodeCustomFieldConfigurationNumericFragment {
  const base = makeInterfaceCustomField(
    CustomFieldConfigurationValueType.NUMERIC,
  );
  return Object.assign(
    base,
    {
      __typename: "CustomFieldConfigurationNumeric",
      numericDefaultValue: Math.floor(Math.random() * 100),
      unit: ["M", "Lbs", "$"][Math.floor(Math.random() * 3)],
    },
    overrides,
  );
}

type CustomFieldConfigurationWithNullableTransferredFrom = Omit<
  CustomFieldConfigurationNodeCustomFieldConfigurationTextFragment,
  "transferedFrom"
> & {
  transferedFrom:
    | {
        __typename?:
          | "CustomFieldConfigurationArea"
          | "CustomFieldConfigurationDropdown"
          | "CustomFieldConfigurationText"
          | "CustomFieldConfigurationNumeric"
          | "CustomFieldConfigurationTrueFalse"
          | "CustomFieldConfigurationLink";
        id: string;
      }
    | undefined
    | null;
};

export function makeTextCustomField(
  overrides: Partial<CustomFieldConfigurationWithNullableTransferredFrom> = {},
): CustomFieldConfigurationNodeCustomFieldConfigurationTextFragment {
  const base = makeInterfaceCustomField(CustomFieldConfigurationValueType.TEXT);
  return Object.assign(
    base,
    {
      __typename: "CustomFieldConfigurationText",
      textDefaultValue: `${Math.floor(Math.random() * 100000)}`,
    },
    overrides,
  );
}

function makeInterfaceCustomField(
  valueType: CustomFieldConfigurationValueType,
): CustomFieldConfigurationInterface {
  const appliesTo = randomEnum(CustomFieldAppliesTo) as CustomFieldAppliesTo;
  return {
    appliesTo: appliesTo,
    createdAt: new Date().toISOString(),
    id: `${Math.floor(Math.random() * 100000)}`,
    name: ["Widget", "Thingger", "Sales", "Size", "Are Doggos Great?"][
      Math.floor(Math.random() * 5)
    ],
    readOnly: [true, false][Math.floor(Math.random() * 2)],
    sortOrder: 0,
    archived: false,
    transferable: [true, false][Math.floor(Math.random() * 2)],
    updatedAt: new Date().toISOString(),
    valueType: valueType,
    valueCount: {
      clients: 10,
      invoices: 12,
      jobs: 9,
      properties: 3,
      quotes: 6,
      users: 7,
    },
  };
}

// @ts-ignore
function randomEnum(anEnum) {
  const possibleValues = Object.values(anEnum);
  return possibleValues[Math.floor(Math.random() * possibleValues.length)];
}
