import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reEngagePastClientsTitle: {
    id: "campaigns.landingPage.reEngagePastClientsTitle",
    defaultMessage: "Re-engage past clients",
    description: "Title of the Re-engage past clients template",
  },
  reEngagePastClientsDescription: {
    id: "campaigns.landingPage.reEngagePastClientsDescription",
    defaultMessage:
      "Win back customers who haven't booked a job with you in a while.",
    description: "Description of the Re-engage past clients template",
  },
  reEngagePastClientsImgAlt: {
    id: "campaigns.landingPage.reEngagePastClientsImgAlt",
    defaultMessage: "Re-engage past clients",
    description: "Alt message of the Re-engage past clients template",
  },
  upSellCustomersTitle: {
    id: "campaigns.landingPage.upSellCustomersTitle",
    defaultMessage: "Upsell existing clients",
    description: "Title of the Upsell Customers template",
  },
  upSellCustomersDescription: {
    id: "campaigns.landingPage.upSellCustomersDescription",
    defaultMessage: "Recommend new services to clients with upcoming visits.",
    description: "Description of the Upsell Customers template",
  },
  upSellCustomersImgAlt: {
    id: "campaigns.landingPage.upSellCustomersImgAlt",
    defaultMessage: "Upsell your customers",
    description: "Alt message of the Upsell customers template",
  },
  startFromScratchTitle: {
    id: "campaigns.landingPage.startFromScratchTitle",
    defaultMessage: "Start from Scratch",
    description: "Title of the Start from Scratch template",
  },
  startFromScratchDescription: {
    id: "campaigns.landingPage.startFromScratchDescription",
    defaultMessage:
      "Already have an idea for a campaign in mind? Get creative and build your own email.",
    description: "Description of the Start from Scratch template",
  },
  startFromScratchImgAlt: {
    id: "campaigns.landingPage.startFromScratchImgAlt",
    defaultMessage: "Start from Scratch",
    description: "Alt message of the Start from Scratch template",
  },
  automatedReEngagePastClientsTitle: {
    id: "campaigns.landingPage.automatedReEngagePastClientsTitle",
    defaultMessage: "Re-engage past clients",
    description: "Title of the Re-engage past clients template",
  },
  automatedReEngagePastClientsDescription: {
    id: "campaigns.landingPage.automatedReEngagePastClientsDescription",
    defaultMessage:
      "Automatically sends an email to clients who haven't booked a job with you in a while",
    description: "Description of the Automated Re-engage past clients template",
  },
  automatedReEngagePastClientsImgAlt: {
    id: "campaigns.landingPage.reEngagePastClientsImgAlt",
    defaultMessage: "Re-engage past clients",
    description: "Alt message of the Re-engage past clients template",
  },
  automatedCloseOnPendingQuotesTitle: {
    id: "campaigns.landingPage.automatedCloseOnPendingQuotesTitle",
    defaultMessage: "Close on pending quotes",
    description: "Title of the Automated Close on Pending Quotes template",
  },
  automatedCloseOnPendingQuotesDescription: {
    id: "campaigns.landingPage.automatedCloseOnPendingQuotesDescription",
    defaultMessage:
      "Automatically sends a final follow-up to clients with pending quotes to move forward and close the deal",
    description:
      "Description of the Automated Close on Pending Quotes template",
  },
  automatedCloseOnPendingQuotesImgAlt: {
    id: "campaigns.landingPage.automatedCloseOnPendingQuotesImgAlt",
    defaultMessage: "Close on Quotes",
    description:
      "Alt message of the Automated Close on Pending Quotes template",
  },
  automatedWinBackLostLeadsTitle: {
    id: "campaigns.landingPage.automatedWinBackLostLeadsTitle",
    defaultMessage: "Win back lost leads",
    description: "Title of the Automated Win back lost leads template",
  },
  automatedWinBackLostLeadsDescription: {
    id: "campaigns.landingPage.automatedFollowUpOnQuotesDescription",
    defaultMessage:
      "Automatically recapture leads with archived quotes that were previously considered lost",
    description: "Description of the Automated Win back lost leads template",
  },
  automatedWinBackLostLeadsImgAlt: {
    id: "campaigns.landingPage.automatedWinBackLostLeadsImgAlt",
    defaultMessage: "Win back clients",
    description: "Alt message of the Automated Win back lost leads template",
  },
  automatedWinBackLostLeadsTooltipIconAlt: {
    id: "campaigns.landingPage.automatedWinBackLostLeadsTooltipIconAlt",
    defaultMessage: "Learn more",
    description:
      "Alt message of the Automated Win back lost leads tooltip icon",
  },
  referralTitle: {
    id: "campaigns.landingPage.referralTitle",
    defaultMessage: "Share your referral program",
    description: "Title of the Referral template",
  },
  referralDescription: {
    id: "campaigns.landingPage.referralDescription",
    defaultMessage:
      "Encourage clients to refer a friend by sharing your referral program details.",
    description: "Description of the Referral template",
  },
  referralImgAlt: {
    id: "campaigns.landingPage.referralImgAlt",
    defaultMessage: "Share your referral program",
    description: "Alt message of the Referral template",
  },
});
