import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Template } from "~/utilities/API/graphql";
import { messages as bottomBarMessages } from "jobber/campaigns/components/Bottombar/messages";
import { messages as errorBannerMessages } from "jobber/campaigns/components/ErrorBanner/messages";
import { messages } from "./messages";

// Selectors

export function getPageTitle(title: string) {
  return screen.getByText(title);
}

export function getPageSubtitle(subtitle: string) {
  return screen.queryByText(subtitle);
}

export function getCardTitle(title: string) {
  return screen.queryByText(title);
}

export function getCardDescription(description: string) {
  return screen.queryByText(description);
}

export function glimmerOnLoadingState() {
  return screen.getAllByTestId("ATL-GlimmerText");
}

export function getUpsellCustomersRadioOption() {
  return screen.getByDisplayValue(Template.UPSELL);
}

export function getReEngagePastClientsRadioOption() {
  return screen.getByDisplayValue(Template.RE_ENGAGE);
}

export function getStartFromScratchRadioOption() {
  return screen.getByDisplayValue(Template.START_FROM_SCRATCH);
}

export function getAutomatedReEngagePastClientsRadioOption() {
  return screen.getByDisplayValue(Template.AUTOMATED_RE_ENGAGE);
}

export function getContinueToRecipientsButton() {
  return screen.getByText(
    bottomBarMessages.continueToRecipients.defaultMessage,
  );
}

export function getTemplateChangeConfirmationMessage() {
  return screen.queryByText(
    messages.templateChangeConfirmationMessage.defaultMessage,
  );
}

export function getTemplateChangeConfirmationButton() {
  return screen.getByText(
    messages.templateChangeConfirmationButtonLabel.defaultMessage,
  );
}

export function getErrorBanner() {
  return screen.getByText(errorBannerMessages.genericError.defaultMessage);
}

// Solo Actions

export function iClickTheUpsellCustomersCard() {
  userEvent.click(getUpsellCustomersRadioOption());
}

export function iClickTheReEngagePastClientsCard() {
  userEvent.click(getReEngagePastClientsRadioOption());
}

export function iClickTheStartFromScratchCard() {
  userEvent.click(getStartFromScratchRadioOption());
}

export function iClickTheAutomatedReEngagePastClientsCard() {
  userEvent.click(getAutomatedReEngagePastClientsRadioOption());
}

export function iClickTheContinueToRecipientsButton() {
  userEvent.click(getContinueToRecipientsButton());
}

export function iClickTheTemplateChangeConfirmationButton() {
  userEvent.click(getTemplateChangeConfirmationButton());
}
