import { AvailableEditors } from "jobber/campaigns/views/CampaignsContentPage/types";

export const mockedRefs = {
  [AvailableEditors.subject]: {
    current: {
      focus: jest.fn(),
      blur: jest.fn(),
      insert: jest.fn(),
      scrollIntoView: jest.fn(),
    },
  },
  [AvailableEditors.header]: {
    current: {
      focus: jest.fn(),
      blur: jest.fn(),
      insert: jest.fn(),
      scrollIntoView: jest.fn(),
    },
  },
  [AvailableEditors.body]: {
    current: {
      focus: jest.fn(),
      blur: jest.fn(),
      insert: jest.fn(),
      setValue: jest.fn(),
    },
  },
  [AvailableEditors.buttonText]: {
    current: {
      focus: jest.fn(),
      blur: jest.fn(),
      insert: jest.fn(),
      scrollIntoView: jest.fn(),
    },
  },
  [AvailableEditors.linkValue]: {
    current: {
      focus: jest.fn(),
      blur: jest.fn(),
      insert: jest.fn(),
      scrollIntoView: jest.fn(),
    },
  },
};
