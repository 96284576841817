import React, { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./ConversationList.module.css";
import { ConversationListItem, messageType } from "./ConversationListItem";
import { Loading } from "./Loading";

interface ConversationListProps {
  readonly items: ConversationListItem[];
  readonly isLoadingMessage: boolean;
}

export function ConversationList({
  items,
  isLoadingMessage,
}: ConversationListProps) {
  const lastRequestRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lastRequestRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [items.length]);

  return (
    <AnimatePresence>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <motion.li
            key={item.id}
            initial={
              index === items.length - 1 || index === 0
                ? { opacity: 1 }
                : { opacity: 0 }
            }
            whileInView={{ opacity: 1 }}
          >
            {isLastRequest(item, index) ? (
              <div ref={lastRequestRef}>
                {renderConversationListItem(item, index)}
              </div>
            ) : (
              renderConversationListItem(item, index)
            )}
          </motion.li>
        ))}
        {isLoadingMessage && (
          <li>
            <Loading />
          </li>
        )}
      </ul>
    </AnimatePresence>
  );

  function renderConversationListItem(
    item: ConversationListItem,
    index: number,
  ) {
    const showFeedbackOptions = index !== 0 && item.actions.length === 0;

    return (
      <ConversationListItem
        item={item}
        showFeedbackOptions={showFeedbackOptions}
      />
    );
  }

  function isLastRequest(item: ConversationListItem, index: number) {
    return (
      item.type === messageType.REQUEST &&
      (index === items.length - 1 || index === items.length - 2)
    );
  }
}
