import React from "react";
import { Glimmer } from "@jobber/components/Glimmer";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import styles from "./GlimmerLoadingPlanCard.module.css";

export function GlimmerLoadingPlanCard() {
  const numberOfFeatures = 5;
  return (
    <div className={styles.loadingCard} data-testid="glimmer-loading-plan-card">
      <Card>
        <Content>
          <div className={styles.glimmerContent}>
            <div className={styles.glimmerHeading}>
              <Glimmer shape="rectangle" size="larger" />
            </div>
            <div className={styles.glimmerDescription}>
              <Glimmer shape="rectangle" size="large" />
              <Glimmer shape="rectangle" size="large" />
            </div>
            <div className={styles.glimmerPrice}>
              <Glimmer shape="rectangle" size="auto" />
            </div>
            <div className={styles.glimmerFeatures}>
              {Array.from({ length: numberOfFeatures }).map((_, index) => (
                <Glimmer key={index} shape="rectangle" size="large" />
              ))}
            </div>
            <div className={styles.glimmerUserSection}>
              <div className={styles.glimmerUsersDescription}>
                <Glimmer shape="rectangle" size="large" />
              </div>
              <Glimmer shape="rectangle" size="large" />
            </div>
            <div className={styles.glimmerButton}>
              <Glimmer shape="rectangle" size="largest" />
            </div>
          </div>
        </Content>
      </Card>
    </div>
  );
}
