import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonText: {
    id: "discover.onlineBooking.buttonText",
    defaultMessage: "Set up online booking",
    description: "Primary call to action",
  },
  description: {
    id: "discover.onlineBooking.description",
    defaultMessage:
      "Choose the bookable services, team members, and maximum drive times that suit your business.",
    description: "Marketing description for online booking",
  },
  title: {
    id: "discover.onlineBooking.title",
    defaultMessage:
      "Win work while you sleep - Online Booking now allows bookable assessments",
    description: "Feature title for online booking",
  },
  imageAlt: {
    id: "discover.onlineBooking.imageAlt",
    defaultMessage: "Discover online booking",
    description: "Alt text for online booking image",
  },
});
