interface PlansData {
  [key: string]: {
    previousPlan: string;
    description: string;
    topFeatures: Array<string>;
  };
}
export const plansData: PlansData = {
  lite: {
    previousPlan: "",
    description:
      "Ideal for those just getting started—quote, invoice and get paid faster.",
    topFeatures: [
      "Manage unlimited clients",
      "24/7 client self-serve hub",
      "Send quotes and invoices",
      "Online payments",
    ],
  },
  core: {
    previousPlan: "Lite",
    description:
      "Organize your operations—from quoting to scheduling to invoicing—all in one place.",
    topFeatures: [
      "Schedule and manage jobs",
      "Access over 20 built-in reports",
      "1-on-1 product coaching",
      "Online request form",
    ],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "core plus": {
    previousPlan: "",
    description:
      "Organize your operations in one place, including accounting and custom tracking.",
    topFeatures: [
      "24/7 client self-serve hub",
      "Schedule jobs",
      "Send quotes and invoices",
      "Access over 20 built-in reports",
    ],
  },
  connect: {
    previousPlan: "Core",
    description:
      "Build efficient processes that better connect your team and impress your customers.",
    topFeatures: [
      "Online booking",
      "Automated reminders",
      "Automatic payments",
      "QuickBooks Online sync",
    ],
  },
  grow: {
    previousPlan: "Connect",
    description:
      "Scale your business with powerful sales and marketing automation tools.",
    topFeatures: [
      "Quote add-ons and images",
      "Automated quote follow-ups",
      "Tag and manage leads",
      "Job costing",
    ],
  },
  loyalty: {
    previousPlan: "",
    description:
      "Organize your operations-from quoting to scheduling to invoicing-all in one place.",
    topFeatures: [
      "Schedule and manage jobs",
      "Send quotes and invoices",
      "Online payments",
      "Access over 20 built-in reports",
    ],
  },
  unlimited: {
    previousPlan: "",
    description:
      "Organize your operations-from quoting to scheduling to invoicing-all in one place.",
    topFeatures: [
      "Quote add-ons and images",
      "Automated quote follow-ups",
      "Tag and manage leads",
      "Job costing",
    ],
  },
};
