/**
 * Campaigns Add On Prompt Page Object Model
 *
 * Selectors:
 *
 * - campaignsAddOnPromptTitle
 * - campaignsAddOnPromptDescription
 * - campaignsAddOnPromptCtaLabel
 * - campaignsAddOnPromptImg
 * - campaignsAddOnPromptImgAlt
 * - campaignsAddOnPromptNewLabel
 *
 * Simulators:
 * - iClickLearnMoreButton
 */

import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage: t } = createIntl();

export function campaignsAddOnPromptTitle() {
  return screen.getByRole("heading", {
    level: 2,
    name: t(messages.campaignsAddOnPromptTitle),
  });
}

export function campaignsAddOnPromptDescription() {
  return screen.getAllByText(t(messages.campaignsAddOnPromptDescription))[0];
}

export function campaignsAddOnPromptCtaLabel() {
  return screen.getByRole("button", {
    name: t(messages.campaignsAddOnPromptCtaLabel),
  });
}

export function campaignsAddOnPromptImg() {
  return screen.getByRole("img", {
    name: t(messages.campaignsAddOnPromptimgAlt),
  }) as HTMLElement;
}

export function campaignsAddOnPromptImgAlt() {
  return screen.getByAltText(t(messages.campaignsAddOnPromptimgAlt));
}

export function campaignsAddOnPromptNewLabel() {
  return screen.getByText("New");
}

/***************** Simulators ********************/

export function iClickLearnMoreButton() {
  screen
    .getByRole("button", {
      name: t(messages.campaignsAddOnPromptCtaLabel),
    })
    .click();
}
