import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import React, { useEffect, useState } from "react";
import { Text } from "@jobber/components/Text";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { RestartTrialMutation } from "~/utilities/API/graphql";
import { FlexibleQuotingImage } from "./images";
import styles from "./ReverseTrialRestartTrialModal.module.css";
import { RESTART_TRIAL } from "./RestartTrial.graphql";

interface ReverseTrialRestartTrialModalProps {
  shouldShowModal: boolean;
  closeModal(): void;
}

export function ReverseTrialRestartTrialModal({
  shouldShowModal,
  closeModal,
}: ReverseTrialRestartTrialModalProps) {
  useEffect(() => {
    if (shouldShowModal) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: "reverse_trial_restart_trial_modal",
      });
    }
  }, [shouldShowModal]);

  const trackSuccessfulTrialRestart = () =>
    Amplitude.TRACK_EVENT("Interacted with Experiment", {
      experiment: "reverse_trial",
      source: "reverse_trial_restart_trial_modal",
      interaction: "Trial Restarted",
    });

  const [restartTrial, { loading: restartTrialLoading }] =
    useMutation<RestartTrialMutation>(RESTART_TRIAL);

  const [bannerError, setBannerError] = useState("");

  const onRestartTrial = async () => {
    try {
      setBannerError("");
      const result = await restartTrial();
      const userErrors = result?.data?.restartTrial?.userErrors ?? [];

      if (userErrors.length > 0) {
        setBannerError(userErrors[0].message);
      } else {
        trackSuccessfulTrialRestart();
        window.location.assign("/home");
      }
    } catch (error) {
      setBannerError("Something went wrong. Please try again.");
    }
  };

  return (
    <Modal
      title="Restart Free 14-Day Trial"
      open={shouldShowModal}
      onRequestClose={closeModal}
      primaryAction={{
        label: "Restart Trial",
        onClick: onRestartTrial,
        loading: restartTrialLoading,
      }}
      tertiaryAction={{
        label: "Cancel",
        onClick: closeModal,
        variation: "subtle",
        type: "primary",
      }}
    >
      <Content>
        {bannerError && (
          <Banner type="error" dismissible={false}>
            {bannerError}
          </Banner>
        )}
        <div className={styles.imageWrapper}>
          <img
            src={FlexibleQuotingImage.src}
            alt={FlexibleQuotingImage.alt}
            className={styles.image}
          />
        </div>
        <Text size="large">
          With Grow, you&apos;ll have the tools to stand out from the
          competition. You can boost your revenue with optional line items, show
          off your services with photos, and automatically follow-up on quotes
          and invoices.
        </Text>
      </Content>
    </Modal>
  );
}
