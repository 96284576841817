import { defineMessages } from "react-intl";

export const messages = defineMessages({
  discountLabel: {
    id: "billing.addonPurchasePreview.discountLabel",
    defaultMessage: "Discount",
    description: "Discount label",
  },
  discountLabelWithPercent: {
    id: "billing.addonPurchasePreview.discountLabelWithPercent",
    defaultMessage: "Discount ({discountAmount}% off)",
    description: "Discount label with percent",
  },
  billedToday: {
    id: "billing.addonPurchasePreview.billedToday",
    defaultMessage: "Billed today",
    description: "Purchase preview header",
  },
  futureBilledHeader: {
    id: "billing.addonPurchasePreview.futureBilledHeader",
    defaultMessage: "Billed {startBillingOnDate}",
    description: "Purchase preview header for a future billed subscription",
  },
  previewDetails: {
    id: "billing.addonPurchasePreview.previewDetails",
    defaultMessage:
      "You will receive an immediate prorated charge of <b>{proratedTotal}*</b>. Your subscription will renew at <b>{monthlyCost}*</b> on {nextBillingDate}. Charges automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description: "Purchase preview details",
  },
  discountedPreviewDetails: {
    id: "billing.addonPurchasePreview.discountedPreviewDetails",
    defaultMessage:
      "You will receive an immediate prorated charge of <b>{proratedTotal}*</b>. Your subscription will renew at <b>{monthlyDiscountedCost}*</b> on {nextBillingDate}. After the discount period ends on {discountEndDate}, your subscription will renew at <b>{monthlyCost}*</b>. Charges automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description: "Purchase preview details",
  },
  multipleDiscountedPreviewDetails: {
    id: "billing.addonPurchasePreview.multipleDiscountedPreviewDetails",
    defaultMessage:
      "You will receive an immediate prorated charge of <b>{proratedTotal}*</b>. Charges automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description: "Purchase preview details with multiple discounts",
  },
  futureBilledPreviewDetails: {
    id: "billing.addonPurchasePreview.futureBilledPreviewDetails",
    defaultMessage:
      "You will be billed <b>{monthlyCost}*</b> when your subscription starts on {startBillingOnDate}. Charges automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description:
      "Purchase preview details for a future billed discounted subscription",
  },
  futureBilledDiscountedPreviewDetails: {
    id: "billing.addonPurchasePreview.futureBilledDiscountedPreviewDetails",
    defaultMessage:
      "You will be billed <b>{monthlyDiscountedCost}*</b> when your subscription starts on {startBillingOnDate}. After the discount period ends, your subscription will renew at <b>{monthlyCost}</b>. Charges automatically renew unless cancelled before the end of the current period through Account and Billing.",
    description:
      "Purchase preview details for a future billed discounted subscription",
  },
  taxDisclaimer: {
    id: "billing.addonPurchasePreview.taxDisclaimer",
    defaultMessage: "*Plus taxes where applicable",
    description: "Purchase preview tax disclaimer",
  },
});
