/* eslint-disable @typescript-eslint/naming-convention */
import type { Result, Section } from "./HelpDrawer.d";

type RecommendedArticles = Required<{
  [key in Section]: Result[];
}>;

export const RECOMMENDED_ARTICLES: RecommendedArticles = {
  calendar: [
    {
      title: "Schedule overview",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009233447-Calendar-Overview",
      snippet:
        "View your schedule in five different calendar views and learn more about tasks and events.",
    },
    {
      title: "Tasks",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009404788-Tasks",
      snippet:
        "Tasks are used to schedule tasks that will not be invoiced for, such as scheduling time to create a quote, or to run an errand.",
    },
    {
      title: "Events",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115010161028-Events",
      snippet:
        "Events are scheduled items that your entire team can see in their schedule.",
    },
    {
      title: "New Visits Tool - Create Multiple Visits at Once",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115012682348-New-Visits-Tool-Create-Multiple-Visits-at-Once",
      snippet:
        "You can create visits for multiple jobs at one time using the New Visits tool on the calendar.",
    },
  ],
  clients: [
    {
      title: "Client Basics",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009450867-Client-Basics",
      snippet:
        "Keep track of important client details whether you’re at the office, on the way to a job, or out in the field.",
    },
    {
      title: "Creating New Properties",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115010161128-Creating-New-Properties",
      snippet:
        "You can create a property a few different ways in Jobber: by creating a new client, through a client's page, or through the properties section.",
    },
    {
      title: "Import Your Clients",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360034980534-Import-Your-Clients",
      snippet:
        "Check out a birds eye view of all the work you have on the go - see quotes, jobs, invoices, timesheets, and expenses all on one page. Say goodbye to things falling through the cracks!",
    },
    {
      title: "Import Clients from QuickBooks Desktop",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360035482534-Import-Clients-from-QuickBooks-Desktop",
      snippet:
        "Check out a birds eye view of all the work you have on the go - see quotes, jobs, invoices, timesheets, and expenses all on one page. Say goodbye to things falling through the cracks!",
    },
  ],
  work: [
    {
      title: "Work Overview",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360033312414-Work-Overview",
      snippet:
        "Check out a birds eye view of all the work you have on the go - see quotes, jobs, invoices, timesheets, and expenses all on one page. Say goodbye to things falling through the cracks!",
    },
    {
      title: "Navigating Jobber",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360033836333-Navigating-Jobber",
      snippet:
        "You've done it! You've created a Jobber account, but now that you're here how do you navigate around?",
    },
    {
      title: "Activity Feed",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360037055533-Activity-Feed",
      snippet:
        "Keep a pulse on the everyday activities happening in your business and stay on top of who is doing what, when, and where.",
    },
  ],
  workRequests: [
    {
      title: "Requests & Online Booking",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009737048-Requests-Online-Booking",
      snippet:
        "Attract new customers when you let them book new work through your website and Facebook page - and receive a notification as soon as it happens.",
    },
    {
      title: "Add Online Booking to Your Website and Social Media Approvals",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360026249434-Add-Online-Booking-to-Your-Website-and-Social-Media",
      snippet:
        "The online booking form gives your client an option to select a date that works best for them — gathering this information upfront cuts down on back and forth. ",
    },
    {
      title: "Using Assessments to Schedule and Convert Booking Requests",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360005363854-Using-Assessments-to-Schedule-and-Convert-Booking-Requests",
      snippet:
        "Assessments allow you to block off time on your calendar to complete a booking request. Assessments can be helpful when you need to view a property in order to make a quote.",
    },
  ],
  quotes: [
    {
      title: "Quote Basics",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009378727-Quote-Basics",
      snippet:
        "Quotes allow you to send clients estimates for the price of your services. If a client would like to go forward on the quote, you can convert the quote to a job and schedule it.",
    },
    {
      title: "Quote Approvals",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115012715008-Quote-Approvals",
      snippet:
        "Using Quote Approval, you can seamlessly have your clients approve a quote through a digital signature, or request changes to the quote before approving.",
    },
    {
      title: "Deposits on Quotes",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009379007-Deposits-on-Quotes",
      snippet:
        "If you need to collect money upfront from your client, you can add required deposits to quotes. Clients will see that there is a deposit on the quote so they know how much to pay you.",
    },
    {
      title: "Converting a Quote to a Job",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009542728-Converting-a-Quote-to-a-Job",
      snippet:
        "Converting a quote to a job pulls over all the quoted line items onto the job so that you can get started with the work.",
    },
  ],
  workOrders: [
    {
      title: "Job Basics",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009379027-Job-Basics",
      snippet:
        "Create a job, choose a client, and assign it to your team with only a few clicks.",
    },
    {
      title: "Job Forms",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009740048-Job-Forms",
      snippet:
        "Build custom forms and checklists so your team delivers consistent service, and share them with your customers to show off what was done.",
    },
    {
      title: "Creating a Recurring Job",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009542848-Creating-a-Recurring-Job",
      snippet:
        "Recurring jobs are used for jobs that have multiple visits. Eg. You visit the client every Tuesday or where you invoice your client periodically Eg. Once per month or after each visit.",
    },
    {
      title: "Creating a One-Off Job",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009379047-Creating-a-One-Off-job",
      snippet: "You've done the work, now it's time to invoice and get paid!",
    },
  ],
  invoices: [
    {
      title: "Invoicing Basics",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009685047-Invoicing-Basics",
      snippet: "You've done the work, now it's time to invoice and get paid!",
    },
    {
      title: "How to Collect Payment on an Invoice",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360033907753-How-to-Collect-Payment-on-an-Invoice",
      snippet:
        "Get paid on average 10x faster than checks when your clients pay with debit or credit. Securely save cards on file for future payments with Jobber Payments.",
    },
    {
      title: "Invoice Reminders",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009517847-Invoice-Reminders",
      snippet:
        "Invoice reminders appear on the calendar to remind you of what you need to invoice and when.",
    },
    {
      title: "Batch Create Invoices",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009687088-Batch-Create-Invoices",
      snippet:
        "Batch invoice creation allows you to generate invoices for jobs with a few simple clicks.",
    },
  ],
  reports: [
    {
      title: "Reports Basics",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009784848-Reports",
      snippet:
        "With over 20 built-in reports, you’ll know exactly how your business is doing.",
    },
    {
      title: "Invoice and Payment Reports",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115015294767-Invoice-and-Payment-Reports",
      snippet:
        "Invoice and payment reports can be found from the Reports tab under the heading for Financial Reports.",
    },
    {
      title: "Client Communications Report",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115016068927-Client-Communications-Report",
      snippet:
        "The client communications report shows all email and text messages sent from your Jobber account to your clients.",
    },
    {
      title: "Client Balance Summary Report",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115015770007-Client-Balance-Summary-Report",
      snippet:
        "The client balance summary report shows which clients have owing balances on their account, and which have credits.",
    },
  ],
  timeSheets: [
    {
      title: "Timesheets",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009543028-Time-Sheets",
      snippet:
        "Your team can clock in and out of jobs and track expenses from anywhere, so you can prep payroll faster.",
    },
    {
      title: "Approving Timesheets",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009614247-Approving-Time-Sheets",
      snippet:
        "Approving timesheets confirms a user's timesheets. If you are syncing timesheets with QuickBooks, when you approve timesheets they are flagged to sync to QuickBooks.",
    },
    {
      title: "Timers in the Jobber App",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009560687-Timers-in-the-Jobber-App",
      snippet:
        "In the Jobber App, you can start and stop timers during your day. There are two types of timers — general timers and visit timers.",
    },
    {
      title: "Confirm Payroll",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115010492748-Confirm-Payroll",
      snippet: "Keep track of who you've paid through confirming payroll.",
    },
  ],
  marketplace: [
    {
      title: "Quickbooks Integration Faqs",
      html_url:
        "https://help.getjobber.com/hc/en-us/categories/115001334127-App-Marketplace",
      snippet:
        "A curated list of questions and answered all about setting up and syncing your quickbooks.",
    },
    {
      title: "Email Marketing & Ads - Powered by Mailchimp",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360022744493-Email-Marketing-Ads-Powered-by-Mailchimp",
      snippet:
        "Find more business with ready-to-run email marketing campaigns, postcards, and more.",
    },
    {
      title: "Live GPS Tracking - Powered by FleetSharp",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360037054873-Live-GPS-Tracking-Powered-by-FleetSharp",
      snippet:
        "Track your vehicles in real time and use their location to make scheduling decisions based on location.",
    },
    {
      title: "Task Automation - Powered by Zapier",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/360026513994-Task-Automation-Powered-by-Zapier",
      snippet:
        "Save time when you move info automatically between Jobber and 1,500+ web apps.",
    },
  ],
  accountAndBilling: [
    {
      title: "Account Ownership",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009736948-Account-Ownership",
      snippet:
        "The account owner is the individual that owns the rights to the Jobber account and all associated information.",
    },
    {
      title: "How to Add or Change Your Billing Information",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115010328667-How-to-Add-or-Change-Your-Billing-Information",
      snippet:
        "Do you have a new credit card? To add or change your billing information for your Jobber account, click the Gear Icon > Account and Billing.",
    },
    {
      title: "Referrals",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009616267-Referrals",
      snippet:
        "When you refer a friend to Jobber, they get a free month and VIP treatment—and you get a free month too!",
    },
  ],
  settings: [
    {
      title: "Settings",
      html_url:
        "https://help.getjobber.com/hc/en-us/categories/115001334107-Settings",
      snippet:
        "Manage your team as well as company settings, work settings, route settings, and other settings.",
    },
    {
      title: "Branding",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009735608-Branding",
      snippet:
        "Show off your company's logo and customize your PDFs to reflect your brand.",
    },
    {
      title: "Manage Team — How to Add, Manage, and Deactivate Team Members",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009568647-Manage-Team-How-to-Add-Manage-and-Deactivate-Team-Members",
      snippet:
        "To add a new user to your account, click the Gear Icon > Manage Team. On this page, click on the Add User button on the right.",
    },
    {
      title: "Products & Services List",
      html_url:
        "https://help.getjobber.com/hc/en-us/articles/115009735848-Products-Services-List",
      snippet:
        "This area of your Jobber account allows you to enter and save a list of the products and services your company offers.",
    },
  ],
  default: [
    {
      title: "Get Started",
      html_url:
        "https://help.getjobber.com/hc/en-us/categories/360000372333-Get-Started",
      snippet:
        "A hand-picked list of articles specifically focused at getting Jobber up and running for your company.",
    },
    {
      title: "Schedule",
      html_url:
        "https://help.getjobber.com/hc/en-us/categories/115001324208-Calendar",
      snippet:
        "View your schedule in five different calendar views and learn more about tasks and events.",
    },
    {
      title: "Work",
      html_url:
        "https://help.getjobber.com/hc/en-us/categories/115001328167-Work",
      snippet:
        "Check out help articles that cover each step of your workflow — requests, quotes, jobs, invoices, timesheets, and expenses.",
    },
    {
      title: "Clients",
      html_url:
        "https://help.getjobber.com/hc/en-us/categories/115001328147-Clients",
      snippet:
        "Manage your clients and the communications you send them including email templates, text messages, and reminders.",
    },
  ],
};
