/* eslint-disable max-statements */
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useRef, useState } from "react";
import { Divider } from "@jobber/components/Divider";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
  JobberPaymentsSettingsName,
} from "~/utilities/API/graphql";
import type {
  ClientHubSettingsQuery,
  MutationErrors,
} from "~/utilities/API/graphql";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { withSplitClient } from "utilities/split";
import { SettingsSwitch } from "./SettingsSwitch";
import { DefaultPaymentRadioGroup } from "./DefaultPaymentRadioGroup";
import { messages } from "./messages";
import styles from "./QuotePreferenceSettings.module.css";
import { useUpdateDefaultPaymentPreference } from "./useUpdateDefaultPaymentPreference";
import { useAchDefaultPaymentPreferenceSplit } from "./useAchDefaultPaymentPreferenceSplit";

const genericError = new Error("Could not update settings");

interface QuotePreferencesSettingsProps {
  quoteData?: ClientHubSettingsQuery;
  setMutationErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  payQuoteDeposits?: boolean;
  setPayQuoteDeposits: (_: boolean) => void;
  setToggleAchFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  updateDefaultPreference?: {
    preference: string;
    achEnabled: boolean;
  };
  setIsAchEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  isAchEnabled?: boolean;
}

function QuotePreferencesSettings({
  quoteData,
  setMutationErrorMessage,
  payQuoteDeposits,
  setPayQuoteDeposits,
  setToggleAchFlag,
  updateDefaultPreference,
  setIsAchEnabled,
  isAchEnabled,
}: QuotePreferencesSettingsProps) {
  const { formatMessage } = useIntl();
  const isFirstRender = useRef(true);
  const [preference, setDefaultPreference] = useState<
    JobberPaymentsDefaultPaymentPreference | string
  >(
    quoteData?.mandatoryCardOnFile.enabled || !quoteData?.achPayments.enabled
      ? JobberPaymentsDefaultPaymentPreference.CREDIT_CARD
      : quoteData?.jobberPaymentsSettings.quotePaymentOptionPreference ??
          JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
  );

  const [
    isMandatoryCardOnFileSettingEnabled,
    setIsMandatoryCardOnFileSettingEnabled,
  ] = useState<boolean>(quoteData?.mandatoryCardOnFile.enabled ?? false);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (updateDefaultPreference) {
      if (!isMandatoryCardOnFileSettingEnabled) {
        setDefaultPreference(updateDefaultPreference.preference);
      }
      setIsAchEnabled?.(updateDefaultPreference.achEnabled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDefaultPreference]);
  const { permissions } = useJobberPayments();
  const { handleUpdateSettings } = useUpdateDefaultPaymentPreference();
  const { hasAchDefaultPaymentPreferences } =
    useAchDefaultPaymentPreferenceSplit();

  return (
    <Card title={formatMessage(messages.quotePreferenceSettingsCardTitle)}>
      <Content spacing="large">
        <SettingsSwitch
          settingsName={JobberPaymentsSettingsName.PAY_QUOTE_DEPOSITS}
          title={formatMessage(messages.quotePreferenceSettingsTitle)}
          defaultEnabled={quoteData?.payQuoteDeposits.enabled ?? false}
          onError={handleMutationError}
          onSuccessCallback={updatePayQuoteDeposits}
        />
        <Divider />
        <div className={styles.lastSwitch}>
          <SettingsSwitch
            settingsName={JobberPaymentsSettingsName.MANDATORY_CARD_ON_FILE}
            title={formatMessage(messages.quoteMandatoryCardOnFileTitle)}
            defaultEnabled={quoteData?.mandatoryCardOnFile.enabled ?? false}
            onError={handleMutationError}
            onSuccessCallbackIsEnabled={updateRadioOptions}
          />
        </div>
        {permissions.canToggleAchPayments &&
          hasAchDefaultPaymentPreferences && (
            <>
              <Divider />
              <DefaultPaymentRadioGroup
                handleOnChange={handleRadioOnChange}
                selectedOption={preference}
                objectType={InvoiceOrQuote.QUOTE}
                onError={handleMutationError}
                isMandatoryCardOnFileEnabled={
                  isMandatoryCardOnFileSettingEnabled
                }
                isAchEnabled={isAchEnabled}
                setIsAchEnabled={setIsAchEnabled}
                setToggleAchFlag={setToggleAchFlag}
              />
              {isMandatoryCardOnFileSettingEnabled && (
                <Text size="small">
                  {formatMessage(messages.mandatoryCardOnHelpText)}
                </Text>
              )}
            </>
          )}
      </Content>
    </Card>
  );

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }

  function updatePayQuoteDeposits() {
    setPayQuoteDeposits(!payQuoteDeposits);
  }

  function handleRadioOnChange(value: JobberPaymentsDefaultPaymentPreference) {
    setDefaultPreference(value);
    showToast({
      message: formatMessage(messages.paymentOptionChangeToastMessage),
    });
  }

  async function updateRadioOptions(enabled: boolean) {
    setIsMandatoryCardOnFileSettingEnabled(enabled);
    if (enabled) {
      await updateRadioOption(
        JobberPaymentsDefaultPaymentPreference.CREDIT_CARD,
      );
    } else if (isAchEnabled) {
      await updateRadioOption(
        JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
      );
    }
  }

  async function updateRadioOption(
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    if (isAchEnabled) {
      try {
        const { data } = await handleUpdateSettings({
          objectType: InvoiceOrQuote.QUOTE,
          preference: value,
        });
        const errors = data?.jobberPaymentsUpdateDefaultPaymentPreference
          ?.userErrors as MutationErrors[];

        if (!data) {
          handleMutationError(genericError);
        }

        if (
          errors.length > 0 &&
          data?.jobberPaymentsUpdateDefaultPaymentPreference.success === false
        ) {
          handleMutationError(new Error(errors[0].message) || genericError);
        } else {
          handleRadioOnChange(value);
        }
      } catch (error) {
        handleMutationError(error as Error);
      }
    }
  }
}

const SplitWrappedComponent = withSplitClient(QuotePreferencesSettings);
export { SplitWrappedComponent as QuotePreferencesSettings };
