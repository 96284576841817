import { generatePath, useHistory } from "react-router-dom";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_PATH,
} from "jobber/campaigns/constants";
import { landingPath } from "jobber/campaigns/utils";
import { Template } from "~/utilities/API/graphql";

interface UseClientSegmentPageNavigationArgs {
  template?: Template;
  campaignId?: string;
}
export function useTemplatePageNavigation({
  template = Template.RE_ENGAGE,
  campaignId,
}: UseClientSegmentPageNavigationArgs) {
  const history = useHistory();

  function onBack() {
    history.push(landingPath(template));
  }
  function onCampaignNotFound() {
    history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
  }

  function onNext() {
    let segmentPath;
    if (campaignId) {
      segmentPath = generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
        campaignId: campaignId,
      });
    } else {
      segmentPath = generatePath(CAMPAIGNS_SEGMENT_PATH, {
        templateType: template,
      });
    }
    history.push(segmentPath);
  }
  return { onBack, onNext, onCampaignNotFound };
}
