import { type ApolloError, useMutation } from "@apollo/client";
import { useCallback } from "react";
import type { GraphQLError } from "graphql";
import type {
  CampaignSendMutation,
  CampaignSendMutationVariables,
  MutationErrors,
} from "~/utilities/API/graphql";
import { Intercom } from "utilities/chat";
import { CAMPAIGN_SEND } from "jobber/campaigns/views/CampaignsContentPage/CampaignsContentPage.graphql";

export interface UserErrors {
  userErrors: MutationErrors[];
  mutationErrors: readonly GraphQLError[] | undefined;
}

interface useSendCampaignArgs {
  loading: boolean;

  campaignSend(input?: CampaignSendMutationVariables): Promise<UserErrors>;

  data?: CampaignSendMutation | null;
  error?: ApolloError;
}

export function useSendCampaign(): useSendCampaignArgs {
  const [sendEmail, { loading, error, data }] = useMutation<
    CampaignSendMutation,
    CampaignSendMutationVariables
  >(CAMPAIGN_SEND);

  const campaignSend = useCallback(
    async (input: CampaignSendMutationVariables) => {
      if (!input.campaignId) {
        return {
          userErrors: [{ message: "Campaign ID is required", path: [] }],
          mutationErrors: [],
        };
      }
      const mutationResult = await sendEmail({
        variables: input,
      })
        .then(result => {
          Intercom.EXECUTE("trackEvent", "marketing_campaign_sent");
          return result;
        })
        .catch(e => {
          return {
            data: undefined,
            errors: e,
          };
        });

      const userErrors = mutationResult.data?.campaignSend.userErrors ?? [];
      return {
        userErrors: userErrors,
        mutationErrors: mutationResult.errors,
      };
    },
    [sendEmail],
  );

  return {
    campaignSend,
    data,
    loading,
    error,
  };
}
