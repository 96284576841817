import { defineMessages } from "react-intl";

export const initialStateMessages = defineMessages({
  verifyFragment: {
    id: "ukKycRegistration.initial.verifyFragment",
    defaultMessage: "Verify",
    description:
      "Part of the larger sentence in the approved state for ''Verify your business in 7 minutes''",
  },
  inSevenMinsFragment: {
    id: "ukKycRegistration.initial.inSevenMinsFragment",
    defaultMessage: "your business in 7 mins",
    description:
      "Part of the larger sentence in the approved state for ''Verify your business in 7 minutes''",
  },
  initialRegistrationBody: {
    id: "ukKycRegistration.initial.initialRegistrationBody",
    defaultMessage:
      "Complete verification to use a dedicated phone number for client communication. The approval process may take up to three days.",
    description: "Message body to complete verification",
  },
  initialButtonText: {
    id: "ukKycRegistration.initial.initialButtonText",
    defaultMessage: "Start",
    description: "CTA text for the initial widget state",
  },
});
