import { Routes, type SetupWizardStep } from "./types";

export const ANALYTICS_EVENTS = {
  viewedSetupWizard: {
    eventName: "Viewed Setup Wizard",
    validSteps: {
      business: "Business",
      profile: "Profile",
      topPriority: "Top Priority",
      heardAboutUs: "Heard About Us",
      promotion: "Promotion",
    },
  },
  interactedWithPromoMessage: {
    eventName: "Interacted with Promo Message",
    interaction: {
      clickedModalCta: "Clicked modal CTA",
      dismissedModal: "Dismissed Modal",
    },
  },
};

export const getAmplitudeStepByRoute = (
  wantedStep: SetupWizardStep,
): string => {
  switch (wantedStep.route) {
    case Routes.profile:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.profile;
    case Routes.business:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.business;
    case Routes.topPriority:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.topPriority;
    case Routes.hdyhau:
      return ANALYTICS_EVENTS.viewedSetupWizard.validSteps.heardAboutUs;
    default:
      return "";
  }
};
