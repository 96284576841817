import { defineMessages } from "react-intl";

export const messages = defineMessages({
  campaignsAddOnPromptTitle: {
    id: "upgradePage.campaignsAddOnPrompt.campaignsAddOnPromptTitle",
    defaultMessage: "Boost your revenue with campaigns",
    description: "Campaigns add-on title copy",
  },
  campaignsAddOnPromptDescription: {
    id: "upgradePage.campaignsAddOnPrompt.campaignsAddOnPromptDescription",
    defaultMessage:
      "Send effective email campaigns that keep you at the top of your customers’ minds and drive more revenue for your business.",
    description: "Campaigns add-on description copy",
  },
  campaignsAddOnPromptCtaLabel: {
    id: "upgradePage.campaignsAddOnPrompt.campaignsAddOnPromptCtaLabel",
    defaultMessage: "Learn More",
    description: "Campaigns add-on CTA label copy",
  },
  campaignsAddOnPromptimgAlt: {
    id: "upgradePage.campaignsAddOnPrompt.campaignsAddOnPromptimgAlt",
    defaultMessage: "Campaigns add-on image",
    description: "Campaigns add-on image alt text",
  },
});
