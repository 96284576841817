import React from "react";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { messages } from "./messages";

interface ActiveAddonDetailsProps {
  subscriptionAddon: SubscriptionAddon;
}

export function ActiveAddonDetails(props: ActiveAddonDetailsProps) {
  const { subscriptionAddon } = props;
  const { currency, currentBillingCycle } = subscriptionAddon;

  const { formatMessage } = useIntl();

  if (!currentBillingCycle) {
    return null;
  }

  const {
    monthlyCost,
    billingCycleName,
    renewalDate,
    futureStartBillingOnDate,
  } = currentBillingCycle;

  return (
    <>
      <Text>{`${monthlyCost} ${currency} ${billingCycleName}`}</Text>
      <Text>
        {futureStartBillingOnDate
          ? formatMessage(messages.futureBilled, {
              futureStartBillingOnDate: futureStartBillingOnDate,
            })
          : formatMessage(messages.renewal, {
              renewalDate: renewalDate,
            })}
      </Text>
    </>
  );
}
