import React, { useReducer } from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { isAfter, parseISO } from "date-fns";
import { useQuery } from "@apollo/client";
import {
  type JobCostExpenseFragment,
  type JobCostTimeSheetEntryFragment,
  type JobDetailsFragment,
  JobTypeTypeEnum,
  type JobViewProfit,
  type User,
} from "~/utilities/API/graphql";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { lineItemsBulkEditReducer } from "~/jobber/lineItems/hooks";
import type { LineItem } from "~/jobber/lineItems/types";
import { LocationTimersCTA } from "jobber/locationTimersCTA";
import {
  GROW_TRIAL_ELIGIBILITY,
  type GrowTrialEligibilityData,
} from "jobber/connectToGrowTrial/ConnectToGrowTrial.graphql";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { ExpensesTable } from "./components/ExpensesTable";
import { LabourTable } from "./components/LabourTable";
import { LineItemsTable } from "./components/LineItemsTable";
import styles from "./JobCost.module.css";
import { JobCostHeader } from "./components/JobCostHeader";
import { useReconcileLineItems } from "./hooks";
import { JobCostDiscoveryCard } from "./components/JobCostDiscoveryCard";

export const LOCATION_TIMERS_LAUNCH_DATE = parseISO("2023-10-05T00:00:00");

export interface JobCostProps {
  timesheets?: JobCostTimeSheetEntryFragment[];
  timesheetsTotalCost: number;
  timesheetsTotalDuration: number;
  expenses?: JobCostExpenseFragment[];
  expensesTotal: number;
  lineItems: LineItem[];
  lineItemsTotalCost: number;
  lineItemsTotalPrice: number;
  jobCosting: JobViewProfit;
  job?: JobDetailsFragment;
  user: User;
  hasMoreLineItems: boolean;
  hasMoreExpenses: boolean;
  hasMoreTimesheets: boolean;
  fetchMoreLineItems?: () => Promise<void>;
  fetchMoreTimesheets?: () => Promise<void>;
  fetchMoreExpenses?: () => Promise<void>;
}

export function JobCost({
  timesheets,
  timesheetsTotalCost,
  timesheetsTotalDuration,
  expenses,
  expensesTotal,
  lineItems,
  lineItemsTotalCost,
  lineItemsTotalPrice,
  jobCosting,
  job,
  user,
  hasMoreLineItems,
  hasMoreExpenses,
  hasMoreTimesheets,
  fetchMoreLineItems,
  fetchMoreTimesheets,
  fetchMoreExpenses,
}: JobCostProps) {
  const [state, dispatch] = useReducer(lineItemsBulkEditReducer, { lineItems });

  const activeLineItems = state.lineItems.filter(item => !item.isDeleted);

  useReconcileLineItems(state, lineItems, dispatch, job);

  const { showExpenses, showTimeSheets, showJobCosting } = usePermissions();

  const GrowTrialEligibilityQuery = useQuery<GrowTrialEligibilityData>(
    GROW_TRIAL_ELIGIBILITY,
  );

  const eligibleForGrowTrial =
    GrowTrialEligibilityQuery.data?.accountFeatureTrialEligibility
      .eligibleForConnectToGrowTrial;

  const showLocationTimerCTA =
    job?.createdAt !== undefined &&
    isAfter(parseISO(job.createdAt), LOCATION_TIMERS_LAUNCH_DATE);

  return (
    <div className={contentClassName(showJobCosting, job?.jobType)}>
      <Card
        header={
          showJobCosting && job?.id ? (
            <JobCostHeader
              jobCosting={jobCosting}
              jobId={job.id}
              isRecurring={job.jobType === JobTypeTypeEnum.RECURRING}
              billingType={job.billingType}
              lineItems={activeLineItems}
            />
          ) : undefined
        }
      >
        <div className={styles.jobCostTables}>
          <Content>
            {!showJobCosting && showTimeSheets && (
              <JobCostDiscoveryCard eligibleForTrial={eligibleForGrowTrial} />
            )}
            {job && (
              <LineItemsTable
                lineItems={activeLineItems}
                lineItemsTotalCost={lineItemsTotalCost}
                lineItemsTotalPrice={lineItemsTotalPrice}
                hasMoreLineItems={hasMoreLineItems}
                fetchMoreLineItems={fetchMoreLineItems}
                dispatch={dispatch}
                jobId={job.id}
              />
            )}
            {!eligibleForGrowTrial && showLocationTimerCTA && (
              <LocationTimersCTA />
            )}
            {showTimeSheets && job && (
              <LabourTable
                timeSheetEntries={timesheets}
                timeSheetEntriesTotalCost={timesheetsTotalCost}
                timeSheetEntriesTotalDuration={timesheetsTotalDuration}
                hasMoreTimesheets={hasMoreTimesheets}
                fetchMoreTimesheets={fetchMoreTimesheets}
                job={job}
                user={user}
              />
            )}
            {showExpenses && job && (
              <ExpensesTable
                expensesEntries={expenses}
                expensesTotal={expensesTotal}
                job={job}
                hasMoreExpenses={hasMoreExpenses}
                fetchMoreExpenses={fetchMoreExpenses}
                currentUser={user}
              />
            )}
          </Content>
        </div>
      </Card>
    </div>
  );
}

const contentClassName = (showJobCosting: boolean, jobType?: JobTypeTypeEnum) =>
  [
    styles.jobCostContent,
    showJobCosting && jobType === JobTypeTypeEnum.ONE_OFF
      ? styles.greyContent
      : styles.whiteContent,
  ].join(" ");

const usePermissions = () => {
  const { can } = useAuthorization();
  const account = useAccount();

  const showExpenses =
    account.features.expenses.enabled && can("view", "Expenses");
  const showTimeSheets =
    account.features.timeSheets.enabled && can("view", "Timesheets");
  const showJobCosting =
    account.features.jobCosting.enabled && can("view", "JobCosts");

  return { showExpenses, showTimeSheets, showJobCosting };
};
