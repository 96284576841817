import { SplitNames, useSplit } from "utilities/split";

export function useAchDefaultPaymentPreferenceSplit(): {
  hasAchDefaultPaymentPreferences: boolean;
} {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.AchDefaultPaymentPreferences],
  });

  return {
    hasAchDefaultPaymentPreferences: getTreatmentValue(
      SplitNames.AchDefaultPaymentPreferences,
    ),
  };
}
