import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import styles from "./OnlinePaymentsToggle.module.css";
import { PaymentMethodToggle } from "./PaymentMethodToggle";
import { messages } from "./messages";

export interface OnlinePaymentsSettingsProps {
  showAchPayments: boolean;
  isMandatoryCardOnFileSettingEnabled: boolean;
  objectName: "quote" | "invoice";
  allowOnlinePayment: boolean;
  disableCreditCardPayments: boolean;
  disableAchPayments: boolean;
}

export const OnlinePaymentsSettings: React.FC<OnlinePaymentsSettingsProps> = ({
  showAchPayments,
  isMandatoryCardOnFileSettingEnabled,
  objectName,
  allowOnlinePayment,
  disableAchPayments,
  disableCreditCardPayments,
}) => {
  const { formatMessage } = useIntl();
  const [creditCardEnabled, setCreditCardEnabled] = useState<boolean>(
    !disableCreditCardPayments,
  );
  const [achEnabled, setAchEnabled] = useState<boolean>(!disableAchPayments);

  const paymentName = {
    quote: "Quote Deposits",
    invoice: "Invoice Payments",
  };

  return (
    <div>
      <Content>
        <Emphasis variation={"bold"}>
          {formatMessage(messages.onlinePaymentSettingsTitle, {
            object: objectName === "quote" ? "Deposit" : "Invoice",
          })}
        </Emphasis>
        <Content>
          <div className={styles.secondaryText}>
            <Text size="small" variation="subdued">
              {formatMessage(messages.onlinePaymentSettingsSecondaryText, {
                object: objectName,
                link: (
                  <a
                    href="/jobber_payments/setup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(messages.onlinePaymentSettingsLinkText)}
                  </a>
                ),
              })}
            </Text>
          </div>

          <PaymentMethodToggle
            labelText={"Card Payments"}
            paymentMethodType={"credit_card"}
            objectName={objectName}
            enabled={creditCardEnabled}
            onChange={setCreditCardEnabled}
          />
          {showAchPayments && (
            <>
              <PaymentMethodToggle
                labelText={"ACH bank payments"}
                paymentMethodType={"ach"}
                disableToggle={isMandatoryCardOnFileSettingEnabled}
                objectName={objectName}
                disabledToggleHelpText={
                  "Bank payments are not available when you require a card saved on file"
                }
                enabled={achEnabled}
                onChange={setAchEnabled}
              />

              {!allowOnlinePayment && (
                <Text size="small" variation="subdued">
                  {formatMessage(messages.onlinePaymentSettingsNotEnabledText, {
                    object: paymentName[objectName],
                    link: (
                      <a
                        href={"/jobber_payments/setup"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage(
                          messages.onlinePaymentSettingsNotEnabledLinkText,
                        )}
                      </a>
                    ),
                  })}
                </Text>
              )}
            </>
          )}
        </Content>
      </Content>
    </div>
  );
};
