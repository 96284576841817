import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";

const baseCustomFieldAttributes = {
  appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
  createdAt: new Date().toISOString(),
  id: btoa("99"),
  name: "Some Custom Field",
  readOnly: false,
  sortOrder: 0,
  transferable: false,
  updatedAt: new Date().toISOString(),
  valueType: CustomFieldConfigurationValueType.TEXT,
  valueCount: {
    clients: 0,
    invoices: 0,
    jobs: 0,
    properties: 0,
    quotes: 0,
    users: 0,
  },
};

// Text Custom Fields
export const customFieldClientText: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Text Client Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
  valueType: CustomFieldConfigurationValueType.TEXT,
  textDefaultValue: "Default text value for clients",
  id: btoa("1"),
  archived: false,
};
export const customFieldQuoteText: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Text Quote Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_QUOTES,
  valueType: CustomFieldConfigurationValueType.TEXT,
  textDefaultValue: "Default text value for quotes",
  id: btoa("2"),
  archived: false,
};
export const customFieldInvoiceText: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Text Invoice Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_INVOICES,
  valueType: CustomFieldConfigurationValueType.TEXT,
  textDefaultValue: "Default text value for invoices",
  id: btoa("3"),
  archived: false,
};
export const customFieldJobText: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Text Job Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_JOBS,
  valueType: CustomFieldConfigurationValueType.TEXT,
  textDefaultValue: "Default text value for jobs",
  id: btoa("4"),
  archived: false,
};
export const customFieldPropertyText: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Text Property Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_PROPERTIES,
  valueType: CustomFieldConfigurationValueType.TEXT,
  textDefaultValue: "Default text value for properties",
  id: btoa("5"),
  archived: false,
};
export const customFieldUserText: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Text User Custom Field",
  appliesTo: CustomFieldAppliesTo.TEAM,
  valueType: CustomFieldConfigurationValueType.TEXT,
  textDefaultValue: "Default text value for users",
  id: btoa("6"),
  archived: false,
};

// Numeric Custom Fields
export const customFieldClientNumeric: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Numeric Client Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  numericDefaultValue: 12,
  unit: "C",
  id: btoa("7"),
  archived: false,
};
export const customFieldQuoteNumeric: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Numeric Quote Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_QUOTES,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  numericDefaultValue: 543,
  unit: "Q",
  id: btoa("8"),
  archived: false,
};
export const customFieldInvoiceNumeric: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Numeric Invoice Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_INVOICES,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  numericDefaultValue: 74,
  unit: "I",
  id: btoa("9"),
  archived: false,
};
export const customFieldJobNumeric: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Numeric Job Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_JOBS,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  numericDefaultValue: 1001,
  unit: "J",
  id: btoa("10"),
  archived: false,
};
export const customFieldPropertyNumeric: CustomFieldConfigurationNodeFragment =
  {
    ...baseCustomFieldAttributes,
    name: "Numeric Property Custom Field",
    appliesTo: CustomFieldAppliesTo.ALL_PROPERTIES,
    valueType: CustomFieldConfigurationValueType.NUMERIC,
    numericDefaultValue: 19,
    unit: "P",
    id: btoa("11"),
    archived: false,
  };
export const customFieldUserNumeric: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Numeric User Custom Field",
  appliesTo: CustomFieldAppliesTo.TEAM,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  numericDefaultValue: 5296,
  unit: "U",
  id: btoa("12"),
  archived: false,
};

// Area Custom Fields
export const customFieldClientArea: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Area Client Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
  valueType: CustomFieldConfigurationValueType.AREA,
  areaDefaultValue: {
    length: 12,
    width: 21,
  },
  unit: "AC",
  id: btoa("13"),
  archived: false,
};
export const customFieldQuoteArea: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Area Quote Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_QUOTES,
  valueType: CustomFieldConfigurationValueType.AREA,
  areaDefaultValue: {
    length: 35,
    width: 17,
  },
  unit: "AQ",
  id: btoa("14"),
  archived: false,
};
export const customFieldInvoiceArea: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Area Invoice Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_INVOICES,
  valueType: CustomFieldConfigurationValueType.AREA,
  areaDefaultValue: {
    length: 67,
    width: 4,
  },
  unit: "AI",
  id: btoa("15"),
  archived: false,
};
export const customFieldJobArea: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Area Job Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_JOBS,
  valueType: CustomFieldConfigurationValueType.AREA,
  areaDefaultValue: {
    length: 67,
    width: 4,
  },
  unit: "AJ",
  id: btoa("16"),
  archived: false,
};
export const customFieldPropertyArea: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Area Property Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_PROPERTIES,
  valueType: CustomFieldConfigurationValueType.AREA,
  areaDefaultValue: {
    length: 8,
    width: 47,
  },
  unit: "AP",
  id: btoa("17"),
  archived: false,
};
export const customFieldUserArea: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Area User Custom Field",
  appliesTo: CustomFieldAppliesTo.TEAM,
  valueType: CustomFieldConfigurationValueType.AREA,
  areaDefaultValue: {
    length: 11,
    width: 53,
  },
  unit: "AU",
  id: btoa("18"),
  archived: false,
};

// TrueFalse Custom Fields
export const customFieldClientTrueFalse: CustomFieldConfigurationNodeFragment =
  {
    ...baseCustomFieldAttributes,
    name: "TrueFalse Client Custom Field",
    appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
    valueType: CustomFieldConfigurationValueType.NUMERIC,
    booleanDefaultValue: true,
    id: btoa("19"),
    archived: false,
  };
export const customFieldQuoteTrueFalse: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "TrueFalse Quote Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_QUOTES,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  booleanDefaultValue: false,
  id: btoa("20"),
  archived: false,
};
export const customFieldInvoiceTrueFalse: CustomFieldConfigurationNodeFragment =
  {
    ...baseCustomFieldAttributes,
    name: "TrueFalse Invoice Custom Field",
    appliesTo: CustomFieldAppliesTo.ALL_INVOICES,
    valueType: CustomFieldConfigurationValueType.NUMERIC,
    booleanDefaultValue: true,
    id: btoa("21"),
    archived: false,
  };
export const customFieldJobTrueFalse: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "TrueFalse Job Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_JOBS,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  booleanDefaultValue: false,
  id: btoa("22"),
  archived: false,
};
export const customFieldPropertyTrueFalse: CustomFieldConfigurationNodeFragment =
  {
    ...baseCustomFieldAttributes,
    name: "TrueFalse Property Custom Field",
    appliesTo: CustomFieldAppliesTo.ALL_PROPERTIES,
    valueType: CustomFieldConfigurationValueType.NUMERIC,
    booleanDefaultValue: true,
    id: btoa("23"),
    archived: false,
  };
export const customFieldUserTrueFalse: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "TrueFalse User Custom Field",
  appliesTo: CustomFieldAppliesTo.TEAM,
  valueType: CustomFieldConfigurationValueType.NUMERIC,
  booleanDefaultValue: false,
  id: btoa("24"),
  archived: false,
};

// Dropdown Custom Fields
export const customFieldClientDropdown: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Dropdown Client Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
  valueType: CustomFieldConfigurationValueType.DROPDOWN,
  dropdownDefaultValue: "apple",
  dropdownOptions: ["apple", "banana", "grape"],
  id: btoa("25"),
  archived: false,
};
export const customFieldQuoteDropdown: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Dropdown Quote Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_QUOTES,
  valueType: CustomFieldConfigurationValueType.DROPDOWN,
  dropdownDefaultValue: "square",
  dropdownOptions: ["square", "circle", "triangle"],
  id: btoa("26"),
  archived: false,
};
export const customFieldInvoiceDropdown: CustomFieldConfigurationNodeFragment =
  {
    ...baseCustomFieldAttributes,
    name: "Dropdown Invoice Custom Field",
    appliesTo: CustomFieldAppliesTo.ALL_INVOICES,
    valueType: CustomFieldConfigurationValueType.DROPDOWN,
    dropdownDefaultValue: "fire",
    dropdownOptions: ["fire", "water", "earth"],
    id: btoa("27"),
    archived: false,
  };
export const customFieldJobDropdown: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Dropdown Job Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_JOBS,
  valueType: CustomFieldConfigurationValueType.DROPDOWN,
  dropdownDefaultValue: "red",
  dropdownOptions: ["red", "blue", "yellow", "purple"],
  id: btoa("28"),
  archived: false,
};
export const customFieldPropertyDropdown: CustomFieldConfigurationNodeFragment =
  {
    ...baseCustomFieldAttributes,
    name: "Dropdown Property Custom Field",
    appliesTo: CustomFieldAppliesTo.ALL_PROPERTIES,
    valueType: CustomFieldConfigurationValueType.DROPDOWN,
    dropdownDefaultValue: "dog",
    dropdownOptions: ["dog", "cat"],
    id: btoa("29"),
    archived: false,
  };
export const customFieldUserDropdown: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Dropdown User Custom Field",
  appliesTo: CustomFieldAppliesTo.TEAM,
  valueType: CustomFieldConfigurationValueType.DROPDOWN,
  dropdownDefaultValue: "dublin",
  dropdownOptions: ["dublin", "venice", "london", "paris", "berlin"],
  id: btoa("30"),
  archived: false,
};

// Link Custom Fields
export const customFieldClientLink: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Link Client Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_CLIENTS,
  valueType: CustomFieldConfigurationValueType.LINK,
  linkDefaultValue: {
    url: "https://www.ferrari.com",
    text: "Cool Red Car",
  },
  id: btoa("31"),
  archived: false,
};
export const customFieldQuoteLink: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Link Quote Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_QUOTES,
  valueType: CustomFieldConfigurationValueType.LINK,
  linkDefaultValue: {
    url: "https://www.bouncy-castles.com",
    text: "Bouncing Fun",
  },
  id: btoa("32"),
  archived: false,
};
export const customFieldInvoiceLink: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Link Invoice Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_INVOICES,
  valueType: CustomFieldConfigurationValueType.LINK,
  linkDefaultValue: {
    url: "https://www.windows-and-walls.com",
    text: "Walls & Windows",
  },
  id: btoa("33"),
  archived: false,
};
export const customFieldJobLink: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Link Job Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_JOBS,
  valueType: CustomFieldConfigurationValueType.LINK,
  linkDefaultValue: {
    url: "https://www.skeletons.com",
    text: "Scary Stuff",
  },
  id: btoa("34"),
  archived: false,
};
export const customFieldPropertyLink: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Link Property Custom Field",
  appliesTo: CustomFieldAppliesTo.ALL_PROPERTIES,
  valueType: CustomFieldConfigurationValueType.LINK,
  linkDefaultValue: {
    url: "https://www.cats-and-kittens.com",
    text: "Cute Fluffies",
  },
  id: btoa("35"),
  archived: false,
};
export const customFieldUserLink: CustomFieldConfigurationNodeFragment = {
  ...baseCustomFieldAttributes,
  name: "Link User Custom Field",
  appliesTo: CustomFieldAppliesTo.TEAM,
  valueType: CustomFieldConfigurationValueType.LINK,
  linkDefaultValue: {
    url: "https://www.forests.com",
    text: "Nature and trees",
  },
  id: btoa("36"),
  archived: false,
};

export const allMockCustomFieldData = [
  customFieldClientText,
  customFieldQuoteText,
  customFieldInvoiceText,
  customFieldJobText,
  customFieldPropertyText,
  customFieldUserText,
  customFieldClientNumeric,
  customFieldQuoteNumeric,
  customFieldInvoiceNumeric,
  customFieldJobNumeric,
  customFieldPropertyNumeric,
  customFieldUserNumeric,
  customFieldClientArea,
  customFieldQuoteArea,
  customFieldInvoiceArea,
  customFieldJobArea,
  customFieldPropertyArea,
  customFieldUserArea,
  customFieldClientTrueFalse,
  customFieldQuoteTrueFalse,
  customFieldInvoiceTrueFalse,
  customFieldJobTrueFalse,
  customFieldPropertyTrueFalse,
  customFieldUserTrueFalse,
  customFieldClientDropdown,
  customFieldQuoteDropdown,
  customFieldInvoiceDropdown,
  customFieldJobDropdown,
  customFieldPropertyDropdown,
  customFieldUserDropdown,
  customFieldClientLink,
  customFieldQuoteLink,
  customFieldInvoiceLink,
  customFieldJobLink,
  customFieldPropertyLink,
  customFieldUserLink,
];
