import React from "react";
import { Text } from "@jobber/components/Text";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import type { LineItem } from "~/jobber/lineItems/types";
import { AddNewLineItemButton } from "legacy/jobber/workOrders/components/JobCost/components/LineItemsTable/components/AddNewLineItemButton";
import { IntlProvider } from "@translations/IntlProvider";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import styles from "./LineItemTotals.module.css";

export interface LineItemTotalsProps {
  showCosting: boolean;
  dispatch: (action: LineItemsAction) => void;
  lineItems: LineItem[];
  canViewPricing: boolean;
  footerSection?: React.ReactNode;
}

export function LineItemTotals({
  dispatch,
  lineItems,
  showCosting,
  canViewPricing,
  footerSection,
}: LineItemTotalsProps) {
  const { currencySymbol } = useAccount();

  const lineItemsTotalCost = lineItems
    .reduce((total, lineItem) => total + (lineItem.totalCost || 0), 0.0)
    .toFixed(2);

  const lineItemsTotalPrice = lineItems
    .reduce((total, lineItem) => total + (lineItem.totalPrice || 0), 0.0)
    .toFixed(2);

  return (
    <div className={styles.container}>
      <div className={styles.lineItemsFooter}>
        <div className={styles.lineItemsTotalButton}>
          <IntlProvider>
            <AddNewLineItemButton
              onPress={() =>
                dispatch({
                  type: LineItemsBulkEditActionTypes.AddLineItem,
                })
              }
            />
          </IntlProvider>
        </div>
        <div className={styles.lineItemsTotals}>
          {showCosting && (
            <div className={styles.lineItemsTotalCost}>
              <Text>Total cost</Text>
              <Text>
                {currencySymbol}
                {lineItemsTotalCost}
              </Text>
            </div>
          )}
          {canViewPricing && (
            <div className={styles.lineItemsTotalPrice}>
              <Text>
                <b>Total price</b>
              </Text>
              <Text>
                <b>
                  {currencySymbol}
                  {lineItemsTotalPrice}
                </b>
              </Text>
            </div>
          )}
          {footerSection}
        </div>
      </div>
    </div>
  );
}
