import { defineMessages } from "react-intl";

export const messages = defineMessages({
  faq1Question: {
    id: "upgradePage.faq.faq1Question",
    defaultMessage: "Are there any commitments or contracts?",
    description: "commitments or contracts question text",
  },
  faq1Description1: {
    id: "upgradePage.faq.faq1Description1",
    defaultMessage: "There are no commitments or contracts.",
    description: "commitments or contracts question primary description",
  },
  faq1Description2: {
    id: "upgradePage.faq.faq1Description2",
    defaultMessage:
      "If you sign up for Jobber's monthly subscription, you can cancel your subscription at any time without penalty. If you sign up for an annual subscription, you are billed for the entire year at the start of your subscription.",
    description: "commitments or contracts question secondary description",
  },
  faq1Description3: {
    id: "upgradePage.faq.faq1Description3",
    defaultMessage:
      "There will be no refunds or credits for partial months of service, upgrade or downgrade refunds, or refunds for months unused.",
    description: "commitments or contracts question tertiary description",
  },
  faq1Description4: {
    id: "upgradePage.faq.faq1Description4",
    defaultMessage:
      "Review our [Terms of Service]({linkUrl}) for more information.",
    description: "commitments or contracts question quaternary description",
  },
  faq2Question: {
    id: "upgradePage.faq.faq2Question",
    defaultMessage: "How do I know which plan is best for my business?",
    description: "plan question text",
  },
  faq2Description: {
    id: "upgradePage.faq.faq2Description",
    defaultMessage:
      "Our plans are built to offer you the most commonly required feature needs based on your company size. We've created a helpful table above to help you compare plan features.",
    description: "plan question description",
  },
  faq3Question: {
    id: "upgradePage.faq.faq3Question",
    defaultMessage: "Can I change plans at any time?",
    description: "change plan question text",
  },
  faq3Description: {
    id: "upgradePage.faq.faq3Description",
    defaultMessage:
      "You can upgrade your plan at any time. You can downgrade your plan at the end of your current billing cycle—month-to-month or annual. Click here to [downgrade your plan]({linkUrl}).",
    description: "change plan question description",
  },
  faq4Question: {
    id: "upgradePage.faq.faq4Question",
    defaultMessage: "Do you have a sales team?",
    description: "sales team question text",
  },
  faq4Description1: {
    id: "upgradePage.faq.faq4Description1",
    defaultMessage:
      "You can reach out to our team at any time to book a demo to learn more about each plan. Call us at ",
    description: "sales team question primary description",
  },
  faq4Description2: {
    id: "upgradePage.faq.faq4Description2",
    defaultMessage: " or ",
    description: "sales team question secondary description",
  },
  faq4Description3: {
    id: "upgradePage.faq.faq4Description3",
    defaultMessage: ".",
    description: "sales team question secondary description",
  },
  faq4PhoneNumber: {
    id: "upgradePage.faq.faq4PhoneNumber",
    defaultMessage: "1-833-460-0177",
    description: "sales team phone number",
  },
  faq4chatLink: {
    id: "upgradePage.faq.faq4chatLink",
    defaultMessage: "chat with us live",
    description: "sales team chat link text",
  },
  faq5Question: {
    id: "upgradePage.faq.faq5Question",
    defaultMessage: "What if I have more questions?",
    description: "more questions question text",
  },
  faq5Description: {
    id: "upgradePage.faq.faq5Description",
    defaultMessage:
      "You can try searching for what you need in our [Help Center]({helpCenterLink}). If you don't find what you're looking for, you can [get help]({getHelpLink}) here.",
    description: "more questions question primary description",
  },
});
