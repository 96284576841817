import React, { type ReactElement, useMemo } from "react";
import { Text } from "@jobber/components/Text";
import { useQuery } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { Gallery } from "@jobber/components/Gallery";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Body, Cell, Row } from "components/Table";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import { LineItemInput } from "~/jobber/lineItems/components/LineItemsBulkEdit/components";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import type {
  JobCreateLineItemsInput,
  JobEditLineItemsInput,
  ProductsQuery,
} from "~/utilities/API/graphql";
import { formatCurrency } from "utilities/formatCurrency";
import type { LineItem } from "~/jobber/lineItems/types";
import { messages } from "jobber/workOrders/components/JobCost/components/LineItemsTable/messages";
import styles from "./LineItemsTableBody.module.css";
import { OverrideDates } from "./OverrideDates";
import {
  columnsRequiringJobCostingFeature,
  columnsRequiringPricingPermission,
} from "../constants";
import {
  messages as queryMessages,
  useJobCreateLineItemsMutation,
  useJobDeleteLineItemsMutation,
  useJobEditLineItemMutation,
} from "../../../hooks";

interface LineItemsTableBodyProps {
  lineItems: LineItem[];
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  children?: JSX.Element;
}

export function LineItemsTableBody({
  lineItems,
  dispatch,
  jobId,
  children,
}: LineItemsTableBodyProps) {
  const { formatMessage } = useIntl();
  const { features, currencySymbol } = useAccount();
  const canViewCostColumn =
    features.jobCosting.enabled && features?.quoteMargins.enabled;
  const { can } = useAuthorization();
  const canViewPricing = can("view", "Pricing");

  return (
    <Body>
      <>
        {lineItems.map((lineItem, index) =>
          renderLineItem({
            index,
            lineItem,
            canViewPricing,
            canViewCostColumn,
            dispatch,
            jobId,
            currencySymbol,
            formatMessage,
          }),
        )}
        {children}
      </>
    </Body>
  );
}

function renderLineItem({
  index,
  lineItem,
  canViewPricing,
  canViewCostColumn,
  dispatch,
  jobId,
  currencySymbol,
  formatMessage,
}: {
  index: number;
  lineItem: LineItem;
  canViewPricing: boolean;
  canViewCostColumn: boolean;
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  currencySymbol: string;
  formatMessage: (
    messageDescriptor: MessageDescriptor,
    values?: unknown,
  ) => string;
}) {
  const cellsToRender = [
    <Cell variation="bold" key="description" size="large">
      <div className={styles.lineItemNameImageContainer}>
        <div className={styles.lineItemNameAndDescriptionContainer}>
          <h5 className={styles.heading}>{lineItem.name}</h5>
          {lineItem.overrideDates && lineItem.overrideDates.length > 0 && (
            <div className={styles.variationDates}>
              <OverrideDates overrideDates={lineItem.overrideDates} />
            </div>
          )}
          {lineItem.description && (
            <div className={styles.description}>
              <Text>{renderLineItemDescription(lineItem.description)}</Text>
            </div>
          )}

          {!lineItem.taxable && (
            <div className={styles.taxableContainer} data-testid="taxableLabel">
              <Text size="small">
                {formatMessage(messages.lineItemNonTaxableLabel)}
              </Text>
            </div>
          )}
        </div>
        {lineItem.image && (
          <div onClick={e => e.stopPropagation()} aria-hidden>
            <Gallery
              files={[
                {
                  name: lineItem.image?.fileName || "",
                  type: lineItem.image?.contentType || "",
                  size: lineItem.image?.fileSize || 0,
                  src: lineItem.image?.url || "",
                  progress: 1,
                  key: `${lineItem.image?.fileName}_${lineItem.image?.fileSize}`,
                },
              ]}
            />
          </div>
        )}
      </div>
    </Cell>,
    <Cell key="quantity" className={styles.quantityCell}>
      <div className={styles.alignCenter}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemQuantity)}
        </span>
        <span data-testid="quantity">{lineItem.quantity}</span>
      </div>
    </Cell>,
    <Cell key="cost">
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemCost)}
        </span>
        <span className={styles.numeric} data-testid="cost">
          {formatCurrency(lineItem.unitCost || 0, currencySymbol)}
        </span>
      </div>
    </Cell>,
    <Cell key="price">
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemPrice)}
        </span>
        <span className={styles.numeric} data-testid="price">
          {formatCurrency(lineItem.unitPrice || 0, currencySymbol)}
        </span>
      </div>
    </Cell>,
    <Cell key="total">
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemTotal)}
        </span>
        <span className={styles.numeric} data-testid="total">
          {formatCurrency(lineItem.totalPrice || 0, currencySymbol)}
        </span>
      </div>
    </Cell>,
  ];

  const permissionFilteredCellsToRender = cellsToRender
    .filter(
      cell =>
        !columnsRequiringPricingPermission.includes(
          `${cell.key?.toString()}`,
        ) || canViewPricing,
    )
    .filter(
      cell =>
        !columnsRequiringJobCostingFeature.includes(
          `${cell.key?.toString()}`,
        ) || canViewCostColumn,
    );

  return (
    <LineItemRow
      index={index}
      key={lineItem.reactKey}
      lineItem={lineItem}
      cellsToRender={permissionFilteredCellsToRender}
      dispatch={dispatch}
      jobId={jobId}
      canViewCostColumn={canViewCostColumn}
    />
  );
}

interface LineItemRowProps {
  index: number;
  lineItem: LineItem;
  cellsToRender: ReactElement[];
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  canViewCostColumn: boolean;
}

function LineItemRow({
  index,
  lineItem,
  cellsToRender,
  dispatch,
  jobId,
  canViewCostColumn,
}: LineItemRowProps) {
  const { can } = useAuthorization();
  const canViewJobCosts = can("view", "JobCosts");
  const canEditJobs = can("edit", "Jobs");
  const canViewPricing = can("view", "Pricing");

  const { data } = useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const { handleDeleteLineItem } = useJobDeleteLineItemsMutation({
    jobId,
    lineItemId: lineItem.id,
    canViewJobCosts,
    canViewPricing,
    onSuccess: () => evictLineItem(dispatch, lineItem),
  });

  const { handleOnDeleteLineItem } = deleteLineItem(
    dispatch,
    handleDeleteLineItem,
  );

  const { handleJobEditLineItem } = useJobEditLineItemMutation(
    jobId,
    canViewJobCosts,
    canViewPricing,
    () => updateLineItem(dispatch, lineItem),
  );

  const { handleJobCreateLineItems } = useJobCreateLineItemsMutation({
    jobId,
    canViewJobCosts,
    canViewPricing,
    onSuccess: () => saveNewLineItem(dispatch, lineItem),
  });

  const { handleOnSaveLineItem } = saveLineItem(
    dispatch,
    handleJobCreateLineItems,
    handleJobEditLineItem,
  );

  const handleRowClick = useMemo(() => {
    if (!canEditJobs || !canViewPricing) {
      return undefined;
    }

    return () => {
      if (!lineItem.editMode) {
        dispatch({
          type: LineItemsBulkEditActionTypes.SetEditMode,
          reactKey: lineItem.reactKey,
          value: true,
        });
      }
    };
  }, [
    canEditJobs,
    canViewPricing,
    lineItem.reactKey,
    lineItem.editMode,
    dispatch,
  ]);

  return (
    <Row
      key={lineItem.reactKey}
      testId={"lineItemRow"}
      onClick={handleRowClick}
    >
      {lineItem.editMode ? (
        <td colSpan={100} data-testid="lineItemEditing">
          <LineItemInput
            index={index}
            shouldAutoFocus
            lineItem={lineItem}
            dispatch={dispatch}
            showCosting={canViewCostColumn}
            initialOptions={data?.products.nodes ?? []}
            onDelete={handleOnDeleteLineItem}
            shouldRenderSaveButton={true}
            onSaveLineItem={handleOnSaveLineItem}
          />
        </td>
      ) : (
        cellsToRender
      )}
    </Row>
  );
}

function evictLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  lineItem: LineItem,
) {
  dispatch({
    type: LineItemsBulkEditActionTypes.DeleteLineItem,
    reactKey: lineItem.reactKey,
  });
}

function updateLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  lineItem: LineItem,
) {
  dispatch({
    type: LineItemsBulkEditActionTypes.FullUpdate,
    reactKey: lineItem.reactKey,
    value: { ...lineItem, editMode: false },
  });
}

function saveNewLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  lineItem: LineItem,
) {
  dispatch({
    type: LineItemsBulkEditActionTypes.FullUpdate,
    reactKey: lineItem.reactKey,
    value: { ...lineItem, editMode: false },
  });
}

function deleteLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  handleDeleteLineItem: () => Promise<void>,
): { handleOnDeleteLineItem: (lineItem: LineItem) => Promise<void> } {
  async function handleOnDeleteLineItem(lineItem: LineItem) {
    if (!lineItem.id) {
      evictLineItem(dispatch, lineItem);
      showToast({
        message: queryMessages.lineItemDeleted,
        variation: "success",
      });
    } else {
      await handleDeleteLineItem();
    }
  }

  return { handleOnDeleteLineItem };
}

function saveLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  handleJobCreateLineItems: (
    input: JobCreateLineItemsInput,
  ) => Promise<string[] | undefined> | Promise<void>,
  handleJobEditLineItem: (input: JobEditLineItemsInput) => Promise<void>,
): {
  handleOnSaveLineItem: (newLineItemState: LineItem) => Promise<void>;
} {
  async function handleOnSaveLineItem(newLineItemState: LineItem) {
    const lineItemDetails = {
      description: newLineItemState.description,
      name: newLineItemState.name,
      quantity: newLineItemState.quantity,
      unitCost: newLineItemState.unitCost,
      unitPrice: newLineItemState.unitPrice,
    };

    if (!newLineItemState.id) {
      const globalLineItemIds = await handleJobCreateLineItems({
        lineItems: [
          {
            ...lineItemDetails,
            saveToProductsAndServices: false,
            taxable: newLineItemState.taxable,
            unitCost: newLineItemState.unitCost || 0,
            unitPrice: newLineItemState.unitPrice || 0,
          },
        ],
      });

      if (
        Array.isArray(globalLineItemIds) &&
        typeof globalLineItemIds[0] === "string"
      ) {
        dispatch({
          type: LineItemsBulkEditActionTypes.Destroy,
          reactKey: newLineItemState.reactKey,
        });
      }
    } else {
      await handleJobEditLineItem({
        lineItems: [
          {
            ...lineItemDetails,
            lineItemId: newLineItemState.id,
          },
        ],
      });
    }
  }

  return { handleOnSaveLineItem };
}

function renderLineItemDescription(description: string) {
  if (description.includes("\n")) {
    return description.split("\n").map((paragraph: string, index: number) => {
      if (index === 0) {
        return paragraph;
      }
      return (
        <>
          <br></br>
          {paragraph}
        </>
      );
    });
  }

  return description;
}
