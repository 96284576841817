import { gql } from "@apollo/client";

export const CUSTOM_FIELD_TRANSFERED_FROM_FRAGMENT = gql`
  fragment transferedFrom on CustomFieldConfigurationInterface {
    id
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_INTERFACE = gql`
  fragment baseConfiguration on CustomFieldConfigurationInterface {
    app {
      id
      name
      logoUrl
      marketplaceUrl
    }
    id
    name
    appliesTo
    createdAt
    readOnly
    sortOrder
    transferable
    transferedFrom {
      ...transferedFrom
    }
    updatedAt
    valueType
    archived
  }
  ${CUSTOM_FIELD_TRANSFERED_FROM_FRAGMENT}
`;

export const CUSTOM_FIELD_CONFIGURATION_AREA = gql`
  fragment areaDetails on CustomFieldConfigurationArea {
    unit
    areaDefaultValue: defaultValue {
      length
      width
    }
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_DROPDOWN = gql`
  fragment dropdownDetails on CustomFieldConfigurationDropdown {
    dropdownOptions
    dropdownDefaultValue: defaultValue
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_LINK = gql`
  fragment linkDetails on CustomFieldConfigurationLink {
    linkDefaultValue: defaultValue {
      text
      url
    }
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_NUMERIC = gql`
  fragment numericDetails on CustomFieldConfigurationNumeric {
    numericDefaultValue: defaultValue
    unit
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_TEXT = gql`
  fragment textDetails on CustomFieldConfigurationText {
    textDefaultValue: defaultValue
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_TRUE_FALSE = gql`
  fragment trueFalseDetails on CustomFieldConfigurationTrueFalse {
    booleanDefaultValue: defaultValue
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_NODE = gql`
  fragment customFieldConfigurationNode on CustomFieldConfigurationInterface {
    ...baseConfiguration
    ... on CustomFieldConfigurationArea {
      ...areaDetails
    }
    ... on CustomFieldConfigurationDropdown {
      ...dropdownDetails
    }
    ... on CustomFieldConfigurationLink {
      ...linkDetails
    }
    ... on CustomFieldConfigurationNumeric {
      ...numericDetails
    }
    ... on CustomFieldConfigurationText {
      ...textDetails
    }
    ... on CustomFieldConfigurationTrueFalse {
      ...trueFalseDetails
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_INTERFACE}
  ${CUSTOM_FIELD_CONFIGURATION_AREA}
  ${CUSTOM_FIELD_CONFIGURATION_DROPDOWN}
  ${CUSTOM_FIELD_CONFIGURATION_LINK}
  ${CUSTOM_FIELD_CONFIGURATION_NUMERIC}
  ${CUSTOM_FIELD_CONFIGURATION_TEXT}
  ${CUSTOM_FIELD_CONFIGURATION_TRUE_FALSE}
`;

export const CUSTOM_FIELD_LIST = gql`
  query CustomFieldPageList {
    customFieldConfigurations {
      nodes {
        __typename
        ...customFieldConfigurationNode
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_NODE}
`;

export const CUSTOM_FIELD_CONFIGURATION_VALUE_COUNTS_FRAGMENT = gql`
  fragment customFieldConfigurationValueCounts on CustomFieldConfigurationInterface {
    ... on CustomFieldConfigurationArea {
      id
      name
      valueCount {
        clients
        invoices
        jobs
        properties
        quotes
        users
      }
    }
    ... on CustomFieldConfigurationDropdown {
      id
      name
      valueCount {
        clients
        invoices
        jobs
        properties
        quotes
        users
      }
    }
    ... on CustomFieldConfigurationLink {
      id
      name
      valueCount {
        clients
        invoices
        jobs
        properties
        quotes
        users
      }
    }
    ... on CustomFieldConfigurationNumeric {
      id
      name
      valueCount {
        clients
        invoices
        jobs
        properties
        quotes
        users
      }
    }
    ... on CustomFieldConfigurationText {
      id
      name
      valueCount {
        clients
        invoices
        jobs
        properties
        quotes
        users
      }
    }
    ... on CustomFieldConfigurationTrueFalse {
      id
      name
      valueCount {
        clients
        invoices
        jobs
        properties
        quotes
        users
      }
      appliesTo
    }
  }
`;

export const CUSTOM_FIELD_CONFIGURATION_VALUE_COUNTS = gql`
  query CustomFieldsValueCount(
    $valueType: CustomFieldConfigurationValueType
    $appliesTo: CustomFieldAppliesTo
  ) {
    customFieldConfigurations(
      filter: { valueType: $valueType, appliesTo: $appliesTo }
    ) {
      nodes {
        ...customFieldConfigurationValueCounts
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_VALUE_COUNTS_FRAGMENT}
`;

export const CUSTOM_FIELD_CONFIGURATION_ARCHIVE = gql`
  mutation CustomFieldConfigurationArchive($ids: [EncodedId!]!) {
    customFieldConfigurationArchive(customFieldConfigurationIds: $ids) {
      customFieldConfigurations {
        __typename
        ...customFieldConfigurationNode
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_NODE}
`;

export const CUSTOM_FIELD_CONFIGURATION_UNARCHIVE = gql`
  mutation CustomFieldConfigurationUnarchive($ids: [EncodedId!]!) {
    customFieldConfigurationUnarchive(customFieldConfigurationIds: $ids) {
      customFieldConfigurations {
        __typename
        ...customFieldConfigurationNode
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CUSTOM_FIELD_CONFIGURATION_NODE}
`;
