import React, { type ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Breakpoints } from "@jobber/hooks/useResizeObserver";
import styles from "./TwoColumn.module.css";

export interface TwoColumnProps {
  leftComponent: ReactNode;
  rightComponent: ReactNode;
  showColumnDivider?: boolean;
}

export function TwoColumn({
  leftComponent,
  rightComponent,
  showColumnDivider = true,
}: TwoColumnProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const onMobileWeb = window.innerWidth <= Breakpoints.small;

  const leftColumnDividerStyles = () => {
    if (params.has("back")) {
      return showColumnDivider && !onMobileWeb
        ? [styles.leftColumn, styles.backward].join(" ")
        : [styles.leftColumn, styles.hiddenDivider].join(" ");
    }
    return showColumnDivider && !onMobileWeb
      ? [styles.leftColumn, styles.forward].join(" ")
      : [styles.leftColumn, styles.hiddenDivider].join(" ");
  };
  const rightColumnDividerStyles = () => {
    return showColumnDivider
      ? styles.rightColumn
      : [styles.rightColumn, styles.hiddenDivider].join(" ");
  };

  return (
    <div className={styles.twoColumnGrid}>
      <div data-testid="left-column" className={leftColumnDividerStyles()}>
        {leftComponent}
      </div>
      <div data-testid="right-column" className={rightColumnDividerStyles()}>
        {rightComponent}
      </div>
    </div>
  );
}
