import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reviewsAddOnPromptTitle: {
    id: "upgradePage.reviewsAddOnPrompt.reviewsAddOnPromptTitle",
    defaultMessage: "Get more 5-star reviews with Reviews",
    description: "reviews add-on title copy",
  },
  reviewsAddOnPromptDescription: {
    id: "upgradePage.reviewsAddOnPrompt.reviewsAddOnPromptDescription",
    defaultMessage:
      "Automate review requests to get more reviews that attract even more customers to your business.",
    description: "reviews add-on description copy",
  },
  reviewsAddOnPromptCtaLabel: {
    id: "upgradePage.reviewsAddOnPrompt.reviewsAddOnPromptCtaLabel",
    defaultMessage: "Learn More",
    description: "reviews add-on CTA label copy",
  },
  reviewsAddOnPromptimgAlt: {
    id: "upgradePage.reviewsAddOnPrompt.reviewsAddOnPromptimgAlt",
    defaultMessage: "Reviews add-on image",
    description: "reviews add-on image alt text",
  },
});
