import { useState } from "react";

export function useSentDelighter() {
  const [open, setOpen] = useState<boolean>(false);
  const [clientCount, setClientCount] = useState<number | string | null>(null);

  return {
    open,
    clientCount,
    setOpen,
    setClientCount,
  };
}
