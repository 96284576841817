import React from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import type {
  BillingCycleNameType,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
} from "~/shared/billing/pricePreview/types";
import {
  formatPrice,
  getDiscountInformationByBillingCycle,
} from "~/shared/billing/pricePreview/utils";
import { messages } from "./messages";

interface DiscountInfoProps {
  selectedBillingCycle: BillingCycleNameType;
  discountInformation: SubscriptionDiscountGroup;
  subscriptionPreview: SubscriptionPreview;
}

export function DiscountInfo({
  selectedBillingCycle,
  discountInformation,
  subscriptionPreview,
}: DiscountInfoProps) {
  const { formatMessage } = useIntl();

  const discountAmountMessage = getDiscountAmountMessage(
    selectedBillingCycle,
    discountInformation,
    subscriptionPreview,
  );

  if (!discountAmountMessage) {
    return null;
  }

  return (
    <>
      <Text size="large">{formatMessage(messages.discountLabelMessage)}</Text>
      <Text size="large">
        <Emphasis variation="bold">{discountAmountMessage}</Emphasis>
      </Text>
    </>
  );
}

function getDiscountAmountMessage(
  selectedBillingCycle: BillingCycleNameType,
  discountInformation: SubscriptionDiscountGroup,
  subscriptionPreview: SubscriptionPreview,
) {
  const discountInformationByBillingCycle =
    getDiscountInformationByBillingCycle(
      selectedBillingCycle,
      discountInformation,
    );

  if (!discountInformationByBillingCycle) {
    return "";
  }

  const discountAmount =
    discountInformationByBillingCycle.discountType === "PERCENT"
      ? subscriptionPreview.proratedDiscount
      : discountInformationByBillingCycle.discountAmount;

  if (!discountAmount) {
    return "";
  }

  return `-${formatPrice(discountAmount)}`;
}
