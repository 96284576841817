import React from "react";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import JobCostingDesktopImage from "@images/JobCostingProfitMarginDesktop.png";
import JobCostingMobileWebImage from "@images/JobCostingProfitMarginMobileWeb.png";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./JobCostingUpgradePrompt.module.css";
import { TrialPromptCard } from "../TrialPromptCard";

export function JobCostingUpgradePrompt() {
  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();
  return (
    <div ref={ref} className={styles.trialPromptContainer}>
      <TrialPromptCard
        trialTitle={messages.jobCostingUpgradePrompt.title}
        trialDescription={messages.jobCostingUpgradePrompt.description}
        ctaLabel={messages.jobCostingUpgradePrompt.ctaLabel}
        ctaCallBack={navigateToLandingPage}
        imgSource={
          width > Breakpoints.large
            ? JobCostingDesktopImage
            : JobCostingMobileWebImage
        }
        imgAlt={messages.jobCostingUpgradePrompt.imgAlt}
        showNewLabel={true}
      />
    </div>
  );

  function navigateToLandingPage() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: "job_costing_upgrade_prompt",
    });
    window.open("/job_costing_feature", "_blank");
  }
}
