import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { WelcomeToolboxImage } from "./images";
import styles from "./ReverseTrialInitialEducationModal.module.css";

export function ReverseTrialInitialEducationModal() {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <Modal
      title="Welcome to Jobber!"
      open={modalOpen}
      onRequestClose={closeModal}
      primaryAction={{
        label: "Got It",
        onClick: closeModal,
      }}
      size="small"
    >
      <Content>
        <div className={styles.imgWrapper}>
          <img
            src={WelcomeToolboxImage.src}
            alt={WelcomeToolboxImage.alt}
            className={styles.image}
          />
        </div>
        <Text size="large">
          In your 14 day trial, you&apos;ll have access to enjoy all our Grow
          features! Once your trial has ended, you will be automatically moved
          to our free tier.
        </Text>
      </Content>
    </Modal>
  );

  function closeModal() {
    setModalOpen(false);
  }
}
