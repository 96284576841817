import type { LogsEvent } from "@datadog/browser-logs";

// Ignores sending errors from ResizeObserver to Datadog
const resizeObserverError = (event: LogsEvent) => {
  const errorMessage = event.message;

  return !!errorMessage.includes("ResizeObserver loop");
};

function typeError(event: LogsEvent) {
  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();
    return (
      exceptionDescription?.includes("typeerror") &&
      exceptionDescription?.includes("load failed")
    );
  } catch (e) {
    return false;
  }
}

function abortError(event: LogsEvent) {
  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();
    return (
      exceptionDescription?.includes("aborterror") &&
      exceptionDescription?.includes("the user aborted a request")
    );
  } catch (e) {
    return false;
  }
}

function chromeExtensionError(event: LogsEvent) {
  const CHROME_EXTENSION_ERROR_STRING = "chrome-extension:";

  try {
    const exceptionDescription = event.error?.stack?.toLowerCase();
    return exceptionDescription?.includes(CHROME_EXTENSION_ERROR_STRING);
  } catch (e) {
    return false;
  }
}

export { resizeObserverError, typeError, abortError, chromeExtensionError };
