import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sentDelighterTitle: {
    id: "campaigns.sentDelighter.title",
    defaultMessage: "It's on its way!",
    description: "Sent modal title label",
  },
  sentDelighterTextWithCount: {
    id: "campaigns.sentDelighter.validTotalCount.text",
    defaultMessage:
      "Success! Your campaign is queued up and is on its way to the inboxes for {count} of your clients.",
    description: "Success message label",
  },
  sentDelighterTextWithoutCount: {
    id: "campaigns.sentDelighter.invalidTotalCount.text",
    defaultMessage:
      "Success! Your campaign is queued up and is on its way to the inboxes of your clients.",
    description: "Success message label",
  },
  sentDelighterButtonLabel: {
    id: "campaigns.sentDelighter.buttonLabel",
    defaultMessage: "Got it!",
    description: "Got it button label",
  },
});
