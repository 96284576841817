import React from "react";
import { Card } from "@jobber/components/Card";
import { CarouselCardContent } from "jobber/freemium/onboarding/OnboardingCarousel/CarouselCardContent";
import type { ObjectInfoProps } from "jobber/freemium/onboarding/OnboardingCarousel/OnboardingCarousel.d";

export function CarouselCard({ objectContent, url, type }: ObjectInfoProps) {
  const accentColour = (objectType: "quote" | "invoice" | undefined) => {
    if (objectType) {
      if (objectType === "quote") {
        return "quoteColor";
      } else if (objectType === "invoice") {
        return "invoiceColor";
      }
    } else {
      return undefined;
    }
  };

  return (
    <div className="carouselCard u-paddingRightSmall">
      <Card accent={accentColour(type)} url={url}>
        <div
          className="u-paddingSmallest"
          style={{ width: "11rem", height: "9rem" }}
        >
          {objectContent && <CarouselCardContent {...objectContent} />}
        </div>
      </Card>
    </div>
  );
}
