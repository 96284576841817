import React from "react";
import { useQuery } from "@apollo/client";
import {
  DragDropContext,
  type DropResult,
  Droppable,
  type DroppableProvided,
} from "react-beautiful-dnd";
import { LineItemsBulkEditEmptyState } from "~/jobber/lineItems/components/LineItemsBulkEdit/LineItemsBulkEditEmptyState";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import type { ProductsQuery } from "~/utilities/API/graphql";
import { AddNewLineItemButton } from "jobber/workOrders/components/JobCost/components/LineItemsTable/components/AddNewLineItemButton";
import { useLineItemPermissions } from "~/jobber/lineItems/hooks/useLineItemPermissions";
import type { LineItem } from "~/jobber/lineItems/types";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./styles.module.css";
import { LineItemInput, LineItemTotals } from "./components";
import { DraggableLineItemInputWrapper } from "./components/LineItemInput/LineItemInput";
import { PRODUCT_OR_SERVICES_QUERY } from "./components/graphql";

export interface LineItemsBulkEditProps {
  lineItems: LineItem[];
  dispatch: (action: LineItemsAction) => void;
  footerSection?: React.ReactNode;
}

export function onDragEnd(
  lineItems: LineItem[],
  dispatch: (action: LineItemsAction) => void,
) {
  return function (
    result: Pick<DropResult, "source" | "destination" | "draggableId">,
  ) {
    const { source, destination, draggableId } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newLineItems = Array.from(lineItems);
    const draggedLineItem = newLineItems.find(
      (element: LineItem) => String(element.reactKey) === draggableId,
    );
    newLineItems.splice(source.index, 1);
    if (!draggedLineItem) {
      return;
    }
    newLineItems.splice(destination.index, 0, draggedLineItem);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newLineItems.forEach((lineItem: any, index: number) => {
      lineItem.sort_order = index;
    });

    dispatch({
      type: LineItemsBulkEditActionTypes.ReorderLineItems,
      reorderedLineItems: newLineItems,
    });
  };
}

export function LineItemsBulkEdit(props: LineItemsBulkEditProps) {
  const { showCosting } = useLineItemPermissions();

  return (
    <div className={styles.lineItemsBulkEdit} data-testid="lineItems">
      <LineItemsBulkEditHeader dispatch={props.dispatch} />
      <DragDropContext onDragEnd={onDragEnd(props.lineItems, props.dispatch)}>
        <Droppable droppableId={"line-items-bulk-edit"}>
          {(providedDroppable: DroppableProvided) => (
            <div
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              <LineItemsBulkEditContent {...props} showCosting={showCosting} />
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <LineItemTotals
        dispatch={props.dispatch}
        lineItems={props.lineItems}
        showCosting={showCosting}
        footerSection={props.footerSection}
        canViewPricing
      />
    </div>
  );
}

function LineItemsBulkEditHeader({
  dispatch,
}: {
  dispatch: (action: LineItemsAction) => void;
}) {
  return (
    <div className={styles.lineItemsBulkEditHeader}>
      <h3>Line Items</h3>
      <IntlProvider>
        <AddNewLineItemButton
          onPress={() =>
            dispatch({
              type: LineItemsBulkEditActionTypes.AddLineItem,
            })
          }
        />
      </IntlProvider>
    </div>
  );
}

interface LineItemsBulkEditContentProps extends LineItemsBulkEditProps {
  showCosting: boolean;
}

function LineItemsBulkEditContent({
  lineItems,
  dispatch,
  showCosting,
}: LineItemsBulkEditContentProps) {
  const { data } = useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);
  if (lineItems.length <= 0) {
    return <LineItemsBulkEditEmptyState />;
  }

  return (
    <div className={styles.lineItemsBulkEditWrapper}>
      {lineItems.map((lineItem, index) => (
        <div
          className={styles.lineItemsInputContainer}
          key={lineItem.reactKey}
          data-testid="lineItem"
        >
          <DraggableLineItemInputWrapper
            lineItemIndex={index}
            lineItemState={lineItem}
          >
            <LineItemInput
              index={index}
              initialOptions={data?.products.nodes ?? []}
              lineItem={lineItem}
              dispatch={dispatch}
              showCosting={showCosting}
              shouldAutoFocus={lineItems?.length > 1}
              onDelete={async () => {
                dispatch({
                  type: LineItemsBulkEditActionTypes.DeleteLineItem,
                  reactKey: lineItem.reactKey,
                });
              }}
              shouldRenderSaveButton={false}
              onSaveLineItem={async () => {
                dispatch({
                  type: LineItemsBulkEditActionTypes.FullUpdate,
                  reactKey: lineItem.reactKey,
                  value: lineItem,
                });
              }}
            />
          </DraggableLineItemInputWrapper>
        </div>
      ))}
    </div>
  );
}
