import React, { useState } from "react";
import { showToast } from "@jobber/components/Toast";
import { useMutation } from "@apollo/client";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";
import { CustomFieldsSection } from "jobber/customFields/CustomFieldsPage/CustomFieldsSection";
import {
  CUSTOM_FIELD_CONFIGURATION_ARCHIVE,
  CUSTOM_FIELD_CONFIGURATION_UNARCHIVE,
} from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";
import { CustomFieldDeleteConfirmationModal } from "./CustomFieldDeleteConfirmationModal";
import {
  useCustomFieldConfigurations,
  useCustomFieldConfigurationsList,
} from "./hooks";
import { CustomFieldArchiveModal } from "./CustomFieldArchiveModal";
import { CustomFieldConfigurationModal } from "../customFieldConfigurationModal";

interface CustomFieldsPageInternalProps {
  customFieldConfigurations: CustomFieldConfigurationNodeFragment[];
  refetch(): void;
  adminSuperpowers: boolean;
}

// eslint-disable-next-line max-statements
export function CustomFieldsPageInternal({
  customFieldConfigurations,
  refetch,
  adminSuperpowers,
}: CustomFieldsPageInternalProps) {
  const { deleteCustomField } = useCustomFieldConfigurations();
  const { customFields } = useCustomFieldConfigurationsList(
    customFieldConfigurations,
  );
  const [selectedCustomField, setSelectedCustomField] =
    useState<CustomFieldConfigurationNodeFragment>();

  const [archiveConfiguration] = useMutation(
    CUSTOM_FIELD_CONFIGURATION_ARCHIVE,
    {
      onCompleted: () => {
        showToast({
          message: "Succesfully archived the custom field",
          variation: "success",
        });
      },
      onError: () => {
        showToast({
          message: "Could not archive the custom field",
          variation: "error",
        });
      },
    },
  );

  const [unarchiveConfiguration] = useMutation(
    CUSTOM_FIELD_CONFIGURATION_UNARCHIVE,
    {
      onCompleted: () => {
        showToast({
          message: "Succesfully unarchived the custom field",
          variation: "success",
        });
      },
      onError: () => {
        showToast({
          message: "Could not unarchive the custom field",
          variation: "error",
        });
      },
    },
  );

  function editCustomField(
    customFieldToUpdate: CustomFieldConfigurationNodeFragment,
  ) {
    setSelectedCustomField(customFieldToUpdate);
  }

  function closeCustomFieldModal() {
    setSelectedCustomField(undefined);
  }

  function onCustomFieldSavedSuccessfully(
    newConfiguration: CustomFieldConfigurationNodeFragment,
  ) {
    // If the field that was saved was a transferable field, we need to refetch data since updating the
    // related transfered fields in the apollo cache is a nightmare
    if (newConfiguration.transferable) {
      refetch();
    }

    closeCustomFieldModal();
  }

  function CustomFieldCreateOrEditModal() {
    if (selectedCustomField) {
      return (
        <CustomFieldConfigurationModal
          existingCustomField={selectedCustomField}
          open={!!selectedCustomField && archiveModalOpen === false}
          appliesToDisabled={true}
          onClose={closeCustomFieldModal}
          onSuccess={onCustomFieldSavedSuccessfully}
          onArchive={openArchiveModal}
        />
      );
    }
    return <></>;
  }

  function encodedGlobalIdFromCustomFieldConfiguration(
    customFieldConfiguration: CustomFieldConfigurationNodeFragment | undefined,
  ) {
    if (!customFieldConfiguration || !customFieldConfiguration.id) {
      showToast({
        message: "Operation failed",
        variation: "error",
      });
      return "";
    }

    return btoa(
      `gid://Jobber/${
        FIELD_TYPE_TO_CUSTOM_FIELD_CONFIGURATION_TYPE[
          customFieldConfiguration.valueType as CustomFieldConfigurationValueType
        ]
      }/${atob(customFieldConfiguration.id)}`,
    );
  }

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] =
    useState<boolean>(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
  const [userConfirmDelete, setUserConfirmDelete] = useState<boolean>(false);
  const [customFieldConfigValueForModal, setCustomFieldConfigValueForModal] =
    useState<CustomFieldConfigurationNodeFragment>();

  function closeConfirmDeleteModal() {
    setConfirmDeleteModalOpen(false);
    setUserConfirmDelete(false);
    setCustomFieldConfigValueForModal(undefined);
  }

  function closeArchiveModal() {
    setSelectedCustomField(undefined);
    setArchiveModalOpen(false);
    setCustomFieldConfigValueForModal(undefined);
  }

  function openConfirmDeleteModal(
    existingCustomField: CustomFieldConfigurationNodeFragment,
  ) {
    setCustomFieldConfigValueForModal(existingCustomField);
    setConfirmDeleteModalOpen(true);
  }

  function openArchiveModal(
    existingCustomField: CustomFieldConfigurationNodeFragment,
  ) {
    setCustomFieldConfigValueForModal(existingCustomField);
    setArchiveModalOpen(true);
  }

  async function handleDeleteCustomField() {
    if (!customFieldConfigValueForModal || !customFieldConfigValueForModal.id) {
      showToast({
        message: "Could not delete the custom field",
        variation: "error",
      });
      return;
    }

    const wasSuccessfulDelete = await deleteCustomField(
      customFieldConfigValueForModal,
    );
    if (wasSuccessfulDelete) {
      showToast({ message: "Custom field has been deleted" });
      closeConfirmDeleteModal();
      setCustomFieldConfigValueForModal(undefined);
    } else {
      showToast({
        message: "Could not delete the custom field",
        variation: "error",
      });
    }
  }

  async function handleArchiveCustomField() {
    if (!customFieldConfigValueForModal || !customFieldConfigValueForModal.id) {
      showToast({
        message: "Could not delete the custom field",
        variation: "error",
      });
      return;
    }

    await archiveConfiguration({
      variables: {
        ids: encodedGlobalIdFromCustomFieldConfiguration(
          customFieldConfigValueForModal,
        ),
      },
    });

    if (customFieldConfigValueForModal.transferable) {
      refetch();
    }

    closeArchiveModal();
  }

  async function handleUnarchiveCustomField(
    existingCustomField: CustomFieldConfigurationNodeFragment,
  ) {
    if (!existingCustomField || !existingCustomField.id) {
      showToast({
        message: "Could not unarchive the custom field",
        variation: "error",
      });
      return;
    } else if (!existingCustomField.app) {
      await unarchiveConfiguration({
        variables: {
          ids: encodedGlobalIdFromCustomFieldConfiguration(existingCustomField),
        },
      });

      if (existingCustomField.transferable) {
        refetch();
      }
    } else if (existingCustomField.app) {
      setSelectedCustomField(existingCustomField);
      openArchiveModal(existingCustomField);
    }
  }

  return (
    <>
      <CustomFieldCreateOrEditModal />
      <CustomFieldDeleteConfirmationModal
        customFieldConfiguration={customFieldConfigValueForModal}
        confirmDeleteModalOpen={confirmDeleteModalOpen}
        userConfirmDelete={userConfirmDelete}
        closeConfirmDeleteModal={closeConfirmDeleteModal}
        handleDeleteCustomField={handleDeleteCustomField}
        setUserConfirmDelete={setUserConfirmDelete}
      />
      <CustomFieldArchiveModal
        customFieldConfiguration={customFieldConfigValueForModal}
        open={archiveModalOpen}
        closeModal={closeArchiveModal}
        handleArchiveCustomField={handleArchiveCustomField}
      />
      <CustomFieldsSection
        customFieldModelType={CustomFieldAppliesTo.ALL_CLIENTS}
        customFieldList={customFields.client}
        onEditCustomField={editCustomField}
        adminSuperpowers={adminSuperpowers}
        onDelete={openConfirmDeleteModal}
        onArchive={openArchiveModal}
        onUnarchive={handleUnarchiveCustomField}
      />
      <CustomFieldsSection
        customFieldModelType={CustomFieldAppliesTo.ALL_PROPERTIES}
        customFieldList={customFields.property}
        onEditCustomField={editCustomField}
        adminSuperpowers={adminSuperpowers}
        onDelete={openConfirmDeleteModal}
        onArchive={openArchiveModal}
        onUnarchive={handleUnarchiveCustomField}
      />
      <CustomFieldsSection
        customFieldModelType={CustomFieldAppliesTo.ALL_QUOTES}
        customFieldList={customFields.quote}
        onEditCustomField={editCustomField}
        adminSuperpowers={adminSuperpowers}
        onDelete={openConfirmDeleteModal}
        onArchive={openArchiveModal}
        onUnarchive={handleUnarchiveCustomField}
      />
      <CustomFieldsSection
        customFieldModelType={CustomFieldAppliesTo.ALL_JOBS}
        customFieldList={customFields.job}
        onEditCustomField={editCustomField}
        adminSuperpowers={adminSuperpowers}
        onDelete={openConfirmDeleteModal}
        onArchive={openArchiveModal}
        onUnarchive={handleUnarchiveCustomField}
      />
      <CustomFieldsSection
        customFieldModelType={CustomFieldAppliesTo.ALL_INVOICES}
        customFieldList={customFields.invoice}
        onEditCustomField={editCustomField}
        adminSuperpowers={adminSuperpowers}
        onDelete={openConfirmDeleteModal}
        onArchive={openArchiveModal}
        onUnarchive={handleUnarchiveCustomField}
      />
      <CustomFieldsSection
        customFieldModelType={CustomFieldAppliesTo.TEAM}
        customFieldList={customFields.team}
        onEditCustomField={editCustomField}
        adminSuperpowers={adminSuperpowers}
        onDelete={openConfirmDeleteModal}
        onArchive={openArchiveModal}
        onUnarchive={handleUnarchiveCustomField}
      />
    </>
  );
}

const FIELD_TYPE_TO_CUSTOM_FIELD_CONFIGURATION_TYPE = {
  [CustomFieldConfigurationValueType.TEXT]: "CustomFieldConfigurationText",
  [CustomFieldConfigurationValueType.NUMERIC]:
    "CustomFieldConfigurationNumeric",
  [CustomFieldConfigurationValueType.TRUE_FALSE]:
    "CustomFieldConfigurationTrueFalse",
  [CustomFieldConfigurationValueType.DROPDOWN]:
    "CustomFieldConfigurationDropdown",
  [CustomFieldConfigurationValueType.AREA]: "CustomFieldConfigurationArea",
  [CustomFieldConfigurationValueType.LINK]: "CustomFieldConfigurationLink",
};
