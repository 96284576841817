import React from "react";
import type { IntlFormatters } from "react-intl";
import GrowTrialAutomatedQuote from "@images/GrowTrialAutomatedQuote.png";
import GrowTrialOLI from "@images/GrowTrialOLI.png";
import GrowTrialLocationTimers from "@images/GrowTrialLocationTimers.png";
import GrowTrialJobCostingProfitMargin from "@images/GrowTrialJobCostingProfitMargin.png";
import { Carousel } from "components/Carousel";
import { CarouselCard } from "./components";
import { messages } from "./messages";

export interface ConnectToGrowFeatureCarouselProps {
  showArrowIndicators?: boolean;
  formatMessage: IntlFormatters["formatMessage"];
}

export function ConnectToGrowFeatureCarousel({
  showArrowIndicators = true,
  formatMessage,
}: ConnectToGrowFeatureCarouselProps) {
  const features: {
    title: keyof typeof messages;
    description: keyof typeof messages;
    imgSrc: string;
  }[] = [
    {
      title: "jobCostingTitle",
      description: "jobCostingDescription",
      imgSrc: GrowTrialJobCostingProfitMargin as string,
    },
    {
      title: "locationTimersTitle",
      description: "locationTimersDescription",
      imgSrc: GrowTrialLocationTimers as string,
    },
    {
      title: "oliTitle",
      description: "oliDescription",
      imgSrc: GrowTrialOLI as string,
    },
    {
      title: "automatedQuoteFollowUpsTitle",
      description: "automatedQuoteFollowUpsDescription",
      imgSrc: GrowTrialAutomatedQuote as string,
    },
  ];

  return (
    <Carousel showArrowIndicators={showArrowIndicators} autoplaySeconds={6}>
      {features.map((feature, index) => (
        <CarouselCard
          key={index}
          img={{
            src: feature.imgSrc,
            alt: formatMessage(messages[feature.title]),
          }}
          title={formatMessage(messages[feature.title])}
          description={formatMessage(messages[feature.description])}
        />
      ))}
    </Carousel>
  );
}
