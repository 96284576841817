import React from "react";
import { Button } from "@jobber/components/Button";
import styles from "./ConversationPills.module.css";

interface ConversationPillsProps {
  readonly prompts: { displayText: string; prompt: string }[];
  readonly onClick: (prompt: string) => void;
}

export function ConversationPills({
  prompts,
  onClick,
}: ConversationPillsProps) {
  return (
    <div className={styles.wrapper} data-testid="conversation-pills">
      {prompts.map((prompt, index) => (
        <Button
          key={index}
          size="small"
          type="secondary"
          onClick={() => onClick(prompt.prompt)}
          label={prompt.displayText}
        />
      ))}
    </div>
  );
}
