import React from "react";
import type { CurrencyType } from "@jobber/formatters";

// Extend this as needed to check for additional features
// Populated via js_helper.rb
export type FeatureName =
  | "jobs"
  | "quoteMargins"
  | "jobCosting"
  | "timeSheets"
  | "expenses"
  | "selfServeBookings"
  | "franchiseReporting"
  | "arrivalWindows"
  | "leadOrganization"
  | "salespersonAttribution";

export interface Feature {
  available: boolean;
  enabled: boolean;
}

export interface AccountContextType {
  currencyCode: CurrencyType;
  countryCode: string;
  currencySymbol: string;
  timezone: string;
  features: Record<FeatureName, Feature>;
  isFranchiseAdmin: boolean;
  hasAliasAccounts: boolean;
  dateFormat: string;
  timeFormat: string;
  calendarFirstDay: 0 | 1;
}

export const AccountContext = React.createContext<AccountContextType>({
  currencyCode: "USD",
  countryCode: "",
  currencySymbol: "",
  timezone: "",
  calendarFirstDay: 0,
  features: {
    jobs: { available: false, enabled: false },
    quoteMargins: { available: false, enabled: false },
    jobCosting: { available: false, enabled: false },
    timeSheets: { available: false, enabled: false },
    expenses: { available: false, enabled: false },
    selfServeBookings: { available: false, enabled: false },
    franchiseReporting: { available: false, enabled: false },
    arrivalWindows: { available: false, enabled: false },
    leadOrganization: { available: false, enabled: false },
    salespersonAttribution: { available: false, enabled: false },
  },
  isFranchiseAdmin: false,
  hasAliasAccounts: false,
  dateFormat: "",
  timeFormat: "",
});
AccountContext.displayName = "AccountContext";
