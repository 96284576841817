import type { MutableRefObject } from "react";
import React, { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { ButtonDismiss } from "@jobber/components/ButtonDismiss";
import { Grid } from "@jobber/components/Grid";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import discoveryImage from "@images/PLE_OLB.png";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import type { CallToActionRef } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";
import styles from "./HeaderCallToAction.module.css";

interface ContentProps {
  readonly linkTo: string;
  readonly loading: boolean;
  readonly onClick: () => void;
  readonly onDismiss: () => void;
  readonly visible: boolean;
}

function Content({
  linkTo,
  loading,
  onClick,
  onDismiss,
  visible,
}: ContentProps): JSX.Element {
  const { formatMessage } = useIntl();

  if (!visible) {
    return <div data-testid="cta:hidden" />;
  }

  return (
    <div className={styles.container}>
      <Grid gap={false} alignItems="center">
        <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <img
            className={styles.image}
            src={discoveryImage}
            alt={formatMessage(messages.imageAlt)}
          />
        </Grid.Cell>
        <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <div className={styles.flexColumn}>
            <div className={styles.dismiss}>
              <ButtonDismiss ariaLabel="Dismiss" onClick={onDismiss} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>
                <Heading level={3}>{formatMessage(messages.title)}</Heading>
              </div>
              <Text>{formatMessage(messages.description)}</Text>
              <div className={styles.button}>
                <Button
                  label={formatMessage(messages.buttonText)}
                  type="secondary"
                  variation="learning"
                  loading={loading}
                  onClick={onClick}
                  url={linkTo}
                />
              </div>
            </div>
          </div>
        </Grid.Cell>
      </Grid>
    </div>
  );
}

interface Props {
  readonly name: string;
  readonly linkTo: string;
}

export function HeaderCallToAction({ linkTo, name }: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  const onClick = useCallback(() => {
    setLoading(true);
    convertCTA(ctaRef)();
  }, []);

  const onDismiss = useCallback(() => {
    setVisible(false);
    dismissCTA(ctaRef)();
  }, []);

  return (
    <APIProvider>
      <IntlProvider>
        <CallToAction ref={ctaRef} ctaName={name}>
          <Content
            loading={loading}
            visible={visible}
            linkTo={linkTo}
            onClick={onClick}
            onDismiss={onDismiss}
          />
        </CallToAction>
      </IntlProvider>
    </APIProvider>
  );
}
