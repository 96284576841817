import React from "react";
import { Card } from "@jobber/components/Card";
import { APIProvider } from "~/utilities/API/APIProvider";
import { DedicatedPhoneNumberSetting } from "jobber/settings/dedicatedPhoneNumber/DedicatedPhoneNumberSetting";
import type { TwilioRegistrationStatus } from "~/utilities/API/graphql";

interface DedicatedPhoneNumberSettingWrapperProps {
  selectedNumber?: string;
  registrationStatus: TwilioRegistrationStatus;
  isSelectedNumberActive?: boolean;
  requiresRegistration: boolean;
}

export function DedicatedPhoneNumberSettingWrapper({
  selectedNumber,
  registrationStatus,
  isSelectedNumberActive,
  requiresRegistration,
}: DedicatedPhoneNumberSettingWrapperProps) {
  return (
    <APIProvider>
      <Card title="Dedicated phone number">
        <DedicatedPhoneNumberSetting
          selectedNumber={selectedNumber}
          registrationStatus={registrationStatus}
          isSelectedNumberActive={isSelectedNumberActive}
          requiresRegistration={requiresRegistration}
        />
      </Card>
    </APIProvider>
  );
}
