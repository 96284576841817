import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "UpgradePage.Header",
    defaultMessage: "Change your plan",
    description: "Upgrade page heading",
  },
  subtitle: {
    id: "UpgradePage.Subtitle",
    defaultMessage: "Find the best plan for your business",
    description: "Upgrade page subtitle",
  },
  hideFeaturesButton: {
    id: "UpgradePage.HideFeaturesButton",
    defaultMessage: "Hide All Features",
    description: "Label for hide features button",
  },
  disclaimer: {
    id: "UpgradePage.promotionDisclaimer",
    defaultMessage: "*{disclaimer}",
    description: "Disclaimer text",
  },
});
