import { messages as heardAboutUsPageMessages } from "jobber/setupWizard/components/HeardAboutUsPage/messages";
import { messages as profilePageMessages } from "jobber/setupWizard/components/ProfilePage/messages";
import { messages as businessPageMessages } from "jobber/setupWizard/components/BusinessPage/messages";
import { messages as topPriorityPageMessages } from "jobber/setupWizard/components/TopPriorityPage/messages";
import type { MessageFormat } from "../types";
/**
 * Any changes to the values of this array (case-sensitive) must be coordinated with BizOps due to HubSpot integration
 */
export const companySizeArray: readonly {
  value: string;
  label: MessageFormat;
}[] = [
  { value: "Just me", label: businessPageMessages.companySizeJustMe },
  { value: "2-3 people", label: businessPageMessages.companySize2To3People },
  { value: "4-10 people", label: businessPageMessages.companySize4To10People },
  { value: "10+ people", label: businessPageMessages.companySize10PlusPeople },
];

/**
 * Any changes to the values of this array (case-sensitive) must be coordinated with BizOps due to HubSpot integration
 */
export const companyRevenueArray: readonly {
  value: string;
  label: MessageFormat;
}[] = [
  { value: "$0 - $50,000", label: businessPageMessages.companyRevenue0To50K },
  {
    value: "$50,000 - $150,000",
    label: businessPageMessages.companyRevenue50To150K,
  },
  {
    value: "$150,000 - $500,000",
    label: businessPageMessages.companyRevenue150To500K,
  },
  { value: "$500,000+", label: businessPageMessages.companyRevenue500KPlus },
  {
    value: "I'd prefer not to say",
    label: businessPageMessages.companyRevenuePreferNotToSay,
  },
];

export const topPriorityArray: { value: string; label: MessageFormat }[] = [
  {
    value: "Win more work",
    label: topPriorityPageMessages.topPriorityWinMoreWork,
  },
  {
    value: "Increase efficiency",
    label: topPriorityPageMessages.topPriorityIncreaseEfficiency,
  },
  {
    value: "Get paid faster",
    label: topPriorityPageMessages.topPriorityGetPaidFaster,
  },
];

export const lastSubmittedStepQuestionKey = "Last submitted step";

export const heardAboutUsPageDefaultFeatureList = [
  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem1Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem1Subtext,
  },
  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem2Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem2Subtext,
  },

  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem3Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem3Subtext,
  },
  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem4Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem4Subtext,
  },
];

export const profilePageDefaultStats = [
  {
    copy: profilePageMessages.profilePageDefaultStatsCopy1,
    maskingTape: "one" as const,
  },
  {
    copy: profilePageMessages.profilePageDefaultStatsCopy2,
    color: "highlight",
    maskingTape: "two" as const,
  },
  {
    copy: profilePageMessages.profilePageDefaultStatsCopy3,
    maskingTape: "three" as const,
  },
] as const;
