import React from "react";
import styles from "./Cell.module.css";
import { Cell } from "./Cell";

export interface CellNumericProps {
  value: number | string;
  size?: "small" | "base";
  variation?: "normal" | "bold";
  testId?: string;
}

export function CellNumeric({
  value,
  size = "base",
  variation = "normal",
  testId,
}: CellNumericProps) {
  return (
    <Cell align="right" size={size} variation={variation} testId={testId}>
      <span className={styles.numeric}>{value.toString()}</span>
    </Cell>
  );
}
