import { defineMessages } from "react-intl";

export const messages = defineMessages({
  jobCostingTitle: {
    id: "growFeatureCarousel.jobCostingTitle",
    defaultMessage: "Job costing",
    description: "Job costing title",
  },
  jobCostingDescription: {
    id: "growFeatureCarousel.jobCostingDescription",
    defaultMessage:
      "Be confident that every job is profitable. See profitability for every job based on the line items, labor, and expenses you've tracked.",
    description: "Job costing description",
  },
  locationTimersTitle: {
    id: "growFeatureCarousel.locationTimersTitle",
    defaultMessage: "Location timers",
    description: "Location timers title",
  },
  locationTimersDescription: {
    id: "growFeatureCarousel.locationTimersDescription",
    defaultMessage:
      "Maintain accurate and up-to-date timesheets by tracking your team's visit time automatically when near a client's property.",
    description: "Location timers description",
  },
  oliTitle: {
    id: "growFeatureCarousel.oliTitle",
    defaultMessage: "Optional line items",
    description: "Optional line items title",
  },
  oliDescription: {
    id: "growFeatureCarousel.oliDescription",
    defaultMessage:
      "Sell more by offering premium packages or add-ons in your quotes. Clients can select extras and approve the entire quote all in one go.",
    description: "Optional line items description",
  },
  automatedQuoteFollowUpsTitle: {
    id: "growFeatureCarousel.automatedQuoteFollowUpsTitle",
    defaultMessage: "Automated quote follow-ups",
    description: "Automated quote follow-ups title",
  },
  automatedQuoteFollowUpsDescription: {
    id: "growFeatureCarousel.automatedQuoteFollowUpsDescription",
    defaultMessage:
      "Clients automatically receive emails and/or texts reminding them to review and approve their quote so you can win more deals, faster.",
    description: "Automated quote follow-ups description",
  },
});
