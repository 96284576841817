import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "@jobber/components/Spinner";
import { useMutation } from "@apollo/client";
import { useCollectionQuery } from "@jobber/hooks/useCollectionQuery";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import { ConversationsNotFoundEmptyState } from "jobber/chat/components/ChatDrawer/ChatList/EmptyState/ConversationsNotFoundEmptyState";
import type {
  ConversationMessageSubscription,
  ConversationMessagesQuery,
  MessageStatusEnum,
  Recipient,
  Scalars,
} from "~/utilities/API/graphql";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton";
import {
  CONVERSATION_MESSAGES_QUERY,
  CONVERSATION_MESSAGE_SUBSCRIPTION,
  READ_CONVERSATION_MUTATION,
} from "jobber/chat/components/ChatDrawer/Chat/Chat.graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import {
  addSendingMessage,
  processMessages,
} from "jobber/chat/components/ChatDrawer/Chat/ChatWrapper";
import { Conversation } from "./Conversation";

interface ConversationLoaderProps {
  conversationId: string;
  afterSend?(recipient: Recipient): void;
}

// eslint-disable-next-line max-statements
export function ConversationLoader({
  conversationId,
  afterSend,
}: ConversationLoaderProps) {
  const [markConversationRead] = useMutation(READ_CONVERSATION_MUTATION);
  const [, dispatch] = useContext(MessageCenterContext);
  const { currentUser } = useCurrentUser(["fullName"]);

  const { data, error, nextPage, loadingInitialContent } = useCollectionQuery<
    ConversationMessagesQuery,
    ConversationMessageSubscription
  >({
    query: CONVERSATION_MESSAGES_QUERY,
    queryOptions: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        id: conversationId,
      },
    },
    getCollectionByPath: result => result?.conversation?.messages,
    subscription: {
      document: CONVERSATION_MESSAGE_SUBSCRIPTION,
      options: {
        variables: {
          conversationId,
        },
      },
      getNodeByPath: (edges: ConversationMessageSubscription) => {
        markAsRead();
        return edges?.conversationMessage.message;
      },
    },
  });

  useEffect(() => {
    const withConversation: Recipient | undefined = data?.conversation?.with;
    const idConversation: string | undefined = data?.conversation?.id;

    if (dispatch) {
      if (withConversation) {
        dispatch({
          type: "SET_RECIPIENT",
          recipient: {
            contactInfo: withConversation.phoneNumber?.friendly || "",
            name: withConversation.name,
            id: withConversation.id,
          },
        });
      }

      if (idConversation) {
        dispatch({
          type: "SET_CONVERSATION_ID",
          conversationId: idConversation,
        });
      }
    }
  }, [data]);

  if (error) {
    Rollbar.EXECUTE("Error Fetching More Chat Messages", error);
  }

  const [listItems, setListItems] = useState(
    data?.conversation?.messages.edges?.map(edge => edge.node) || [],
  );

  useEffect(() => {
    setListItems(processMessages(data, listItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function afterInput(
    message: string,
    clientGeneratedId: Scalars["Uuid"]["input"],
    status: MessageStatusEnum,
  ) {
    setListItems(
      addSendingMessage(
        message,
        clientGeneratedId,
        listItems,
        currentUser,
        status,
      ),
    );
  }

  if (loadingInitialContent) {
    return (
      <div className={"row collapse align-center u-paddingTopLarger"}>
        <Spinner inline={true} />
      </div>
    );
  }

  if (!data?.conversation || !data?.conversation?.with) {
    return <ConversationsNotFoundEmptyState />;
  }

  return (
    <Conversation
      messages={listItems}
      hasNextPage={data?.conversation?.messages?.pageInfo?.hasNextPage}
      recipient={data?.conversation?.with as Recipient}
      getMoreMessages={nextPage}
      markConversationAsRead={markAsRead}
      afterSend={afterSend}
      afterInput={afterInput}
    />
  );

  function markAsRead() {
    markConversationRead({
      variables: {
        id: conversationId,
      },
    }).catch((err: Error) => {
      Rollbar.EXECUTE("Error Marking Conversation as Read", err);
    });
  }
}
