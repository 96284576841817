import React, { useEffect, useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { APIProvider } from "~/utilities/API/APIProvider";
import styles from "./ReverseTrialRestartTrialBanner.module.css";
import { messages } from "./messages";
import { ReverseTrialRestartTrialModal } from "./ReverseTrialRestartTrialModal";

export function ReverseTrialRestartTrialBanner() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: "reverse_trial_restart_trial_prompt",
    });
  }, []);

  return (
    <div className={styles.restartTrialPromptBannerWrapper}>
      <Banner
        type="notice"
        dismissible={false}
        primaryAction={{
          label: messages.button.label,
          onClick: openModal,
        }}
      >
        {messages.content.body}
      </Banner>
      <APIProvider>
        <ReverseTrialRestartTrialModal
          shouldShowModal={showModal}
          closeModal={closeModal}
        />
      </APIProvider>
    </div>
  );

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }
}
