import React from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Glimmer } from "@jobber/components/Glimmer";
import { IntlProvider } from "@translations/IntlProvider";
import { AddonPurchaseModal } from "legacy/jobber/billing/features/AddonPurchaseModal";
import { Intercom } from "legacy/utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";

interface PurchaseFlowButtonProps {
  isMobileBilled: boolean;
  recurlyPublicKey: string;
  addonSetIdentifier?: string;
  fullWidth?: boolean;
  buttonText?: string;
  modalActionProps?: ModalActionProps;
  buttonType?: "primary" | "secondary" | "tertiary";
  buttonSize?: "small" | "base" | "large";
  successAction?: () => void;
  onClick?: () => boolean;
  onDismiss?: () => void;
  onOpen?: () => void;
  loading?: boolean;
}

interface ModalActionProps {
  buttonText?: string;
  onClick?: () => void;
}

function PurchaseFlowButton({
  isMobileBilled,
  recurlyPublicKey,
  addonSetIdentifier,
  fullWidth,
  buttonText,
  modalActionProps,
  buttonType,
  buttonSize,
  successAction,
  onClick,
  onDismiss,
  onOpen,
  loading,
}: PurchaseFlowButtonProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      {isMobileBilled ? (
        <div className="bot_launcher_button">
          <Button
            label={formatMessage(messages.intercomButton)}
            onClick={triggerIntercom}
            variation={"work"}
            type={buttonType || "primary"}
            size={buttonSize || "base"}
            loading={loading}
          />
        </div>
      ) : addonSetIdentifier ? (
        <AddonPurchaseModal
          recurlyPublicKey={recurlyPublicKey}
          addonSetIdentifier={addonSetIdentifier}
          successAction={successAction}
          onDismiss={onDismiss}
          modalActionProps={modalActionProps}
          buttonProps={{
            buttonSize: buttonSize || "base",
            buttonText: buttonText,
            buttonFullWidth: fullWidth,
            onClick: onClick,
            buttonType: buttonType || "primary",
            loading: loading,
          }}
          onOpen={onOpen}
        />
      ) : (
        <Glimmer shape="rectangle" size="largest" timing="base" width={109} />
      )}
    </>
  );

  function triggerIntercom() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "chat_with_us",
      source: "campaigns_landing_page",
    });
    const workflowEventId = "account_mobilebilled_marketingtools";
    Intercom.EXECUTE("trackEvent", workflowEventId);
  }
}

export function PurchaseFlowButtonWrapper({
  isMobileBilled,
  recurlyPublicKey,
  addonSetIdentifier,
  fullWidth,
  buttonText,
  modalActionProps,
  buttonType,
  buttonSize,
  successAction,
  onClick,
  onDismiss,
  onOpen,
  loading,
}: PurchaseFlowButtonProps) {
  return (
    <IntlProvider>
      <PurchaseFlowButton
        isMobileBilled={isMobileBilled}
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        fullWidth={fullWidth}
        buttonText={buttonText}
        modalActionProps={modalActionProps}
        buttonType={buttonType}
        buttonSize={buttonSize}
        successAction={successAction}
        onClick={onClick}
        onDismiss={onDismiss}
        onOpen={onOpen}
        loading={loading}
      />
    </IntlProvider>
  );
}
