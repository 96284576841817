import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import { defaultDateRangeOption } from "./components/ReportFilters/components/DateSelector/constants";
import type { ReportBasicDateFilterType } from "./types";

export const DEFAULT_PAGE_NUMBER_OPTIONS = [10, 25, 50];

export const INNER_CELL_WIDTH_LARGE = 535;
export const INNER_CELL_WIDTH_BASE = 300;
export const INNER_CELL_WIDTH_SMALL = 175;
export const INNER_CELL_WIDTH_SMALLER = 120;

export const DEFAULT_DATE_FILTER_VALUES: FilterType = {
  key: "date_range",
  value: {
    startDate: defaultDateRangeOption.start,
    endDate: defaultDateRangeOption.end,
  },
};

export const DEFAULT_DATE_FILTER_TYPE: ReportBasicDateFilterType = "createdOn";
/* TRACKING EVENTS */
/* Follow Amplitude Developer Guide: https://jobber.atlassian.net/wiki/spaces/BIZOPS/pages/187891959/Amplitude+Developer+Guide*/
