/* eslint-disable max-statements */
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import React, { useState } from "react";
import { Divider } from "@jobber/components/Divider";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { FormattedMessage, useIntl } from "react-intl";
import { showToast } from "@jobber/components/Toast";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import type {
  MutationErrors,
  PaymentOptionsCardQuery,
} from "~/utilities/API/graphql";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
  JobberPaymentsSettingsName,
} from "~/utilities/API/graphql";
import { CardBrand } from "components/CardBrand";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { withSplitClient } from "utilities/split";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { SettingsSwitch } from "../SettingsSwitch";
// eslint-disable-next-line import/no-internal-modules
import { DefaultACHModal } from "../modals/DefaultACHModal";
import { useUpdateDefaultPaymentPreference } from "../useUpdateDefaultPaymentPreference";
import { messages as ToastMessage } from "../messages";
import { useAchDefaultPaymentPreferenceSplit } from "../useAchDefaultPaymentPreferenceSplit";
import { useRequestLimitIncreaseButtonSplit } from "../useRequestLimitIncreaseButtonSplit";
import { ManageACHLimits } from "../ManageACHLimits";

interface PaymentOptionsCardProps {
  data: PaymentOptionsCardQuery;
  toggleSwitch: boolean;
  setUpdateDefaultPreference: React.Dispatch<
    React.SetStateAction<{
      preference: string;
      achEnabled: boolean;
    }>
  >;
}

function PaymentOptionsCard({
  data,
  toggleSwitch,
  setUpdateDefaultPreference,
}: PaymentOptionsCardProps) {
  const creditCardRates = data.jobberPaymentsFees.creditCard;
  const achFees = data.jobberPaymentsFees.ach;
  const achLimits = data.jobberPaymentsSettings.achLimits;
  const accountCurrency = data.account?.settings?.localization.currencySymbol;
  const achToggleEnabled = data.achEnabledSetting.enabled || false;
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { permissions } = useJobberPayments();
  const { formatMessage } = useIntl();
  const genericError = new Error("Could not update settings");
  const { handleUpdateSettings } = useUpdateDefaultPaymentPreference();
  const { hasAchDefaultPaymentPreferences } =
    useAchDefaultPaymentPreferenceSplit();
  const { hasRequestLimitIncreaseButton } =
    useRequestLimitIncreaseButtonSplit();
  const [shouldShowManageACHLimits, setShouldShowManageACHLimits] =
    useState(achToggleEnabled);

  return (
    <>
      {mutationErrorMessage && (
        <Banner type="error" onDismiss={onErrorBannerDismiss}>
          {mutationErrorMessage}
        </Banner>
      )}
      <Card header={messages.paymentOptionsCardTitle.defaultMessage}>
        <Content spacing="large">
          <Content spacing="small">
            <Heading level={4}>
              {messages.cardPaymentTitle.defaultMessage}
            </Heading>
            <div className={styles.cardPaymentsTitleRow}>
              <CardBrandRow />
              <Icon name="checkmark" />
            </div>
            <CreditCardRateContent />
          </Content>
          {permissions.canToggleAmexPayments && (
            <SettingsSwitch
              settingsName={
                JobberPaymentsSettingsName.AMERICAN_EXPRESS_PAYMENTS
              }
              title={""}
              description={messages.amexEnabledToggleTitle.defaultMessage}
              defaultEnabled={data.amexEnabledSetting.enabled || false}
              onError={handleMutationError}
            />
          )}
        </Content>
        {permissions.canToggleAchPayments && (
          <Content>
            <Divider />
            <Content spacing="small">
              <SettingsSwitch
                settingsName={JobberPaymentsSettingsName.ALLOW_ACH_PAYMENTS}
                title={messages.achPaymentsSectionTitle.defaultMessage}
                defaultEnabled={achToggleEnabled}
                onError={handleMutationError}
                onSuccessCallbackIsEnabled={onEnableAch}
                toggleSwitch={toggleSwitch}
              />
              <AchRateContent />
            </Content>
            {hasRequestLimitIncreaseButton && shouldShowManageACHLimits && (
              <>
                <Divider />
                <ManageACHLimits
                  achTier={achLimits?.tier}
                  canRequestAchLimitIncrease={
                    permissions.canRequestAchLimitIncrease
                  }
                />
              </>
            )}
          </Content>
        )}
      </Card>
      <DefaultACHModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(!isModalOpen)}
        onError={handleMutationError}
        setUpdateDefaultPreference={setUpdateDefaultPreference}
      />
    </>
  );

  function CardBrandRow() {
    const cardBrands = ["visa", "mastercard", "amex", "applePay", "googlePay"];
    return (
      <div className={styles.cardBrandRow}>
        {cardBrands.map(brand => (
          <CardBrand key={brand} brand={brand} size="small" />
        ))}
      </div>
    );
  }

  function CreditCardRateContent() {
    const [t] = useTranslation();
    const centCurrency = t("centCurrency");
    return (
      <div className={styles.infoRow}>
        <Text>
          <Emphasis variation="bold">
            {messages.feeRateTag.defaultMessage}
          </Emphasis>
          <FormattedMessage
            {...messages.creditCardFeeRateDetail}
            values={{
              percent: creditCardRates?.percent,
              centsPerCharge: creditCardRates?.centsPerCharge,
              centCurrency,
            }}
          />
        </Text>
      </div>
    );
  }

  function AchRateContent() {
    return (
      <Content spacing="large">
        <Content spacing="small">
          <div className={styles.infoRow}>
            <Text>
              <Emphasis variation="bold">
                {messages.feeRateTag.defaultMessage}
              </Emphasis>
              <FormattedMessage
                {...messages.achFeeRateDetail}
                values={{
                  percent: achFees?.percent,
                }}
              />
            </Text>
          </div>
          <div className={styles.infoRow}>
            <Text>
              <Emphasis variation="bold">
                {messages.AchLimitsTag.defaultMessage}
              </Emphasis>
              {achRatesText()}
            </Text>
            <span className="u-marginLeftSmallest">
              <tooltip-icon>
                {formatMessage(messages.achPaymentsLimitsDisclaimer)}
              </tooltip-icon>
            </span>
          </div>
        </Content>
      </Content>
    );
  }

  function achRatesText() {
    let baseString = `${accountCurrency}${formatAchRates(
      (achLimits?.singleTransactionLimit || 0) / 100,
    )} / transaction`;

    if (achLimits?.twentyFourHourTransactionLimit) {
      baseString = baseString.concat(
        `, ${accountCurrency}${formatAchRates(
          (achLimits.twentyFourHourTransactionLimit || 0) / 100,
        )} / day`,
      );
    }

    if (achLimits?.monthTransactionLimit) {
      baseString = baseString.concat(
        `, ${accountCurrency}${formatAchRates(
          (achLimits.monthTransactionLimit || 0) / 100,
        )} / month`,
      );
    }

    return baseString;
  }

  function formatAchRates(number: number) {
    return Intl.NumberFormat("en-US", {
      notation: "standard",
    }).format(number);
  }

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }

  function onErrorBannerDismiss() {
    setMutationErrorMessage("");
  }

  async function onEnableAch(enabled: boolean) {
    if (!hasAchDefaultPaymentPreferences) {
      return;
    }

    if (enabled) {
      setShouldShowManageACHLimits(true);
      setIsModalOpen(true);
    } else {
      setShouldShowManageACHLimits(false);
      try {
        const { data: preferenceData } = await handleUpdateSettings({
          objectType: InvoiceOrQuote.INVOICE_AND_QUOTE,
          preference: JobberPaymentsDefaultPaymentPreference.CREDIT_CARD,
        });
        const errors = preferenceData
          ?.jobberPaymentsUpdateDefaultPaymentPreference
          ?.userErrors as MutationErrors[];

        if (!preferenceData) {
          handleMutationError(genericError);
        }
        if (
          errors &&
          errors.length > 0 &&
          preferenceData?.jobberPaymentsUpdateDefaultPaymentPreference
            .success === false
        ) {
          handleMutationError(new Error(errors[0].message) || genericError);
        } else {
          setUpdateDefaultPreference({
            preference: JobberPaymentsDefaultPaymentPreference.CREDIT_CARD,
            achEnabled: false,
          });
          showToast({
            message: formatMessage(
              ToastMessage.paymentOptionChangeToastMessage,
            ),
          });
        }
      } catch (error) {
        handleMutationError(error as Error);
      }
    }
  }
}

const SplitWrappedComponent = withSplitClient(PaymentOptionsCard);
export { SplitWrappedComponent as PaymentOptionsCard };
