import { defineMessages } from "react-intl";

export const wrapperMessages = defineMessages({
  bannerMessage: {
    id: "ukKycRegistration.wrapper.bannerMessage",
    defaultMessage:
      "You will lose access to messages if verification is not complete by September 30",
    description: "Banner warning message for UK KYC registration",
  },
});
