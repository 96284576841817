/* eslint-disable max-statements */
import React, { useCallback, useState } from "react";
import { Content } from "@jobber/components/Content";
import { showToast } from "@jobber/components/Toast";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { AddPaymentMethodForm } from "~/components/AddPaymentMethodForm/AddPaymentMethodForm";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import { PaymentMethodSetupIntentType } from "~/utilities/API/graphql";
import { SplitNames, useSplit } from "utilities/split";
import type {
  AccountType,
  BankAccountData,
  BankAccountFormState,
} from "jobber/AddPaymentMethod/AddBankAccount/types";
import { defaultBankState } from "jobber/AddPaymentMethod/AddBankAccount/types";
import { AddBankAccount } from "./AddBankAccount";
import { useAddBankAccount } from "./useAddBankAccount";
import { messages } from "./messages";
import { useAddPaymentMethod } from "./useAddPaymentMethod";
import { PaymentMethodChips } from "./AddPaymentMethodChips";

export type AddPaymentMethodState = "Credit card" | "Bank account";

export interface AddPaymentMethodBankAccountData extends BankAccountData {
  authorizedByCustomer: boolean;
}

export const defaultAddPaymentMethodBankAccountState: AddPaymentMethodBankAccountData =
  {
    ...defaultBankState,
    authorizedByCustomer: false,
  };

export interface AddPaymentMethodProps {
  clientId: string;
  clientName: string | undefined;
  billingAddress: BillingAddress | undefined;
  countryCodesToNameMap: Record<string, string>;
  testCompleteOverride?: boolean;
  modalOpen: boolean;
  toggleModal: () => void;
  onAddPaymentMethodSuccess: () => void;
}

export function AddPaymentMethodComponent({
  clientId,
  clientName = "",
  billingAddress,
  countryCodesToNameMap,
  testCompleteOverride,
  modalOpen,
  toggleModal,
  onAddPaymentMethodSuccess,
}: AddPaymentMethodProps) {
  const { formatMessage } = useIntl();
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.GmpAchAutopay],
  });
  const canAddBankAccounts = getTreatmentValue(SplitNames.GmpAchAutopay);

  const { handleCreateBankAccount } = useAddBankAccount(clientId);
  const { handleCreatePaymentMethod } = useAddPaymentMethod();

  const [selectedPaymentMethod, setSelected] =
    useState<AddPaymentMethodState>("Credit card");

  const [cardErrorMessage, setCardErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [bannerErrorMessage, setBannerErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [billingAddressForm, setBillingAddress] = useState<
    BillingAddress | undefined
  >(billingAddress);
  const [nameOnCard, setNameOnCard] = useState(clientName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreditCardFormComplete, setIsCreditCardFormComplete] =
    useState(false);

  const [bankData, setBankData] = useState<AddPaymentMethodBankAccountData>(
    defaultAddPaymentMethodBankAccountState,
  );
  const [isBankAccountFormComplete, setIsBankAccountFormComplete] =
    useState(false);

  function onCardElementChange(event: StripeCardElementChangeEvent) {
    setCardErrorMessage(undefined);
    if (event.error) {
      setCardErrorMessage(event.error.message);
    }

    if (event.complete) {
      setIsCreditCardFormComplete(true);
    }
  }

  const handleBankAccountFormChange = useCallback(
    (formState: BankAccountFormState) => {
      setBankData({
        routingNumber: formState.routingNumber,
        accountNumber: formState.accountNumber,
        accountType: formState.accountType as AccountType,
        confirmAccountNumber: formState.confirmAccountNumber,
        accountOwnerName: formState.accountOwnerName,
        authorizedByCustomer: formState.authorizedByCustomer || false,
      });
      setIsBankAccountFormComplete(formState.isValid);
    },
    [setBankData, setIsBankAccountFormComplete],
  );

  const handleOnSave = async () => {
    setCardErrorMessage(undefined);
    setBannerErrorMessage(undefined);
    setIsSubmitting(true);

    if (selectedPaymentMethod === "Bank account") {
      await handleBankAccountSave();
    } else if (selectedPaymentMethod === "Credit card") {
      await handleCreditCardSave();
    }

    setIsSubmitting(false);
  };

  const handleBankAccountSave = async () => {
    if (
      !bankData.authorizedByCustomer ||
      (!isBankAccountFormComplete && !testCompleteOverride)
    ) {
      setBannerErrorMessage(formatMessage(messages.incompleteBankFields));

      return;
    }

    const result = await handleCreateBankAccount({
      accountType: bankData.accountType,
      routingNumber: bankData.routingNumber,
      accountNumber: bankData.accountNumber,
      accountOwnerName: bankData.accountOwnerName,
      consentGiven: bankData.authorizedByCustomer,
    });

    if (result.error) {
      handleError(result.error);
      setIsSubmitting(false);
    } else if (result.success) {
      await handleSuccess(messages.successAddedBankAccount);

      setBankData(defaultAddPaymentMethodBankAccountState);
    } else {
      handleError(formatMessage(messages.errorAddingBankAccount));
    }
  };

  const handleCreditCardSave = async () => {
    if (
      (!isCreditCardFormComplete && !testCompleteOverride) ||
      !billingAddressForm ||
      nameOnCard == ""
    ) {
      if (!isCreditCardFormComplete && !testCompleteOverride) {
        setCardErrorMessage(formatMessage(messages.incompleteCardFields));
      } else {
        handleError(formatMessage(messages.errorNameOnCardAndBillingAddress));
      }
      return;
    }

    try {
      const uuid = await handleCreatePaymentMethod(
        PaymentMethodSetupIntentType.CREDIT_CARD,
        clientId,
        nameOnCard,
        billingAddressForm,
      );

      if (uuid) {
        await handleSuccess(messages.successAddedCreditCard);
      }
    } catch (createError) {
      handleError(createError.message);
    }
  };

  const handleError = (message: string) => {
    setBannerErrorMessage(message);
    setIsSubmitting(false);
  };

  const handleSuccess = async (message: MessageDescriptor) => {
    showToast({
      message: formatMessage(message),
      variation: "success",
    });
    toggleModal();

    await onAddPaymentMethodSuccess();
  };

  function handleCloseModal() {
    setBankData(defaultAddPaymentMethodBankAccountState);
    setCardErrorMessage(undefined);
    setBannerErrorMessage(undefined);
    toggleModal();
  }

  return (
    <Modal
      title={formatMessage(messages.addPaymentMethodTitle)}
      open={modalOpen}
      primaryAction={{
        label: formatMessage(messages.saveButton),
        onClick: handleOnSave,
        loading: isSubmitting,
        ariaLabel: "Save",
      }}
      secondaryAction={{
        label: formatMessage(messages.cancelButton),
        onClick: handleCloseModal,
        ariaLabel: "Cancel",
      }}
      onRequestClose={handleCloseModal}
    >
      <Content>
        {canAddBankAccounts && (
          <PaymentMethodChips
            selectedPaymentMethod={selectedPaymentMethod}
            onSelectedPaymentMethodChange={(value: AddPaymentMethodState) => {
              if (!value) return;

              setSelected(value);
            }}
          />
        )}
        {bannerErrorMessage && (
          <Banner type="error" dismissible={false}>
            {bannerErrorMessage}
          </Banner>
        )}
        {selectedPaymentMethod === "Bank account" && canAddBankAccounts && (
          <AddBankAccount
            bankData={bankData}
            onBankAccountFormChange={handleBankAccountFormChange}
          />
        )}
        {(selectedPaymentMethod === "Credit card" || !canAddBankAccounts) && (
          <AddPaymentMethodForm
            nameOnCard={nameOnCard}
            isLoading={false}
            billingAddress={billingAddressForm}
            hideSetDefaultCheckbox={true}
            showSecurityDisclosure={false}
            cardErrorMessage={cardErrorMessage}
            countryCodesToNameMap={countryCodesToNameMap}
            onCardElementChange={onCardElementChange}
            onNameOnCardChange={(name: string) => setNameOnCard(name)}
            onBillingAddressChange={(address: BillingAddress) =>
              setBillingAddress(address)
            }
          />
        )}
      </Content>
    </Modal>
  );
}
