import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bannerDescription: {
    id: "upgradePage.promotionBanner.description",
    defaultMessage: "Upgrade now and save! Only for a limited time!",
    description: "Promotion banner description",
  },
  bannerImageAltText: {
    id: "upgradePage.promotionBanner.imageAltText",
    defaultMessage:
      "An image of a mobile device and a laptop screen displaying the Jobber app.",
    description: "Promotion banner image alt text",
  },
});
