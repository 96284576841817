import { Banner } from "@jobber/components/Banner";
import React from "react";
import { useQuery } from "@apollo/client";
import { parseISO, subMinutes } from "date-fns";
import { Spinner } from "@jobber/components/Spinner";
import type { AiAssistantConversationsQuery } from "~/utilities/API/graphql";
import { AI_ASSISTANT_LAST_CONVERSATION } from "./JobberAssistant.graphql";
import { JobberAssistantLoader } from "./JobberAssistantLoader";
import styles from "./JobberAssistantLoader.module.css";

const MAX_CONVERSATION_AGE_MINUTES = 180;

interface JobberAssistantConversationLoaderProps {
  closeChatSidebar: () => void;
  shouldLoadConversationHistory: boolean;
}

export function JobberAssistantConversationLoader({
  shouldLoadConversationHistory,
  closeChatSidebar,
}: JobberAssistantConversationLoaderProps) {
  const { loading, error, data } = useQuery<AiAssistantConversationsQuery>(
    AI_ASSISTANT_LAST_CONVERSATION,
    {
      fetchPolicy: "no-cache",
      skip: !shouldLoadConversationHistory,
    },
  );

  const conversationId = getConversationId(data);
  const messages = data?.aiAssistantConversations?.nodes?.[0]?.messages?.nodes
    ?.slice()
    .reverse();

  return (
    <>
      {loading ? (
        <div className={styles.loadingWrapper} data-testid="loading-spinner">
          <Spinner />
        </div>
      ) : error ? (
        <Banner type="error" dismissible={false}>
          {error.message}
        </Banner>
      ) : (
        <JobberAssistantLoader
          closeChatSidebar={closeChatSidebar}
          conversationIdToResume={conversationId}
          messages={conversationId ? messages : undefined}
          showInitialMessage={shouldLoadConversationHistory}
        />
      )}
    </>
  );
}

function getConversationId(data?: AiAssistantConversationsQuery) {
  const createdAt =
    data?.aiAssistantConversations?.nodes?.[0]?.messages?.nodes?.[0]?.createdAt;
  if (!createdAt) {
    return;
  }

  const conversationStart = parseISO(createdAt);
  const oldestStartToResume = subMinutes(
    Date.now(),
    MAX_CONVERSATION_AGE_MINUTES,
  );
  if (conversationStart < oldestStartToResume) {
    return;
  }

  return data.aiAssistantConversations.nodes[0].conversationId;
}
