import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "@jobber/design";
import { useIntl } from "react-intl";
import type { JobCostRecord } from "jobber/workOrders/components/JobCost/components/JobCostHeader/JobCostHeader";
import { capitalizeFirstLetter } from "utilities/capitalizeFirstLetter";
import { useViewport } from "jobber/hooks/useViewport";
import { toPercentage } from "jobber/workOrders/components/JobCost/components/JobCostHeader/utils";
import { messages as jobCostHeaderMessages } from "jobber/workOrders/components/JobCost/components/JobCostHeader/messages";
import { messages as jobCostMessages } from "jobber/workOrders/components/JobCost/messages";
import styles from "./JobProfitPieGraph.module.css";
import { JobProfitTooltip } from "../JobProfitTooltip";

interface JobProfitPieGraphProps {
  jobCosting: JobCostRecord;
}

export function JobProfitPieGraph({
  jobCosting,
}: JobProfitPieGraphProps): JSX.Element {
  const { innerWidth } = useViewport();
  const { formatMessage } = useIntl();

  const { profitAmount, expenseCost, labourCost, lineItemCost, totalRevenue } =
    jobCosting;

  if (
    totalRevenue === 0 ||
    // DO NOT COPY THIS! This is a custom breakpoint for Profit Bar only
    innerWidth <= 1180
  ) {
    return <></>;
  }

  const pieData = [
    {
      id: "profit",
      label: formatMessage(jobCostMessages.profit),
      value: toPercentage(profitAmount, totalRevenue),
      color: tokens["color-green"],
    },
    {
      id: "expenses",
      label: formatMessage(jobCostMessages.expenses),
      value: toPercentage(expenseCost, totalRevenue),
      color: tokens["color-lightBlue--light"],
    },
    {
      id: "labour",
      label: capitalizeFirstLetter(formatMessage(jobCostMessages.labour)),
      value: toPercentage(labourCost, totalRevenue),
      color: tokens["color-lightBlue"],
    },
    {
      id: "lineItemCost",
      label: formatMessage(jobCostHeaderMessages.lineItemCost),
      value: toPercentage(lineItemCost, totalRevenue),
      color: tokens["color-lightBlue--dark"],
    },
  ];

  const nonZeroData = pieData.filter(i => i.value > 0);

  return (
    <div className={styles.jobProfitPieGraph} data-testid={"JobProfitPieGraph"}>
      <ResponsivePie
        data={nonZeroData}
        margin={{
          top: tokens["space-smaller"],
          right: tokens["space-larger"],
          bottom: tokens["space-larger"],
          left: tokens["space-smaller"],
        }}
        innerRadius={0.4}
        padAngle={5}
        activeOuterRadiusOffset={3}
        colors={nonZeroData.map(i => i.color)}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        tooltip={tooltipData => (
          <JobProfitTooltip tooltipData={tooltipData?.datum} />
        )}
      />
    </div>
  );
}
