import { defineMessages } from "react-intl";

export const messages = defineMessages({
  renewal: {
    id: "subscriptionAddons.activeAddonDetails.renewsPrefix",
    defaultMessage: "Renews {renewalDate}",
    description: "Renewal date",
  },
  futureBilled: {
    id: "subscriptionAddons.activeAddonDetails.futureBilledPrefix",
    defaultMessage: "Billed {futureStartBillingOnDate}",
    description: "Future billed date",
  },
});
