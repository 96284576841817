import { Text } from "@jobber/components/Text";
import React from "react";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import styles from "./TrialPromptCard.module.css";
import { messages } from "./messages";

interface TrialPromptCardProps {
  trialTitle: string;
  trialDescription: string;
  trialDescriptionSecondary?: string;
  ctaLabel: string;
  ctaCallBack: () => void;
  imgSource: string;
  imgAlt: string;
  showNewLabel?: boolean;
}

export function TrialPromptCard({
  trialTitle,
  trialDescription,
  trialDescriptionSecondary,
  ctaLabel,
  ctaCallBack,
  imgSource,
  imgAlt,
  showNewLabel = false,
}: TrialPromptCardProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.promptCard} data-testid="trialPromptCard">
      <div className={styles.promptCardTextContainer}>
        {showNewLabel && (
          <div className={styles.newLabel}>
            <Text variation="subdued">
              <Emphasis variation="highlight">
                <Emphasis variation="bold">
                  {formatMessage(messages.newLabel)}
                </Emphasis>
              </Emphasis>
            </Text>
          </div>
        )}
        <div className={styles.promptCardText}>
          <h2 className={styles.textHeader}>{trialTitle}</h2>
          <Text size="large">{trialDescription}</Text>
          {trialDescriptionSecondary && (
            <div className={styles.promptCardTextSecondary}>
              <Text size="base" variation="subdued">
                {trialDescriptionSecondary}
              </Text>
            </div>
          )}
        </div>
        <div className={styles.promptCardTextMobile}>
          <h4 className={styles.textHeader}>{trialTitle}</h4>
          <Text size="base">{trialDescription}</Text>
          {trialDescriptionSecondary && (
            <div className={styles.promptCardTextSecondary}>
              <Text size="base" variation="subdued">
                {trialDescriptionSecondary}
              </Text>
            </div>
          )}
        </div>
        <div className={styles.promptCardButton}>
          <Button
            label={ctaLabel}
            onClick={ctaCallBack}
            variation="learning"
            type="secondary"
          ></Button>
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <img src={imgSource} alt={imgAlt} />
      </div>
    </div>
  );
}
