import { defineMessages } from "react-intl";

export const messages = defineMessages({
  campaignsDescription: {
    id: "subscriptionAddons.inactiveAddonDetails.campaignsDescription",
    defaultMessage:
      "Re-engage your customers with email campaigns that promote your services and strengthen relationships",
    description: "Campaigns addon description",
  },
  reviewsDescription: {
    id: "subscriptionAddons.inactiveAddonDetails.reviewsDescription",
    defaultMessage:
      "Build your online reputation with automated review requests to your preferred customers",
    description: "Reviews addon description",
  },
  recommended: {
    id: "subscriptionAddons.inactiveAddonDetails.recommended",
    defaultMessage: "Recommended",
    description: "Recommended label text",
  },
});
