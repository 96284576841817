import React from "react";
import { Text } from "@jobber/components/Text";

export function LsaDisclaimer() {
  return (
    <Text variation="subdued">
      Service providers must have a Local Services Ads account with Google.
      Local Services Ads are only available in the US for select industries.
      Pricing for leads and bookings is set by Google and managed in your Local
      Services Ad account. No additional Jobber fees apply.{" "}
      <a
        href="https://ads.google.com/local-services-ads/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        Learn more.
      </a>
    </Text>
  );
}
