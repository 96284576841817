import React, { useContext, useState } from "react";
import { parse } from "date-fns";
import { type FormatDateOptions, useIntl } from "react-intl";
import {
  Banner,
  Button,
  ConfirmationModal,
  Glimmer,
  Heading,
  Text,
} from "@jobber/components";
import { useMutation } from "@apollo/client";
import type {
  GoogleBusinessAccountDisconnectMutation,
  NotificationSchedule,
} from "~/utilities/API/graphql";
import { useFollowUpMessageSettingsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useFollowUpMessageSettingsQuery";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";
import { GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION } from "../ReviewsSettings.graphql";

export function ManageSettings() {
  const { formatMessage, formatTime } = useIntl();
  const {
    loading: queryLoading,
    error: queryError,
    schedules,
  } = useFollowUpMessageSettingsQuery();
  const { drawerActions } = useContext(ReviewsSettingsDrawerContext);

  const [disconnectAccount, { loading: disconnectLoading }] =
    useMutation<GoogleBusinessAccountDisconnectMutation>(
      GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION,
    );
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  if (queryLoading) {
    return (
      <div className={styles.container}>
        <LoadingLayout />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {displayBanner && (
        <Banner type={"error"} dismissible={false}>
          {formatMessage(messages.generalError)}
        </Banner>
      )}

      {queryError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.scheduledTimeOffsetError)}
        </Banner>
      )}

      <Heading level={3}>{formatMessage(messages.template)}</Heading>
      <div className={styles.section}>
        <Heading level={4}>{formatMessage(messages.messageTitle)}</Heading>
        <EditButton
          label={formatMessage(messages.messageDescription)}
          ariaLabel={formatMessage(messages.messageSettingsAriaLabel)}
          onClick={() => {
            drawerActions?.goTo(DrawerView.MessageSettings);
          }}
        />
      </div>
      <div className={styles.section}>
        <Heading level={4}>{formatMessage(messages.followUpsTitle)}</Heading>
        {queryError ||
        schedules?.[0] === undefined ||
        schedules?.[1] === undefined ? (
          <Text variation={"error"}>
            {formatMessage(messages.followUpError)}
          </Text>
        ) : (
          <ul>
            <li>
              <EditButton
                ariaLabel={formatMessage(
                  messages.firstFollowUpMessageSettingsAriaLabel,
                )}
                label={formatMessage(
                  messages.initialFollowUp,
                  buildMessageParams(schedules[0], formatTime),
                )}
                onClick={() => {
                  drawerActions?.goTo(DrawerView.FollowUpMessageSettings1);
                }}
              />
            </li>
            <li>
              <EditButton
                ariaLabel={formatMessage(
                  messages.secondFollowUpMessageSettingsAriaLabel,
                )}
                label={formatMessage(
                  messages.secondaryFollowUp,
                  buildMessageParams(schedules[1], formatTime),
                )}
                onClick={() => {
                  drawerActions?.goTo(DrawerView.FollowUpMessageSettings2);
                }}
              />
            </li>
          </ul>
        )}
      </div>
      <div>
        <Heading level={3}>{formatMessage(messages.accounts)}</Heading>
        <div className={styles.inlineSection}>
          <Text>{formatMessage(messages.accountsDescription)}</Text>
          <Button
            label={formatMessage(messages.disconnect)}
            type="secondary"
            variation="destructive"
            size="small"
            loading={disconnectLoading}
            onClick={() => setConfirmationModalOpen(true)}
          />
          <ConfirmationModal
            title={formatMessage(messages.confirmDisconnectTitle)}
            message={formatMessage(messages.confirmDisconnectDescription)}
            variation="destructive"
            size="small"
            open={confirmationModalOpen}
            confirmLabel={formatMessage(messages.disconnect)}
            onConfirm={() =>
              disconnectAccount()
                .then(() => {
                  window.location.href = "/reviews/";
                })
                .catch(() => {
                  setDisplayBanner(true);
                })
            }
            onRequestClose={() => setConfirmationModalOpen(false)}
          />
        </div>
      </div>
    </div>
  );
}

function LoadingLayout() {
  return (
    <>
      <div className={styles.section}>
        <Glimmer size={"largest"} />
      </div>
      <div className={styles.section}>
        <Glimmer size={"largest"} />
      </div>
      <div className={styles.inlineSection}>
        <Glimmer size={"largest"} />
      </div>
    </>
  );
}

function buildMessageParams(
  node: Partial<NotificationSchedule>,
  formatTime: (
    value: number | Date | string,
    opts?: FormatDateOptions,
  ) => string,
) {
  let nodeAt = "";

  if (node.at) {
    const parsedTime = parse(node.at, "HH:mm:ss.SSSSSSSSS", new Date());
    nodeAt = formatTime(parsedTime, {
      hourCycle: window.is_24_hour_format ? "h23" : "h12",
    });
  }

  return {
    offsetValue: node.offset?.value || 0,
    offsetUnit:
      node.offset?.value === 1
        ? node.offset?.unit.toLowerCase()
        : `${node.offset?.unit.toLowerCase()}s`,
    at: nodeAt,
  };
}

function EditButton({
  label,
  ariaLabel,
  onClick,
}: {
  label: string;
  ariaLabel: string;
  onClick: () => void;
}) {
  return (
    <div className={[styles.inlineSection, styles.actionItem].join(" ")}>
      <Button
        type="tertiary"
        variation="subtle"
        ariaLabel={ariaLabel}
        label={label}
        icon="arrowRight"
        iconOnRight={true}
        onClick={onClick}
      />
    </div>
  );
}
