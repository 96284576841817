import React, { type ReactNode } from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { AddonPurchaseModal } from "jobber/billing/features/AddonPurchaseModal";
import { getDiscountHeader } from "jobber/billing/features/Checkout/utils";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { BillingCycleName } from "~/utilities/API/graphql";
import { ActiveAddonDetails } from "./components/ActiveAddonDetails";
import { InactiveAddonDetails } from "./components/InactiveAddonDetails";
import styles from "./SubscriptionAddonCard.module.css";
import { RemoveAddonModal } from "./components/RemoveAddonModal";

interface SubscriptionAddonCardProps {
  subscriptionAddon: SubscriptionAddon;
  recurlyPublicKey?: string;
  inactiveCardAction?: ReactNode;
}

export function SubscriptionAddonCard(props: SubscriptionAddonCardProps) {
  const { recurlyPublicKey, subscriptionAddon, inactiveCardAction } = props;
  const { isActive, name, currentBillingCycle, identifier } = subscriptionAddon;

  const { formatMessage } = useIntl();

  const discountHeader = getDiscountHeader(
    formatMessage,
    BillingCycleName.MONTHLY,
    subscriptionAddon.discountGroup?.monthlyAddonDiscount?.discountType,
    subscriptionAddon.discountGroup?.monthlyAddonDiscount?.discountAmount,
    subscriptionAddon.discountGroup?.monthlyAddonDiscount
      ?.remainingDiscountedFullMonths,
  );

  return (
    <Card
      header={
        <CardHeader title={name} discountHeader={discountHeader}>
          {isActive ? renderActiveCardAction() : renderInactiveCardAction()}
        </CardHeader>
      }
    >
      <Content>
        <div className={styles.cardBody}>
          {isActive ? (
            <ActiveAddonDetails subscriptionAddon={subscriptionAddon} />
          ) : (
            <InactiveAddonDetails subscriptionAddon={subscriptionAddon} />
          )}
        </div>
      </Content>
    </Card>
  );

  function renderActiveCardAction() {
    if (!currentBillingCycle) {
      return null;
    }

    return (
      <RemoveAddonModal
        addonIdentifier={identifier}
        addonName={name}
        addonCode={currentBillingCycle.addonCode}
      />
    );
  }

  function renderInactiveCardAction() {
    if (inactiveCardAction) {
      return inactiveCardAction;
    }

    if (!recurlyPublicKey) {
      return null;
    }

    return (
      <AddonPurchaseModal
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={identifier}
      />
    );
  }
}

function CardHeader({
  title,
  children,
  discountHeader,
}: {
  title: string;
  children: ReactNode;
  discountHeader: string | undefined;
}) {
  return (
    <div className={styles.cardHeader}>
      <div className={styles.headerAndDiscountContainer}>
        <Heading level={4}>{title}</Heading>
        <Emphasis variation={"highlight"}>{discountHeader}</Emphasis>
      </div>
      {children}
    </div>
  );
}
