import { Button } from "@jobber/components/Button";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Form } from "@jobber/components/Form";
import { InputValidation } from "@jobber/components/InputValidation";
import { Routes, type SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import {
  generateLastSubmittedStepQandA,
  safelyExtractAnswer,
} from "jobber/setupWizard/utils";
import { BusinessPriorityCards } from "./BusinessPriorityCards";
import styles from "../../SetupWizardForms.module.css";
import { messages } from "../messages";

export function TopPriorityPageForm({
  navigation,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, topPriorityOptions, saving } =
    useContext(SetupWizardContext);
  const [topPriority, setTopPriority] = useState<string>(() => {
    return safelyExtractAnswer(
      "business_goals",
      wizardData.questionsAndAnswers,
    );
  });
  const [validations, setValidations] = useState<boolean>(false);
  const shouldShowValidations = !topPriority;

  const handleSubmit = async () => {
    if (shouldShowValidations) {
      return setValidations(true);
    }
    const dataToSend = {
      questionsAndAnswers: [
        {
          question: "business_goals",
          answer: topPriority,
        },

        generateLastSubmittedStepQandA(Routes.topPriority),
      ],
    };
    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <BusinessPriorityCards
        topPriorityArray={topPriorityOptions}
        handleSelect={value => {
          setTopPriority(value);
          updateWizardData(
            {
              questionsAndAnswers: [
                {
                  question: "business_goals",
                  answer: value,
                },
              ],
            },
            false,
            undefined,
            true,
          );
          shouldShowValidations && setValidations(true);
        }}
        defaultValue={topPriority}
      />
      {validations && shouldShowValidations && (
        <InputValidation message={formatMessage(messages.topPriorityError)} />
      )}
      <div className={styles.button}>
        <Button
          id={"topPriorityPageSubmit"}
          fullWidth={onMobileWeb}
          label={navigation.nextCtaCopy}
          submit={true}
          loading={saving}
          size="large"
        />
      </div>
    </Form>
  );
}
