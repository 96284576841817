import React from "react";
import { useIntl } from "react-intl";
import { MetricsCard } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/MetricsCard/MetricsCard";
import type { CommsCampaignQuery } from "~/utilities/API/graphql";
import { CampaignEmailFilterFields } from "~/utilities/API/graphql";
import type { FilterActions } from "jobber/campaigns/views/CampaignReportingDetailsPage/hooks/useCampaignsReportFilters";
import { messages } from "./messages";
import styles from "./FilterCards.module.css";

export function FilterCards({
  commsCampaign,
  filterActions,
}: {
  commsCampaign: CommsCampaignQuery["commsCampaign"];
  filterActions: FilterActions;
}) {
  const { formatMessage } = useIntl();
  const { setFilterType } = filterActions;
  const loading = !commsCampaign;

  const unsubscribeRate =
    commsCampaign?.totalUnsubscribed && commsCampaign?.totalSent
      ? (commsCampaign?.totalUnsubscribed / commsCampaign?.totalSent) * 100
      : 0;

  const METRIC_CARDS: MetricsCard[] = [
    {
      heading: formatMessage(messages.openRateTitle),
      value: commsCampaign?.openRate || 0,
      toolTipText: formatMessage(messages.openRateToolTip),
      subValue: commsCampaign?.totalOpened || 0,
      isPercent: true,
      onCardClick: () => {
        setFilterType(CampaignEmailFilterFields.OPENED);
      },
    },
    {
      heading: formatMessage(messages.clickRateTitle),
      value: commsCampaign?.clickRate || 0,
      toolTipText: formatMessage(messages.clickRateToolTip),
      subValue: commsCampaign?.totalClicked || 0,
      isPercent: true,
      onCardClick: () => {
        setFilterType(CampaignEmailFilterFields.CLICKED);
      },
    },
    {
      heading: formatMessage(messages.revenueTitle),
      value: commsCampaign?.revenue?.value || 0,
      toolTipText: formatMessage(messages.revenueToolTip),
      subValue: commsCampaign?.revenue?.count || 0,
      isPercent: false,
      onCardClick: () => {
        setFilterType(CampaignEmailFilterFields.JOBS);
      },
    },
    {
      heading: formatMessage(messages.unsubscribeTitle),
      value: unsubscribeRate,
      toolTipText: formatMessage(messages.unsubscribeToolTip),
      subValue: commsCampaign?.totalUnsubscribed || 0,
      isPercent: true,
      onCardClick: () => {
        setFilterType(CampaignEmailFilterFields.UNSUBSCRIBED);
      },
    },
  ];

  return (
    <div className={styles.cardsContainer}>
      {METRIC_CARDS.map((card: MetricsCard, index) => {
        return (
          <div className={styles.card} key={index}>
            <MetricsCard
              heading={card.heading}
              value={card.value}
              subValue={card.subValue}
              loading={loading}
              toolTipText={card.toolTipText}
              isPercent={card.isPercent}
              onCardClick={card.onCardClick}
            />
          </div>
        );
      })}
    </div>
  );
}
