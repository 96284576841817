import { defineMessages } from "react-intl";

export const messages = defineMessages({
  primaryDescription: {
    id: "mobileBilledDisclaimer.primaryDescription",
    defaultMessage:
      "Your subscription is managed through your {billingPlatform}. ",
    description: "The primary description for the mobile billed disclaimer",
  },
  secondaryDescription: {
    id: "mobileBilledDisclaimer.secondaryDescription",
    defaultMessage: `For details on managing your subscription, see [Account and Billing]({link}) in your settings.`,
    description: "The secondary description for the mobile billed disclaimer",
  },
});
