import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { useIntl } from "react-intl";
import { Markdown } from "@jobber/components/Markdown";
import { Intercom } from "utilities/chat";
import styles from "./FAQ.module.css";
import { QuestionDropdown } from "./QuestionDropdown";
import { messages } from "./messages";

export function FAQ() {
  const { formatMessage } = useIntl();
  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();

  const faq1 = () => {
    return {
      question: "Are there any commitments or contracts?",
      answer: (
        <Content>
          <Text>{formatMessage(messages.faq1Description1)}</Text>

          <Text>{formatMessage(messages.faq1Description2)}</Text>

          <Text>{formatMessage(messages.faq1Description3)}</Text>

          <Markdown
            content={formatMessage(messages.faq1Description4, {
              linkUrl: "https://getjobber.com/terms-of-service/",
            })}
          />
        </Content>
      ),
    };
  };

  const faq2 = () => {
    return {
      question: formatMessage(messages.faq2Question),
      answer: (
        <Content>
          <Text>{formatMessage(messages.faq2Description)}</Text>
        </Content>
      ),
    };
  };

  const faq3 = () => {
    return {
      question: formatMessage(messages.faq3Question),
      answer: (
        <Content>
          <Markdown
            content={formatMessage(messages.faq3Description, {
              linkUrl: "/accounts/billing_info/pricing_downgrade/",
            })}
          />
        </Content>
      ),
    };
  };

  const faq4 = () => {
    function openChatWidget() {
      Intercom.EXECUTE("showNewMessage");
    }
    return {
      question: formatMessage(messages.faq4Question),
      answer: (
        <Content>
          <Text>
            {formatMessage(messages.faq4Description1)}
            {width < Breakpoints.base ? (
              <a data-testid="phoneNumberMobile" href="tel:+18334600177">
                {formatMessage(messages.faq4PhoneNumber)}
              </a>
            ) : (
              <span
                data-testid="phoneNumberDesktop"
                style={{ fontWeight: 600 }}
              >
                {formatMessage(messages.faq4PhoneNumber)}
              </span>
            )}
            {formatMessage(messages.faq4Description2)}
            <button onClick={openChatWidget}>
              <span style={{ color: "#7DB00E", fontWeight: 600 }}>
                {formatMessage(messages.faq4chatLink)}
              </span>
            </button>
            {formatMessage(messages.faq4Description3)}
          </Text>
        </Content>
      ),
    };
  };

  const faq5 = () => {
    return {
      question: formatMessage(messages.faq5Question),
      answer: (
        <Content>
          <Markdown
            content={formatMessage(messages.faq5Description, {
              helpCenterLink: "https://help.getjobber.com/hc/en-us/",
              getHelpLink: "https://getjobber.com/contact/",
            })}
          />
        </Content>
      ),
    };
  };

  const FAQdata = [
    {
      question: faq1().question,
      answer: faq1().answer,
    },
    {
      question: faq2().question,
      answer: faq2().answer,
    },
    {
      question: faq3().question,
      answer: faq3().answer,
    },
    {
      question: faq4().question,
      answer: faq4().answer,
    },
    {
      question: faq5().question,
      answer: faq5().answer,
    },
  ];

  const FAQQuestions = FAQdata.map(
    (question: { question: string; answer: JSX.Element }) => {
      return (
        <QuestionDropdown
          key={question.question}
          question={question.question}
          answer={question.answer}
        />
      );
    },
  );

  return (
    <div ref={ref}>
      <Heading level={2}>Frequently asked questions</Heading>
      <div className={styles.questions}>{FAQQuestions}</div>
    </div>
  );
}
