import React, { useEffect, useState } from "react";
import { Page } from "@jobber/components/Page";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Link } from "@jobber/components/Link";
import { Icon } from "@jobber/components/Icon";
import { Banner } from "@jobber/components/Banner";
import { StatusLabel, Typography } from "@jobber/components";
import { useQuery } from "@apollo/client";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { useCommsCampaignTemplateQuery } from "jobber/campaigns/hooks";
import type {
  ClientSegment,
  Iso8601DateTimeRangeInput,
  ProductsQuery,
} from "~/utilities/API/graphql";
import { CampaignStatus } from "~/utilities/API/graphql";
import { NoMatch } from "jobber/settings/users/utilities/routing";
import { useCommsCampaignEmailsQuery } from "jobber/campaigns/hooks/useCampaignEmailsQuery/useCampaignEmailsQuery";
import { CampaignEmailsTable } from "jobber/campaigns/views/CampaignReportingDetailsPage/components/CampaignEmailsTable/CampaignEmailsTable";
import { viewedPageAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import { useCampaignsReportFilters } from "jobber/campaigns/views/CampaignReportingDetailsPage/hooks/useCampaignsReportFilters";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { mainPath } from "jobber/campaigns/utils";
import { messages } from "./messages";
import styles from "./CampaignReportingDetailsPage.module.css";
import { FilterCards } from "./components/FilterCards/FilterCards";
import { CampaignTemplatePreviewModal } from "../../components/CampaignTemplatePreviewModal/CampaignTemplatePreview";

export interface CampaignReportingDetailsPageProps {
  campaignId: string;
}

export function CampaignReportingDetailsPage(
  props: CampaignReportingDetailsPageProps,
) {
  return (
    <APIProvider>
      <IntlProvider>
        <CampaignReportingDetailsPageInternal {...props} />
      </IntlProvider>
    </APIProvider>
  );
}

// eslint-disable-next-line max-statements
function CampaignReportingDetailsPageInternal({
  campaignId,
}: CampaignReportingDetailsPageProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [campaignPreviewModalOpen, setCampaignPreviewModalOpen] =
    useState(false);

  const {
    filter,
    actions: filterActions,
    filterOptions,
    dateRangeFilter,
  } = useCampaignsReportFilters();

  const { template, campaign, loading, error } = useCommsCampaignTemplateQuery({
    campaignId,
  });

  const { data: lineItemData, loading: productsLoading } =
    useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: emailData,
    loadingInitialContent: loadingEmailsInitialContent,
    loadingNextPage: loadingEmailsNextPage,
    nextPage: loadMoreEmails,
    setSortVariable,
  } = useCommsCampaignEmailsQuery({
    campaignId: campaignId,
    searchTerm: searchTerm,
    filter: {
      type: filter?.id,
      sentAt: dateRangeFilter.values
        .dateRangeFilter as Iso8601DateTimeRangeInput,
    },
  });

  const handleSearch = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    viewedPageAmplitudeEvent({
      name: "Campaign Summary",
    });
  }, []);

  if (
    !loading &&
    !error &&
    campaign?.status !== CampaignStatus.SENT &&
    campaign?.status !== CampaignStatus.AUTOMATIONACTIVE &&
    campaign?.status !== CampaignStatus.AUTOMATIONINACTIVE
  ) {
    return <NoMatch />;
  }

  const campaignStatusLabel =
    campaign?.status === CampaignStatus.AUTOMATIONACTIVE
      ? {
          status: "success",
          label: formatMessage(messages.automationActiveStatusLabel),
        }
      : {
          status: "inactive",
          label: formatMessage(messages.automationInactiveStatusLabel),
        };

  return (
    <>
      {!productsLoading && (
        <Content>
          <Link
            url={mainPath(template.type)}
            external={false}
            ariaLabel={"returnLink"}
          >
            <div className={styles.breadcrumb}>
              <Icon name={"arrowLeft"} color={"interactive"} />
              <Typography textColor={"interactive"}>
                {formatMessage(messages.campaignsBreadcrumb)}
              </Typography>
            </div>
          </Link>

          {error && (
            <div className={styles.errorBanner}>
              <Banner type={"error"}>
                {formatMessage(messages.errorMessage)}
              </Banner>
            </div>
          )}
          {!error && (
            <Page
              title={template.subject}
              width="fill"
              primaryAction={{
                label: formatMessage(messages.viewCampaignLabel),
                onClick: () => setCampaignPreviewModalOpen(true),
              }}
              titleMetaData={
                campaign?.isAutomated && (
                  <StatusLabel
                    status={
                      campaignStatusLabel.status as "success" | "inactive"
                    }
                    label={campaignStatusLabel.label}
                  ></StatusLabel>
                )
              }
            >
              <FilterCards
                commsCampaign={campaign}
                filterActions={filterActions}
              />
              <CampaignTemplatePreviewModal
                isOpen={campaignPreviewModalOpen}
                setOpen={setCampaignPreviewModalOpen}
                sentDate={campaign?.sentAt || ""}
                fromEmail={campaign?.fromEmail || ""}
                replyToEmail={campaign?.replyToEmail || ""}
                totalSent={campaign?.totalSent || 0}
                totalDelivered={campaign?.totalDelivered || 0}
                clientSegment={
                  (campaign?.clientSegment as ClientSegment) || null
                }
                companyName={campaign?.updatedBy?.account?.name || ""}
                campaignIsAutomated={campaign?.isAutomated}
                automationRule={campaign?.automationRule || undefined}
                lineItemData={lineItemData}
                productsLoading={productsLoading}
              />
              <CampaignEmailsTable
                emails={emailData?.commsCampaign?.emails?.edges || []}
                loadingInitial={loadingEmailsInitialContent}
                loadingMore={loadingEmailsNextPage}
                totalCount={emailData?.commsCampaign?.emails?.totalCount || 0}
                loadMoreEmails={loadMoreEmails}
                handleSearch={handleSearch}
                filter={filter}
                filterActions={filterActions}
                filterOptions={filterOptions}
                setVariablesForSorting={setSortVariable}
                dateRangeFilter={dateRangeFilter}
                campaignIsAutomated={campaign?.isAutomated}
              />
            </Page>
          )}
        </Content>
      )}
    </>
  );
}
