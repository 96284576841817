import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfYesterday,
  formatISO,
  isMonday,
  isSaturday,
  isSunday,
  nextSaturday,
  nextSunday,
  parse,
  previousMonday,
  previousSunday,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import type { Iso8601DateRange } from "jobber/dataVisualizations/types";

export interface getDateRangeNDaysFromDayProps {
  numDays: number;
  fromDate?: Date;
}

// Generate a date range for the past 30 days, including today
export function getPast30Days(): Iso8601DateRange {
  return getDateRangeNDaysFromDay({
    numDays: -30,
  });
}

// Generate a date range for the past 60 days, including today
export function getPast60Days(): Iso8601DateRange {
  return getDateRangeNDaysFromDay({
    numDays: -60,
  });
}

// Generate a date range for the next 30 days, starting tomorrow (excludes today)
export function getNext30Days(): Iso8601DateRange {
  return getDateRangeNDaysFromDay({
    numDays: 30,
    fromDate: addDays(new Date(), 1),
  });
}

// Generate a date range for N days from a specified day
export function getDateRangeNDaysFromDay({
  numDays,
  fromDate,
}: getDateRangeNDaysFromDayProps): Iso8601DateRange {
  const daysFromDate = Math.abs(numDays) - 1;
  let startAt, endAt;

  if (numDays < 0) {
    endAt = fromDate ? endOfDay(fromDate) : endOfDay(new Date());
    startAt = startOfDay(subDays(endAt, daysFromDate));
  } else if (numDays > 0) {
    startAt = fromDate ? startOfDay(fromDate) : startOfDay(new Date());
    endAt = endOfDay(addDays(startAt, daysFromDate));
  } else {
    startAt = fromDate ? startOfDay(fromDate) : startOfDay(new Date());
    endAt = fromDate ? endOfDay(fromDate) : endOfDay(new Date());
  }

  return { after: formatISO(startAt), before: formatISO(endAt) };
}

// Generate a date range for a specified month
export function getMonthlyDateRange(currentMonth?: string, month?: string) {
  if (currentMonth === month) {
    return {
      start: startOfMonth(new Date()),
      end: startOfDay(subDays(new Date(), 1)),
    };
  } else {
    // hack to return the correct month and not the month before (set day to 15)
    return {
      start: startOfMonth(parse(`${month}-15`, "yyyy-MM-dd", new Date())),
      end: endOfMonth(parse(`${month}-15`, "yyyy-MM-dd", new Date())),
    };
  }
}

// Get the date range for a past certain number of months in either DateRange or Iso8601DateRange format
export function getDateRangePastNMonths(numMonths: number): Iso8601DateRange {
  const endAt = endOfYesterday();
  const startAt = subMonths(startOfMonth(startOfDay(endAt)), numMonths - 1);

  return { after: startAt.toISOString(), before: endAt.toISOString() };
}

export function getLastNMonthLabels(num: number) {
  const months = [];
  const date = new Date();
  const monthIndex = date.getMonth();
  date.setDate(1);
  for (let i = 0; i < num; i++) {
    date.setMonth(monthIndex - i);
    months.push(date.toLocaleString("default", { month: "short" }));
  }

  return months.reverse();
}

export const getStartOfWeekDay = (
  firstDate: Date,
  weekStartsOnMonday: boolean,
) => {
  if (isMonday(firstDate) && weekStartsOnMonday) {
    return firstDate;
  }

  if (isSunday(firstDate) && !weekStartsOnMonday) {
    return firstDate;
  }

  return weekStartsOnMonday
    ? previousMonday(firstDate)
    : previousSunday(firstDate);
};

export const getEndOfWeekDay = (
  firstDate: Date,
  weekStartsOnMonday: boolean,
) => {
  if (isSunday(firstDate) && weekStartsOnMonday) {
    return firstDate;
  }

  if (isSaturday(firstDate) && !weekStartsOnMonday) {
    return firstDate;
  }

  return weekStartsOnMonday ? nextSunday(firstDate) : nextSaturday(firstDate);
};
