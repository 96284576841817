import FlexibleQuotingSrc from "@images/flexible-quoting.png";

interface ImageType {
  src: string;
  alt: string;
}

const FlexibleQuotingImage: ImageType = {
  src: FlexibleQuotingSrc as string,
  alt: "Flexible quoting Grow feature",
};

export { type ImageType, FlexibleQuotingImage };
