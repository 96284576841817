import React from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { showToast } from "@jobber/components/Toast";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import styles from "./BottomBar.module.css";
import { messages } from "./messages";

export interface BottomBarProps {
  onCancel: () => void;
  onNext: () => void;
  onSaveDraft?: () => Promise<string>;
  step: number;
  loading?: boolean;
  isFromEmailValidated?: boolean;
  onCancelLabel?: string;
  children?: React.ReactNode;
}

export function BottomBar({
  onCancel,
  onNext,
  onSaveDraft,
  step: currentStep,
  loading,
  isFromEmailValidated,
  onCancelLabel,
  children,
}: BottomBarProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { mediumAndUp } = useBreakpoints();
  const { getBottomBarCTA } = useCampaignUpsellSplit();

  function saveDraft() {
    if (onSaveDraft) {
      void onSaveDraft()
        .then(() => {
          showToast({
            message: formatMessage(messages.savedDraftSuccessToast),
            variation: "success",
          });
        })
        .catch(() => undefined);
    }
  }
  const ctaLabel = getBottomBarCTA(currentStep, loading, isFromEmailValidated);

  const displaySaveDraftButton =
    ctaLabel === formatMessage(messages.reviewCampaign);

  // The long-term plan is to move the button definitions to children and remove them from this component.
  // This should be a container component and not know anything about the state of of the wizard.
  return (
    <div className={styles.bottomBarContainer}>
      {!mediumAndUp && (
        <div className={styles.bottomBarMedAndDown}>
          {displaySaveDraftButton && (
            <div className={styles.mobileButtons}>
              <Button
                label={formatMessage(messages.saveDraft)}
                onClick={saveDraft}
                variation={"work"}
                type={"tertiary"}
                fullWidth={true}
                loading={loading}
                id={"addons-save-draft-button"}
              />
            </div>
          )}
          <div className={styles.mobileButtons}>
            {children ? (
              children
            ) : (
              <Button
                label={ctaLabel}
                onClick={onNext}
                type={"primary"}
                loading={loading}
              />
            )}
          </div>
        </div>
      )}
      {mediumAndUp && (
        <div className={styles.bottomBarMedAndUp}>
          <Button
            label={getBackLabel()}
            onClick={onCancel}
            type={"primary"}
            variation={"subtle"}
            loading={loading}
          />
          <div className={styles.bottomRightBarMedAndUp}>
            {displaySaveDraftButton && (
              <Button
                label={formatMessage(messages.saveDraft)}
                onClick={saveDraft}
                variation={"work"}
                type={"tertiary"}
                loading={loading}
                id={"addons-save-draft-button"}
              />
            )}
            {children ? (
              children
            ) : (
              <Button
                label={ctaLabel}
                onClick={onNext}
                type={"primary"}
                loading={loading}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );

  function getBackLabel() {
    if (onCancelLabel) return onCancelLabel;
    return currentStep === 1
      ? formatMessage(messages.cancelButton)
      : formatMessage(messages.backButton);
  }
}
