import { gql } from "@apollo/client";

export const CLIENT_REFERRAL_FEATURE_QUERY = gql`
  query ClientReferralsFeature {
    feature(key: "marketing_client_referrals") {
      enabled
      available
    }
  }
`;

export const CLIENT_QUERY = gql`
  query client($clientId: EncodedId!) {
    client(id: $clientId) {
      id
      name
      secondaryName
    }
  }
`;
