import WelcomeToolboxSrc from "@images/WelcomeToolboxImage.png";

interface ImageType {
  src: string;
  alt: string;
}

const WelcomeToolboxImage: ImageType = {
  src: WelcomeToolboxSrc as string,
  alt: "Welcome Toolbox Image",
};

export { type ImageType, WelcomeToolboxImage };
