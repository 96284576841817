import React, { useReducer } from "react";
import { lineItemsBulkEditReducer } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEdit } from "~/jobber/lineItems/components/LineItemsBulkEdit";
import type { LineItem } from "~/jobber/lineItems/types";
import { reducerInitialState } from "~/jobber/lineItems/hooks/lineItemsBulkEditReducer";
import { LineItemsType } from "~/jobber/lineItems/hooks/types";
import { WorkObjectContextProvider } from "~/jobber/lineItems/hooks/WorkObjectContext";
import { AuthorizationContextProvider } from "~/utilities/contexts/authorization/AuthorizationContextProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  type WorkOrderLineItem,
  workOrderLineItemToLineItem,
} from "jobber/workOrders/components/LineItems/types";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import type { DepositDetailsType } from "~/jobber/workOrders/components/DepositDetails/DepositDetails";
import { DepositDetailsWrapper } from "~/jobber/workOrders/components/DepositDetails/DepositDetailsWrapper";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { useQuoteDepositShownOnJobSplit } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/LineItemTotals/useQuoteDepositOnJobSplit";
import { withSplitClient } from "utilities/split";
import { PropertyContext } from "./PropertyContext";

interface DepositDetailsWorkOrderProps extends DepositDetailsType {
  hasRequiredDeposit: boolean;
}

export function ContextWrappedWorkOrderBulkLineItems({
  initialLineItems = [],
  propertyId,
  depositData,
}: {
  initialLineItems?: WorkOrderLineItem[];
  propertyId: string;
  depositData?: DepositDetailsWorkOrderProps;
}) {
  const [state, dispatch] = useReducer(
    lineItemsBulkEditReducer,
    reducerInitialState(initialLineItems.map(workOrderLineItemToLineItem)),
  );
  const activeLineItems = state.lineItems.filter(item => !item.isDeleted);

  return (
    <APIProvider>
      <AuthorizationContextProvider>
        <PropertyContext.Provider value={propertyId}>
          <WorkObjectContextProvider
            value={{ workObjectType: LineItemsType.Job }}
          >
            <HiddenFields lineItems={state.lineItems} />
            <LineItemsBulkEdit
              lineItems={activeLineItems}
              dispatch={dispatch}
              footerSection={
                <DepositFooterSectionWithSplit depositData={depositData} />
              }
            />
          </WorkObjectContextProvider>
        </PropertyContext.Provider>
      </AuthorizationContextProvider>
    </APIProvider>
  );
}

const WorkOrderBulkLineItemsWithRailsProps = withRailsPropsAsContexts()(
  ContextWrappedWorkOrderBulkLineItems,
);

export { WorkOrderBulkLineItemsWithRailsProps as WorkOrderBulkLineItems };

function DepositFooterSection({
  depositData,
}: {
  depositData?: DepositDetailsWorkOrderProps;
}) {
  const { can } = useAuthorization();
  const canViewQuotes = can("view", "Quotes");
  const { hasQuoteDepositShownOnJob } = useQuoteDepositShownOnJobSplit();

  return (
    <>
      {canViewQuotes &&
        hasQuoteDepositShownOnJob &&
        depositData?.hasRequiredDeposit && (
          <DepositDetailsWrapper
            depositAmount={depositData?.depositAmount}
            depositAmountOutstanding={depositData?.depositAmountOutstanding}
            depositPaymentsRecords={depositData?.depositPaymentsRecords}
          />
        )}
    </>
  );
}

const DepositFooterSectionWithSplit = withSplitClient(DepositFooterSection);

/* This component contains all the inputs required for saving the parent Job in Rails  */
function HiddenFields({ lineItems }: { lineItems: LineItem[] }) {
  return (
    <div>
      {lineItems.map((lineItem, index) => {
        return (
          <div key={index}>
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][_destroy]`}
              value={lineItem.isDeleted ? "true" : "false"}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][id]`}
              value={getIdForHiddenControl(lineItem)}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][sort_order]`}
              value={index}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][name]`}
              value={lineItem.name}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][description]`}
              value={lineItem.description}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][stripped_qty]`}
              value={lineItem.quantity}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][internal_unit_cost]`}
              value={lineItem.unitCost}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][stripped_unit_cost]`}
              value={lineItem.unitPrice}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][cost]`}
              value={lineItem.totalPrice}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][quote_line_item_id]`}
              value={lineItem.quoteLineItemId}
            />
            <input
              type="hidden"
              name={`work_order[line_items_attributes][${index}][taxable]`}
              value={lineItem.taxable?.toString()}
            />
          </div>
        );
      })}
    </div>
  );
}

function getIdForHiddenControl(lineItem: LineItem) {
  try {
    return lineItem.id ? decodeId(lineItem.id) : "";
  } catch {
    return "";
  }
}
