import { formatISO } from "date-fns";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";

// This is deprecated; going forward all reports should be using a single DateTimeRangeInput object for date filters.
//   TODO: remove this in `https://jobber.atlassian.net/browse/JOB-88271`
const formatDate = (defaultDate: Date, date?: Date) => {
  return formatISO(date || defaultDate);
};

export const formatBeforeDate = (date?: Date) => {
  return formatDate(defaultDateRangeOption.end, date);
};

export const formatAfterDate = (date?: Date) => {
  return formatDate(defaultDateRangeOption.start, date);
};

export const formatDateForQuery = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0",
  )}-${String(date.getDate()).padStart(2, "0")}`;
};
