import React from "react";
import {
  type OperationVariables,
  type QueryResult,
  useQuery,
} from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import { Page } from "@jobber/components/Page";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { APIProvider } from "~/utilities/API/APIProvider";
import { LoadingError } from "jobber/billing/components/LoadingError";
import type { AccountAddonsInfoQuery } from "~/utilities/API/graphql";
import { formatCurrency } from "utilities/formatCurrency";
import { SubscriptionAddons } from "./SubscriptionAddons";
import { ACCOUNT_ADDONS_INFO } from "./SubscriptionAddons.graphql";
import { formatRenewalDate } from "./utils";
import { messages } from "./messages";

interface SubscriptionAddonsLoaderProps {
  recurlyPublicKey: string;
}

export function SubscriptionAddonsLoader(props: SubscriptionAddonsLoaderProps) {
  return (
    <IntlProvider>
      <APIProvider>
        <SubscriptionAddonsPage {...props} />
      </APIProvider>
    </IntlProvider>
  );
}

function SubscriptionAddonsPage(props: SubscriptionAddonsLoaderProps) {
  const { formatMessage } = useIntl();

  return (
    <Page title={formatMessage(messages.title)}>
      <InternalSubscriptionAddonsLoader {...props} />
    </Page>
  );
}

function InternalSubscriptionAddonsLoader(
  props: SubscriptionAddonsLoaderProps,
) {
  const accountAddons = useQuery<AccountAddonsInfoQuery>(ACCOUNT_ADDONS_INFO);

  if (accountAddons.loading) {
    return <Spinner />;
  }

  if (accountAddons.error) {
    return (
      <LoadingError
        source="SubscriptionAddonsLoader"
        logMessage={`Could not load subscription addons: ${accountAddons.error}`}
      />
    );
  }

  return (
    <SubscriptionAddons
      recurlyPublicKey={props.recurlyPublicKey}
      subscriptionAddons={getFormattedAddons(accountAddons)}
    />
  );
}

export function getFormattedAddons(
  queryResult: QueryResult<AccountAddonsInfoQuery, OperationVariables>,
): SubscriptionAddon[] {
  const addonsData = queryResult.data?.accountAddonsInfo?.addons || [];

  return addonsData.map(addon => {
    const {
      name,
      isActive,
      identifier,
      currency,
      currentBillingCycle,
      monthlyBillingCycle,
      discountGroup,
    } = addon;

    const formattedCurrentBillingCycle = currentBillingCycle
      ? {
          billingCycleName: currentBillingCycle.name.toLowerCase(),
          monthlyCost: formatCurrency(currentBillingCycle.monthlyCost, "$"),
          renewalDate: formatRenewalDate(currentBillingCycle.renewalDate),
          addonCode: currentBillingCycle.addonCode,
          futureStartBillingOnDate: formatRenewalDate(
            currentBillingCycle.futureStartBillingOnDate,
          ),
        }
      : undefined;

    return {
      name: name,
      isActive: isActive,
      identifier: identifier,
      currency: currency,
      currentBillingCycle: formattedCurrentBillingCycle,
      monthlyBillingCycle: {
        monthlyCost: monthlyBillingCycle?.monthlyCost,
      },
      discountGroup: discountGroup,
    };
  });
}
