import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "subscriptionAddons.removeAddonModal.modalTitle",
    defaultMessage: "Remove {addonName}?",
    description: "Modal title",
  },
  cancel: {
    id: "subscriptionAddons.removeAddonModal.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button label",
  },
  remove: {
    id: "subscriptionAddons.removeAddonModal.remove",
    defaultMessage: "Remove",
    description: "Remove button label",
  },
  removalInformation: {
    id: "subscriptionAddons.removeAddonModal.removalInformation",
    defaultMessage:
      "This subscription will be cancelled immediately. You'll lose access to {addonName}, including:",
    description: "Information about what happens when you remove an addon",
  },
  removalCreditMessage: {
    id: "subscriptionAddons.removeAddonModal.removalCreditMessage",
    defaultMessage:
      "If you have an unused portion of this subscription, you will receive a credit towards your next bill.",
    description: "Information about credits given when removing an addon",
  },
  campaignsFeature1: {
    id: "subscriptionAddons.removeAddonModal.campaignsFeature1",
    defaultMessage: "creating and sending campaigns",
    description: "A feature that will be lost when removing campaigns",
  },
  campaignsFeature2: {
    id: "subscriptionAddons.removeAddonModal.campaignsFeature2",
    defaultMessage: "viewing and tracking past campaigns",
    description: "A feature that will be lost when removing campaigns",
  },
  reviewsFeature1: {
    id: "subscriptionAddons.removeAddonModal.reviewsFeature1",
    defaultMessage: "automatic review requests",
    description: "A feature that will be lost when removing reviews",
  },
  reviewsFeature2: {
    id: "subscriptionAddons.removeAddonModal.reviewsFeature2",
    defaultMessage: "viewing and tracking reviews data",
    description: "A feature that will be lost when removing reviews",
  },
  addonRemoved: {
    id: "subscriptionAddons.removeAddonModal.addonRemoved",
    defaultMessage: "Removed {addonName}",
    description: "Message displayed when an addon is removed",
  },
  // This is used when the GQL mutation fails, but we don't get the error message back from the userErrors
  genericError: {
    id: "subscriptionAddons.removeAddonModal.genericError",
    defaultMessage:
      "An unexpected error occurred, please contact us at 1-888-721-1115",
    description: "Generic error message when removing an addon fails",
  },
});
