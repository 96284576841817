import { defineMessages } from "react-intl";

export const messages = defineMessages({
  currentPlan: {
    id: "upgradePage.planCard.currentPlan",
    defaultMessage: "YOUR PLAN",
    description: "Current plan",
  },
  mostPopularPlan: {
    id: "upgradePage.planCard.mostPopularPlan",
    defaultMessage: "MOST POPULAR",
    description: "Most popular plan",
  },
  pricePerMonth: {
    id: "upgradePage.planCard.pricePerMonth",
    defaultMessage: `{price}/mo`,
    description: "Per month pricing text",
  },
  billedAnnually: {
    id: "upgradePage.planCard.billedAnnually",
    defaultMessage: `billed annually`,
    description: "billed annually",
  },
  billedMonthly: {
    id: "upgradePage.planCard.billedMonthly",
    defaultMessage: "billed monthly",
    description: "billed monthly",
  },
  whenBilledAnnually: {
    id: "upgradePage.planCard.whenBilledAnnually",
    defaultMessage: `when{br}billed annually`,
    description: "when(maybe line break)billed annually",
  },
  whenBilledMonthly: {
    id: "upgradePage.planCard.whenBilledMonthly",
    defaultMessage: "when billed monthly",
    description: "when billed monthly",
  },
  durationYears: {
    id: "upgradePage.planCard.durationYears",
    defaultMessage: `for {years} {years, plural, one {year} other {years}}`,
    description: "Duration in years (accepts plural)",
  },
  durationMonths: {
    id: "upgradePage.planCard.durationMonths",
    defaultMessage: `for {months} {months, plural, one {month} other {months}}`,
    description: "Duration in months (accepts plural)",
  },
  saveAmount: {
    id: "upgradePage.planCard.saveAmount",
    defaultMessage: `Save {amount} {duration}`,
    description: "Save $X for Y months/ years caption",
  },
  unlimitedUsers: {
    id: "upgradePage.planCard.unlimitedUsers",
    defaultMessage: "Unlimited users",
    description: "Caption for unlimited number of users included",
  },
  fixedUsers: {
    id: "upgradePage.planCard.fixedUsers",
    defaultMessage: `{users} {users, plural, one {user} other {users}} included`,
    description: "Caption for fixed number of users included",
  },
  additionalUsersAdded: {
    id: "upgradePage.planCard.additionalUsersAdded",
    defaultMessage: `{users} additional {users, plural, one {user} other {users}} added`,
    description: "Already added additional users",
  },
  addUsers: {
    id: "upgradePage.planCard.addUsers",
    defaultMessage: " Add users to this plan for",
    description: "Add users caption",
  },
  perAdditionalUser: {
    id: "upgradePage.planCard.additionalUsers",
    defaultMessage: " per additional user",
    description: "per additional user text",
  },
  everythingInCurrentTier: {
    id: "upgradePage.planCard.everythingInCurrentTier",
    defaultMessage: `Everything in {tier} includes`,
    description: "Current plan features comparison caption",
  },
  everythingInPrevTier: {
    id: "upgradePage.planCard.everythingInPrevTier",
    defaultMessage: `Everything in {tier}, plus`,
    description: "Upgrade plan features comparison caption",
  },
  billingDialogLink: {
    id: "upgradePage.planCard.billingDialogLink",
    defaultMessage: `/accounts/billing_info/edit.dialog?plan_set_identifier={planIdentifier}&tier_name={tierName}&is_annual={isAnnual}&is_choosing_plan=true&offer={offer}`,
    description: "billing dialog link",
  },
  choosePlanButtonCopy: {
    id: "upgradePage.planCard.choosePlanButtonCopy",
    defaultMessage: "Choose Plan",
    description: "Choose plan button copy",
  },
  resubscribeButtonCopy: {
    id: "upgradePage.planCard.resubscribeButtonCopy",
    defaultMessage: "Resubscribe",
    description: "Resubscribe button copy",
  },
  seeAllFeaturesButtonCopy: {
    id: "upgradePage.planCard.seeAllFeaturesButtonCopy",
    defaultMessage: "See All Features",
    description: "See all features button copy",
  },
  connectDescriptionCopy: {
    id: "upgradePage.planCard.connectDescriptionCopy",
    defaultMessage:
      "Build efficient processes that better connect your team and impress your customers.",
    description: "Connect description copy",
  },
  loyaltyAndUnlimitedDescriptionCopy: {
    id: "upgradePage.planCard.loyaltyAndUnlimitedDescriptionCopy",
    defaultMessage:
      "Organize your operations-from quoting to scheduling to invoicing-all in one place.",
    description: "Loyalty and unlimited description copy",
  },
  corePlusDescriptionCopy: {
    id: "upgradePage.planCard.corePlusDescriptionCopy",
    defaultMessage:
      "Organize your operations in one place, including accounting and custom tracking.",
    description: "Core plus description copy",
  },
  onlineBookingFeatureCopy: {
    id: "upgradePage.planCard.onlineBookingFeatureCopy",
    defaultMessage: "Online booking",
    description: "Online booking feature copy",
  },
  automatedRemindersFeatureCopy: {
    id: "upgradePage.planCard.automatedRemindersFeatureCopy",
    defaultMessage: "Automated reminders",
    description: "Automated reminders feature copy",
  },
  automaticPaymentsFeatureCopy: {
    id: "upgradePage.planCard.automaticPaymentsFeatureCopy",
    defaultMessage: "Automatic payments",
    description: "Automatic payments feature copy",
  },
  quickBooksOnlineFeatureCopy: {
    id: "upgradePage.planCard.quickBooksOnlineFeatureCopy",
    defaultMessage: "QuickBooks Online sync",
    description: "QuickBooks Online feature copy",
  },
  scheduleAndManageJobsFeatureCopy: {
    id: "upgradePage.planCard.scheduleAndManageJobsFeatureCopy",
    defaultMessage: "Schedule and manage jobs",
    description: "Schedule and manage jobs feature copy",
  },
  sendQuotesAndInvoicesFeatureCopy: {
    id: "upgradePage.planCard.sendQuotesAndInvoicesFeatureCopy",
    defaultMessage: "Send quotes and invoices",
    description: "Send quotes and invoices feature copy",
  },
  onlinePaymentsFeatureCopy: {
    id: "upgradePage.planCard.onlinePaymentsFeatureCopy",
    defaultMessage: "Online payments",
    description: "Online payments feature copy",
  },
  reportsFeatureCopy: {
    id: "upgradePage.planCard.reportsFeatureCopy",
    defaultMessage: "Access over 20 built-in reports",
    description: "Reports feature copy",
  },
  quoteAddOnsFeatureCopy: {
    id: "upgradePage.planCard.quoteAddOnsFeatureCopy",
    defaultMessage: "Quote add-ons and images",
    description: "Quote add-ons and images feature copy",
  },
  automatedQuoteFollowupsFeatureCopy: {
    id: "upgradePage.planCard.automatedQuoteFollowupsFeatureCopy",
    defaultMessage: "Automated quote follow-ups",
    description: "Automated quote follow-ups feature copy",
  },
  tagAndManageLeadsFeatureCopy: {
    id: "upgradePage.planCard.tagAndManageLeadsFeatureCopy",
    defaultMessage: "Tag and manage leads",
    description: "Tag and manage leads feature copy",
  },
  jobCostingFeatureCopy: {
    id: "upgradePage.planCard.jobCostingFeatureCopy",
    defaultMessage: "Job costing",
    description: "Job costing feature copy",
  },
  selfServeFeatureCopy: {
    id: "upgradePage.planCard.selfServeFeatureCopy",
    defaultMessage: "24/7 client self-serve hub",
    description: "24/7 client self-serve hub feature copy",
  },
  scheduleJobsFeatureCopy: {
    id: "upgradePage.planCard.scheduleJobsFeatureCopy",
    defaultMessage: "Schedule jobs",
    description: "Schedule jobs feature copy",
  },
  yourCurrentPlanButtonCopy: {
    id: "upgradePage.planCard.yourCurrentPlanButtonCopy",
    defaultMessage: "Your Current Plan",
    description: "Your current plan copy",
  },
  increaseUserLimitButtonCopy: {
    id: "upgradePage.planCard.increaseUserLimitButtonCopy",
    defaultMessage: "Increase User Limit",
    description: "Increase plan user limit button copy",
  },
});
