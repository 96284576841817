import { defineMessages } from "react-intl";

export const messages = defineMessages({
  primaryDescription: {
    id: "upgradePage.legacyBilledDisclaimer.primaryDescription",
    defaultMessage:
      "As a long time customer, you're currently on our {planName} plan!",
    description: "The primary description for the legacy plan disclaimer",
  },
  secondaryDescription: {
    id: "upgradePage.legacyBilledDisclaimer.secondaryDescription",
    defaultMessage:
      "Our newest plans include features that are not available on your current plan. If you choose to upgrade to take advantage of these features you will not be able to revert back to your original plan in the future, and will no longer receive the loyalty pricing.",
    description: "The secondary description for the legacy plan disclaimer",
  },
});
