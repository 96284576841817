import React, { useState } from "react";
import {
  DragDropContext,
  Draggable,
  type DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { Icon } from "@jobber/components/Icon";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Divider } from "@jobber/components/Divider";
import {
  CustomFieldItem,
  type CustomFieldItemProps,
} from "jobber/customFields/CustomFieldsPage/CustomFieldItem";
import {
  AppCustomFieldItem,
  type AppCustomFieldItemProps,
} from "jobber/customFields/CustomFieldsPage/AppCustomFieldItem/AppCustomFieldItem";
import {
  CustomFieldAppliesTo,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
} from "~/utilities/API/graphql";
import styles from "./CustomFieldsSection.module.css";
import {
  type UseCustomFieldConfigurationsType,
  useCustomFieldConfigurations,
} from "../hooks";
import { CustomFieldItemContent } from "../CustomFieldItemContent";
import { AppCustomFieldItemContent } from "../AppCustomFieldItemContent";
import { CustomFieldItemRowActions } from "../CustomFieldItemRowActions";

export interface CustomFieldsSectionProps {
  customFieldList: CustomFieldConfigurationNodeFragment[];
  customFieldModelType: CustomFieldAppliesTo;
  onEditCustomField: (
    customField: CustomFieldConfigurationNodeFragment,
  ) => void;
  adminSuperpowers: boolean;
  onDelete: (customField: CustomFieldConfigurationNodeFragment) => void;
  onArchive: (customField: CustomFieldConfigurationNodeFragment) => void;
  onUnarchive: (customField: CustomFieldConfigurationNodeFragment) => void;
}

export interface CustomFieldsListProps {
  customFieldList: CustomFieldConfigurationNodeFragment[];
  customFieldModelType: CustomFieldAppliesTo;
  onEditCustomField: (
    customField: CustomFieldConfigurationNodeFragment,
  ) => void;
  adminSuperpowers: boolean;
  onArchive: (customField: CustomFieldConfigurationNodeFragment) => void;
  updateCustomFieldPosition: UseCustomFieldConfigurationsType["updateCustomFieldPosition"];
}

interface IconMappingValue {
  iconName: "clients" | "property" | "quote" | "job" | "invoice" | "user";
  modelTitleName: "Client" | "Property" | "Quote" | "Job" | "Invoice" | "Team";
  modelItemName: "client" | "property" | "quote" | "job" | "invoice" | "user";
}

const MODEL_TYPE_MAPPING: {
  [key in CustomFieldAppliesTo]: IconMappingValue;
} = {
  [CustomFieldAppliesTo.ALL_CLIENTS]: {
    iconName: "clients",
    modelTitleName: "Client",
    modelItemName: "client",
  },
  [CustomFieldAppliesTo.ALL_PROPERTIES]: {
    iconName: "property",
    modelTitleName: "Property",
    modelItemName: "property",
  },
  [CustomFieldAppliesTo.ALL_QUOTES]: {
    iconName: "quote",
    modelTitleName: "Quote",
    modelItemName: "quote",
  },
  [CustomFieldAppliesTo.ALL_JOBS]: {
    iconName: "job",
    modelTitleName: "Job",
    modelItemName: "job",
  },
  [CustomFieldAppliesTo.ALL_INVOICES]: {
    iconName: "invoice",
    modelTitleName: "Invoice",
    modelItemName: "invoice",
  },
  [CustomFieldAppliesTo.TEAM]: {
    iconName: "user",
    modelTitleName: "Team",
    modelItemName: "user",
  },
};

export function CustomFieldsSection({
  customFieldList,
  customFieldModelType,
  onEditCustomField,
  adminSuperpowers,
  onDelete,
  onArchive,
  onUnarchive,
}: CustomFieldsSectionProps) {
  function newCustomField() {
    const customFieldConfig: CustomFieldConfigurationNodeFragment = {
      id: "",
      createdAt: "",
      updatedAt: "",
      sortOrder: 0,
      appliesTo: customFieldModelType,
      transferable: false,
      name: "",
      valueType: CustomFieldConfigurationValueType.TEXT,
      textDefaultValue: "",
      readOnly: false,
      archived: false,
    };
    onEditCustomField(customFieldConfig);
  }

  const { updateCustomFieldPosition } = useCustomFieldConfigurations();
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const customFields = customFieldList.filter(
    customField => !customField.archived,
  );
  const visibleArchivedCustomFields = customFieldList.filter(
    customField => customField.archived && !customField.transferedFrom,
  );

  return (
    <div className={styles.customFieldsSectionCard}>
      <Card
        header={{
          title: getSectionHeader(customFieldModelType),
          action: (
            <Button
              type="secondary"
              label="Add Field"
              onClick={newCustomField}
            />
          ),
        }}
      >
        {customFieldList.length > 0 ? (
          <>
            <CustomFieldsList
              customFieldList={customFields}
              customFieldModelType={customFieldModelType}
              onEditCustomField={onEditCustomField}
              updateCustomFieldPosition={updateCustomFieldPosition}
              adminSuperpowers={adminSuperpowers}
              onArchive={onArchive}
            />

            {visibleArchivedCustomFields.length > 0 && (
              <>
                {customFields.length > 0 && <Divider />}
                <ArchivedCustomFields
                  showArchive={showArchive}
                  setShowArchive={setShowArchive}
                  customFields={visibleArchivedCustomFields}
                  onDelete={onDelete}
                  onUnarchive={onUnarchive}
                />
              </>
            )}
          </>
        ) : (
          <EmptySection
            customFieldModelType={customFieldModelType}
            newCustomField={newCustomField}
          />
        )}
      </Card>
    </div>
  );
}

export interface ArchivedCustomFieldsProps {
  showArchive: boolean;
  setShowArchive: (showArchive: boolean) => void;
  customFields: CustomFieldConfigurationNodeFragment[];
  onDelete: (customField: CustomFieldConfigurationNodeFragment) => void;
  onUnarchive: (customField: CustomFieldConfigurationNodeFragment) => void;
}

export function ArchivedCustomFields({
  showArchive,
  setShowArchive,
  customFields,
  onDelete,
  onUnarchive,
}: ArchivedCustomFieldsProps) {
  const groups = getGroupingByApp(customFields);
  return (
    <div>
      <div className={styles.archivedCustomFieldSectionButton}>
        <Button
          variation={"subtle"}
          type="secondary"
          size="base"
          label={`Archived (${customFields.length})`}
          ariaLabel="Display Archived Custom Fields"
          icon={showArchive ? "arrowUp" : "arrowDown"}
          iconOnRight={true}
          onClick={() => setShowArchive(!showArchive)}
        />
      </div>
      <div className={styles.archivedCustomFields}>
        {showArchive && (
          <>
            {groups.map((customFieldGroup, index) =>
              Array.isArray(customFieldGroup) ? (
                <div key={index}>
                  <div className={styles.archivedCustomField}>
                    <AppCustomFieldItemContent
                      customFields={customFieldGroup}
                      onUnarchive={onUnarchive}
                      onDelete={onDelete}
                    />
                  </div>
                  {index !== groups.length - 1 && <Divider />}
                </div>
              ) : (
                <div key={index}>
                  <div className={styles.archivedCustomField}>
                    <CustomFieldItemContent
                      key={index}
                      customField={customFieldGroup}
                      moreActions={
                        <div className={styles.customFieldMoreActionsButton}>
                          <CustomFieldItemRowActions
                            actions={[
                              {
                                label: "Unarchive",
                                icon: "archive",
                                onClick: () => onUnarchive(customFieldGroup),
                              },
                              {
                                label: "Delete",
                                icon: "trash",
                                onClick: () => onDelete(customFieldGroup),
                              },
                            ]}
                          />
                        </div>
                      }
                    />
                  </div>
                  {index !== groups.length - 1 && <Divider />}
                </div>
              ),
            )}
          </>
        )}
      </div>
    </div>
  );
}

function getSectionHeader(customFieldModelType: CustomFieldAppliesTo) {
  return `${MODEL_TYPE_MAPPING[customFieldModelType].modelTitleName} custom fields`;
}

function renderCustomFields(
  customFieldList: CustomFieldConfigurationNodeFragment[],
  onEditCustomField: (
    customField: CustomFieldConfigurationNodeFragment,
  ) => void,
  adminSuperpowers: boolean,
  onArchive: (customField: CustomFieldConfigurationNodeFragment) => void,
) {
  return getGroupingByApp(customFieldList).map((customFieldGroup, index) =>
    Array.isArray(customFieldGroup) ? (
      <AppDraggableCustomFieldRow
        key={customFieldGroup[0].id}
        customFields={customFieldGroup}
        sortOrder={index}
        onArchive={onArchive}
      />
    ) : (
      <DraggableCustomFieldRow
        key={customFieldGroup.id}
        customField={customFieldGroup}
        sortOrder={index}
        onEditCustomField={onEditCustomField}
        adminSuperpowers={adminSuperpowers}
        onArchive={onArchive}
      />
    ),
  );
}

interface DraggableCustomFieldRowProps extends CustomFieldItemProps {
  sortOrder: number;
}

function DraggableCustomFieldRow({
  customField,
  onEditCustomField,
  adminSuperpowers,
  sortOrder,
  onArchive,
}: DraggableCustomFieldRowProps) {
  return (
    <Draggable draggableId={customField.id} index={sortOrder}>
      {provided => (
        <div
          key={`draggableContainer${customField.id}`}
          ref={provided.innerRef}
          className={styles.itemContainer}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps}>
            <CustomFieldItem
              customField={customField}
              onEditCustomField={onEditCustomField}
              adminSuperpowers={adminSuperpowers}
              onArchive={onArchive}
            />
          </div>
          <div className={styles.itemContainerBottomSpace}></div>
          <div className={styles.divider}>
            <Divider />
          </div>
        </div>
      )}
    </Draggable>
  );
}

interface AppDraggableCustomFieldRowProps extends AppCustomFieldItemProps {
  sortOrder: number;
  onArchive: (customField: CustomFieldConfigurationNodeFragment) => void;
}

function AppDraggableCustomFieldRow({
  customFields,
  sortOrder,
  onArchive,
}: AppDraggableCustomFieldRowProps) {
  return (
    <Draggable draggableId={customFields[0].id} index={sortOrder}>
      {provided => (
        <div
          key={`draggableContainer${customFields[0].id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={styles.itemContainer}
        >
          <div {...provided.dragHandleProps}>
            <AppCustomFieldItem
              key={customFields[0].id}
              customFields={customFields}
              onArchive={onArchive}
            />
          </div>
          <div className={styles.itemContainerBottomSpace}></div>
          <div className={styles.divider}>
            <Divider />
          </div>
        </div>
      )}
    </Draggable>
  );
}

function CustomFieldsList({
  customFieldList,
  customFieldModelType,
  onEditCustomField,
  updateCustomFieldPosition,
  adminSuperpowers,
  onArchive,
}: CustomFieldsListProps) {
  const droppableContainerId = `${customFieldModelType}`;

  function onDragEnd(result: DropResult) {
    if (
      result.reason == "CANCEL" ||
      !result.destination ||
      result.destination.droppableId != droppableContainerId
    ) {
      return;
    }

    const groupedCustomFields = getGroupingByApp(customFieldList);

    // Move the custom field group to the correct position
    const customFieldGroup = groupedCustomFields.splice(
      result.source.index,
      1,
    )[0];
    groupedCustomFields.splice(result.destination.index, 0, customFieldGroup);

    updateCustomFieldPosition(
      groupedCustomFields.flat(),
      result.destination.droppableId as CustomFieldAppliesTo,
      result.draggableId,
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={droppableContainerId}
        type={`${customFieldModelType}_CustomField`}
      >
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {renderCustomFields(
              customFieldList,
              onEditCustomField,
              adminSuperpowers,
              onArchive,
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className={styles.itemContainerBottomSpace}></div>
    </DragDropContext>
  );
}

interface EmptyCustomFieldSectionProps {
  customFieldModelType: CustomFieldAppliesTo;
  newCustomField(): void;
}

function EmptySection({
  customFieldModelType,
  newCustomField,
}: EmptyCustomFieldSectionProps) {
  const iconAndIndividualModelDetails =
    MODEL_TYPE_MAPPING[customFieldModelType];
  return (
    <div className={styles.emptyStateContainer}>
      <div className={styles.emptyStateIconContainer}>
        <div className={styles.emptyStateIconBackground}>
          <Icon
            size="base"
            color="greyBlue"
            name={iconAndIndividualModelDetails.iconName}
          />
        </div>
      </div>
      <div className={styles.emptyStateDetailsContainer}>
        <div>
          <div>
            <Heading level={4}>No custom fields</Heading>
          </div>
          <div className={styles.emptyStateDetailsText}>
            <Text>{`Keep track of ${iconAndIndividualModelDetails.modelItemName} details by adding a custom field`}</Text>
          </div>
          <div>
            <Button
              type="secondary"
              size="small"
              label="Add Field"
              onClick={newCustomField}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function getGroupingByApp(
  customFieldList: CustomFieldConfigurationNodeFragment[],
) {
  const groupedCustomFields: Array<
    | CustomFieldConfigurationNodeFragment
    | CustomFieldConfigurationNodeFragment[]
  > = [];

  customFieldList.forEach(customField => {
    if (customField.app) {
      const existingGroup = groupedCustomFields.find(
        groupedCustomField =>
          Array.isArray(groupedCustomField) &&
          groupedCustomField[0].app?.id === customField.app?.id,
      );

      if (existingGroup && Array.isArray(existingGroup)) {
        existingGroup.push(customField);
      } else {
        groupedCustomFields.push([customField]);
      }
    } else {
      groupedCustomFields.push(customField);
    }
  });

  return groupedCustomFields;
}
