import React from "react";
import {
  type Datum,
  type Point,
  type PointTooltip,
  ResponsiveLine,
} from "@nivo/line";
import { tokens } from "@jobber/design";
import { formatTick } from "jobber/dataVisualizations/utils/dataVisualizationsUtils";

interface LineChartProps {
  xTickValues?: string[];
  yTickValues: number[];
  data: Datum[];
  tooltip?: PointTooltip;
  clickHandler?: (data: Point) => void;
  enableGridY?: boolean;
  lineColor?: string;
  yTickSuffix?: string;
  axisBottomFormatter?: (value: string) => string;
}

export function LineChart({
  xTickValues,
  yTickValues,
  data,
  tooltip,
  clickHandler,
  enableGridY = false,
  lineColor = "#42AB25",
  yTickSuffix,
  axisBottomFormatter,
}: LineChartProps) {
  const lineChartData = [
    {
      id: "lineChartData",
      data,
    },
  ];

  return (
    <ResponsiveLine
      onClick={datum => {
        if (clickHandler) {
          clickHandler(datum);
        }
      }}
      data={lineChartData}
      colors={lineColor}
      lineWidth={2}
      enableCrosshair={false}
      enableGridX={false}
      enableGridY={enableGridY}
      enablePoints={false}
      gridYValues={yTickValues}
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "slices",
        "points",
        HoverPoint,
        "mesh",
      ]}
      defs={[
        {
          id: "gradient",
          type: "linearGradient",
          colors: [
            { offset: 0, color: tokens["color-green"] },
            { offset: 100, color: "#ffffff" },
          ],
        },
      ]}
      fill={[{ match: "*", id: "gradient" }]}
      enableArea={true}
      margin={{
        top: tokens["space-larger"],
        right: tokens["space-base"],
        bottom: enableGridY ? tokens["space-large"] : tokens["space-largest"],
        left:
          tokens["space-larger"] +
          (!yTickSuffix ? tokens["space-smaller"] : tokens["space-small"]),
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: tokens["color-text--secondary"],
              fontSize: tokens["typography--fontSize-small"],
            },
          },
        },
      }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: tokens["space-small"],
        tickRotation: 0,
        tickValues: xTickValues,
        format: value =>
          axisBottomFormatter ? axisBottomFormatter(value) : value,
      }}
      axisLeft={{
        format: value => formatTick(value, { yTickSuffix }),
        tickValues: yTickValues,
        tickSize: 0,
        tickPadding: tokens["space-small"],
        tickRotation: 0,
      }}
      pointSize={8}
      pointColor={tokens["color-white"]}
      useMesh={true}
      tooltip={tooltip}
      role="application"
    />
  );
}

/* There is no typescript definition for the props passed into custom components in the Nivo library
 * and unknown how to test this function as of yet
 */
/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function HoverPoint(props: any) {
  const { currentPoint } = props;

  if (currentPoint) {
    return (
      <g>
        <circle
          fill={tokens["color-white"]}
          r={4}
          strokeWidth={"2"}
          stroke={tokens["color-green"]}
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
      </g>
    );
  }
}
