import Quill, {
  type SelectionChangeHandler,
  type TextChangeHandler,
} from "quill";
import { useRef } from "react";
import { v4 as uuidV4 } from "uuid";
import { ImageResizer } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/hooks/useQuill/modules/imageResize";
import { setupIcons } from "./icons";
import { QUILL_FORMATS, SUPPORTED_FONTS, setupAttributors } from "./formats";

export const historyDelay = 2000;

export function useQuill({
  setIsImageUploadModalOpen,
  setImageSize,
}: {
  setIsImageUploadModalOpen: (isOpen: boolean) => void;
  setImageSize: (btn: string) => void;
}) {
  const editorId = useRef<string | null>(`richText${uuidV4()}`);
  const quillInstance = useRef<Quill | null>(null);
  const initialValueLoaded = useRef(false);
  const lastKnownPosition = useRef(0);

  function setupQuill(
    placeholder: string,
    onTextChange: TextChangeHandler,
    onSelectionChange: SelectionChangeHandler,
    canUploadImages: boolean,
  ) {
    setupIcons();
    setupAttributors();

    Quill.register("modules/imageResize", ImageResizer);

    quillInstance.current = new Quill(`#${editorId.current}`, {
      modules: {
        imageResize: {
          menuId: "image-resize-menu",
          onResize: setImageSize,
        },
        toolbar: {
          container: [
            [{ font: SUPPORTED_FONTS }],
            [{ header: ["1", "2", false] }],
            [{ align: ["", "center", "right", "justify"] }],
            ["bold", "italic", "underline", "strike"],
            ["link"],
            ["undo"],
            ["redo"],
            canUploadImages ? ["image"] : [],
          ],
          handlers: {
            undo: function () {
              quillInstance.current?.history.undo();
            },
            redo: function () {
              quillInstance.current?.history.redo();
            },
            image: function () {
              setIsImageUploadModalOpen(true);
            },
          },
        },
        history: {
          delay: historyDelay,
          maxStack: 500,
          userOnly: true,
        },
      },
      placeholder: placeholder,
      theme: "snow",
      bounds: `#${editorId.current}`,
      formats: QUILL_FORMATS,
    });
    quillInstance.current.on("text-change", onTextChange);
    quillInstance.current.on("selection-change", onSelectionChange);
  }

  return {
    setupQuill,
    editorId,
    quillInstance,
    initialValueLoaded,
    lastKnownPosition,
  };
}
