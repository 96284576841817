import React, { useEffect } from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Disclosure } from "@jobber/components/Disclosure";
import { Icon } from "@jobber/components/Icon";
import { Table } from "@jobber/components/Table";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import type {
  AccountPromotion,
  CurrentPlan,
  PlanRate,
  UpgradablePlan,
} from "jobber/upgradePage/UpgradePage";
import { APIProvider } from "~/utilities/API/APIProvider";
import styles from "./FeatureTable.module.css";
import { StickyHeader } from "./components/StickyHeader";
import { FEATURES_BY_CATEGORY } from "./FeaturesByCategory.graphql";
import { messages } from "./messages";

export interface Feature {
  featureDetails: string;
  featureId: string;
  featureLabel: string;
  learnMoreLink: string;
}

export interface FeatureCategory {
  featureCategory: string;
  features: Array<Feature>;
}

export interface FeatureTableWrapperProps {
  isAnnual: boolean;
  currentPlan: CurrentPlan;
  currentPlanCost: number;
  upgradePlans: Array<UpgradablePlan> | undefined;
  activePromotion: AccountPromotion | undefined;
  canUpgrade: boolean;
}

interface FeaturesByCategory {
  featuresByCategory: Array<FeatureCategory>;
}

export interface FeatureTableProps extends FeatureTableWrapperProps {
  fullFeatureList?: FeaturesByCategory;
  loading: boolean;
  canUpgrade: boolean;
}

export interface FeatureObject {
  [key: string]: string;
}

export function FeatureTableWrapper({
  isAnnual,
  currentPlan,
  currentPlanCost,
  upgradePlans,
  activePromotion,
  canUpgrade,
}: FeatureTableWrapperProps) {
  const { data, loading } = useQuery<FeaturesByCategory>(FEATURES_BY_CATEGORY);

  useEffect(() => {
    if (!loading && data?.featuresByCategory) {
      const featureTableSection = document.getElementById(
        "featureTableSection",
      );
      featureTableSection?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [loading, data?.featuresByCategory]);

  return (
    <APIProvider>
      <FeatureTableWrapperComponent
        fullFeatureList={data}
        loading={loading}
        currentPlan={currentPlan}
        currentPlanCost={currentPlanCost}
        isAnnual={isAnnual}
        upgradePlans={upgradePlans}
        activePromotion={activePromotion}
        canUpgrade={canUpgrade}
      />
    </APIProvider>
  );
}

// eslint-disable-next-line max-statements
function FeatureTableWrapperComponent({
  isAnnual,
  fullFeatureList,
  loading,
  currentPlan,
  currentPlanCost,
  upgradePlans,
  activePromotion,
  canUpgrade,
}: FeatureTableProps) {
  // Used to show/hide the sticky header based on view of the table
  const { ref: stickRef, inView } = useInView({ initialInView: true });
  const currentPlanFeatures = parseFeatureList(currentPlan?.features);
  const upgradePlanFeatures: (FeatureObject | undefined)[] = [];
  const { formatMessage } = useIntl();

  if (upgradePlans) {
    for (const plan of upgradePlans) {
      const planFeaturesToAdd = parseFeatureList(plan.features);
      upgradePlanFeatures.push(planFeaturesToAdd);
    }
  }
  const upgradePlansList = upgradePlans?.map(tier => {
    return (
      <th
        className={styles.colTierLabel}
        key={tier.planTier}
        data-testid="planHeader"
      >
        {tier.planTier.toLowerCase()}
      </th>
    );
  });
  let featureTable: JSX.Element[] = [];
  if (!loading && fullFeatureList?.featuresByCategory) {
    featureTable = fullFeatureList.featuresByCategory.map(category => {
      return (
        <div
          key={category.featureCategory}
          className={
            upgradePlans?.length === 0
              ? styles.featureTableOneColumn
              : styles.featureTable
          }
        >
          <Table>
            <thead>
              <tr>
                <th
                  className={styles.colCategoryLabel}
                  data-testid="planHeader"
                >
                  {category.featureCategory}
                </th>
                <th className={styles.colTierLabel} data-testid="planHeader">
                  {currentPlan.planTier.toLowerCase()}
                </th>
                {upgradePlansList}
              </tr>
            </thead>

            <tbody className={styles.tableBody}>
              {category.features.map(item => {
                return (
                  <tr key={item.featureId} className={styles.featureRowLabel}>
                    <td className={styles.featureRowLabel}>
                      <Disclosure title={item.featureLabel}>
                        <Content>
                          <Text>
                            {item.featureDetails}{" "}
                            {item.learnMoreLink && (
                              <>
                                <a
                                  href={item.learnMoreLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {formatMessage(messages.learnMoreLabel)}
                                </a>
                                {"."}
                              </>
                            )}
                          </Text>
                        </Content>
                      </Disclosure>
                    </td>
                    {currentPlan &&
                      findFeatureMatch(
                        currentPlanFeatures,
                        item.featureId,
                        currentPlan?.planTier,
                        currentPlan?.jobberPaymentsRate,
                        currentPlan?.mobileCardReaderRate,
                        formatMessage(messages.featureAvailable),
                        formatMessage(messages.featureUnavailable),
                        formatMessage(messages.featureCost, {
                          percent: currentPlan?.jobberPaymentsRate?.percent,
                          centsPerCharge:
                            currentPlan?.jobberPaymentsRate?.centsPerCharge,
                        }),
                        formatMessage(messages.featureCost, {
                          percent: currentPlan?.mobileCardReaderRate?.percent,
                          centsPerCharge:
                            currentPlan?.mobileCardReaderRate?.centsPerCharge,
                        }),
                      )}

                    {upgradePlans &&
                      upgradePlanFeatures.map((plan, index) => {
                        return findFeatureMatch(
                          plan,
                          item.featureId,
                          upgradePlans[index].planTier,
                          upgradePlans[index].jobberPaymentsRate,
                          upgradePlans[index].mobileCardReaderRate,
                          formatMessage(messages.featureAvailable),
                          formatMessage(messages.featureUnavailable),
                          formatMessage(messages.featureCost, {
                            percent:
                              upgradePlans[index].jobberPaymentsRate?.percent,
                            centsPerCharge:
                              upgradePlans[index].jobberPaymentsRate
                                ?.centsPerCharge,
                          }),
                          formatMessage(messages.featureCost, {
                            percent:
                              upgradePlans[index].mobileCardReaderRate?.percent,
                            centsPerCharge:
                              upgradePlans[index].mobileCardReaderRate
                                ?.centsPerCharge,
                          }),
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    });
  }

  return (
    <div className={styles.tablesContainer}>
      <div ref={stickRef}></div>
      <StickyHeader
        currentPlan={currentPlan}
        currentPlanCost={currentPlanCost}
        upgradePlans={upgradePlans}
        isPricingAnnual={isAnnual}
        showStickyHeader={!inView}
        activePromotion={activePromotion}
        canUpgrade={canUpgrade}
      />
      <div className={styles.featureRows}>{featureTable}</div>
    </div>
  );
}

function findFeatureMatch(
  featurePlanList: FeatureObject | undefined,
  featureId: string,
  planTier: string,
  jobberPaymentsRate?: PlanRate,
  mobileCardReaderRate?: PlanRate,
  featureAvailableLabel?: string,
  featureUnavailableLabel?: string,
  jobberPaymentsCostLabel?: string,
  mobileCardReaderCostLabel?: string,
) {
  if (featurePlanList) {
    if (
      ((featurePlanList[featureId] && featureId === "online_payment") ||
        (featurePlanList[featureId] && featureId === "automatic_payments")) &&
      !!jobberPaymentsRate
    ) {
      return (
        <td
          key={`${planTier}-${featureId}`}
          data-testid={`${planTier}-${featureId}`}
          className={styles.featureCell}
        >
          {jobberPaymentsCostLabel}
        </td>
      );
    } else if (
      featurePlanList[featureId] &&
      featureId === "mobile_card_reader" &&
      !!mobileCardReaderRate
    ) {
      return (
        <td
          key={`${planTier}-${featureId}`}
          data-testid={`${planTier}-${featureId}`}
          className={styles.featureCell}
        >
          {mobileCardReaderCostLabel}
        </td>
      );
    } else if (featurePlanList[featureId]) {
      return (
        <td
          key={`${planTier}-${featureId}`}
          data-testid={`${planTier}-${featureId}`}
          className={styles.featureCell}
          aria-label={featureAvailableLabel}
        >
          <Icon name="checkmark" />
        </td>
      );
    } else {
      return (
        <td
          key={`${planTier}-${featureId}`}
          data-testid={`${planTier}-${featureId}`}
          className={`${styles.featureCell} ${styles.unavailableFeature}`}
          aria-label={featureUnavailableLabel}
        >
          -
        </td>
      );
    }
  }
}

function parseFeatureList(
  featurePlanList: { featureId: string }[] | undefined,
) {
  if (featurePlanList) {
    const parsedFeatureList: FeatureObject = {};

    for (const feature of featurePlanList) {
      parsedFeatureList[feature.featureId] = feature.featureId;
    }
    return parsedFeatureList;
  }
}
