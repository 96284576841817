import { gql } from "@apollo/client";

export const GROW_TRIAL_ELIGIBILITY = gql`
  query GrowTrialEligibility {
    accountFeatureTrialEligibility {
      eligibleForConnectToGrowTrial
    }
  }
`;

export interface GrowTrialEligibilityData {
  accountFeatureTrialEligibility: {
    eligibleForConnectToGrowTrial: boolean;
  };
}

export const ACTIVATE_GROW_TRIAL = gql`
  mutation ActivateConnectToGrowTrial($input: ActivateConnectToGrowTrialInput) {
    activateConnectToGrowTrial(input: $input) {
      membership {
        id
        experimentId
        variationId
        abTestableId
        abTestableType
      }
      userErrors {
        message
      }
    }
  }
`;

export const UPGRADABLE_PLAN_NAME_IDENTIFIER_FOR_GROW = gql`
  query UpgradablePlanNameIdentifierForGrow {
    upgradePlans(filter: { planTier: GROW }) {
      planIdentifier
    }
  }
`;

export interface UpgradablePlanNameIdentifierForGrowData {
  upgradePlans: {
    planIdentifier: string;
  }[];
}
