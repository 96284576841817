import React from "react";
import { Grid } from "@jobber/components/Grid";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { SubscriptionAddonCard } from "~/jobber/billing/components/SubscriptionAddonCard/SubscriptionAddonCard";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import { isAddonSelected } from "~/shared/billing/pricePreview/utils";
import styles from "./SubscriptionAddonCards.module.css";
import { messages } from "./messages";

interface SubscriptionAddonCardsProps {
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  onSelectAddon: (addonCode?: string) => void;
  onDeselectAddon: (addonCode?: string) => void;
}

export function SubscriptionAddonCards(props: SubscriptionAddonCardsProps) {
  const {
    subscriptionAddons,
    selectedAddonCodes,
    onSelectAddon,
    onDeselectAddon,
  } = props;

  const { formatMessage } = useIntl();

  if (!subscriptionAddons.length) {
    return null;
  }

  return (
    <div className={styles.container} data-testid="addon-cards">
      <Card>
        <Content>
          <Heading level={4}>{formatMessage(messages.addons)}</Heading>
          <Grid>
            {subscriptionAddons.map((subscriptionAddon, index) => (
              <Grid.Cell
                key={`addon-card-${index}`}
                size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              >
                <SubscriptionAddonCard
                  subscriptionAddon={subscriptionAddon}
                  inactiveCardAction={
                    isAddonSelected(selectedAddonCodes, subscriptionAddon) ? (
                      <Button
                        size={"small"}
                        type={"secondary"}
                        variation={"destructive"}
                        label={formatMessage(messages.remove)}
                        ariaLabel={formatMessage(messages.removeAriaLabel, {
                          addonName: subscriptionAddon.name,
                        })}
                        onClick={() =>
                          onDeselectAddon(
                            subscriptionAddon.monthlyBillingCycle.addonCode,
                          )
                        }
                      />
                    ) : (
                      <Button
                        size={"small"}
                        label={formatMessage(messages.addToPlan)}
                        ariaLabel={formatMessage(messages.addToPlanAriaLabel, {
                          addonName: subscriptionAddon.name,
                        })}
                        onClick={() =>
                          onSelectAddon(
                            subscriptionAddon.monthlyBillingCycle.addonCode,
                          )
                        }
                      />
                    )
                  }
                />
              </Grid.Cell>
            ))}
          </Grid>
        </Content>
      </Card>
    </div>
  );
}
