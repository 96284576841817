import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalHeader: {
    id: "customFieldArchiveModal.modalHeader",
    defaultMessage: "Archive {appName} custom field",
    description: "Text to display in the modal title",
  },
  modalInitialContent: {
    id: "customFieldArchiveModal.modalInitialContent",
    defaultMessage: "Archiving this custom field means",
    description: "Initial content within the modal",
  },
  modalBoldContent: {
    id: "customFieldArchiveModal.modalBoldContent",
    defaultMessage:
      "it will not appear on any new {workObjectName} created, but will remain visible on any current or past {workObjectName}",
    description: "Bolded text within the modal",
  },
  modalEndingContent: {
    id: "customFieldArchiveModal.modalEndingContent",
    defaultMessage:
      "If you change your mind, you can unarchive this custom field to restore it. You will also be able to permanently delete it.",
    description: "Ending content within the modal",
  },
  appModalArchivedHeader: {
    id: "customFieldArchiveModal.appModalArchivedHeader",
    defaultMessage: "Cannot archive {appName} custom fields",
    description: "Text to display in the modal title",
  },
  appModalUnarchivedHeader: {
    id: "customFieldArchiveModal.appModalUnarchivedHeader",
    defaultMessage: "Cannot unarchive {appName} custom fields",
    description: "Text to display in the modal title",
  },
  appModalArchivedIntitialContent: {
    id: "customFieldArchiveModal.appModalArchivedInitialContent",
    defaultMessage:
      "Archiving {appName} custom fields is not possible while your Jobber account is connected to {appName}.",
    description: "Initial content within the modal",
  },
  appModalArchivedEndingContent: {
    id: "customFieldArchiveModal.appModalArchivedEndingContent",
    defaultMessage:
      "Disconnect {appName} first, and then its custom fields will be archived automatically.",
    description: "Ending content within the modal",
  },
  appModalUnarchivedContent: {
    id: "customFieldArchiveModal.appModalUnarchivedContent",
    defaultMessage:
      "You need to reconnect the {appName} app before unarchiving this field.",
    description: "Content within the modal",
  },
});
