/* eslint-disable import/no-internal-modules */
import { Button } from "@jobber/components/Button";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Page } from "@jobber/components/Page";
import { Text } from "@jobber/components/Text";
import React, {
  type MutableRefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { InlinePrompt } from "components/InlinePrompt";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import {
  JPayAutoenableTurnOffReasonModal,
  ctaName as jPayAutoenableTurnOffReasonModalCtaName,
} from "jobber/jPayAutoenable/components/JPayAutoenableTurnOffReasonModal";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type {
  AchProcessingRate,
  ProcessingRates,
} from "~/jobber/managed_accounts/ProcessingRateBadges/types";
import { BankDetails, type BankDetailsProps } from "./BankDetails";
import { InstantPayoutDetails } from "./InstantPayoutDetails";
import { NotificationSettings } from "./Settings/NotificationSettingsProvider";
import { PaymentOptionsCard } from "./Settings/PaymentOptionCard";
import styles from "./JobberPaymentsSettings.module.css";
import { remoteBooleanReducer } from "./remoteBooleanReducer";
import { CardReaders, type CardReadersProps } from "./CardReaders";
import { ClientHubSettings } from "./Settings/ClientHubSettingsProvider";
import { PaymentsCard, type PaymentsCardProps } from "./PaymentsCard";

export interface JobberPaymentsSettingsProps {
  bankDetailsProps: BankDetailsProps;
  processingRates: ProcessingRates;
  instantPayoutDebitCardInfo?: CreditCard;
  cardReaderProps: CardReadersProps;
  hasLoan: boolean;
  newSignUpSettingsEnabled: boolean;
  payoutsErrorMessage?: string;
  achProcessingRate?: AchProcessingRate;
  paymentCard: PaymentsCardProps;
  shouldShowJobberPaymentCard: boolean;
  shouldShowDisputesRow: boolean;
}

// eslint-disable-next-line max-statements
export function JobberPaymentsSettings(props: JobberPaymentsSettingsProps) {
  const {
    bankDetailsProps,
    hasLoan,
    newSignUpSettingsEnabled,
    paymentCard,
    shouldShowJobberPaymentCard,
    shouldShowDisputesRow,
  } = props;
  const {
    enabled: globalEnabled,
    setEnabled: setGlobalEnabled,
    permissions,
    tipsEnabled,
  } = useJobberPayments();
  const [authenticityToken] = useAuth();
  const [
    jobberPaymentsPayoutsPath,
    jobberPaymentsTransactionsPath,
    stripeCapitalReportLinkPath,
  ] = useUrls(
    "jobberPaymentsPayoutsPath",
    "jobberPaymentsTransactionsPath",
    "stripeCapitalReportLinkPath",
  );
  const [state, dispatch] = useReducer(remoteBooleanReducer, {
    type: "idle",
    targetEnable: undefined,
    currentState: globalEnabled,
  });
  const [instantPayoutDebitCardInfo, setInstantPayoutDebitCardInfo] = useState(
    props.instantPayoutDebitCardInfo,
  );
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const jPayAutoenableReminderCtaRef =
    useRef() as MutableRefObject<CallToActionRef>;
  const [t] = useTranslation();
  const [showJPayTurnOffReasonModal, setShowJPayTurnOffReasonModal] =
    useState(false);

  const [toggleAchFlag, setToggleAchFlag] = useState(false);
  const [updateDefaultPreference, setUpdateDefaultPreference] = useState({
    preference: "",
    achEnabled: false,
  });

  const tipsCTAText = tipsEnabled
    ? "Your clients can now include a tip when paying invoices through client hub! To disable this, change your settings below."
    : "Want to receive tips from clients when they’re paying invoices through client hub? Change your settings below to enable tip collection.";

  useEffect(() => {
    if (state.type !== "fetching") return;

    const { targetEnable } = state;
    let mounted = true;

    async function disableJPay() {
      try {
        const response = await fetch(
          "/jobber_payments/set_managed_account_state",
          {
            method: "POST",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              enable: targetEnable,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              authenticity_token: authenticityToken,
            }),
          },
        );

        if (!mounted) return;
        if (!response.ok) {
          dispatch({ type: "reset" });
          return;
        }

        dispatch({ type: "success", updatedEnable: targetEnable });
      } catch (error) {
        if (!mounted) return;
        dispatch({ type: "reset" });
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    disableJPay();

    return () => void (mounted = false);
  }, [state]);

  useEffect(() => {
    if (state.type !== "success") return;

    setGlobalEnabled(state.currentState);
  }, [state]);

  const mergedPaymentCardProps = {
    showHeader: false,
    ...paymentCard,
  };

  const showJobberPaymentCard =
    shouldShowJobberPaymentCard ||
    (!shouldShowJobberPaymentCard && shouldShowDisputesRow);

  return (
    <div className={styles.pageWrapper}>
      <Page title={t("jobberPaymentsTitle")}>
        {!state.currentState && !newSignUpSettingsEnabled && (
          <Banner
            {...(permissions.canEnablePayments && {
              primaryAction: {
                label: "Turn On",
                onClick: onEnableJobberPaymentsClick,
              },
            })}
            type="notice"
          >
            {permissions.canEnablePayments
              ? "Jobber Payments is currently turned off, turn it on to collect card payments"
              : "Jobber Payments is currently turned off, you'll need your account owner to turn it on to collect payments"}
          </Banner>
        )}
        <div className={styles.container}>
          <section className={styles.bankDetails}>
            <BankDetails {...bankDetailsProps} />

            <Content>
              {permissions.canViewInstantPayouts && (
                <>
                  <hr />
                  <InstantPayoutDetails
                    debitCard={instantPayoutDebitCardInfo}
                    onDebitCardChange={setInstantPayoutDebitCardInfo}
                    isJobberMoneyPayout={
                      paymentCard.jobberMoney.payoutIsJobberMoneyAccount
                    }
                  />
                </>
              )}
              <hr />
              {showJobberPaymentCard && (
                <PaymentsCard
                  {...mergedPaymentCardProps}
                  shouldShowJobberPaymentCard={shouldShowJobberPaymentCard}
                />
              )}

              <Content spacing="small">
                <Button
                  label="View bank payouts"
                  type="primary"
                  url={jobberPaymentsPayoutsPath}
                  fullWidth={true}
                />
                <Button
                  label="View transactions"
                  type="secondary"
                  url={jobberPaymentsTransactionsPath}
                  fullWidth={true}
                />
                {hasLoan && (
                  <Button
                    label="View loan report"
                    type="secondary"
                    url={stripeCapitalReportLinkPath}
                    fullWidth={true}
                    external={true}
                  />
                )}
              </Content>
            </Content>
          </section>
          <section className={styles.notifications}>
            <Content spacing="large">
              <APIProvider>
                <CallToAction
                  ctaName={"jobber_payments_settings_tips_helper"}
                  ref={ctaRef}
                >
                  <InlinePrompt
                    title={tipsCTAText}
                    handleDismiss={dismissCTA(ctaRef)}
                  />
                </CallToAction>
              </APIProvider>
              <PaymentOptionsCard
                toggleAchSwitchFlag={toggleAchFlag}
                setUpdateDefaultPreference={setUpdateDefaultPreference}
              />

              <ClientHubSettings
                dismissCta={dismissCTA(ctaRef)}
                setToggleAchFlag={setToggleAchFlag}
                updateDefaultPreference={updateDefaultPreference}
              />

              <NotificationSettings />

              <CardReaders {...props.cardReaderProps} />
            </Content>
          </section>
          {state.currentState && permissions.canDisablePayments && (
            <section>
              <Content spacing="small">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- Grandfathered error: Please fix if touching this code. */}
                  <a href="javascript:;" onClick={onDisableJobberPaymentsClick}>
                    <Emphasis variation="bold">
                      DISABLE JOBBER PAYMENTS
                    </Emphasis>
                  </a>
                </div>
                <Text>
                  Clients will not be able to pay any outstanding or new quotes
                  and invoices online.
                </Text>
              </Content>
            </section>
          )}
        </div>
        {showJPayTurnOffReasonModal && (
          <APIProvider>
            <CallToAction
              ref={jPayAutoenableReminderCtaRef}
              ctaName={jPayAutoenableTurnOffReasonModalCtaName}
            >
              <JPayAutoenableTurnOffReasonModal
                dismissCTA={dismissCTA(jPayAutoenableReminderCtaRef)}
                convertCTA={convertCTA(jPayAutoenableReminderCtaRef)}
              />
            </CallToAction>
          </APIProvider>
        )}
      </Page>
    </div>
  );

  function onEnableJobberPaymentsClick() {
    if (state.type === "fetching") return;

    dispatch({ type: "start", targetEnable: true });
  }

  function onDisableJobberPaymentsClick() {
    if (state.type === "fetching") return;

    dispatch({ type: "start", targetEnable: false });

    setShowJPayTurnOffReasonModal(true);
  }
}
