// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";

export interface DateRange {
  after: Date | undefined;
  before: Date | undefined;
}

export interface PresetRange {
  preset: DateRanges;
  range: DateRange;
}

// These strings are related to the Intl keys in messages.ts
export enum DateRanges {
  all = "dateRangeAll",
  week = "dateRangeWeek",
  thirtyDays = "dateRangeThirtyDays",
  month = "dateRangeMonth",
  year = "dateRangeYear",
  twelveMonths = "dateRangeTwelveMonths",
  thisMonth = "dateRangeThisMonth",
  custom = "dateRangeCustom",
}

export const defaultDateRangeOptions: DateRangeOptions[] = [
  DateRanges.all,
  DateRanges.week,
  DateRanges.thirtyDays,
  DateRanges.month,
  DateRanges.year,
  DateRanges.twelveMonths,
  DateRanges.custom,
];

export type DateRangeOptions =
  | DateRanges.all
  | DateRanges.week
  | DateRanges.thirtyDays
  | DateRanges.month
  | DateRanges.year
  | DateRanges.twelveMonths
  | DateRanges.custom
  | DateRanges.thisMonth;

// Any new DateRangeOptions need to be added to this list
export const presetDateRanges = {
  [DateRanges.all]: {
    after: undefined,
    before: undefined,
  },
  [DateRanges.week]: {
    after: moment()
      .subtract(1, "week")
      .startOf("week")
      .hour(0)
      .minute(0)
      .toDate(),
    before: moment()
      .subtract(1, "week")
      .endOf("week")
      .hour(23)
      .minute(59)
      .toDate(),
  },
  [DateRanges.thirtyDays]: {
    after: moment()
      .subtract(30, "day")
      .startOf("hours")
      .hour(0)
      .minute(0)
      .toDate(),
    before: moment().startOf("hours").hour(23).minute(59).toDate(),
  },
  [DateRanges.month]: {
    after: moment()
      .subtract(1, "month")
      .startOf("month")
      .startOf("hours")
      .hour(0)
      .minute(0)
      .toDate(),
    before: moment()
      .subtract(1, "month")
      .endOf("month")
      .startOf("hours")
      .hour(23)
      .minute(59)
      .toDate(),
  },
  [DateRanges.year]: {
    after: new Date(new Date().getFullYear(), 0, 1),
    before: moment().startOf("hours").hour(23).minute(59).toDate(),
  },
  [DateRanges.twelveMonths]: {
    after: moment()
      .subtract(12, "month")
      .startOf("hours")
      .hour(0)
      .minute(0)
      .toDate(),
    before: moment().startOf("hours").hour(23).minute(59).toDate(),
  },
  [DateRanges.custom]: {
    after: customStartDate(),
    before: customEndDate(),
  },
  [DateRanges.thisMonth]: {
    after: moment().startOf("month").hour(0).minute(0).toDate(),
    before: moment().endOf("month").hour(0).minute(0).toDate(),
  },
};

function customStartDate() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function customEndDate() {
  const date = new Date();
  date.setHours(23, 59, 59, 999);
  return date;
}
