import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadSizeError: {
    id: "campaign.imageUploader.uploadSizeError",
    defaultMessage:
      "The size of the image is too large. Please choose a smaller image.",
    description:
      "Error message for when the user tries to upload an image that is too large",
  },
  uploadButton: {
    id: "campaign.imageUploader.uploadButton",
    defaultMessage: "Upload Image",
    description: "Button text displayed to the user",
  },
});
