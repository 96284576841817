import React from "react";
import styles from "./MessageCenterNotificationBadge.module.css";

export interface MessageCenterNotificationBadgeProps {
  unreadCount: number;
}

export function MessageCenterNotificationBadge({
  unreadCount,
}: MessageCenterNotificationBadgeProps) {
  const unreadLabel = unreadCount > 99 ? "99+" : unreadCount.toString();

  return (
    <div
      className={styles.unreadBadge}
      style={{ width: calculateWidth(unreadCount) }}
    >
      <div className="notifications-number">{unreadLabel}</div>
    </div>
  );
}

function calculateWidth(unreadCount: number) {
  if (unreadCount < 10) {
    return "0.9rem";
  }
  if (unreadCount < 100) {
    return "1.3rem";
  }
  return "1.5rem";
}
