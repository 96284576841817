import { useCallback } from "react";
import {
  type CampaignTemplateCtaLinkTo,
  type CommsCampaignQuery,
  type File,
  IntervalUnits,
  Template,
} from "~/utilities/API/graphql";
import {
  useCampaignCreatePastClientsMutation,
  useCampaignCreateTemplatesMutation,
} from "jobber/campaigns/hooks";
import { useCampaignUpdateTemplatesMutation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignUpdateTemplates";

export function validateUrl(url: string) {
  return validUrlRegex.test(url);
}

export const validUrlRegex = new RegExp(
  /^((https):\/\/)[a-z0-9]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/,
);

export interface UpdateCampaignTemplateArgs {
  onSuccess: (params: { campaignId?: string }) => void;
  onError: (error: string) => void;
  subject: string;
  body: string;
  header: string;
  showLogo: boolean;
  ctaButtonEnabled: boolean;
  linkTo?: CampaignTemplateCtaLinkTo;
  linkValue?: string;
  buttonText?: string;
  buttonColor?: string;
  templateType?: Template;
  ctaVisible?: boolean;
  headerImage?: File;
  imageIds?: string[];
}

export function useHandleUpdateCampaign({
  commsCampaign,
}: {
  commsCampaign?: CommsCampaignQuery["commsCampaign"];
}) {
  const { campaignCreatePastClients } = useCampaignCreatePastClientsMutation();
  const { campaignCreateTemplates } = useCampaignCreateTemplatesMutation();
  const { campaignUpdateTemplates } = useCampaignUpdateTemplatesMutation();

  const updateCampaignTemplates = useCallback(
    // eslint-disable-next-line max-statements
    async ({
      subject,
      body,
      header,
      showLogo,
      ctaButtonEnabled,
      linkTo,
      linkValue,
      buttonText,
      buttonColor,
      templateType = Template.RE_ENGAGE,
      headerImage,
      imageIds,
      onSuccess,
      onError,
    }: UpdateCampaignTemplateArgs) => {
      if (commsCampaign?.id) {
        if (!commsCampaign.templates?.nodes.length) {
          const result = await campaignCreateTemplates({
            input: {
              campaignId: commsCampaign.id,
              templates: [
                {
                  body: body,
                  subject: subject,
                  header: header,
                  showLogo: showLogo,
                  ctaVisible: ctaButtonEnabled,
                  ctaLinkTo: linkTo,
                  ctaUrl: linkValue,
                  ctaText: buttonText,
                  ctaColor: buttonColor,
                  type: templateType,
                  headerImage: headerImage,
                  imageIds: imageIds,
                },
              ],
            },
          });
          if (result.userErrors.length) {
            onError(result.userErrors[0].message);
          } else {
            onSuccess({ campaignId: result.campaign?.id });
          }
        } else {
          const result = await campaignUpdateTemplates({
            input: {
              campaignId: commsCampaign?.id,
              templates: [
                {
                  id: commsCampaign.templates.nodes[0].id,
                  body: body,
                  subject: subject,
                  header: header,
                  showLogo: showLogo,
                  ctaVisible: ctaButtonEnabled,
                  ctaLinkTo: linkTo,
                  ctaUrl: linkValue,
                  ctaText: buttonText,
                  ctaColor: buttonColor,
                  type: templateType,
                  headerImage: headerImage,
                  imageIds: imageIds,
                },
              ],
            },
          });
          if (result.userErrors.length) {
            onError(result.userErrors[0].message);
          } else {
            onSuccess({ campaignId: result.campaign?.id });
          }
        }
      } else {
        const result = await campaignCreatePastClients({
          input: {
            criteria: { interval: 12, unit: IntervalUnits.MONTHS },
            template: {
              body: body,
              subject: subject,
              header: header,
              showLogo: showLogo,
              ctaVisible: ctaButtonEnabled,
              ctaLinkTo: linkTo,
              ctaUrl: linkValue,
              ctaText: buttonText,
              ctaColor: buttonColor,
              type: templateType,
              headerImage: headerImage,
              imageIds: imageIds,
            },
          },
        });
        if (result.userErrors.length) {
          onError(result.userErrors[0].message);
        } else {
          onSuccess({ campaignId: result.campaign?.id });
        }
      }
    },
    [
      commsCampaign?.id,
      commsCampaign?.templates?.nodes,
      campaignCreateTemplates,
      campaignUpdateTemplates,
      campaignCreatePastClients,
    ],
  );
  return {
    updateCampaignTemplates,
  };
}
