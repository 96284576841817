import { Button } from "@jobber/components/Button";
import React from "react";
import { useStartChatWithSuccess } from "jobber/jobberAssistant/hooks";

interface ChatWithSuccessButtonProps {
  message: string;
  requestId: string;
}

export function ChatWithSuccessButton({
  message,
  requestId,
}: ChatWithSuccessButtonProps) {
  const startChatWithSuccess = useStartChatWithSuccess();

  return (
    <Button
      onClick={async () => {
        await startChatWithSuccess(message, requestId);
      }}
      label="Chat with Success"
      fullWidth
    />
  );
}
