/**
 * PlanCard Page Object Model
 *
 * Selectors:
 *
 * - planCardByTestId
 * - planTierByTestId
 * - planTierByDescriptionByTestId
 * - connectDescriptionCopy
 * - loyaltyAndUnlimitedDescriptionCopy
 * - corePlusDescriptionCopy
 * - planCostByTestId
 * - planCostDescriptionByTestId
 * - usersIncludedByTestId
 * - planTopFeaturesListByTestId
 * - seeAllFeaturesByTestId
 * - seeAllFeaturesButton
 * - planPriceAnnualByTestId
 * - cardLabelByTestId
 * - connectHeading
 * - onlineBookingFeatureCopy
 * - automatedRemindersFeatureCopy
 * - automaticPaymentsFeatureCopy
 * - quickBooksOnlineFeatureCopy
 * - scheduleAndManageJobsFeatureCopy
 * - sendQuotesAndInvoicesFeatureCopy
 * - onlinePaymentsFeatureCopy
 * - reportsFeatureCopy
 * - quoteAddOnsFeatureCopy
 * - automatedQuoteFollowupsFeatureCopy
 * - tagAndManageLeadsFeatureCopy
 * - jobCostingFeatureCopy
 * - selfServeFeatureCopy
 * - scheduleJobsFeatureCopy
 * - unlimitedUsersCopy
 * - yourCurrentPlanButton
 * - resubscribeButton
 * - increasePlanUserLimitByTestId
 * - increaseUserLimitButton
 * - noIncreaseUserLimitCtaByTestId
 * - addExtraUserByTestId
 * - addUsersByTestId
 * - yourPlanCopy
 * - everythingInLiteIncludesCopy
 * - everythingInCorePlusCopy
 * - addUsersToThisPlanForCopy
 * - perAdditionalUserCopy
 * - numOfUsersByTestId
 * - choosePlanButton
 * - mostPopularCopy
 * - billedMonthlyCopy
 * - billedAnnuallyCopy
 * - whenBilledMonthlyCopy
 * - whenBilledAnnuallyCopy
 *
 * Simulators:
 *
 * - iClickSeeAllFeaturesButton
 * - iClickResubscribeButton
 * - iClickChoosePlanButton
 * - iClickIncreaseUserLimitButton
 */

import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage: t } = createIntl();

export function planCardByTestId() {
  return screen.getByTestId("planCard");
}

export function planTierByTestId() {
  return screen.getByTestId("planTier");
}

export function planTierByDescriptionByTestId() {
  return screen.getByTestId("planTierDescription");
}

export function connectDescriptionCopy() {
  return screen.getByText(t(messages.connectDescriptionCopy));
}

export function loyaltyAndUnlimitedDescriptionCopy() {
  return screen.getByText(t(messages.loyaltyAndUnlimitedDescriptionCopy));
}

export function corePlusDescriptionCopy() {
  return screen.getByText(t(messages.corePlusDescriptionCopy));
}

export function planCostByTestId() {
  return screen.getByTestId("planCost");
}

export function costDescriptionByTestId() {
  return screen.getByTestId("costDescription");
}

export function usersIncludedByTestId() {
  return screen.getByTestId("usersIncluded");
}

export function planTopFeaturesListByTestId() {
  return screen.getByTestId("planTopFeaturesList");
}

export function seeAllFeaturesByTestId() {
  return screen.getByTestId("seeAllFeatures");
}

export function seeAllFeaturesButton() {
  return screen.getByRole("button", {
    name: t(messages.seeAllFeaturesButtonCopy),
  });
}

export function planPriceAnnualByTestId() {
  return screen.getByTestId("planPriceAnnual");
}

export function cardLabelByTestId() {
  return screen.getByTestId("cardLabel");
}

export function connectHeading() {
  return screen.getByRole("heading", { name: /Connect/i });
}

export function onlineBookingFeatureCopy() {
  return screen.getByText(t(messages.onlineBookingFeatureCopy));
}

export function automatedRemindersFeatureCopy() {
  return screen.getByText(t(messages.automatedRemindersFeatureCopy));
}

export function automaticPaymentsFeatureCopy() {
  return screen.getByText(t(messages.automaticPaymentsFeatureCopy));
}

export function quickBooksOnlineFeatureCopy() {
  return screen.getByText(t(messages.quickBooksOnlineFeatureCopy));
}

export function scheduleAndManageJobsFeatureCopy() {
  return screen.getByText(t(messages.scheduleAndManageJobsFeatureCopy));
}

export function sendQuotesAndInvoicesFeatureCopy() {
  return screen.getByText(t(messages.sendQuotesAndInvoicesFeatureCopy));
}

export function onlinePaymentsFeatureCopy() {
  return screen.getByText(t(messages.onlinePaymentsFeatureCopy));
}

export function reportsFeatureCopy() {
  return screen.getByText(t(messages.reportsFeatureCopy));
}

export function quoteAddOnsFeatureCopy() {
  return screen.getByText(t(messages.quoteAddOnsFeatureCopy));
}

export function automatedQuoteFollowupsFeatureCopy() {
  return screen.getByText(t(messages.automatedQuoteFollowupsFeatureCopy));
}

export function tagAndManageLeadsFeatureCopy() {
  return screen.getByText(t(messages.tagAndManageLeadsFeatureCopy));
}

export function jobCostingFeatureCopy() {
  return screen.getByText(t(messages.jobCostingFeatureCopy));
}

export function selfServeFeatureCopy() {
  return screen.getByText(t(messages.selfServeFeatureCopy));
}

export function scheduleJobsFeatureCopy() {
  return screen.getByText(t(messages.scheduleJobsFeatureCopy));
}

export function unlimitedUsersCopy() {
  return screen.getByText(t(messages.unlimitedUsers));
}

export function yourCurrentPlanButton() {
  return screen.getByRole("button", {
    name: t(messages.yourCurrentPlanButtonCopy),
  });
}

export function resubscribeButton() {
  return screen.getByText(t(messages.resubscribeButtonCopy));
}

export function increasePlanUserLimitByTestId() {
  return screen.getByTestId("increasePlanUserLimit");
}

export function increaseUserLimitButton() {
  return screen.getByRole("button", {
    name: t(messages.increaseUserLimitButtonCopy),
  });
}

export function noIncreaseUserLimitCtaByTestId() {
  return screen.getByTestId("noIncreaseUserLimitCta");
}

export function addExtraUserByTestId() {
  return screen.getByTestId("addExtraUser");
}

export function addUsersByTestId() {
  return screen.getByTestId("addUsers");
}

export function yourPlanCopy() {
  return screen.getByText(t(messages.currentPlan));
}

export function everythingInLiteIncludesCopy() {
  return screen.getByText("Everything in Lite includes");
}

export function everythingInCorePlusCopy() {
  return screen.getByText("Everything in Core, plus");
}

export function addUsersToThisPlanForCopy() {
  return screen.getByText("Add users to this plan for");
}

export function perAdditionalUserCopy() {
  return screen.getByText("per additional user");
}

export function numOfUsersByTestId() {
  return screen.getByTestId("numOfUsers");
}

export function legacyChoosePlanButton() {
  return screen.getByText(t(messages.choosePlanButtonCopy));
}

export function choosePlanButton(tierDisplayName: string) {
  return screen.getByLabelText(`Choose ${tierDisplayName}`);
}

export function mostPopularCopy() {
  return screen.getByText(t(messages.mostPopularPlan));
}

export function billedMonthlyCopy() {
  return screen.getByText(/billed monthly/i);
}

export function billedAnnuallyCopy() {
  return screen.getByText(/billed annually/i);
}

export function whenBilledMonthlyCopy() {
  return screen.getByText(/when billed monthly/i);
}

export function whenBilledAnnuallyCopy() {
  return screen.getByText(/when billed annually/i);
}

/***************** Simulators ********************/

export function iClickSeeAllFeaturesButton() {
  seeAllFeaturesButton().click();
}

export function iClickResubscribeButton() {
  resubscribeButton().click();
}

export function iClickLegacyChoosePlanButton() {
  legacyChoosePlanButton().click();
}

export function iClickChoosePlanButton(tierDisplayName: string) {
  choosePlanButton(tierDisplayName).click();
}

export function iClickIncreaseUserLimitButton() {
  increaseUserLimitButton().click();
}
