import { defineMessages } from "react-intl";

export const messages = defineMessages({
  // Feature Highlights
  firstRowTitle: {
    id: "reviews.purchase.firstRowTitle",
    defaultMessage: "Build a trusted brand",
    description: "Title for the first row of content",
  },
  firstRowBody: {
    id: "reviews.purchase.firstRowBody",
    defaultMessage:
      "Getting more reviews from your preferred clients has never been easier. Automatically ask clients for a review via text message once they’ve paid their invoice, and Jobber will take care of the rest.",
    description: "Body for the first row of content",
  },
  secondRowTitle: {
    id: "reviews.purchase.secondRowTitle",
    defaultMessage: "Protect your reputation",
    description: "Title for the second row of content",
  },
  secondRowBody: {
    id: "reviews.purchase.secondRowBody",
    defaultMessage:
      "Keep tabs on your current Google rating and review count from your dashboard. As reviews come in, respond to them and get an extra boost in Google's search results.",
    description: "Body for the second row of content",
  },
  thirdRowTitle: {
    id: "reviews.purchase.thirdRowTitle",
    defaultMessage: "Attract more clients",
    description: "Title for the third row of content",
  },
  thirdRowBody: {
    id: "reviews.purchase.thirdRowBody",
    defaultMessage:
      "As your rating and review count increases, your placement on Google will too. Making it easier for potential clients to find—and hire—you.",
    description: "Body for the third row of content",
  },
  // Marketing Header
  headingTitle: {
    id: "reviews.purchase.headingTitle",
    defaultMessage: "Get more 5-star reviews with Reviews",
    description: "Bolded title text for the top heading",
  },
  headingBody: {
    id: "reviews.purchase.headingBody",
    defaultMessage:
      "90% of customers say reviews influence their decision to hire a service provider. Jobber makes it easy to get more reviews from preferred customers—without impacting your workflow—so you can attract even more clients in the future.",
    description: "Paragraph text for the top heading",
  },
  footerToolboxTitle: {
    id: "reviews.purchase.footerToolboxTitle",
    defaultMessage: "Another tool in your <highlight>toolbox</highlight>",
    description: "Title text to emphasize the Reviews product.",
  },
  footerPricing: {
    id: "reviews.purchase.footerPricing",
    defaultMessage: "Add Reviews to your plan for",
    description: "Pricing text that describes the cost of the product.",
  },
  inTrialButton: {
    id: "reviews.purchase.inTrialButton",
    defaultMessage: "Choose Plan and Add Reviews",
    description: "Button text for users in trial",
  },
  //Coming Soon
  comingSoonTitle: {
    id: "reviews.purchase.comingSoonBody",
    defaultMessage: "Coming up next...",
    description: "Title text for the coming soon section",
  },
  comingSoonBody: {
    id: "reviews.purchase.comingSoonTitle",
    defaultMessage:
      "Improve your review success by having more flexibility with your messages",
    description: "Body text for the coming soon section",
  },
  signUpButton: {
    id: "reviews.purchase.signUpButton",
    defaultMessage: "Sign Up for Updates",
    description: "Button to sign up for updates on the upcoming feature",
  },
  //Trial
  trialFooter: {
    id: "reviews.purchase.trialFooter",
    defaultMessage:
      "Reviews is available as an add-on to Jobber plans. You must select a plan before adding Reviews.",
    description: "Footer for the trial button",
  },
});
