import React, { useState } from "react";
import { Banner } from "@jobber/components";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { UkKycRejectedState } from "jobber/chat/components/UkKycRegistration/UkKycRejectedState";
import { UkKycInitialState } from "jobber/chat/components/UkKycRegistration/UkKycInitialState";
import { UkKycApprovedState } from "jobber/chat/components/UkKycRegistration/UkKycApprovedState";
import { TwilioKycStatus } from "./TwilioKycStatus";
import styles from "./UkKycRegistration.module.css";
import { wrapperMessages } from "./wrapperMessages";

export interface KycRegistrationProps {
  showBanner?: boolean;
}

function UkKycRegistrationContent(props: KycRegistrationProps) {
  // This will be getting used in the next ticket
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState<TwilioKycStatus | undefined>();
  const { formatMessage } = useIntl();

  const wrapperClassNames = classnames(
    props.showBanner ? ["u-borderBottom"] : [],
  );

  const childClassNames = classnames(
    styles.childWrapper,
    styles.extraPadding,
    props.showBanner ? [] : ["u-borderBottom"],
  );

  function renderTwilioStateChild() {
    switch (status) {
      case TwilioKycStatus.INITIAL:
        return (
          <UkKycInitialState
            onClick={() => (window.location.href = "/text_messaging_settings")}
          />
        );
      case TwilioKycStatus.TWILIO_REJECTED:
        return (
          <UkKycRejectedState
            onClick={() => (window.location.href = "/text_messaging_settings")}
          />
        );
      case TwilioKycStatus.TWILIO_APPROVED:
        return (
          <UkKycApprovedState
            onClick={() => alert("Hook into mutation here 👍")}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <div className={wrapperClassNames}>
        {props.showBanner && status != TwilioKycStatus.TWILIO_APPROVED && (
          <div className={styles.bannerWrapper}>
            <Banner type="warning" dismissible={false}>
              {formatMessage(wrapperMessages.bannerMessage)}
            </Banner>
          </div>
        )}
      </div>
      <div className={childClassNames}>{renderTwilioStateChild()}</div>
    </>
  );
}

export function UkKycRegistration(props: KycRegistrationProps) {
  return (
    <IntlProvider>
      <UkKycRegistrationContent {...props} />
    </IntlProvider>
  );
}
