import React, {
  type MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import { Heading } from "@jobber/components/Heading";
import type {
  AccountAddonInfo,
  AddonDiscountGroup,
  AddonPreviewGroup,
  MutationErrors,
} from "~/utilities/API/graphql";
import {
  EditBillingInfo,
  type EditBillingInfoRef,
} from "jobber/billing/components/EditBillingInfo";
import { trackViewedRecurlyForm } from "jobber/billing/utils/trackInteractions";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PriceHighlightText } from "jobber/billing/components/PriceHighlightText/PriceHighlightText";
import { AddonPurchasePreview } from "./components/AddonPurchasePreview";
import styles from "./AddonPurchaseForm.module.css";

interface AddonPurchaseFormProps {
  recurlyPublicKey: string;
  accountAddonInfo: Omit<AccountAddonInfo, "isActive">;
  addonPreviewGroup: AddonPreviewGroup;
  addonDiscountGroup?: AddonDiscountGroup;
  startBillingOnDate?: string;
  trackingDetails: TrackingDetails;
  setSaveEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowParentActions?: React.Dispatch<React.SetStateAction<boolean>>;
  onValidationError(error: FieldErrorState): void;
  onSubmitBillingInfoSuccess(): void;
  onSubmitBillingInfoError(errors: MutationErrors[]): void;
}

export const AddonPurchaseForm = forwardRef(InternalAddonPurchaseForm);

function InternalAddonPurchaseForm(
  props: AddonPurchaseFormProps,
  ref: MutableRefObject<EditBillingInfoRef>,
) {
  const {
    recurlyPublicKey,
    accountAddonInfo,
    addonPreviewGroup,
    addonDiscountGroup,
    startBillingOnDate,
    trackingDetails,
    setSaveEnabled,
    setShowParentActions,
    onValidationError,
    onSubmitBillingInfoSuccess,
    onSubmitBillingInfoError,
  } = props;

  const { name, addonName } = trackingDetails;

  const trackFormView = useCallback(
    () =>
      trackViewedRecurlyForm({
        name,
        addonName,
      }),
    [name, addonName],
  );

  useEffect(() => {
    if (addonName) {
      trackFormView();
    }
  }, [addonName, trackFormView]);

  const monthlyCost = accountAddonInfo.monthlyBillingCycle.monthlyCost;
  const currency = accountAddonInfo.currency;
  const monthlyDiscountedCost =
    addonDiscountGroup?.monthlyAddonDiscount?.addonCostMonthlyDiscounted;
  const hasMultipleActiveDiscounts =
    addonDiscountGroup?.monthlyAddonDiscount?.hasMultipleActiveDiscounts;

  return (
    <>
      <div className={styles.purchaseFormHeader}>
        <Heading level={1}>{accountAddonInfo.name}</Heading>
        <PriceHighlightText
          cost={monthlyCost}
          discountedCost={monthlyDiscountedCost}
          hideDiscountedCost={hasMultipleActiveDiscounts}
          size="large"
        />
      </div>
      <EditBillingInfo
        recurlyPublicKey={recurlyPublicKey}
        ref={ref}
        isPurchasing={true}
        showPreview={true}
        trackingDetails={trackingDetails}
        setSaveEnabled={setSaveEnabled}
        setShowParentActions={setShowParentActions}
        onSubmitSuccess={onSubmitBillingInfoSuccess}
        onSubmitError={onSubmitBillingInfoError}
        onValidationError={onValidationError}
      />
      <AddonPurchasePreview
        selectedPurchasePreview={addonPreviewGroup.monthlyPreview}
        selectedDiscount={addonDiscountGroup?.monthlyAddonDiscount}
        monthlyCost={monthlyCost}
        startBillingOnDate={startBillingOnDate}
        currency={currency}
      />
    </>
  );
}
