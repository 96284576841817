import React from "react";
import { useIntl } from "react-intl";
import { Chip, Chips } from "@jobber/components/Chips";
import type { AddPaymentMethodState } from "./AddPaymentMethod";
import { messages } from "./messages";

interface PaymentMethodChipsProps {
  selectedPaymentMethod: string;
  onSelectedPaymentMethodChange: (value: AddPaymentMethodState) => void;
}

export const PaymentMethodChips: React.FC<PaymentMethodChipsProps> = ({
  selectedPaymentMethod,
  onSelectedPaymentMethodChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Chips
      selected={selectedPaymentMethod}
      onChange={onSelectedPaymentMethodChange}
      name={formatMessage(messages.chipPaymentMethodName)}
      type="singleselect"
    >
      <Chip label={formatMessage(messages.creditCard)} value="Credit card" />
      <Chip label={formatMessage(messages.bankAccount)} value="Bank account" />
    </Chips>
  );
};
