import { useMutation } from "@apollo/client";
import type {
  IntercomConversationCreateMutation,
  IntercomConversationCreateMutationVariables,
} from "~/utilities/API/graphql";
import { INTERCOM_CONVERSATION_CREATE } from "./IntercomConversationCreate.graphql";

export const useCreateIntercomConversation = () => {
  const [createConversation] = useMutation<
    IntercomConversationCreateMutation,
    IntercomConversationCreateMutationVariables
  >(INTERCOM_CONVERSATION_CREATE);

  return async (message: string, jobberAssistantRequestId: string) => {
    const result = await createConversation({
      variables: {
        input: {
          userInitiatedMessage: message,
          uniqueIdentifier: jobberAssistantRequestId,
        },
      },
    });
    return result.data?.intercomConversationCreate?.conversationId;
  };
};
