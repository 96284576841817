import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import {
  AddonPurchaseModal,
  type ModalActionProps,
  type PurchaseModalButtonProps,
} from "./AddonPurchaseModal";

export interface AddonPurchaseModalLoaderProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  successAction?: () => void;
  buttonProps?: PurchaseModalButtonProps;
  modalActionProps?: ModalActionProps;
  onDismiss?: () => void;
  onOpen?: () => void;
}

export function AddonPurchaseModalLoader(props: AddonPurchaseModalLoaderProps) {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    successAction,
    onDismiss,
    buttonProps,
    modalActionProps,
    onOpen,
  } = props;

  return (
    <IntlProvider>
      <APIProvider>
        <AddonPurchaseModal
          recurlyPublicKey={recurlyPublicKey}
          addonSetIdentifier={addonSetIdentifier}
          successAction={successAction}
          onDismiss={onDismiss}
          buttonProps={{
            buttonSize: buttonProps?.buttonSize,
            buttonText: buttonProps?.buttonText,
            buttonFullWidth: buttonProps?.buttonFullWidth,
            onClick: buttonProps?.onClick,
            buttonType: buttonProps?.buttonType,
            loading: buttonProps?.loading,
          }}
          modalActionProps={{
            buttonText: modalActionProps?.buttonText,
            onClick: modalActionProps?.onClick,
          }}
          onOpen={onOpen}
        />
      </APIProvider>
    </IntlProvider>
  );
}
