import React from "react";
import classNames from "classnames";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { PrimaryActionButton } from "~/jobber/billing/components/CheckoutLink/CheckoutLink";
import { IntlProvider } from "@translations/IntlProvider";
import { capitalizeFirstLetter } from "utilities/capitalizeFirstLetter";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { SELF_SERVE_ELIGIBILITY } from "jobber/upgradePage/UpgradePage.graphql";
import styles from "./PlanCard.module.css";
import { plansData } from "./planCardData";
import { messages } from "./messages";

export interface SelfServeEligibilityType {
  selfServeEligibility: {
    selfServePerUserPricing: {
      eligible: boolean;
      reachedUserLimit: boolean;
    };
  };
}

export interface PlanCardProps {
  planTier: string;
  planIdentifier: string;
  planUserLimit: number | null;
  planAdditionalUsers: number;
  planCode: string;
  planPriceMonthly: number;
  planPriceMonthlyDiscounted: number;
  monthlyDiscountDuration: number;
  planPriceAnnual: number;
  planPriceAnnualDiscounted: number;
  annualDiscountDuration: number;
  planPriceAdditionalUser: number;
  isPricingAnnual: boolean;
  isFourPlanSection: boolean;
  isAccountPlanTier: boolean;
  isMostPopular: boolean;
  isHighlighted: boolean;
  billingCycleInterval: string;
  allowInWebBillingChanges: boolean;
  allowUserLimitIncrease: boolean;
  setSeeAllFeatures: (value: boolean) => void;
  activePromotionTrackingTag: string | null | undefined;
  allowResubscribeCurrentPlan?: boolean;
}

// eslint-disable-next-line max-statements
function PlanCard({
  planTier,
  planIdentifier,
  planCode,
  planUserLimit,
  planAdditionalUsers,
  planPriceMonthly,
  planPriceMonthlyDiscounted,
  monthlyDiscountDuration,
  planPriceAnnual,
  planPriceAnnualDiscounted,
  annualDiscountDuration,
  planPriceAdditionalUser,
  isPricingAnnual,
  isFourPlanSection,
  isAccountPlanTier,
  isMostPopular,
  isHighlighted,
  billingCycleInterval,
  allowInWebBillingChanges,
  allowUserLimitIncrease,
  setSeeAllFeatures,
  activePromotionTrackingTag,
  allowResubscribeCurrentPlan = false,
}: PlanCardProps) {
  const { formatMessage } = useIntl();

  const annualDiscount = planPriceMonthly - planPriceAnnual,
    tier = planTier.toLowerCase(),
    isTierOffered = Object.keys(plansData).includes(tier);
  const topFeatures = isTierOffered
    ? plansData[tier].topFeatures
    : plansData.lite.topFeatures;
  const featuresItems = topFeatures.map((feature, index) => {
    return (
      <div key={index} className={styles.featureItem}>
        <Text>{feature}</Text>
      </div>
    );
  });
  const { data, loading } = useQuery<SelfServeEligibilityType>(
    SELF_SERVE_ELIGIBILITY,
  );
  const eligibleForSelfServePerUserPricing =
    data?.selfServeEligibility?.selfServePerUserPricing.eligible;
  const formatCurrency = (price: number) => {
    // check for number with no decimal i.e. 5 or 5.00
    if (!(price % 1)) {
      return `$${price}`;
    }

    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  return (
    <div
      data-testid="planCard"
      className={classNames(styles.cardStyle, {
        [styles.cardMostPopular]: isMostPopular || isHighlighted,
        [styles.fourPlanTier]: isFourPlanSection,
      })}
    >
      <Card>
        {isAccountPlanTier || isMostPopular ? (
          <div
            data-testid="cardLabel"
            className={classNames(styles.cardLabel, {
              [styles.mostPopularLabel]: isMostPopular || !isAccountPlanTier,
            })}
          >
            <Text>
              <span className={styles.cardLabelText}>
                {formatMessage(
                  isAccountPlanTier
                    ? messages.currentPlan
                    : messages.mostPopularPlan,
                )}
              </span>
            </Text>
          </div>
        ) : (
          <div className={styles.minHeightDiv} />
        )}

        <div
          className={
            isMostPopular || isHighlighted
              ? styles.cardInfoContainerWithThickBorder
              : styles.cardInfoContainer
          }
        >
          <div className={styles.cardTopSection}>
            <div data-testid="planTier" className={styles.planTier}>
              <Heading level={2}>{planTier}</Heading>
            </div>
            <div
              data-testid="planTierDescription"
              className={styles.planTierDescription}
            >
              <Text>{isTierOffered ? plansData[tier].description : ""}</Text>
            </div>
          </div>

          <div data-testid="planCost" className={styles.costSection}>
            {isAccountPlanTier ? (
              <>
                {billingCycleInterval === "month" ? (
                  <div>
                    <Heading level={1}>
                      {formatMessage(messages.pricePerMonth, {
                        price: formatCurrency(planPriceMonthly),
                      })}
                    </Heading>

                    <div
                      data-testid="costDescription"
                      className={styles.costDescription}
                    >
                      {formatMessage(messages.billedMonthly)}
                    </div>
                  </div>
                ) : (
                  <>
                    <Heading level={1}>
                      {formatMessage(messages.pricePerMonth, {
                        price: formatCurrency(planPriceAnnual),
                      })}
                    </Heading>

                    <div
                      data-testid="costDescription"
                      className={styles.costDescription}
                    >
                      {formatMessage(messages.billedAnnually)}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {isPricingAnnual ? (
                  <>
                    {planPriceAnnualDiscounted < planPriceAnnual ? (
                      <>
                        <div className={styles.priceBeforeDiscount}>
                          <Emphasis variation="bold">
                            {formatMessage(messages.pricePerMonth, {
                              price: formatCurrency(planPriceAnnual),
                            })}
                          </Emphasis>
                        </div>
                        <Heading level={1}>
                          {formatMessage(messages.pricePerMonth, {
                            price: formatCurrency(planPriceAnnualDiscounted),
                          })}
                        </Heading>

                        <div
                          data-testid="costDescription"
                          className={styles.costDescription}
                        >
                          <Text>
                            <span className={styles.boldFont}>
                              {formatMessage(messages.saveAmount, {
                                amount: formatMessage(messages.pricePerMonth, {
                                  price: formatCurrency(
                                    planPriceAnnual - planPriceAnnualDiscounted,
                                  ),
                                }),
                                duration: formatMessage(
                                  messages.durationYears,
                                  { years: annualDiscountDuration },
                                ),
                              })}
                            </span>
                          </Text>
                          <Text>
                            {formatMessage(messages.whenBilledAnnually, {
                              br: " ",
                            })}
                          </Text>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.oldPrice}>
                          <Text variation="subdued">
                            {formatMessage(messages.pricePerMonth, {
                              price: formatCurrency(planPriceMonthly),
                            })}
                          </Text>
                        </div>

                        <Heading level={1}>
                          {formatMessage(messages.pricePerMonth, {
                            price: formatCurrency(planPriceAnnual),
                          })}
                        </Heading>

                        <div
                          data-testid="costDescription"
                          className={styles.costDescription}
                        >
                          <Text>
                            <span className={styles.boldFont}>
                              {formatMessage(messages.saveAmount, {
                                amount: formatMessage(messages.pricePerMonth, {
                                  price: formatCurrency(annualDiscount),
                                }),
                                duration: "",
                              })}
                            </span>
                            {formatMessage(messages.whenBilledAnnually, {
                              br: <br />,
                            })}
                          </Text>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {planPriceMonthlyDiscounted < planPriceMonthly ? (
                      <>
                        <div className={styles.priceBeforeDiscount}>
                          <Emphasis variation="bold">
                            {formatMessage(messages.pricePerMonth, {
                              price: formatCurrency(planPriceMonthly),
                            })}
                          </Emphasis>
                        </div>
                        <Heading level={1}>
                          {formatMessage(messages.pricePerMonth, {
                            price: formatCurrency(planPriceMonthlyDiscounted),
                          })}
                        </Heading>

                        <div
                          data-testid="costDescription"
                          className={styles.costDescription}
                        >
                          <Text>
                            <span className={styles.boldFont}>
                              {formatMessage(messages.saveAmount, {
                                amount: formatMessage(messages.pricePerMonth, {
                                  price: formatCurrency(
                                    planPriceMonthly -
                                      planPriceMonthlyDiscounted,
                                  ),
                                }),
                                duration: formatMessage(
                                  messages.durationMonths,
                                  { months: monthlyDiscountDuration },
                                ),
                              })}
                            </span>
                          </Text>
                          <Text>
                            {formatMessage(messages.whenBilledMonthly)}
                          </Text>
                        </div>
                      </>
                    ) : (
                      <>
                        <Heading level={1}>
                          {formatMessage(messages.pricePerMonth, {
                            price: formatCurrency(planPriceMonthly),
                          })}
                        </Heading>

                        <div
                          data-testid="costDescription"
                          className={styles.costDescription}
                        >
                          {formatMessage(messages.billedMonthly)}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div
            data-testid="planTopFeaturesList"
            className={styles.cardBottomSection}
          >
            <Content>
              <Heading level={5}>
                {isTierOffered && plansData[tier].previousPlan
                  ? formatMessage(messages.everythingInPrevTier, {
                      tier: plansData[tier].previousPlan,
                    })
                  : formatMessage(messages.everythingInCurrentTier, {
                      tier: capitalizeFirstLetter(tier),
                    })}
              </Heading>
              <div
                className={styles.featureItemWithBorder}
                data-testid="usersIncluded"
              >
                {tier === "loyalty" ||
                tier === "unlimited" ||
                planUserLimit === null ? (
                  <Text>
                    <span className={styles.boldFont}>
                      {formatMessage(messages.unlimitedUsers)}
                    </span>
                  </Text>
                ) : (
                  <Text>
                    <span className={styles.boldFont}>
                      {formatMessage(messages.fixedUsers, {
                        users: planUserLimit,
                      })}
                    </span>
                  </Text>
                )}
              </div>
              <div className={styles.featureItems}>{featuresItems}</div>
              <div
                data-testid="seeAllFeatures"
                className={styles.showAllFeatures}
              >
                <Button
                  label={formatMessage(messages.seeAllFeaturesButtonCopy)}
                  onClick={showFeatures}
                  type="tertiary"
                />
              </div>
              {allowUserLimitIncrease ? (
                <>
                  {isAccountPlanTier && planAdditionalUsers > 0 ? (
                    <Text>
                      <span
                        data-testid="numOfUsers"
                        className={styles.boldFont}
                      >
                        {formatMessage(messages.additionalUsersAdded, {
                          users: planAdditionalUsers,
                        })}
                      </span>
                    </Text>
                  ) : (
                    <Text>
                      <span data-testid="addUsers" className={styles.boldFont}>
                        {formatMessage(messages.addUsers)}
                      </span>
                    </Text>
                  )}
                  <Text>
                    {" "}
                    <span
                      data-testid="planPriceAnnual"
                      className={styles.boldFont}
                    >
                      {formatMessage(messages.pricePerMonth, {
                        price: formatCurrency(planPriceAdditionalUser),
                      })}
                    </span>
                    {formatMessage(messages.perAdditionalUser)}
                  </Text>

                  {isAccountPlanTier && !loading ? (
                    eligibleForSelfServePerUserPricing ? (
                      <div className={styles.minHeightButtonDiv} />
                    ) : (
                      <div
                        data-testid="increasePlanUserLimit"
                        className={styles.chatButton}
                      >
                        <Button
                          label={formatMessage(
                            messages.increaseUserLimitButtonCopy,
                          )}
                          onClick={openChatWidget}
                          type="tertiary"
                        />
                      </div>
                    )
                  ) : (
                    <div className={styles.minHeightButtonDiv} />
                  )}
                </>
              ) : (
                <div className={styles.noAdditionalUsersSection} />
              )}
            </Content>
          </div>
          {allowInWebBillingChanges && (
            <>
              {isAccountPlanTier ? (
                allowResubscribeCurrentPlan ? (
                  choosePlanButton(
                    formatMessage(messages.resubscribeButtonCopy),
                    billingCycleInterval === "annual",
                  )
                ) : (
                  <Button
                    label={formatMessage(messages.yourCurrentPlanButtonCopy)}
                    type={"secondary"}
                    size="large"
                    fullWidth={true}
                    disabled={isAccountPlanTier}
                  />
                )
              ) : (
                choosePlanButton(
                  formatMessage(messages.choosePlanButtonCopy),
                  isPricingAnnual,
                )
              )}
            </>
          )}
        </div>
      </Card>
    </div>
  );

  function openChatWidget() {
    Intercom.EXECUTE("showNewMessage");
    Amplitude.TRACK_EVENT("Clicked Increase User Limit", {
      source: "pricing",
      method: "Sales chat",
    });
  }

  function showFeatures() {
    setSeeAllFeatures(true);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "see_all_features",
    });
  }

  function choosePlanButton(label: string, isAnnual: boolean) {
    return revisedCheckoutChoosePlanButton(label, isAnnual);
  }

  function revisedCheckoutChoosePlanButton(label: string, isAnnual: boolean) {
    return (
      <PrimaryActionButton
        planCode={planCode}
        tierDisplayName={planTier}
        buttonStyleProps={{
          buttonType: "primary",
          buttonLabel: label,
          buttonFullWidth: true,
        }}
        legacyCheckoutParams={{
          planSetIdentifier: planIdentifier,
          tierName: planTier.toLowerCase(),
          isAnnual: isAnnual,
          offer: activePromotionTrackingTag || "",
        }}
      />
    );
  }
}

function WrappedPlanCard({
  planTier,
  planIdentifier,
  planCode,
  planUserLimit,
  planAdditionalUsers,
  planPriceMonthly,
  planPriceMonthlyDiscounted,
  monthlyDiscountDuration,
  planPriceAnnual,
  planPriceAnnualDiscounted,
  annualDiscountDuration,
  planPriceAdditionalUser,
  isPricingAnnual,
  isFourPlanSection,
  isAccountPlanTier,
  isMostPopular,
  isHighlighted,
  billingCycleInterval,
  allowInWebBillingChanges,
  allowUserLimitIncrease,
  setSeeAllFeatures,
  activePromotionTrackingTag,
  allowResubscribeCurrentPlan,
}: PlanCardProps) {
  return (
    <IntlProvider>
      <PlanCard
        planTier={planTier}
        planIdentifier={planIdentifier}
        planUserLimit={planUserLimit}
        planAdditionalUsers={planAdditionalUsers}
        planCode={planCode}
        planPriceMonthly={planPriceMonthly}
        planPriceMonthlyDiscounted={planPriceMonthlyDiscounted}
        monthlyDiscountDuration={monthlyDiscountDuration}
        planPriceAnnual={planPriceAnnual}
        planPriceAnnualDiscounted={planPriceAnnualDiscounted}
        annualDiscountDuration={annualDiscountDuration}
        planPriceAdditionalUser={planPriceAdditionalUser}
        isPricingAnnual={isPricingAnnual}
        isFourPlanSection={isFourPlanSection}
        isAccountPlanTier={isAccountPlanTier}
        isMostPopular={isMostPopular}
        isHighlighted={isHighlighted}
        billingCycleInterval={billingCycleInterval}
        allowInWebBillingChanges={allowInWebBillingChanges}
        allowUserLimitIncrease={allowUserLimitIncrease}
        setSeeAllFeatures={setSeeAllFeatures}
        activePromotionTrackingTag={activePromotionTrackingTag}
        allowResubscribeCurrentPlan={allowResubscribeCurrentPlan}
      />
    </IntlProvider>
  );
}

export { WrappedPlanCard as PlanCard };
