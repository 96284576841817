import { Button } from "@jobber/components/Button";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useReducer, useState } from "react";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { SetupOrConfirmTwoFactor } from "jobber/settings/users/components/SetupOrConfirmTwoFactor";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { BankAccountReducerAction } from "jobber/managed_accounts/bankAccountReducer";
import {
  type BankAccountInformation,
  type BankAddress,
  bankAccountInformationReducer,
  initialBankAccountInformation,
} from "jobber/managed_accounts/ManualConnect/bankAccountInformationReducer";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import { useAuth } from "~/utilities/contexts/internal/useAuth";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { AddBankAccountForm } from "./AddBankAccountForm";
import { DebitAgreementForm } from "./DebitAgreementForm";

declare const Stripe: stripe.StripeStatic;

interface BankAccountPayloadInterface {
  country: string;
  currency: string;
  /* eslint-disable @typescript-eslint/naming-convention */

  account_number: string;
  account_holder_name: string;
  routing_number?: string;
  debit_agreement_shown_and_accepted?: boolean;
  /* eslint-enable @typescript-eslint/naming-convention */
}

const stripe = Stripe(jobberOnline.constants.stripeKey);

interface ManualConnectProps {
  actionType: BankAccountReducerAction["type"] | "INIT";
  disableAddBankAccount: boolean;
  isManualConnectLoading: boolean;
  accountDetailsComplete: boolean;
  countryCode: string;
  currencyCode: string;
  plaidEnabled: boolean;
  hostedOnboardingConnectBankAccountURL: string;
  manualConnectErrorMessage?: string;
  requiresDebitAuthorizationForm: boolean;
  inRevampedSettingsExperiment?: boolean;
  dispatch(action: BankAccountReducerAction): void;
}

interface ConnectBankAccountBody {
  bankToken: string;
  /* eslint-disable @typescript-eslint/naming-convention */

  authenticity_token: string;
  debit_agreement_shown_and_accepted?: boolean;
  bank_name?: string;
  bank_address?: BankAddress;
  /* eslint-enable @typescript-eslint/naming-convention */
}

const showIBANInput = (userCountryCode: string): boolean => {
  const eligibleCountries = ["IE"];

  return !!eligibleCountries.find(
    countryCode => countryCode === userCountryCode,
  );
};

/* eslint-disable max-statements */
export function ManualConnect(props: ManualConnectProps) {
  const {
    dispatch,
    actionType,
    manualConnectErrorMessage,
    disableAddBankAccount,
    isManualConnectLoading,
    accountDetailsComplete,
    countryCode,
    currencyCode,
    plaidEnabled,
    hostedOnboardingConnectBankAccountURL,
    requiresDebitAuthorizationForm,
    inRevampedSettingsExperiment,
  } = props;

  const showIBAN = showIBANInput(countryCode);

  const { isModalOpen, setModalOpen, authenticityToken, permissions } =
    useManualConnect();

  const openModal = () => {
    Amplitude.TRACK_EVENT("Interacted with Jobber Payments Setup", {
      interaction: "Clicked Connect Manually",
    });

    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);
  const [bankAccountInformation, bankAccountInformationDispatch] = useReducer(
    bankAccountInformationReducer,
    initialBankAccountInformation(countryCode),
  );
  const [authenticated, setAuthenticated] = useState(false);
  const authenticationSuccess = () => setAuthenticated(true);

  const disabled = disableAddBankAccount || !permissions.canSetup;

  const connectManuallyText = disabled ? (
    <span className="u-colorGrey"> Connect manually </span>
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid -- Grandfathered error: Please fix if touching this code.
    <a
      className={`${disabled && "is-disabled"}`}
      href="javascript:;"
      onClick={openModal}
    >
      {" "}
      Connect Manually{" "}
    </a>
  );

  useEffect(() => {
    if (actionType !== "onSuccess") return;

    onRequestClose();
    bankAccountInformationDispatch({
      type: "reset",
      newState: initialBankAccountInformation(countryCode),
    });
  }, [actionType]);

  useEffect(() => {
    onBankAccountInformationChanged(bankAccountInformation);
  }, [bankAccountInformation]);

  useEffect(() => {
    if (actionType !== "onManualConnectStart") return;
    let mounted = true;

    async function post() {
      /* eslint-disable @typescript-eslint/naming-convention */
      const payload: BankAccountPayloadInterface = {
        country: countryCode,
        currency: currencyCode,
        account_number: bankAccountInformation.accountNumber,
        account_holder_name: bankAccountInformation.accountHolder,
      };

      if (!showIBAN) {
        payload.routing_number = bankAccountInformation.routingNumber;
      }

      if (requiresDebitAuthorizationForm) {
        payload.debit_agreement_shown_and_accepted =
          bankAccountInformation.debitAgreementForm.debitAgreementShownAndAccepted;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = await stripe.createToken("bank_account", payload as any);

      if (!mounted) return;

      if (result.error) {
        dispatch({
          type: "onManualConnectError",
          data: { manualConnectErrorMessage: result.error.message },
        });
      }

      if (result.token) {
        const body: ConnectBankAccountBody = {
          bankToken: result.token.id,
          authenticity_token: authenticityToken,
        };

        if (requiresDebitAuthorizationForm) {
          body.debit_agreement_shown_and_accepted =
            bankAccountInformation.debitAgreementForm.debitAgreementShownAndAccepted;
          body.bank_name = bankAccountInformation.debitAgreementForm.bankName;
          body.bank_address =
            bankAccountInformation.debitAgreementForm.bankAddress;
        }

        const response = await fetch(hostedOnboardingConnectBankAccountURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(body),
        });

        const json = (await response.json()) as {
          message: string;
          bank_name: string;
          bank_last4: string;
          payouts_to_jobber_money: boolean;
          payout_schedule_in_days: number;
        };

        if (!mounted) return;

        if (response.ok) {
          dispatch({
            type: "onSuccess",
            data: {
              successMessage: json.message,
              bank: {
                bankName: json.bank_name,
                bankLast4: json.bank_last4,
                payoutsToJobberMoney: json.payouts_to_jobber_money,
                payoutScheduleInDays: json.payout_schedule_in_days,
              },
            },
          });
        } else {
          dispatch({
            type: "onManualConnectError",
            data: { manualConnectErrorMessage: json.message },
          });
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    post();

    return () => void (mounted = false);
  }, [actionType]);

  function onRequestClose() {
    setAuthenticated(false);
    closeModal();
  }

  return (
    <>
      {plaidEnabled ? (
        inRevampedSettingsExperiment ? (
          <Text>
            To connect to a bank account, you&apos;ll need your bank login
            details to proceed. Don&apos;t have your bank login?
            {connectManuallyText}
            instead.
          </Text>
        ) : (
          <Text>
            Don’t have your bank login?
            {connectManuallyText}
            instead.
          </Text>
        )
      ) : (
        <Button label="Connect" onClick={openModal} disabled={disabled} />
      )}
      <APIProvider>
        <SetupOrConfirmTwoFactor
          onSuccess={authenticationSuccess}
          isOpen={!authenticated && isModalOpen}
          onClose={onRequestClose}
        />
      </APIProvider>
      <Modal
        open={authenticated && isModalOpen}
        dismissible={true}
        title="Connect Bank Account"
        onRequestClose={onRequestClose}
        primaryAction={{
          label: "Save",
          onClick: handleSaveAction,
          loading: isManualConnectLoading,
          disabled: !accountDetailsComplete,
        }}
        secondaryAction={{ label: "Cancel", onClick: onRequestClose }}
      >
        {requiresDebitAuthorizationForm ? (
          <DebitAgreementForm
            countryCode={countryCode}
            bankAccountInformation={bankAccountInformation}
            bankAccountInformationDispatch={bankAccountInformationDispatch}
            errorMessage={manualConnectErrorMessage}
            showIBAN={showIBAN}
          />
        ) : (
          <AddBankAccountForm
            bankAccountInformation={bankAccountInformation}
            bankAccountInformationDispatch={bankAccountInformationDispatch}
            errorMessage={manualConnectErrorMessage}
            showIBAN={showIBAN}
          />
        )}
      </Modal>
    </>
  );

  function handleSaveAction() {
    dispatch({
      type: "onManualConnectStart",
      data: { bankAccountInformation },
    });
  }

  function onBankAccountInformationChanged(
    newInformation: BankAccountInformation,
  ) {
    dispatch({
      type: "onBankAccountInformationChanged",
      data: {
        bankAccountInformation: newInformation,
        usingIBAN: showIBAN,
        requiresAuthForm: requiresDebitAuthorizationForm,
      },
    });
  }
}

function useManualConnect() {
  const [isModalOpen, setModalOpen] = useState(false);

  const [authenticityToken] = useAuth();
  const { permissions } = useJobberPayments();

  return { isModalOpen, setModalOpen, authenticityToken, permissions };
}
