import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientSegmentPageTitle: {
    id: "campaigns.clientSegmentPage.clientSegmentPageTitle",
    defaultMessage: "Select a client segment",
    description: "Title of select client segment page",
  },
  clientSegmentSideDrawerTitle: {
    id: "campaigns.clientSegmentPage.clientSegmentSideDrawerTitle",
    defaultMessage: "View client segment",
    description: "Title of client segment side drawer",
  },
  clientSegmentSideDrawerButton: {
    id: "campaigns.clientSegmentPage.clientSegmentSideDrawerButton",
    defaultMessage: "Edit Criteria",
    description: "Edit criteria button for the client segment",
  },
  clientListClientHeader: {
    id: "campaigns.clientSegmentPage.clientListClientHeader",
    defaultMessage: "Client",
    description: "Client header for the Client List DataTable",
  },
  clientListEmailHeader: {
    id: "campaigns.clientSegmentPage.clientListEmailHeader",
    defaultMessage: "Email",
    description: "Email header for the Client List DataTable",
  },
  pastClientsDescription: {
    id: "campaigns.clientSegmentPage.pastClientsDescription",
    defaultMessage:
      "Clients with last job completed more than {interval} {unit}(s) ago, with no upcoming jobs scheduled {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the past clients segment card",
  },
  pastClientsTitle: {
    id: "campaigns.clientSegmentPage.pastClientsTitle",
    defaultMessage: "Past clients",
    description: "Title for the past clients segment card",
  },
  allClientsTitle: {
    id: "campaigns.clientSegmentPage.allClientsTitle",
    defaultMessage: "All clients",
    description: "Title for the all clients segment card",
  },
  allClientsDescription: {
    id: "campaigns.clientSegmentPage.allClientsDescription",
    defaultMessage:
      "All clients that you haven't archived {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the all clients segment card",
  },
  upcomingVisitClientsTitle: {
    id: "campaigns.clientSegmentPage.upcomingVisitClientsTitle",
    defaultMessage: "Clients with upcoming visits",
    description: "Title for the upcoming visit clients segment card",
  },
  upcomingVisitClientsDescription: {
    id: "campaigns.clientSegmentPage.upcomingVisitClientsDescription",
    defaultMessage:
      "Clients with at least one visit within the next {interval} {unit}(s) {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the upcoming visit clients segment card",
  },
  edit: {
    id: "campaigns.clientSegmentPage.edit",
    defaultMessage: "Edit",
    description: "Edit",
  },
  clients: {
    id: "campaigns.clientSegmentPage.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}}",
    description: "clients label",
  },
  savedClientSegment: {
    id: "campaigns.clientSegmentPage.updateCriteria.saved",
    defaultMessage: "Saved Client Segment",
    description: "Toast shown after the client segment criteria has been saved",
  },
  automationsTitle: {
    id: "campaigns.clientSegmentPage.automations.title",
    defaultMessage: "Set automation rules",
    description: "Title of the client segment automations page",
  },
});
