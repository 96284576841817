import React from "react";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "utilities/formatCurrency";
import { CellNumeric, type CellNumericProps } from "./CellNumeric";

interface CellCurrencyProps extends CellNumericProps {
  value: number;
  size?: "small" | "base";
  variation?: "normal" | "bold";
  testId?: string;
}

export function CellCurrency({
  value,
  size = "base",
  variation = "normal",
  testId,
}: CellCurrencyProps) {
  const { currencySymbol } = useAccount();

  return (
    <CellNumeric
      size={size}
      variation={variation}
      value={formatCurrency(value, currencySymbol)}
      testId={testId}
    />
  );
}
