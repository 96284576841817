import { gql } from "@apollo/client";

export const RESTART_TRIAL = gql`
  mutation RestartTrial {
    restartTrial {
      userErrors {
        message
        path
      }
    }
  }
`;
