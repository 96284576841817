import { gql } from "@apollo/client";

export const ACCOUNT_PLAN_INFO = gql`
  query AccountPlanInfo {
    accountPlanInfo {
      planTier
      planIdentifier
      userCost
      userLimit
      additionalUsers
      billingPlatform
      allowUserLimitIncrease
      features {
        featureId
      }
      jobberPaymentsRate {
        centsPerCharge
        percent
      }
      mobileCardReaderRate {
        centsPerCharge
        percent
      }
    }
  }
`;

export const ACCOUNT_PROMOTION = gql`
  query AccountPromotion {
    accountPromotion {
      bannerInfo
      billingDialogText
      pricingPageAnnualDiscountLabel
      termsAndConditions
      trackingTag
    }
  }
`;

export const UPGRADE_PLANS = gql`
  query UpgradePlans {
    upgradePlans {
      isRecommended
      planIdentifier
      planCostAnnual
      planCostAnnualDiscounted
      annualDiscountDuration
      planCostMonthly
      planCostMonthlyDiscounted
      monthlyDiscountDuration
      planTier
      userCost
      userLimit
      allowUserLimitIncrease
      features {
        featureId
      }
      jobberPaymentsRate {
        centsPerCharge
        percent
      }
      mobileCardReaderRate {
        centsPerCharge
        percent
      }
      monthlyBillingCycle {
        planCode
        monthlyPrice
        annualPrice
      }
      annualBillingCycle {
        planCode
        monthlyPrice
        annualPrice
      }
    }
  }
`;

export const ACCOUNT_BILLING_INFO = gql`
  query AccountBillingInfo {
    account {
      billingInformation {
        planDisplayName
        planCode
        planPriceMonthly
        userCount
        billingCycleInterval
        allowInWebBillingChanges
        allowResubscribeCurrentPlan
      }
    }
  }
`;

export const SELF_SERVE_ELIGIBILITY = gql`
  query SelfServeEligibility {
    selfServeEligibility {
      selfServePerUserPricing {
        eligible
        reachedUserLimit
      }
    }
  }
`;

export const FEATURE_TRIAL_ELIGIBILITY = gql`
  query FeatureTrialEligibility {
    accountFeatureTrialEligibility {
      eligibleForLiteToCoreTrial
      eligibleForCoreToConnectTrial
    }
  }
`;
