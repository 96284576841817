import React from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import type {
  AccountPromotion,
  CurrentPlan,
  UpgradablePlan,
} from "jobber/upgradePage/UpgradePage";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./StickyHeader.module.css";
import { messages } from "./messages";

interface StickyHeaderProps {
  currentPlan: CurrentPlan;
  currentPlanCost: number;
  upgradePlans: Array<UpgradablePlan> | undefined;
  isPricingAnnual: boolean;
  showStickyHeader: boolean;
  activePromotion: AccountPromotion | undefined;
  canUpgrade: boolean;
}

export function StickyHeader({
  currentPlan,
  currentPlanCost,
  upgradePlans,
  isPricingAnnual,
  showStickyHeader,
  activePromotion,
  canUpgrade,
}: StickyHeaderProps) {
  const useSmallHeader = upgradePlans !== undefined && upgradePlans.length > 2;
  const upgradeHeaders = upgradePlans?.map(tier => {
    return (
      <div
        className={`${styles.stickyColumn} ${styles.stickyBorder}`}
        key={tier.planTier}
      >
        <StickyPlan
          useSmallHeader={useSmallHeader}
          tierName={tier.planTier}
          monthlyCost={
            isPricingAnnual
              ? tier.planCostAnnualDiscounted
              : tier.planCostMonthlyDiscounted
          }
          upgradePlanIdentifier={tier.planIdentifier}
          upgradeIsPricingAnnual={isPricingAnnual}
          activePromotion={activePromotion}
          canUpgrade={canUpgrade}
        />
      </div>
    );
  });

  return (
    <>
      {showStickyHeader && (
        <IntlProvider>
          <div
            data-testid="stickyHeader"
            className={styles.stickyHeaderContainer}
          >
            <div className={styles.stickyHeaderHelper}>
              <div className={styles.stickyHeader}>
                <div
                  id="stickySpacer"
                  className={
                    upgradePlans?.length
                      ? styles.stickyColumn
                      : styles.stickySingleColumn
                  }
                />
                <div
                  className={
                    upgradePlans?.length
                      ? styles.stickyColumn
                      : `${styles.stickySingleColumn} ${styles.stickyBorder}`
                  }
                  key={currentPlan.planTier}
                >
                  <StickyPlan
                    useSmallHeader={useSmallHeader}
                    tierName={currentPlan.planTier}
                    monthlyCost={currentPlanCost}
                    upgradePlanIdentifier={undefined}
                    upgradeIsPricingAnnual={undefined}
                    activePromotion={undefined}
                    canUpgrade={canUpgrade}
                  />
                </div>
                {upgradeHeaders}
              </div>
            </div>
          </div>
        </IntlProvider>
      )}
    </>
  );
}

interface StickyPlanProps {
  useSmallHeader: boolean;
  tierName: string;
  monthlyCost: number;
  upgradePlanIdentifier: string | undefined;
  upgradeIsPricingAnnual: boolean | undefined;
  activePromotion: AccountPromotion | undefined;
  canUpgrade: boolean;
}

function StickyPlan({
  useSmallHeader,
  tierName,
  monthlyCost,
  upgradePlanIdentifier = undefined,
  upgradeIsPricingAnnual = undefined,
  activePromotion,
  canUpgrade,
}: StickyPlanProps) {
  const { formatMessage } = useIntl();

  async function trackUpgradeNowClick(isAnnual: boolean) {
    await $.getScript(
      formatMessage(messages.billingDialogLink, {
        planIdentifier: upgradePlanIdentifier?.toLowerCase(),
        tierName: tierName.toLowerCase(),
        isAnnual: isAnnual.toString(),
        offer: activePromotion?.trackingTag,
      }),
    );
    Amplitude.TRACK_EVENT("Clicked Choose Plan", {
      plan: `${tierName.toLowerCase()}`,
      source: `pricing_${tierName.toLowerCase()}_plan`,
    });
  }

  return (
    <div
      className={
        useSmallHeader
          ? styles.stickyPlanContainerSmall
          : styles.stickyPlanContainer
      }
    >
      <div
        className={useSmallHeader ? styles.stickyPlanSmall : styles.stickyPlan}
      >
        {useSmallHeader ? (
          <h4 className={styles.stickyHeaderTitleDefault}>{tierName}</h4>
        ) : (
          <h3 className={styles.stickyHeaderTitleDefault}>{tierName}</h3>
        )}
        <h4 className={styles.stickyHeaderTitleDefault}>${monthlyCost}/mo</h4>
      </div>
      {/* If no upgrade info is available, show disabled current plan button */}
      {upgradePlanIdentifier !== undefined &&
      upgradeIsPricingAnnual !== undefined &&
      canUpgrade ? (
        <Button
          fullWidth={true}
          label={formatMessage(messages.choosePlanButtonCopy)}
          onClick={() => trackUpgradeNowClick(upgradeIsPricingAnnual)}
        />
      ) : (
        <Button
          label={
            canUpgrade || !upgradePlanIdentifier
              ? formatMessage(messages.yourCurrentPlanButtonCopy)
              : formatMessage(messages.choosePlanButtonCopy)
          }
          type={"secondary"}
          fullWidth={true}
          disabled={true}
        />
      )}
    </div>
  );
}
