import React, { type MutableRefObject, useRef, useState } from "react";
import classnames from "classnames";
import { Popover } from "@jobber/components/Popover";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import {
  type MessageDataFragment,
  MessageDirectionEnum,
} from "~/utilities/API/graphql";
import styles from "./ContactDetails.module.css";

export type ContactObject =
  | MessageDataFragment["internalContactObject"]
  | MessageDataFragment["externalContactObject"];

export interface ContactDetailsProps {
  sentBy: ContactObject;
  sentTo?: ContactObject;
  to?: string[];
  cc?: string[];
  direction: MessageDirectionEnum;
  externalName?: string;
}

export function ContactDetails({
  sentBy,
  to,
  cc,
  sentTo,
  direction,
  externalName,
}: ContactDetailsProps) {
  const hasPopover = to || cc;
  const className = classnames(styles.contactDetails, {
    [styles.outbound]: direction === MessageDirectionEnum.OUTBOUND,
  });
  const popoverDivRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [showPopover, setShowPopover] = useState(false);

  function togglePopover() {
    setShowPopover(value => !value);
  }

  if (!hasPopover) {
    return (
      <div className={className}>
        <Text size="small" variation="subdued">
          {getName(sentBy)}
        </Text>
      </div>
    );
  }
  return (
    <div className={className} ref={popoverDivRef}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus -- Grandfathered error: Please fix if touching this code. */}
      <div role="button" className={styles.clickable} onClick={togglePopover}>
        <Text size="small" variation="subdued">
          {/*
          When we have inbound emails this will need to handle not being attached to a client
          Right now this is assuming emails are always outbound.
          */}
          {getName(sentBy)} to {getName(sentTo, concatArray(to || []))}
          {cc && cc.length > 0 && `, ${concatArray(cc)}`}
          <span className={styles.iconButton}>
            <Button
              type="tertiary"
              variation="subtle"
              icon={"arrowDown"}
              size="small"
              ariaLabel="More Detail"
            />
          </span>
        </Text>
      </div>
      <Popover
        attachTo={popoverDivRef}
        open={showPopover}
        preferredPlacement="left"
        onRequestClose={togglePopover}
      >
        <Content>
          <strong>TO:</strong>
          {to?.map(formatAddress)}
          {cc && cc.length > 0 && (
            <>
              <strong>CC:</strong>
              {cc?.map(formatAddress)}
            </>
          )}
        </Content>
      </Popover>
    </div>
  );

  function getName(
    contactObject?: ContactObject,
    fallbackName?: string,
  ): string | undefined {
    const defaultName =
      direction === MessageDirectionEnum.OUTBOUND ? "Jobber" : externalName;

    switch (contactObject?.__typename) {
      case "User":
        return contactObject.name.full;
      case "Client":
        return contactObject.clientName;
      case "AiReceptionist":
        return contactObject.assistantName || "AI Assistant";
      default:
        return fallbackName || defaultName;
    }
  }

  function concatArray(array: string[]): string {
    return array.map(stripDomain).join(", ");
  }

  function stripDomain(address: string) {
    return address.split("@")[0];
  }

  function formatAddress(address: string, index: number, array: string[]) {
    let output = address;
    if (array.length - 1 !== index) {
      output = `${output},`;
    }
    return (
      <span className={classnames(styles.emailAddress)} key={index}>
        {output}
      </span>
    );
  }
}
