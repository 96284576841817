/* eslint-disable max-statements */
import React, {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { shuffle } from "lodash";
import { SETUP_WIZARD_MUTATION } from "jobber/setupWizard/SetupWizard.graphql";
import type {
  SetupWizardMutation,
  SetupWizardMutationVariables,
} from "~/utilities/API/graphql";
import { useDatadogLogger } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";
import type {
  MessageFormat,
  MutationFunction,
  SetupWizardData,
  SetupWizardExperiments,
} from "../types";
import { topPriorityArray } from "../components/constants";

export interface SetupWizardContextType {
  error: string;
  saving: boolean;
  wizardData: SetupWizardData;
  updateWizardData: (
    newData: SetupWizardData,
    markSetupWizardComplete: boolean,
    onSuccess?: () => void,
    localSave?: boolean,
  ) => void;
  topPriorityOptions: { value: string; label: MessageFormat }[];
  experiments: SetupWizardExperiments;
  updateExperiments: Dispatch<SetStateAction<SetupWizardExperiments>>;
  accountCreatedAt: string;
}
interface SetupWizardProviderProps {
  children?: React.ReactNode;
  defaultState?: SetupWizardData;
  experiments?: SetupWizardExperiments;
  accountCreatedAt?: string;
}

export const createSetupWizardContext = (): {
  Context: React.Context<SetupWizardContextType>;
  Provider: React.FC<SetupWizardProviderProps>;
} => {
  const Context = createContext<SetupWizardContextType>(
    {} as SetupWizardContextType,
  );

  const Provider = ({
    children,
    defaultState,
    experiments: intialExperimentData,
    accountCreatedAt,
  }: SetupWizardProviderProps) => {
    const [wizardData, setWizardData] = useState<SetupWizardData>(
      defaultState ?? ({} as SetupWizardData),
    );
    const [experiments, setExperiments] = useState<SetupWizardExperiments>(
      intialExperimentData ?? ({} as SetupWizardExperiments),
    );
    const [error, updateError] = useState<string>("");
    const [topPriorityOptions] = useState(() => shuffle(topPriorityArray));
    const { logError } = useDatadogLogger();

    const updateQuestionsAndAnswers = (newData: SetupWizardData): void => {
      if (wizardData.questionsAndAnswers && newData.questionsAndAnswers) {
        const answeredQuestions = newData.questionsAndAnswers.map(
          x => x.question,
        );
        newData.questionsAndAnswers = [
          ...wizardData.questionsAndAnswers.filter(
            x => !answeredQuestions.includes(x.question),
          ),
          ...newData.questionsAndAnswers,
        ];
      }
    };

    const updateState = (newData: SetupWizardData) => {
      updateQuestionsAndAnswers(newData);
      const newStateData = {
        ...wizardData,
        ...newData,
      };
      setWizardData(newStateData);
    };

    const [mutateWizardData, { loading: savingSetupWizard }] = useMutation<
      SetupWizardMutation,
      SetupWizardMutationVariables
    >(SETUP_WIZARD_MUTATION);

    const handleMutation = async <TData, TVariables>(
      mutationFunc: MutationFunction<TData, TVariables>,
      variables: TVariables,
      newData: SetupWizardData,
      errorMessage: string,
      onSuccess?: () => void,
    ): Promise<void> => {
      try {
        updateError("");
        await mutationFunc({ variables });
        updateState(newData);
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        logError(errorMessage, {}, e);
        updateError(e.toString());
      }
    };

    const updateWizardData = async (
      newData: SetupWizardData,
      markSetupWizardComplete: boolean,
      onSuccess?: () => void,
      localSave?: boolean,
    ): Promise<void> => {
      if (localSave) {
        updateState(newData);
      } else {
        await handleMutation<SetupWizardMutation, SetupWizardMutationVariables>(
          mutateWizardData,
          {
            setupWizardInput: {
              ...newData,
              completed: markSetupWizardComplete,
            },
          },
          newData,
          "SetupWizardData Mutation Error",
          onSuccess,
        );
      }
    };

    const contextValue: SetupWizardContextType = {
      error,
      saving: savingSetupWizard,
      wizardData,
      updateWizardData,
      topPriorityOptions,
      experiments,
      updateExperiments: setExperiments,
      accountCreatedAt: accountCreatedAt || "",
    };

    return (
      <Context.Provider value={contextValue}>
        <>{children}</>
      </Context.Provider>
    );
  };

  return { Context, Provider };
};
