import * as React from "react";
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import countryCodeToLabelOverrides from "./countryCodeToLabelOverrides";
import useAddressComponents from "./useAddressComponents";
import { CountrySelect, type CountrySelectProps } from "../CountrySelect";

export interface BillingAddressFieldsProps {
  address?: BillingAddress;
  countryCodesToNameMap?: { [key: string]: string };
  CountrySelectComponent?: React.ComponentType<CountrySelectProps>;

  onAddressChange?(address: BillingAddress): void;
}

export function BillingAddressFields(props: BillingAddressFieldsProps) {
  const {
    countryCodesToNameMap,
    onAddressChange,
    address = {},
    CountrySelectComponent = CountrySelect,
  } = props;
  const onChange = React.useCallback(
    (key: keyof BillingAddress, value: string) => {
      if (!onAddressChange || address[key] === value) {
        return;
      }
      onAddressChange({
        ...address,
        [key]: value,
      });
    },
    [onAddressChange, address],
  );
  const labelOverrides = React.useMemo(
    () =>
      address.country_code
        ? countryCodeToLabelOverrides[address.country_code]
        : {},
    [address.country_code],
  );
  const { street1, street2, city, province, pc } = useAddressComponents({
    address: address,
    onChange,
    keysToLabelOverride: labelOverrides,
    className: "fieldGroup-field placeholderField",
  });
  const onCountryCodeChange = React.useCallback(
    (countryCode: string) => onChange("country_code", countryCode),
    [onChange],
  );

  return (
    <>
      <div className="columns u-paddingRightNone u-paddingLeftNone fieldGroup">
        <div className="row row--fullWidth collapse">
          {street1.node && <div className="columns">{street1.node}</div>}
        </div>
        <div className="row row--fullWidth collapse">
          {street2.node && <div className="columns">{street2.node}</div>}
        </div>
        <div className="row row--fullWidth collapse">
          {city.node && <div className="columns">{city.node}</div>}
          {province.node && <div className="columns">{province.node}</div>}
        </div>
        <div className="row row--fullWidth collapse">
          {pc.node && <div className="columns">{pc.node}</div>}
          <div className="columns">
            <CountrySelectComponent
              className="u-marginNone"
              countryCodesToNameMap={countryCodesToNameMap}
              countryCode={address.country_code}
              onCountryCodeSelect={onCountryCodeChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
