import React from "react";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { ConversationPills } from "jobber/jobberAssistant/components/ConversationPills";
import { AiAssistantFeedbackSentiment } from "~/utilities/API/graphql";
import { useFeedbackCreateMutation, useStartChatWithSuccess } from "../hooks";

interface SupportQuestionFollowupProps {
  message: string;
  requestId: string;
}

const THAT_HELPED_PROMPT = "yes";
const THAT_DID_NOT_HELP_PROMPT = "no";

export const SupportQuestionFollowup = ({
  message,
  requestId,
}: SupportQuestionFollowupProps) => {
  const {
    createFeedback,
    createFeedbackErrorMessage,
    createFeedbackSubmitted,
  } = useFeedbackCreateMutation({ requestId });
  const startChatWithSuccess = useStartChatWithSuccess();

  return (
    <>
      <Text>Did that answer your question?</Text>
      {createFeedbackErrorMessage && (
        <Banner type={"error"} dismissible={false}>
          There was an error saving your feedback. Please try again.
        </Banner>
      )}
      {!createFeedbackSubmitted ? (
        <ConversationPills
          onClick={async prompt => {
            if (prompt === THAT_HELPED_PROMPT) {
              await createFeedback(AiAssistantFeedbackSentiment.HELPFUL, "");
            } else {
              await startChatWithSuccess(message, requestId);
            }
          }}
          prompts={[
            { displayText: "That helped. 👍", prompt: THAT_HELPED_PROMPT },
            {
              displayText: "Connect with support. 👤",
              prompt: THAT_DID_NOT_HELP_PROMPT,
            },
          ]}
        />
      ) : (
        <Text align="end" variation="subdued" size="small">
          <Icon name="checkmark" size="small" />
          &nbsp;
          <Emphasis variation="italic">Thanks for your feedback</Emphasis>
        </Text>
      )}
    </>
  );
};
