import React, { useEffect, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Spinner } from "@jobber/components/Spinner";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { MandatoryCardFeature } from "./MandatoryCardFeature";
import { OnlinePaymentsSettings } from "./OnlinePaymentsSettings";
import { useOnlinePaymentsQueries } from "./useOnlinePaymentsQueries";
import { useOnlinePaymentsSettings } from "./useOnlinePaymentsSettings";

export interface OnlinePaymentsToggleProps {
  disableCreditCardPayments: boolean;
  disableAchPayments: boolean;
  showAchPayments: boolean;
  disableACHToggle: boolean;
  allowOnlinePayment: boolean;
  objectName: "quote" | "invoice";
}

export function OnlinePaymentsToggle(props: OnlinePaymentsToggleProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <InternalOnlinePaymentsToggle
          {...props}
          data-testid="internal-online-payments-test-id"
        />
      </IntlProvider>
    </APIProvider>
  );
}

function InternalOnlinePaymentsToggle({
  objectName,
  showAchPayments,
  disableACHToggle: isMandatoryCardOnFileSettingEnabled,
  allowOnlinePayment,
  disableAchPayments,
  disableCreditCardPayments,
}: OnlinePaymentsToggleProps) {
  const { mandatoryCardEnabled, setMandatoryCardEnabled } =
    useOnlinePaymentsSettings({
      collapsed: true,
      mandatoryCardEnabled: undefined,
    });
  const [localMandatoryCardEnabled, setLocalMandatoryCardEnabled] =
    useState(mandatoryCardEnabled);

  const { data, loading, ctaData } = useOnlinePaymentsQueries(
    "show_mandatory_card_on_file_within_quotes_creation",
  );
  const [showDismissButton, setShowDismissButton] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (!loading && data?.jobberPaymentsSetting) {
      setMandatoryCardEnabled(data.jobberPaymentsSetting.enabled);
    }
  }, [data, loading, setMandatoryCardEnabled]);

  useEffect(() => {
    if (!loading && ctaData?.callToAction) {
      setShowDismissButton(ctaData.callToAction.shouldShow);
    }
  }, [loading, ctaData, setShowDismissButton]);

  if (loading) {
    return (
      <>
        <div data-testid="online-payments-toggle-spinner">
          <Spinner size={"base"} />
        </div>
      </>
    );
  }

  return (
    <div data-testid="OnlinePaymentsToggle-test-id">
      <Content>
        <OnlinePaymentsSettings
          showAchPayments={showAchPayments}
          disableCreditCardPayments={disableCreditCardPayments}
          disableAchPayments={disableAchPayments}
          isMandatoryCardOnFileSettingEnabled={
            isMandatoryCardOnFileSettingEnabled
          }
          objectName={objectName}
          allowOnlinePayment={allowOnlinePayment}
          data-testid="onlinePaymentsSettings-test-id"
        />
        {mandatoryCardEnabled === false && objectName === "quote" && (
          <>
            <MandatoryCardFeature
              mandatoryCardEnabled={localMandatoryCardEnabled}
              onSwitch={setLocalMandatoryCardEnabled}
              showDismissButton={showDismissButton}
            />
            <input
              type="hidden"
              name="mandatoryCardEnabled"
              value={localMandatoryCardEnabled ? "true" : "false"}
              data-testid="mandatoryCardEnabled-test-id"
            />
          </>
        )}
      </Content>
    </div>
  );
}
