import React from "react";
import cx from "classnames";
import type { CommsCampaignQuery } from "~/utilities/API/graphql";
import styles from "./SelectableCards.module.css";
import { SelectableCard } from "./SelectableCard";

export interface SelectableCardsProps<
  T extends string | number | readonly string[] = string,
> {
  /**
   * Cards to render of type SelectableCard
   */
  cards: SelectableCard<T>[];

  /**
   * Initial value to select as radio
   */
  value?: T;

  /**
   * Action to perform when radio option is changed
   * @param newValue The value of newly selected option
   */
  onChange: (newValue: T) => void;

  /**
   * Name to uniquely identify the radio group
   */
  name: string;

  /**
   * Whether the card should be full width
   */
  fullWidth?: boolean;

  loading?: boolean;

  /**
   * When set, a View and Edit button is displayed. This function is invoked when
   * the the button is clicked
   */
  additionalButtonClick?: () => void;
  additionalButtonText?: string;
  campaign?: CommsCampaignQuery["commsCampaign"];

  /**
   * Disable the collection of Cards
   */
  disabled?: boolean;
  disabledMessage?: string;

  gridWidth?: 3 | 4;
}

export function SelectableCards<
  T extends string | number | readonly string[] = string,
>({
  cards,
  value,
  onChange,
  name,
  fullWidth,
  loading,
  additionalButtonClick,
  disabled = false,
  disabledMessage = "",
  campaign = undefined,
  gridWidth = 3,
}: SelectableCardsProps<T>) {
  function handleChange(newValue: never) {
    if (newValue !== value) {
      onChange(newValue);
    }
  }

  return (
    <div role="radiogroup" className={styles.radioGroup}>
      <div
        className={
          fullWidth
            ? styles.fullWidth
            : cx(styles.cardsContainerBase, {
                [styles.fourCardContainerBase]: gridWidth === 4,
              })
        }
      >
        {cards.map((card, index) => {
          return (
            <SelectableCard
              loading={loading}
              key={index}
              name={name}
              fullWidth={fullWidth}
              {...card}
              checked={card.value === value}
              onChange={handleChange}
              additionalButtonClick={additionalButtonClick}
              disabled={disabled}
              disabledMessage={disabledMessage}
              campaign={campaign}
            />
          );
        })}
      </div>
    </div>
  );
}
