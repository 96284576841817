import { defineMessages } from "react-intl";

export const messages = defineMessages({
  automationsSubTitle: {
    id: "campaigns.clientSegmentPage.automations.subtitle",
    defaultMessage:
      "Choose the triggers and conditions for when this campaign should send",
    description: "Subtitle of the client segment automations page",
  },
  quoteSentLeadsDefaultCondition: {
    id: "campaigns.clientSegmentPage.automations.quoteSentLeadsDefaultCondition",
    defaultMessage: "and only if the client has not worked with you",
    description: "Default condition for the automation rule",
  },
  pastClientsDefaultCondition: {
    id: "campaigns.clientSegmentPage.automations.pastClientsDefaultCondition",
    defaultMessage: "and only if client has no upcoming work scheduled",
    description: "Default condition for the automation rule",
  },
  mutlipleConditionErrors: {
    id: "campaigns.clientSegmentPage.automations.multipleConditionErrors",
    defaultMessage: "The following errors occured:",
    description: "Error message for multiple conditions",
  },
  quoteSentLeadsDefaultConditionTooltip: {
    id: "campaigns.clientSegmentPage.automations.quoteSentLeadsDefaultConditionTooltip",
    defaultMessage:
      "Clients who have never had a job created for them, an invoice sent to them, or an approved quote",
    description:
      "Tooltip for the default condition for the win back lost leads automation rule",
  },
  campaignAutomationRuleAction: {
    id: "campaigns.clientSegmentPage.automations.campaignAutomationRuleAction",
    defaultMessage: "send the email campaign",
    description: "Action for the automation rule",
  },
});
