import React, {
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useRef,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import { Heading } from "@jobber/components/Heading";
import { ButtonDismiss } from "@jobber/components/ButtonDismiss";
import classNames from "classnames";
import styles from "./SideDrawer.module.css";

export type SideDrawerVariation = "subtle";

export interface SideDrawerProps {
  open: boolean;
  requestDrawerClose(): void;
  title?: string;
  mobileFullWidth?: boolean;
  variation?: SideDrawerVariation;
}

export function SideDrawer({
  open,
  requestDrawerClose,
  title,
  children,
  mobileFullWidth = false,
  variation,
}: PropsWithChildren<SideDrawerProps>) {
  const sideDrawerContainer: RefObject<HTMLDivElement> = useRef(
    document.createElement("div"),
  );

  useEffect(() => {
    if (sideDrawerContainer.current) {
      sideDrawerContainer.current.focus();
    }
  }, [open]);

  catchKeyboardEvent("Escape", open, requestDrawerClose);

  const template = (
    <AnimatePresence>
      {open && (
        <div
          ref={sideDrawerContainer}
          className={styles.container}
          data-elevation={"elevated"}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- Grandfathered error: Please fix if touching this code.
          tabIndex={0}
        >
          <motion.div
            key={styles.overlay}
            className={styles.overlay}
            onClick={requestDrawerClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}
          />
          <motion.div
            key={styles.drawer}
            data-testid="drawer"
            className={classNames(styles.drawer, {
              [styles.fullWidthDrawer]: mobileFullWidth,
              [styles.subtle]: variation === "subtle",
            })}
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}
          >
            <div className={styles.drawerContent}>
              {title !== undefined && (
                <SideDrawerHeader
                  title={title}
                  data-testid="drawer-header"
                  onRequestClose={requestDrawerClose}
                  variation={variation}
                />
              )}
              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
  return ReactDOM.createPortal(template, document.body);
}

function catchKeyboardEvent(
  key: string,
  isSideDrawerOpen: boolean,
  callback?: () => void,
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const handler = (event: { key: string }) => {
      if (isSideDrawerOpen && callback && event.key === key) {
        callback();
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  });
}

interface SideDrawerHeaderProps {
  title: string;
  onRequestClose?(): void;
  variation?: SideDrawerVariation;
}

function SideDrawerHeader({
  title,
  onRequestClose,
  variation,
}: SideDrawerHeaderProps) {
  return (
    <div
      className={classNames(styles.header, {
        [styles.subtle]: variation === "subtle",
      })}
      data-testid="drawer-header"
    >
      <Heading level={2}>{title}</Heading>
      <ButtonDismiss
        onClick={onRequestClose}
        ariaLabel={`Close ${title || "drawer"}`}
      />
    </div>
  );
}
