import React from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { clamp } from "lodash";
import styles from "./StarGroup.module.css";
import { messages } from "./messages";
import type { Rating, StarGroupProps } from "./types";
import { MAX_RATING, MIN_RATING, StarColour } from "./constants";

export function StarGroup({ rating, allowHalfStars = false }: StarGroupProps) {
  const { formatMessage } = useIntl();

  const processedRating = processRating(rating, allowHalfStars);
  const fullRating = (
    processedRating.wholeStars + processedRating.halfStars
  ).toString();

  const ariaLabel = formatMessage(messages.ariaLabel, {
    fullRating,
    MAX_RATING,
  });

  return (
    <div aria-label={ariaLabel} className={styles.starGroup}>
      {handleStars(processedRating, allowHalfStars)}
    </div>
  );
}

function handleStars(result: Rating, allowHalfStars: boolean): JSX.Element {
  const halfStarsDetected = result.halfStars !== 0;

  const stars = generateStarConfiguration(
    result.wholeStars,
    "starFill",
    StarColour.Orange,
  );

  const halfStars = generateStarConfiguration(
    halfStarsDetected ? 1 : 0,
    "starHalf",
    StarColour.Orange,
  );

  const remainingStars = generateStarConfiguration(
    !halfStarsDetected
      ? MAX_RATING - result.wholeStars
      : MAX_RATING - 1 - result.wholeStars,
    allowHalfStars ? "star" : "starFill",
    allowHalfStars ? StarColour.Orange : StarColour.LightOrange,
  );

  const starConfigurations = [...stars, ...halfStars, ...remainingStars];

  return (
    <>
      {starConfigurations.map((config, index) => (
        <Icon
          key={index}
          name={config.name}
          customColor={config.color}
          size={"small"}
        />
      ))}
    </>
  );
}

function generateStarConfiguration(
  count: number,
  name: IconNames,
  color: string,
) {
  return Array.from({ length: count }, () => ({ name, color }));
}

function processRating(
  rating: number,
  allowHalfStars: boolean,
): {
  wholeStars: number;
  halfStars: number;
} {
  const adjustedRating = clamp(rating, MIN_RATING, MAX_RATING);

  const numStars = allowHalfStars
    ? Math.round(adjustedRating * 2) / 2
    : Math.round(adjustedRating);
  const wholeStars = Math.trunc(numStars);
  const halfStars = allowHalfStars ? numStars - wholeStars : 0;

  return { wholeStars, halfStars };
}
