import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import CampaignsUpgradepageMobileWebImage from "@images/CampaignsUpgradepageMobileWebImage.png";
import CampaignsUpgradepageDesktopImage from "@images/CampaignsUpgradepageDesktopImage.png";
import { IntlProvider } from "@translations/IntlProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./CampaignsAddOnPrompt.module.css";
import { TrialPromptCard } from "../TrialPromptCard";

export function CampaignsAddOnPrompt() {
  useEffect(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: "campaigns_ple",
      source: "upgrade_page",
    });
  }, []);

  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();
  const { formatMessage: t } = useIntl();
  return (
    <IntlProvider>
      <div ref={ref} className={styles.campaignsAddOnPromptContainer}>
        <TrialPromptCard
          trialTitle={t(messages.campaignsAddOnPromptTitle)}
          trialDescription={t(messages.campaignsAddOnPromptDescription)}
          ctaLabel={t(messages.campaignsAddOnPromptCtaLabel)}
          ctaCallBack={navigateToLandingPage}
          imgSource={
            width > Breakpoints.small
              ? CampaignsUpgradepageDesktopImage
              : CampaignsUpgradepageMobileWebImage
          }
          imgAlt={t(messages.campaignsAddOnPromptimgAlt)}
          showNewLabel={true}
        />
      </div>
    </IntlProvider>
  );

  function navigateToLandingPage() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: "campaigns_upgrade_page_ple",
    });
    window.location.href = "/campaigns_landing_page";
  }
}
