// Jobber Feature Flagging flag names
// Add here the new feature flag names that will correspond to the split.io dashboard
// Alphabetical order, and please follow the format below for easy traceability
// label = "tag" // owner: Team name or channel, JOB-XXXX (for feature flag cleanup ticket)
export enum SplitNames {
  AchDefaultPaymentPreferences = "ach_default_payment_preferences", // owner: Bookkeeping
  AchManualEntryEnabled = "ach_manual_entry_enabled", // owner: Invoicing
  AutomationsCustomBuilder = "automations_custom_builder", // owner: Automations
  BillingRevisedCheckout = "new_checkout_page", // owner: Subscription Infrastructure
  CampaignAutomations = "campaign_automations", // owner: Campaigns
  CampaignsAutomationsV2 = "campaigns_automations_v2", // owner: Campaigns
  CampaignsImageUploads = "campaigns_image_uploads", // owner: Campaigns
  CommsDeleteSmsConversationOnline = "comms_delete_sms_conversation_online", // owner: Comms Systems
  CommsReassignSmsConversationOnline = "comms_reassign_sms_conversation_online", // owner: Comms Systems
  CommsEmailMarketingImageUpload = "comms_email_marketing_image_upload", // owner: Comms Systems
  CommsEmailMarketingUpsell = "comms_email_marketing_upsell", // owner: Campaigns
  CommsUkKycDpnRegistration = "comms_uk_kyc_dpn_registration", // owner: Comms Systems
  DataVizDevJobs = "data_viz_dev_jobs", // owner: Workday
  GmpAchAutopay = "gmp_ach_autopay", // owner: Invoicing
  JobsImportFlatFile = "jobs_import_flat_file", // owner: Retention, JOB-97740
  MultipleReplyTo = "comms_reply_to_multiple_email", // owner: Comms Experience
  QuoteConversionInsights = "quote_conversion_insights", // owner: Business Insights
  VisitReportClientFilter = "visits_report_client_filter", // owner: Business Insights
  SalespersonField = "salesperson_field", // owner: Business Insights
  SalespersonAttributionJob = "salesperson_attribution_job", // owner: Business Insights

  QuoteDepositShownOnJob = "quote_deposit_shown_on_job", // owner: Bookkeeping
  SchedulingAssistant = "scheduling_assistant", // owner: Scheduling
  SchedulingRecommendations = "scheduling_recommendations", // owner: Work Setup
  SolePropNew = "comms_sole_prop_new", // owner: Comms Experience
  SolePropUpgrade = "comms_sole_prop_upgrade", // owner: Comms Experience
  WalletAchManualEntry = "wallet_ach_manual_entry", // owner: Invoicing
  WebsiteImages = "website_images", // owner: Websites
  WebsiteFeaturedContent = "website_featured_content", // owner: Websites
  RequestLimitIncreaseButton = "request_limit_increase_button", // owner: Invoicing
  ProgressInvoiceCreate = "progress_invoice_create", // owner: Bookkeeping
  ExpansionSspupSingleCoreOnline = "expansion_sspup_single_core_online", // owner: Expansion, JOB-103075
}
