import React, { useState } from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { LiteToCoreTrialBeginModal } from "./LiteToCoreTrialBeginModal";
import { LiteToCoreTrialPreTrialCard } from "./LiteToCoreTrialPreTrialCard";

export interface LiteToCoreTrialPreTrialGetStartedPromptsProps {
  ctaName: string;
  hasViewedCTA: boolean;
  isDeeplink: boolean;
  shouldSeePreTrialCard: boolean;
}

export function LiteToCoreTrialPreTrialGetStartedPrompts({
  ctaName,
  hasViewedCTA,
  isDeeplink,
  shouldSeePreTrialCard,
}: LiteToCoreTrialPreTrialGetStartedPromptsProps) {
  const [modalOpen, setModalOpen] = useState(isDeeplink);
  const [modalSource, setModalSource] = useState(
    isDeeplink ? "deep_link" : "onboarding_card",
  );

  return (
    <APIProvider>
      {shouldSeePreTrialCard && (
        <LiteToCoreTrialPreTrialCard
          ctaName={ctaName}
          hasViewedCTA={hasViewedCTA}
          onLearnMoreButtonClick={onLearnMoreButtonClick}
        />
      )}
      <LiteToCoreTrialBeginModal
        source={modalSource}
        showModal={modalOpen}
        closeModal={closeModal}
      />
    </APIProvider>
  );

  function onLearnMoreButtonClick() {
    setModalSource("onboarding_card");
    setModalOpen(true);
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: ctaName,
    });
  }

  function closeModal() {
    setModalOpen(false);
  }
}
