import { cloneDeep } from "lodash";
import { type LineItem, newLineItem } from "../types";

export interface LineItemsState {
  lineItems: LineItem[];
}

export function reducerInitialState(
  initialLineItems: LineItem[],
): LineItemsState {
  return initialLineItems.length > 0
    ? {
        lineItems: initialLineItems,
      }
    : {
        lineItems: [newLineItem()],
      };
}

export enum LineItemsBulkEditActionTypes {
  AddLineItem,
  DeleteLineItem,
  ReorderLineItems,
  SetEditMode,
  UpdateLineItem,
  FullUpdate,
  Reset,
  Destroy,
}

type UpdateLineItem = NonNullable<
  {
    [k in keyof LineItem]: {
      type: LineItemsBulkEditActionTypes.UpdateLineItem;
      reactKey: string;
      field: k;
      value: LineItem[k];
    };
  }[keyof LineItem]
>;

interface AddLineItem {
  type: LineItemsBulkEditActionTypes.AddLineItem;
}

interface DeleteLineItem {
  type: LineItemsBulkEditActionTypes.DeleteLineItem;
  reactKey: string;
}

interface ReorderLineItems {
  type: LineItemsBulkEditActionTypes.ReorderLineItems;
  reorderedLineItems: LineItem[];
}
interface FullUpdate {
  type: LineItemsBulkEditActionTypes.FullUpdate;
  reactKey: string;
  value: LineItem;
}

interface SetEditMode {
  type: LineItemsBulkEditActionTypes.SetEditMode;
  reactKey: string;
  value: boolean;
}

interface Reset {
  type: LineItemsBulkEditActionTypes.Reset;
  value: LineItemsState;
}

interface Destroy {
  type: LineItemsBulkEditActionTypes.Destroy;
  reactKey: string;
}

export type LineItemsAction =
  | AddLineItem
  | DeleteLineItem
  | ReorderLineItems
  | UpdateLineItem
  | FullUpdate
  | SetEditMode
  | Reset
  | Destroy;

export function lineItemsBulkEditReducer(
  prevState: LineItemsState,
  action: LineItemsAction,
): LineItemsState {
  let nextState = cloneDeep(prevState);
  switch (action.type) {
    case LineItemsBulkEditActionTypes.SetEditMode:
      return {
        ...prevState,
        lineItems: prevState.lineItems.map(li => ({
          ...li,
          editMode:
            li.reactKey === action.reactKey ? action.value : li.editMode,
        })),
      };

    case LineItemsBulkEditActionTypes.AddLineItem:
      nextState.lineItems.push(newLineItem());
      return nextState;

    case LineItemsBulkEditActionTypes.DeleteLineItem:
      return {
        ...prevState,
        lineItems: prevState.lineItems.map(lineItem => ({
          ...lineItem,
          isDeleted:
            lineItem.reactKey === action.reactKey ? true : lineItem.isDeleted,
        })),
      };

    case LineItemsBulkEditActionTypes.ReorderLineItems:
      nextState = {
        ...prevState,
        lineItems: [
          ...action.reorderedLineItems,
          ...prevState.lineItems.filter(lineItem => lineItem.isDeleted),
        ],
      };
      return nextState;

    case LineItemsBulkEditActionTypes.UpdateLineItem:
      return {
        ...prevState,
        lineItems: prevState.lineItems.map(li => {
          return {
            ...li,
            ...(li.reactKey === action.reactKey
              ? updateFields(li, action.field, action.value)
              : undefined),
          };
        }),
      };

    case LineItemsBulkEditActionTypes.FullUpdate:
      return {
        ...prevState,
        lineItems: prevState.lineItems.map(li => {
          if (li.reactKey === action.reactKey) {
            return action.value;
          }

          return li;
        }),
      };

    case LineItemsBulkEditActionTypes.Reset:
      return action.value;

    case LineItemsBulkEditActionTypes.Destroy:
      nextState.lineItems = prevState.lineItems.filter(
        li => li.reactKey !== action.reactKey,
      );
      return nextState;

    default:
      throw new Error("Invalid action type");
  }
}

function updateFields<T extends keyof LineItem>(
  lineItem: LineItem,
  field: T,
  value: LineItem[T],
): Partial<LineItem> | undefined {
  switch (field) {
    case "unitCost":
      return updateNumeric(lineItem, value, updateUnitCost);
    case "unitPrice":
      return updateNumeric(lineItem, value, updateUnitPrice);
    case "totalPrice":
      return updateNumeric(lineItem, value, updateTotalPrice);
    case "quantity":
      return updateNumeric(lineItem, value, updateQuantity);
    default:
      return {
        [field]: value,
      };
  }
}

function updateNumeric(
  lineItem: LineItem,
  value: LineItem[keyof LineItem],
  update: (lineItem: LineItem, value: number | "") => Partial<LineItem>,
): Partial<LineItem> | undefined {
  return typeof value === "number" || value === ""
    ? update(lineItem, value)
    : undefined;
}

function updateUnitCost(lineItem: LineItem, value: number) {
  return {
    unitCost: value,
    totalCost: lineItem.quantity * value,
  };
}

function updateUnitPrice(lineItem: LineItem, value: number) {
  return {
    unitPrice: value,
    totalPrice: value * lineItem.quantity,
  };
}

function updateTotalPrice(lineItem: LineItem, value: number) {
  return {
    totalPrice: value,
    unitPrice: value / lineItem.quantity,
  };
}

function updateQuantity(lineItem: LineItem, value: number) {
  return {
    quantity: value,
    totalPrice: lineItem.unitPrice
      ? value * lineItem.unitPrice
      : lineItem.totalPrice,
    totalCost: lineItem.unitCost
      ? value * lineItem.unitCost
      : lineItem.totalCost,
  };
}
