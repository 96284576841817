import React, { useContext, useEffect, useState } from "react";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { useLazyQuery } from "@apollo/client";
import { Content } from "@jobber/components/Content";
import { PropertyContext } from "jobber/workOrders/components/LineItems/PropertyContext";
import type { LastJobLineItemQuery } from "~/utilities/API/graphql";
import { formatCurrency } from "utilities/formatCurrency";
import { LAST_JOB_LINE_ITEM_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useWorkObject } from "~/jobber/lineItems/hooks/useWorkObject";
import styles from "./FillWithLast.module.css";

export interface FillData {
  description: string;
  price: number;
}
interface FillWithLastProps {
  show: boolean;
  onClose(): void;
  onSelect(fillData: FillData): void;
  productId?: string;
  propertyId?: string;
}

interface LastJobLineItem {
  id: string;
  name: string;
  description: string;
  taxable: boolean;
  quantity: number;
  unitPrice: number;
  unitCost?: number;
}

export function FillWithLast({
  show,
  onClose,
  onSelect,
  productId,
}: FillWithLastProps) {
  const { currencySymbol } = useAccount();

  const { workObjectType } = useWorkObject();
  const [query] = useLazyQuery<LastJobLineItemQuery>(LAST_JOB_LINE_ITEM_QUERY);
  const [lineItemData, setLineItemData] = useState<LastJobLineItem | undefined>(
    undefined,
  );

  const propertyId = useContext(PropertyContext);

  function handleSelect(fillData: FillData) {
    onSelect(fillData);
    Amplitude.TRACK_EVENT("Clicked Fill With", {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      work_object: workObjectType?.toString().toLowerCase() || "unknown",
      type: "last_job",
    });
  }

  useEffect(() => {
    setLineItemData(undefined);
    if (productId) {
      query({
        variables: {
          id: productId,
          propertyId: propertyId ? propertyId : findPropertyId(),
        },
      })
        .then(result => {
          setLineItemData(result.data?.productOrService?.lastJobLineItem);
        })
        .catch(() => {
          setLineItemData(undefined);
        });
    }
  }, [productId, propertyId, query]);

  if (!show || !lineItemData) {
    return <></>;
  }
  return (
    <div>
      <div
        className={styles.overlay}
        onClick={onClose}
        onKeyDown={onClose}
        role="presentation"
      />
      <div className={styles.fillWithLastContainer}>
        <div className={styles.fillWithLastHeader} style={{}}>
          <Heading level={4}>Fill With:</Heading>
          <Button
            onClick={onClose}
            icon="cross"
            type="tertiary"
            variation="subtle"
            ariaLabel="Dismiss"
          />
        </div>
        <Card
          onClick={() => {
            handleSelect({
              description: lineItemData.description || "",
              price: lineItemData.unitPrice,
            });
          }}
        >
          <div className={styles.fillItem}>
            <Content spacing={"small"}>
              <Heading level={5}>{"Last job"}</Heading>
              <Text>{lineItemData.name}</Text>
              <Text variation={"subdued"} size={"small"}>
                {lineItemData.description}
              </Text>
            </Content>
            <Content>
              <Heading level={5}>
                {formatCurrency(lineItemData.unitPrice, currencySymbol)}
              </Heading>
            </Content>
          </div>
        </Card>
      </div>
    </div>
  );
}

/* Used in the job create case, we need to lookup the configured property id from elsewhere on the (Rails) page,
 * as this is not known at the time the page is rendered */
function findPropertyId() {
  const propertyId = (
    document.querySelector(".js-propertyId") as HTMLInputElement
  )?.value;
  if (propertyId) {
    return btoa(propertyId);
  }
  return null;
}
