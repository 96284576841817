import { type RefObject, useRef, useState } from "react";
import { Text } from "@jobber/components/Text";
import {
  InlineLabel,
  type InlineLabelColors,
} from "@jobber/components/InlineLabel";
import { snakeCase } from "lodash";
import { useIntl } from "react-intl";
import { Popover } from "@jobber/components/Popover";
import { Content } from "@jobber/components/Content";
import {
  displayCurrency,
  displayPercentage,
} from "legacy/jobber/franchise/features/Reporting/views/helpers";
import type { ReportDisplayLink } from "~/utilities/API/graphql";
import { messages as reportingMessages } from "~/jobber/features/Reporting/components/Report/messages";
import type {
  ColumnInfo,
  LinkArray,
} from "~/jobber/features/Reporting/components/Report/types";
import styles from "./ReportCells.module.css";
import { messages } from "./messages";
import type { InnerColumnCell } from "./types";

interface ColumnProps {
  text?: string;
  columnInfo?: ColumnInfo;
  currencyAmount?: number;
  number?: number;
  displayLink?: ReportDisplayLink;
  percentage?: number;
  average?: boolean;
  colorsConfig?: Record<string, InlineLabelColors>;
  statusConfig?: Record<string, string>;
  alignRight?: boolean;
}

interface AdditionalConfigType {
  status: {
    colors?: Record<string, InlineLabelColors>;
    keys?: Record<string, string>;
  };
}

interface PopoverTextCellProps {
  cellText: string;
  elementReference: RefObject<HTMLSpanElement>;
  togglePopover: boolean;
  setTogglePopover: (value: boolean) => void;
}

export function CurrencyFooter({ currencyAmount }: ColumnProps): JSX.Element {
  return (
    <Text align="end" size="base">
      <strong>{displayCurrency(currencyAmount)}</strong>
    </Text>
  );
}

export function PercentageFooter({
  percentage,
  average,
}: ColumnProps): JSX.Element {
  return (
    <Text align="end" size="base">
      <strong>{displayPercentage(percentage, average)}</strong>
    </Text>
  );
}

export function NumberFooter({ number }: ColumnProps): JSX.Element {
  return (
    <Text align="end" size="base">
      <strong>{number ? number : 0}</strong>
    </Text>
  );
}

export function StandardFooter({ text }: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Text size="base">
      <strong>
        {text ? text : formatMessage(reportingMessages.emptyCellValue)}
      </strong>
    </Text>
  );
}

export function ComparableHeader({ text }: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.rightAlignSortColumn}>
      {text ? text : formatMessage(reportingMessages.emptyCellValue)}
    </div>
  );
}

export function NonSortableHeader({
  text,
  alignRight,
}: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={`${alignRight && styles.rightAlignSortColumn}`}>
      {text ? text : formatMessage(reportingMessages.emptyCellValue)}
    </div>
  );
}

export function SortableHeader({ text, alignRight }: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div
      className={`${styles.sortableColumn} ${
        alignRight && styles.rightAlignSortColumn
      }`}
    >
      {text ? text : formatMessage(reportingMessages.emptyCellValue)}
    </div>
  );
}

export function MultiLineHeader({ text }: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.multiLineHeader}>
      {text ? text : formatMessage(reportingMessages.emptyCellValue)}
    </div>
  );
}

export function InnerColumnCurrencyCell({
  columnInfo,
}: ColumnProps): JSX.Element {
  return (
    <span className={styles.reportCellTextWrapper}>
      <Text size="base" align="end">
        {displayCurrency(columnInfo?.getValue() as number)}
      </Text>
    </span>
  );
}

export function InnerColumnNumberCell({
  columnInfo,
}: ColumnProps): JSX.Element {
  return (
    <span className={styles.reportCellTextWrapper}>
      <Text size="base" align="end">
        {columnInfo ? (columnInfo.getValue() as number) : 0}
      </Text>
    </span>
  );
}

export function InnerColumnStatusCell({
  columnInfo,
  colorsConfig,
  statusConfig,
}: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();
  const statusKey = snakeCase(columnInfo?.getValue() as string);

  return (
    <span className={styles.statusWrapper}>
      <InlineLabel
        size="base"
        color={colorsConfig ? colorsConfig[statusKey] : "greyBlue"}
      >
        {statusConfig
          ? statusConfig[statusKey]
          : formatMessage(reportingMessages.emptyCellValue)}
      </InlineLabel>
    </span>
  );
}

export function InnerColumnTextCell({ columnInfo }: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();
  const wrapperReference = useRef<HTMLButtonElement>(null);
  const [togglePopover, setTogglePopover] = useState(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const columnText =
    (columnInfo?.getValue() as string) ||
    formatMessage(reportingMessages.emptyCellValue);

  const textCellWithPopover = (
    <>
      <button
        className={styles.reportCellTextWrapper}
        onClick={() => setTogglePopover(!togglePopover)}
        style={{ cursor: "pointer" }}
        ref={wrapperReference}
      >
        <span>{columnText}</span>
      </button>
      <PopoverTextCell
        cellText={columnText}
        elementReference={wrapperReference}
        togglePopover={togglePopover}
        setTogglePopover={setTogglePopover}
      />
    </>
  );

  const regularTextCell = (
    <div
      className={styles.reportCellTextWrapper}
      ref={node => {
        if (node) {
          setIsTruncated(node.scrollWidth > node.clientWidth);
        }
      }}
    >
      <span>{columnText}</span>
    </div>
  );

  return isTruncated ? textCellWithPopover : regularTextCell;
}

export function InnerColumnLinkCell({ columnInfo }: ColumnProps): JSX.Element {
  return (
    <a
      href={(columnInfo?.getValue() as ReportDisplayLink)?.jobberWebUri}
      className={styles.reportCellTextWrapper}
    >
      {(columnInfo?.getValue() as ReportDisplayLink)?.displayText}
    </a>
  );
}

export function PopoverTextCell({
  cellText,
  elementReference,
  togglePopover,
  setTogglePopover,
}: PopoverTextCellProps): JSX.Element {
  return (
    <Popover
      attachTo={elementReference}
      open={togglePopover}
      onRequestClose={() => setTogglePopover(false)}
    >
      <Content>
        <span className={styles.cellBodyPopoverTextWrap}>{cellText}</span>
      </Content>
    </Popover>
  );
}

export function InnerColumnLinkArrayCell({
  columnInfo,
}: ColumnProps): JSX.Element {
  const { formatMessage } = useIntl();
  const linkArray = (columnInfo?.getValue() as LinkArray)?.nodes;
  return (
    <div className={styles.cellLink}>
      {Array.isArray(linkArray) &&
        linkArray.map((link: ReportDisplayLink, index: number) => (
          <span key={index}>
            <a href={link.jobberWebUri}>{link.displayText}</a>
            {index < linkArray.length - 1 ? (
              <>{formatMessage(messages.arrayCellSeparator)}</>
            ) : (
              <></>
            )}
          </span>
        ))}
    </div>
  );
}

export function InnerColumnCellFactory(
  type: InnerColumnCell,
  additionalConfigs?: AdditionalConfigType,
): ((columnInfo: ColumnInfo) => JSX.Element) | undefined {
  switch (type) {
    case "text":
      return function innerTextCell(columnInfo: ColumnInfo) {
        return <InnerColumnTextCell columnInfo={columnInfo} />;
      };
    case "currency":
      return function innerCurrencyCell(columnInfo: ColumnInfo) {
        return <InnerColumnCurrencyCell columnInfo={columnInfo} />;
      };
    case "link":
      return function innerLinkCell(columnInfo: ColumnInfo) {
        return <InnerColumnLinkCell columnInfo={columnInfo} />;
      };
    case "linkArray":
      return function innerLinkArrayCell(columnInfo: ColumnInfo) {
        return <InnerColumnLinkArrayCell columnInfo={columnInfo} />;
      };
    case "number":
      return function innerNumberCell(columnInfo: ColumnInfo) {
        return <InnerColumnNumberCell columnInfo={columnInfo} />;
      };
    case "status":
      return function innerStatusCell(columnInfo: ColumnInfo) {
        return (
          <InnerColumnStatusCell
            colorsConfig={additionalConfigs?.status.colors}
            statusConfig={additionalConfigs?.status.keys}
            columnInfo={columnInfo}
          />
        );
      };
    default:
      return;
  }
}
