import React from "react";
import { Disclosure } from "@jobber/components/Disclosure";
import { Content } from "@jobber/components/Content";
import styles from "./QuestionDropdown.module.css";

interface QuestionProps {
  question: string;
  answer: JSX.Element;
}

export function QuestionDropdown(props: QuestionProps) {
  const { question, answer } = props;

  return (
    <div className={styles.question}>
      <Disclosure title={question}>
        <div className={styles.answer}>
          <Content spacing={"small"}>{answer}</Content>
        </div>
      </Disclosure>
    </div>
  );
}
