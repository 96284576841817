// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Icon } from "@jobber/components/Icon";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import type { MessageFormat } from "jobber/setupWizard/types";
import styles from "../../SetupWizardForms.module.css";

interface BusinessPriorityCardsProp {
  topPriorityArray: { value: string; label: MessageFormat }[];
  handleSelect(newValue: string): void;
  defaultValue: string;
}

export function BusinessPriorityCards({
  topPriorityArray,
  handleSelect,
  defaultValue,
}: BusinessPriorityCardsProp) {
  const { formatMessage } = useIntl();
  const [topPriority, setTopPriority] = useState<string>(defaultValue);

  return (
    <div>
      {topPriorityArray.map((option, index) => {
        const selected = topPriority === option.value;

        return (
          <div key={index}>
            <button
              className={
                selected
                  ? `${styles.goalCard} ${styles.goalCardSelected}`
                  : styles.goalCard
              }
              type="button"
              key={index}
              value={option.value}
              onClick={() => {
                if (topPriority === option.value) {
                  setTopPriority("");
                  handleSelect("");
                } else {
                  setTopPriority(option.value);
                  handleSelect(option.value);
                }
              }}
            >
              <Typography fontWeight={selected ? "bold" : "regular"}>
                {formatMessage(option.label)}
              </Typography>
              <div className={styles.icon}>
                {selected && <Icon name="checkmark" />}
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
}
