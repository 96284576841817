import { gql } from "@apollo/client";

export const ACCOUNT_PLAN_INFO_PLANS_PAGE = gql`
  query AccountPlanInfoPlansPage {
    accountPlanInfo {
      planTier
      planIdentifier
      userCost
      userLimit
      additionalUsers
      billingPlatform
      allowUserLimitIncrease
      shouldSeeUpgradeInfo
      features {
        featureId
      }
      jobberPaymentsRate {
        centsPerCharge
        percent
      }
      mobileCardReaderRate {
        centsPerCharge
        percent
      }
    }
  }
`;

export const ACCOUNT_PROMOTION = gql`
  query AccountPromotion {
    accountPromotion {
      bannerInfo
      billingDialogText
      pricingPageAnnualDiscountLabel
      termsAndConditions
      trackingTag
    }
  }
`;

export const PLANS_AVAILABLE = gql`
  query PlansAvailable {
    plansAvailable {
      isRecommended
      planIdentifier
      planCostAnnual
      planCostAnnualDiscounted
      annualDiscountDuration
      planCostMonthly
      planCostMonthlyDiscounted
      monthlyDiscountDuration
      planTier
      userCost
      userLimit
      allowUserLimitIncrease
      features {
        featureId
      }
      jobberPaymentsRate {
        centsPerCharge
        percent
      }
      mobileCardReaderRate {
        centsPerCharge
        percent
      }
      monthlyBillingCycle {
        planCode
        monthlyPrice
        annualPrice
        isPurchasable
      }
      annualBillingCycle {
        planCode
        monthlyPrice
        annualPrice
        isPurchasable
      }
      annualSubscriptionDiscount {
        discountAmount
        discountType
        remainingDiscountedFullMonths
      }
      monthlySubscriptionDiscount {
        discountAmount
        discountType
        remainingDiscountedFullMonths
      }
      relativeGrade
      belugaPhaseOneSingleUserPlan
    }
  }
`;

export const ACCOUNT_INFO = gql`
  query AccountInfoPlansPage {
    account {
      billingInformation {
        planDisplayName
        planCode
        planPriceMonthly
        userCount
        billingCycleInterval
        allowInWebBillingChanges
        allowResubscribeCurrentPlan
      }
      setupWizard {
        companySize
      }
    }
  }
`;

export const SELF_SERVE_ELIGIBILITY = gql`
  query SelfServeEligibility {
    selfServeEligibility {
      selfServePerUserPricing {
        eligible
        reachedUserLimit
      }
    }
  }
`;

export const FEATURE_TRIAL_ELIGIBILITY = gql`
  query FeatureTrialEligibility {
    accountFeatureTrialEligibility {
      eligibleForLiteToCoreTrial
      eligibleForCoreToConnectTrial
    }
  }
`;

export const ACCOUNT_INDUSTRY_INFO = gql`
  query AccountIndustryInfo {
    account {
      industry
    }
  }
`;

export const PRICING_PAGE_ADDONS_INFO = gql`
  query PricingPageAddonsInfo {
    accountAddonsInfo {
      addons {
        name
        isActive
      }
    }
  }
`;

export const PRICING_PAGE_TRIAL_ADDONS_INFO = gql`
  query PricingPageTrialAddonsInfo($planIdentifier: String!) {
    accountAddonsInfo(planSetIdentifier: $planIdentifier) {
      addons {
        name
        isActive
      }
    }
  }
`;
