import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import ReviewsUpgradepageMobileWebImage from "@images/ReviewsUpgradepageMobileWebImage.png";
import ReviewsUpgradepageDesktopImage from "@images/ReviewsUpgradepageDesktopImage.png";
import { IntlProvider } from "@translations/IntlProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./ReviewsAddOnPrompt.module.css";
import { TrialPromptCard } from "../TrialPromptCard";

export function ReviewsAddOnPrompt() {
  useEffect(() => {
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: "reviews_ple",
      source: "upgrade_page",
    });
  }, []);

  const [ref, { width = 1024 }] = useResizeObserver<HTMLDivElement>();
  const { formatMessage: t } = useIntl();
  return (
    <IntlProvider>
      <div ref={ref} className={styles.reviewsAddOnPromptContainer}>
        <TrialPromptCard
          trialTitle={t(messages.reviewsAddOnPromptTitle)}
          trialDescription={t(messages.reviewsAddOnPromptDescription)}
          ctaLabel={t(messages.reviewsAddOnPromptCtaLabel)}
          ctaCallBack={navigateToLandingPage}
          imgSource={
            width > Breakpoints.small
              ? ReviewsUpgradepageDesktopImage
              : ReviewsUpgradepageMobileWebImage
          }
          imgAlt={t(messages.reviewsAddOnPromptimgAlt)}
          showNewLabel={true}
        />
      </div>
    </IntlProvider>
  );

  function navigateToLandingPage() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "learn_more",
      source: "reviews_upgrade_page_ple",
    });
    window.location.href = "/reviews";
  }
}
