import { InputNumber } from "@jobber/components/InputNumber";
import React, { type ReactElement } from "react";
import { Draggable, type DraggableProvided } from "react-beautiful-dnd";
import { Icon } from "@jobber/components/Icon";
import { Button } from "@jobber/components/Button";
import { InputCurrency } from "components/InputCurrency";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { ProductOrServiceOptionsFragment } from "~/utilities/API/graphql";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import type { LineItem } from "~/jobber/lineItems/types";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import styles from "./LineItemInput.module.css";
import { LineItemName } from "../LineItemName";

export interface LineItemInputProps {
  index: number;
  initialOptions: ProductOrServiceOptionsFragment[];
  lineItem: LineItem;
  dispatch: React.Dispatch<LineItemsAction>;
  showCosting?: boolean;
  onDelete(lineItem: LineItem): Promise<void>;
  shouldRenderSaveButton: boolean;
  onSaveLineItem(newLineItemState: LineItem): Promise<void>;
  shouldAutoFocus: boolean;
}

export function LineItemInput({
  initialOptions,
  lineItem,
  dispatch,
  showCosting,
  onDelete,
  shouldRenderSaveButton,
  shouldAutoFocus,
  onSaveLineItem,
}: LineItemInputProps) {
  const { currencySymbol } = useAccount();

  const [deleting, setDeleting] = React.useState(false);
  const handleDeleteClick = async () => {
    setDeleting(true);
    await onDelete(lineItem);
    setDeleting(false);
  };

  const [saving, setSaving] = React.useState(false);
  const handleSaveClick = async () => {
    setSaving(true);
    await onSaveLineItem(lineItem);
    setSaving(false);
  };

  const { quantity, unitCost, unitPrice, totalPrice, description } = lineItem;

  return (
    <div className={styles.lineItemContainer}>
      <div className={styles.inputFieldsContainer}>
        <div className={styles.wrapper}>
          <div className={styles.firstColumn}>
            <LineItemName
              initialOptions={initialOptions}
              lineItem={lineItem}
              onChange={dispatch}
              currencySymbol={currencySymbol}
              shouldAutoFocus={shouldAutoFocus}
            />
          </div>
          <div className={styles.secondColumn}>
            <div>
              <InputNumber
                name={`lineItems.${lineItem.reactKey}.quantity`}
                value={quantity}
                placeholder="Quantity"
                autocomplete={false}
                onChange={(value: number) => {
                  dispatch({
                    type: LineItemsBulkEditActionTypes.UpdateLineItem,
                    reactKey: lineItem.reactKey,
                    field: "quantity",
                    value,
                  });
                }}
                onBlur={() => {
                  const formattedValue = Number(quantity.toFixed(2));
                  dispatch({
                    type: LineItemsBulkEditActionTypes.UpdateLineItem,
                    reactKey: lineItem.reactKey,
                    field: "quantity",
                    value: formattedValue,
                  });
                }}
                validations={{
                  min: {
                    message: "Quantity must be more than -10 million",
                    value: -9999999,
                  },
                  max: {
                    message: "Quantity must be less than 10 million",
                    value: 9999999,
                  },
                }}
              />
            </div>
            {showCosting && (
              <div>
                <InputCurrency
                  name={`lineItems.${lineItem.reactKey}.unit_cost`}
                  placeholder="Unit cost"
                  value={unitCost}
                  prefix={{ label: currencySymbol }}
                  autocomplete={false}
                  onChange={(value: number) =>
                    dispatch({
                      type: LineItemsBulkEditActionTypes.UpdateLineItem,
                      reactKey: lineItem.reactKey,
                      field: "unitCost",
                      value,
                    })
                  }
                  validations={{
                    min: {
                      message: "Unit cost must be more than -10 billion",
                      value: -9999999999,
                    },
                    max: {
                      message: "Unit cost must be less than 10 billion",
                      value: 9999999999,
                    },
                  }}
                />
              </div>
            )}
            <div>
              <InputCurrency
                name={`lineItems.${lineItem.reactKey}.unit_price`}
                value={unitPrice}
                prefix={{ label: currencySymbol }}
                placeholder="Unit price"
                autocomplete={false}
                onChange={(value: number) =>
                  dispatch({
                    type: LineItemsBulkEditActionTypes.UpdateLineItem,
                    reactKey: lineItem.reactKey,
                    field: "unitPrice",
                    value,
                  })
                }
                validations={{
                  min: {
                    message: "Unit price must be more than -10 billion",
                    value: -9999999999,
                  },
                  max: {
                    message: "Unit price must be less than 10 billion",
                    value: 9999999999,
                  },
                }}
              />
            </div>
            <div>
              <InputCurrency
                name={`lineItems.${lineItem.reactKey}.total`}
                value={totalPrice}
                prefix={{ label: currencySymbol }}
                placeholder="Total"
                autocomplete={false}
                maximumDecimalPlaces={2}
                onChange={(value: number) =>
                  dispatch({
                    type: LineItemsBulkEditActionTypes.UpdateLineItem,
                    reactKey: lineItem.reactKey,
                    field: "totalPrice",
                    value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <MagicInputText
          name={`lineItems.${lineItem.reactKey}.description`}
          placeholder="Description"
          multiline
          rows={{
            min: 3,
            max: 50,
          }}
          autocomplete={false}
          onChange={(value: string) =>
            dispatch({
              type: LineItemsBulkEditActionTypes.UpdateLineItem,
              reactKey: lineItem.reactKey,
              field: "description",
              value,
            })
          }
          value={description}
        />
      </div>
      <div className={styles.buttonGroupContainer}>
        <Button
          label={"Delete"}
          variation={"destructive"}
          type={"tertiary"}
          ariaLabel="Delete"
          size="small"
          onClick={handleDeleteClick}
          loading={deleting}
        />
        {shouldRenderSaveButton && (
          <Button
            ariaLabel="Save"
            label={"Save"}
            icon="checkmark"
            size="small"
            onClick={handleSaveClick}
            loading={saving}
          />
        )}
      </div>
    </div>
  );
}

export interface DraggableLineItemInputWrapperProps {
  lineItemIndex: number;
  lineItemState: LineItem;
  children: ReactElement | ReactElement[];
}

export function DraggableLineItemInputWrapper({
  lineItemIndex,
  lineItemState,
  children,
}: DraggableLineItemInputWrapperProps) {
  return (
    <Draggable
      key={lineItemState.reactKey}
      draggableId={lineItemState.reactKey}
      index={lineItemIndex}
    >
      {(providedDraggable: DraggableProvided) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          <div className={styles.draggableLineItemContainer}>
            <div
              className={styles.dragIconContainer}
              {...providedDraggable.dragHandleProps}
            >
              <Icon name="drag" />
            </div>
            {children}
          </div>
        </div>
      )}
    </Draggable>
  );
}
